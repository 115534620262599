<template>
  <div class="sidebar-left">
    <div class="sidebar">
      <div class="sidebar-content email-app-sidebar">
        <div
          :class="{'email-app-menu': true }"
        >
          <div class="form-group-compose text-center compose-btn">
            <div v-if="formModeView===false">
              <div v-if="![2, 5, 9, 19, 13].includes(Number(currentUser.id_role))">
                <div v-if="typeof vars.sw012.sw012s02 !== 'undefined' && vars.sw012.sw012s02 !== null && vars.sw012.sw012s02 !== '' && typeof vars.sw012.sw012s64 !== 'undefined' && vars.sw012.sw012s64 !== null && (typeof vars.sw012.sw012s70 === 'undefined' || vars.sw012.sw012s70 === null || vars.sw012.sw012s70 === '0')">
                  <b-button
                    v-if="typeof vars.sw012.sw012s379 === 'undefined' || vars.sw012.sw012s379 === null || vars.sw012.sw012s379 !== '1'"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="outline-info"
                    block
                    @click.stop.prevent="saveForm('save_submit_version'); $emit('close-left-sidebar');"
                  >
                    <feather-icon
                      icon="SaveIcon"
                      class="mr-50"
                    />
                    <span class="align-middle">{{ $t('Guardar edição') }}</span>
                  </b-button>
                  <b-button
                    v-if="typeof vars.sw012.sw012s379 !== 'undefined' && vars.sw012.sw012s379 !== null && vars.sw012.sw012s379 === '1'"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    block
                    @click.stop.prevent="saveForm('save_form'); $emit('close-left-sidebar');"
                  >
                    <feather-icon
                      icon="SendIcon"
                      class="mr-50"
                    />
                    <span class="align-middle">{{ $t('Guardar') }}</span>
                  </b-button>
                  <b-button
                    v-if="showButtonSubmit === true && (typeof currentUser.extras !== 'undefined' && (currentUser.extras.basic === 1 || Object.values(currentUser.extras.perm).includes('add_ang_S')))"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    block
                    @click.stop.prevent="saveForm('save_submit'); $emit('close-left-sidebar');"
                  >
                    <feather-icon
                      icon="SendIcon"
                      class="mr-50"
                    />
                    <span class="align-middle">{{ $t('Submeter') }}</span>
                  </b-button>
                </div>
                <div v-else>
                  <b-button
                    v-if="(typeof vars.sw012.sw012s64 !== 'undefined' && vars.sw012.sw012s64 !== null && vars.sw012.sw012s64 === '9') || (Number(load_data_geral.formPreAngariacao) === 1)"
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-info"
                    block
                    @click.stop.prevent="saveForm('save_form'); $emit('close-left-sidebar');"
                  >
                    <feather-icon
                      icon="SaveIcon"
                      class="mr-50"
                    />
                    <span class="align-middle">{{ $t('Guardar pré-angariação') }}</span>
                  </b-button>
                  <b-button
                    v-if="(typeof vars.sw012.sw012s64 === 'undefined' || vars.sw012.sw012s64 === null || Number(vars.sw012.sw012s64) !== 9) && Number(load_data_geral.formPreAngariacao) !== 1"
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-info"
                    block
                    @click.stop.prevent="saveForm('save_form'); $emit('close-left-sidebar');"
                  >
                    <feather-icon
                      icon="SaveIcon"
                      class="mr-50"
                    />
                    <span class="align-middle">{{ $t('Guardar Rascunho') }}</span>
                  </b-button>
                  <b-button
                    v-if="showButtonSubmit === true && ((typeof vars.sw012.sw012s03 !== 'undefined' && vars.sw012.sw012s03 !== null) || (load_data_geral.formPreAngariacao !== 1)) && ((typeof currentUser.extras !== 'undefined' && currentUser.extras.basic === 1) || (typeof currentUser.extras !== 'undefined' && Object.values(currentUser.extras.perm).includes('add_ang_S')))"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    block
                    @click.stop.prevent="saveForm('save_submit'); $emit('close-left-sidebar');"
                  >
                    <feather-icon
                      icon="SendIcon"
                      class="mr-50"
                    />
                    <span class="align-middle">{{ $t('Submeter') }}</span>
                  </b-button>
                </div>
              </div>
              <div v-else>
                <b-button
                  v-if="typeof vars.sw012.sw012s02 !== 'undefined' && vars.sw012.sw012s02 !== null && vars.sw012.sw012s02 !== '' && typeof vars.sw012.sw012s64 !== 'undefined' && vars.sw012.sw012s64 !== null && vars.sw012.sw012s64 !== ''
                    && (typeof vars.sw012.sw012s70 === 'undefined' || vars.sw012.sw012s70 === null || Number(vars.sw012.sw012s70) === 0) && (typeof vars.sw012.sw012s379 === 'undefined' || vars.sw012.sw012s379 === null || Number(vars.sw012.sw012s379) !== 1)"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="outline-info"
                  block
                  @click.stop.prevent="saveForm('save_submit_version'); $emit('close-left-sidebar');"
                >
                  <feather-icon
                    icon="SaveIcon"
                    class="mr-50"
                  />
                  <span class="align-middle">{{ $t('Guardar edição') }}</span>
                </b-button>
                <b-button
                  v-if="typeof vars.sw012.sw012s379 !== 'undefined' && vars.sw012.sw012s379 !== null && vars.sw012.sw012s379 === '1'"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  block
                  @click.stop.prevent="saveForm('save_form'); $emit('close-left-sidebar');"
                >
                  <feather-icon
                    icon="SendIcon"
                    class="mr-50"
                  />
                  <span class="align-middle">{{ $t('Guardar') }}</span>
                </b-button>
                <b-button
                  v-else
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  block
                  @click.stop.prevent="saveForm('save_submit'); $emit('close-left-sidebar');"
                >
                  <feather-icon
                    icon="SendIcon"
                    class="mr-50"
                  />
                  <span class="align-middle">{{ $t('Guardar') }}</span>
                </b-button>
              </div>
            </div>
            <div v-else>
              <div v-if="typeof vars.sw012.sw012s379 === 'undefined' || vars.sw012.sw012s379 === null || vars.sw012.sw012s379 !== '1'">
                <b-button
                  v-if="Number(vars.sw012.sw012s64) === 9 && showButtonPlusAction > 0 && showEditButton === true && ((((typeof vars.sw012.sw012s64 !== 'undefined' && vars.sw012.sw012s64 !== null && [2, 3, 7].includes(Number(vars.sw012.sw012s64)))
                    || (typeof vars.sw012.sw012s330 !== 'undefined' && vars.sw012.sw012s330 !== null && [2, 3, 7].includes(Number(vars.sw012.sw012s330)))) && [5, 9, 10, 13].includes(Number(currentUser.id_role)))
                    || ((typeof vars.sw012.sw012s64 !== 'undefined' && vars.sw012.sw012s64 !== null && ![2, 3, 7].includes(Number(vars.sw012.sw012s64)))
                      && (typeof vars.sw012.sw012s330 === 'undefined' || vars.sw012.sw012s330 === null || ![2, 3, 7].includes(Number(vars.sw012.sw012s330)))))"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  block
                  @click.stop.prevent="actionForm('edit_reg'); $emit('close-left-sidebar');"
                >
                  <feather-icon
                    icon="Edit2Icon"
                    class="mr-50"
                  />
                  <span class="align-middle">{{ $t('Editar') }}</span>
                </b-button>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="outline-info"
                  block
                  @click.stop.prevent="actionForm('back_page'); $emit('close-left-sidebar');"
                >
                  <feather-icon
                    icon="ArrowLeftIcon"
                    class="mr-50"
                  />
                  <span class="align-middle">{{ $t('Voltar') }}</span>
                </b-button>
              </div>
              <div v-else>
                <b-button
                  v-if="Number(vars.sw012.sw012s64) === 9 && showEditButton === true && (typeof vars.sw012.sw012s64 !== 'undefined' && vars.sw012.sw012s64 !== null && ![2, 3, 7].includes(Number(vars.sw012.sw012s64)))
                    && (typeof vars.sw012.sw012s330 === 'undefined' && vars.sw012.sw012s330 === null || ![2, 3, 7].includes(Number(vars.sw012.sw012s330)))"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  block
                  @click.stop.prevent="actionForm('edit_reg'); $emit('close-left-sidebar');"
                >
                  <feather-icon
                    icon="Edit2Icon"
                    class="mr-50"
                  />
                  <span class="align-middle">{{ $t('Editar') }}</span>
                </b-button>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="outline-info"
                  block
                  @click.stop.prevent="actionForm('back_page'); $emit('close-left-sidebar');"
                >
                  <feather-icon
                    icon="ArrowLeftIcon"
                    class="mr-50"
                  />
                  <span class="align-middle">{{ $t('Voltar') }}</span>
                </b-button>
              </div>
            </div>
          </div>

          <vue-perfect-scrollbar
            class="sidebar-menu-list scroll-area"
            :settings="configSettingsScroll"
          >
            <!-- Botoes acoes secundarias -->
            <b-list-group>

              <b-list-group-item
                @click="$emit('close-left-sidebar')"
              >
                <div v-if="formModeView === true && (typeof vars.sw012.sw012s379 === 'undefined' || vars.sw012.sw012s379 === null || Number(vars.sw012.sw012s379) !== 1)">
                  <b-button
                    v-if="typeof vars.sw012.rating !== 'undefined' && (([1, 8, 14].includes(Number(currentUser.id_role))) || (([3, 5, 6, 9, 10, 11, 12, 13].includes(Number(currentUser.id_role)) || (typeof vars.sw012.sw012s04 !== 'undefined' && vars.sw012.sw012s04 !== null && (Number(baseDecode(currentUser.id)) === Number(vars.sw012.sw012s04) || (typeof currentUser.extras !== 'undefined' && Number(currentUser.extras.idLeader) === Number(vars.sw012.sw012s04))))) && load_data_geral.idVersionRegViewer === ''))"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    :variant="typeof vars.sw012.rating !== 'undefined' && vars.sw012.rating.btn_color === 'green' ? 'outline-success' : typeof vars.sw012.rating !== 'undefined' && vars.sw012.rating.btn_color === 'outline-orange' ? 'warning' : 'outline-danger'"
                    block
                    @click.stop.prevent="getModalRatingListing(keyReg, false); $emit('close-left-sidebar');"
                  >
                    <feather-icon
                      :icon="typeof vars.sw012.rating !== 'undefined' && vars.sw012.rating.btn_color === 'green' ? 'SmileIcon' : typeof vars.sw012.rating !== 'undefined' && vars.sw012.rating.btn_color === 'orange' ? 'MehIcon' : 'FrownIcon'"
                      class="mr-50"
                    />
                    <span class="align-middle">{{ vars.sw012.rating.percentagem }}%</span>
                  </b-button>
                  <b-button
                    v-if="load_data_geral.showVersionPending === '' && (typeof vars.sw012.sw012s64 !== 'undefined' && vars.sw012.sw012s64 !== null && ![5, 9].includes(Number(vars.sw012.sw012s64))) && load_data_geral.idVersionRegViewer === ''"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    :variant="colorButton"
                    block
                    @click.stop.prevent="getModalPortaisListing(); $emit('close-left-sidebar');"
                  >
                    <feather-icon
                      icon="GlobeIcon"
                      class="mr-50"
                    />
                    <span class="align-middle">{{ $t('Portais') }}</span>
                  </b-button>
                  <b-button
                    v-if="(typeof vars.sw012.sw012s64 !== 'undefined' && vars.sw012.sw012s64 !== null && [1, 2, 3, 4, 7].includes(Number(vars.sw012.sw012s64))) && appConfig.bUseBrochurasAngariacoes === true
                      && load_data_geral.idVersionRegViewer === '' && showButtonBrochura === true"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="outline-info"
                    block
                  >
                    <feather-icon
                      icon="DownloadIcon"
                      class="mr-50"
                    />
                    <span class="align-middle">{{ $t('Brochura') }}</span>
                  </b-button>
                  <b-button
                    v-if="formModeView === true && [5, 9, 10, 13].includes(Number(currentUser.id_role)) && Number(load_data_geral.aprovaListing) === 1 && (typeof vars.sw012.sw012s261 === 'undefined' || vars.sw012.sw012s261 === null || vars.sw012.sw012s261 === '')"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="outline-danger"
                    block
                    @click.stop.prevent="actionForm('recusar_list'); $emit('close-left-sidebar');"
                  >
                    <feather-icon
                      icon="XIcon"
                      class="mr-50"
                    />
                    <span class="align-middle">{{ $t('Recusar') }}</span>
                  </b-button>
                  <b-button
                    v-if="formModeView === true && [5, 9, 10, 13].includes(Number(currentUser.id_role)) && Number(load_data_geral.aprovaListing) === 1 && (typeof vars.sw012.sw012s261 === 'undefined' || vars.sw012.sw012s261 === null || vars.sw012.sw012s261 === '')"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="outline-success"
                    block
                    @click.stop.prevent="actionForm('aprovar_list'); $emit('close-left-sidebar');"
                  >
                    <feather-icon
                      icon="CheckIcon"
                      class="mr-50"
                    />
                    <span class="align-middle">{{ $t('Aprovar') }}</span>
                  </b-button>
                  <b-button
                    v-if="formModeView === true && appConfig.bUseEstudoMercado === true && load_data_geral.temEstudoMercado === 0 && load_data_geral.idVersionRegViewer === ''
                      && (typeof vars.sw012.sw012s261 === 'undefined' || vars.sw012.sw012s261 === null || Number(vars.sw012.sw012s261) !== 2) && showButtonEM === true"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="outline-info"
                    block
                  >
                    <span class="align-middle">{{ $t('Iniciar estudo de mercado') }}</span>
                  </b-button>
                  <b-button
                    v-if="formModeView === true && appConfig.bUseEstudoMercadoAnalise === true && load_data_geral.temEstudoMercado === 0 && load_data_geral.idVersionRegViewer === ''"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="outline-info"
                    block
                    @click.stop.prevent="openViewMarketResearch(keyReg)"
                  >
                    <span class="align-middle">{{ $t('Iniciar estudo de mercado') }}</span>
                  </b-button>
                  <b-button
                    v-if="formModeView === true && appConfig.bUseEstudoMercado === true && load_data_geral.temEstudoMercado === 1 && load_data_geral.idVersionRegViewer === '' && showButtonEM === true"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="outline-info"
                    block
                  >
                    <feather-icon
                      icon="GlobeIcon"
                      class="mr-50"
                    />
                    <span class="align-middle">{{ $t('Abrir estudo de mercado') }}</span>
                  </b-button>
                  <b-button
                    v-if="[5, 9, 10, 13].includes(Number(currentUser.id_role)) && Number(load_data_geral.showVersionReg) !== 1 && (typeof vars.sw012.sw012s261 === 'undefined' || vars.sw012.sw012s261 === null || vars.sw012.sw012s261 === '')
                      && Number(vars.sw012.sw012s72) === Number(currentUser.idHUB) && (typeof vars.sw012.sw012s64 !== 'undefined' && vars.sw012.sw012s64 !== null && [1, 5, 8, 9].includes(Number(vars.sw012.sw012s64)))"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    :variant="Number(vars.sw012.sw012s329) === 1 ? 'outline-info' : 'outline-danger'"
                    block
                    @click="Number(vars.sw012.sw012s329) === 1 ? actionForm('reativar_contrato') : actionForm('contrato_cancelado')"
                  >
                    <feather-icon
                      :icon="Number(vars.sw012.sw012s329) === 1 ? 'PlusSquareIcon' : 'MinusSquareIcon'"
                      class="mr-50"
                    />
                    <span
                      v-if="Number(vars.sw012.sw012s329) === 1"
                      class="align-middle"
                    >
                      {{ $t('Reativar contrato') }}
                    </span>
                    <span
                      v-else
                      class="align-middle"
                    >
                      {{ $t('Contrato cancelado') }}
                    </span>
                  </b-button>
                  <b-button
                    v-if="showSuspButton === true"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="outline-danger"
                    block
                    @click="suspenderListing"
                  >
                    <span class="align-middle">{{ $t('Suspender') }}</span>
                  </b-button>
                  <b-button
                    v-if="[5, 9, 10, 13].includes(Number(currentUser.id_role)) && (typeof vars.sw012.sw012s261 === 'undefined' || vars.sw012.sw012s261 === null || vars.sw012.sw012s261 === '')
                      && Number(vars.sw012.sw012s72) === Number(currentUser.idHUB) && Number(load_data_geral.editListingHM) === 1"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="outline-info"
                    block
                    @click="deleteListing('')"
                  >
                    <feather-icon
                      icon="XIcon"
                      class="mr-50"
                    />
                    <span class="align-middle">{{ $t('Eliminar') }}</span>
                  </b-button>
                  <b-button
                    v-if="![1, 2, 5, 6, 8, 14, 9, 10, 13, 11, 12].includes(Number(currentUser.id_role)) && (typeof vars.sw012.sw012s261 === 'undefined' || vars.sw012.sw012s261 === null || vars.sw012.sw012s261 === '')
                      && (Number(vars.sw012.sw012s64) === 1 || Number(vars.sw012.sw012s330) === 1) && ((typeof currentUser.extras !== 'undefined' && currentUser.extras.basic === 1) || (typeof currentUser.extras !== 'undefined' && Object.values(currentUser.extras.perm).includes('pode_marcar_visitas_leads'))) && $can('new', 'crm_visitas')"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="outline-info"
                    block
                    @click="createNewVisit"
                  >
                    <span class="align-middle">{{ $t('Nova visita') }}</span>
                  </b-button>
                  <b-button
                    v-if="((Number(vars.sw012.sw012s04) === Number(baseDecode(currentUser.id))) || (typeof currentUser.extras !== 'undefined' && Number(currentUser.extras.idLeader) === Number(vars.sw012.sw012s04))) && Number(vars.sw012.sw012s64) === 1
                      && (typeof vars.sw012.sw012s372 && vars.sw012.sw012s372 !== null && Number(vars.sw012.sw012s372) === 2) && (typeof vars.sw012.sw012s261 === 'undefined' || vars.sw012.sw012s261 === null || vars.sw012.sw012s261 === '')"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="outline-info"
                    block
                  >
                    <feather-icon
                      :icon="(typeof vars.sw012.sw012s261 === 'undefined' || vars.sw012.sw012s261 === null || vars.sw012.sw012s261 === '') ? 'PlusCircleIcon' : 'EyeIcon'"
                      class="mr-50"
                    />
                    <span
                      v-if="(typeof vars.linkInterno === 'undefined' || vars.linkInterno === null || vars.linkInterno === '')"
                      class="align-middle"
                    >{{ $t('Gerar link interno') }}</span>
                    <span
                      v-else
                      class="align-middle"
                    >{{ $t('Ver link interno') }}</span>
                  </b-button>
                  <b-button
                    v-if="[5, 9, 10, 13].includes(Number(currentUser.id_role)) && Number(vars.sw012.sw012s72) === Number(currentUser.idHUB)
                      && (typeof vars.sw012.sw012s64 !== 'undefined' && vars.sw012.sw012s64 !== null && Number(vars.sw012.sw012s64) === 1)"
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-danger"
                    block
                    @click.stop.prevent="changeAgent(); $emit('close-left-sidebar')"
                  >
                    <span class="align-middle">{{ $t('Transferir para outro consultor') }}</span>
                  </b-button>
                </div>
                <b-button
                  v-if="formModeView === false"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-danger"
                  block
                  @click.stop.prevent="cancelForm(); $emit('close-left-sidebar')"
                >
                  <feather-icon
                    icon="XCircleIcon"
                    class="mr-50"
                  />
                  <span class="align-middle">{{ $t('Cancelar') }}</span>
                </b-button>
              </b-list-group-item>

            </b-list-group>

            <!-- Tabs de edição -->
            <h6
              class="section-label mt-3 mb-1 px-2"
            >
              {{ $t('Informação') }}
            </h6>
            <b-list-group class="list-group-messages">
              <b-list-group-item
                :class="{'cursor-pointer': true, 'active' : ((tabContentStepShow === 0) ? true : false) }"
                @click.stop.prevent="tabContentStepShow=0; $emit('close-left-sidebar')"
              >
                <feather-icon
                  icon="UserIcon"
                  :size="sizeIconsAction"
                  class="mr-75"
                />
                <span class="align-text-bottom line-height-1">{{ $t('Geral') }}</span>
              </b-list-group-item>
              <b-list-group-item
                :class="{'cursor-pointer': true, 'active' : ((tabContentStepShow === 1) ? true : false) }"
                @click.stop.prevent="tabContentStepShow=1; $emit('close-left-sidebar')"
              >
                <feather-icon
                  icon="BookOpenIcon"
                  :size="sizeIconsAction"
                  class="mr-75"
                />
                <span class="align-text-bottom line-height-1">{{ $t('Dados') }}</span>
              </b-list-group-item>
              <b-list-group-item
                :class="{'cursor-pointer': true, 'active' : ((tabContentStepShow === 2) ? true : false) }"
                @click.stop.prevent="tabContentStepShow=2;$emit('close-left-sidebar')"
              >
                <feather-icon
                  icon="MapPinIcon"
                  :size="sizeIconsAction"
                  class="mr-75"
                />
                <span class="align-text-bottom line-height-1">{{ $t('Localização') }}</span>
              </b-list-group-item>
              <b-list-group-item
                :class="{'cursor-pointer': true, 'active' : ((tabContentStepShow === 3) ? true : false) }"
                @click.stop.prevent="tabContentStepShow=3;$emit('close-left-sidebar')"
              >
                <feather-icon
                  icon="TagIcon"
                  :size="sizeIconsAction"
                  class="mr-75"
                />
                <span class="align-text-bottom line-height-1">{{ $t('Descritivo') }}</span>
              </b-list-group-item>
              <b-list-group-item
                :class="{'cursor-pointer': true, 'active' : ((tabContentStepShow === 4) ? true : false) }"
                @click.stop.prevent="tabContentStepShow=4;$emit('close-left-sidebar')"
              >
                <feather-icon
                  icon="SlidersIcon"
                  :size="sizeIconsAction"
                  class="mr-75"
                />
                <span class="align-text-bottom line-height-1">{{ $t('Atributos') }}</span>
              </b-list-group-item>
              <b-list-group-item
                :class="{'cursor-pointer': true, 'active' : ((tabContentStepShow === 5) ? true : false) }"
                @click.stop.prevent="tabContentStepShow=5;$emit('close-left-sidebar')"
              >
                <feather-icon
                  icon="UsersIcon"
                  :size="sizeIconsAction"
                  class="mr-75"
                />
                <span class="align-text-bottom line-height-1">{{ $t('Dados legais') }}</span>
              </b-list-group-item>
            </b-list-group>

            <h6
              class="section-label mt-2 mb-1 px-2"
            >
              {{ $t('Media') }}
            </h6>
            <b-list-group class="list-group-messages">
              <b-list-group-item
                :class="{'cursor-pointer': true, 'active' : ((tabContentStepShow === 6) ? true : false) }"
                @click.stop.prevent="tabContentStepShow=6; $emit('close-left-sidebar')"
              >
                <feather-icon
                  icon="CameraIcon"
                  :size="sizeIconsAction"
                  class="mr-75"
                />
                <span class="align-text-bottom line-height-1">{{ $t('Fotografias') }}</span>
              </b-list-group-item>
              <b-list-group-item
                :class="{'cursor-pointer': true, 'active' : ((tabContentStepShow === 7) ? true : false) }"
                @click.stop.prevent="tabContentStepShow=7;$emit('close-left-sidebar')"
              >
                <feather-icon
                  icon="VideoIcon"
                  :size="sizeIconsAction"
                  class="mr-75"
                />
                <span class="align-text-bottom line-height-1">{{ $t('Vídeo') }}</span>
              </b-list-group-item>
              <b-list-group-item
                :class="{'cursor-pointer': true, 'active' : ((tabContentStepShow === 8) ? true : false) }"
                @click.stop.prevent="tabContentStepShow=8;$emit('close-left-sidebar')"
              >
                <feather-icon
                  icon="EyeIcon"
                  :size="sizeIconsAction"
                  class="mr-75"
                />
                <span class="align-text-bottom line-height-1">{{ $t('Realidade virtual') }}</span>
              </b-list-group-item>
              <b-list-group-item
                :class="{'cursor-pointer': true, 'active' : ((tabContentStepShow === 9) ? true : false) }"
                @click.stop.prevent="tabContentStepShow=9;$emit('close-left-sidebar')"
              >
                <feather-icon
                  icon="FileTextIcon"
                  :size="sizeIconsAction"
                  class="mr-75"
                />
                <span class="align-text-bottom line-height-1">{{ $t('Documentos para a rede') }}</span>
              </b-list-group-item>
            </b-list-group>

            <h6
              class="section-label mt-2 mb-1 px-2"
            >
              {{ $t('Privado') }}
            </h6>
            <b-list-group class="list-group-messages">
              <b-list-group-item
                :class="{'cursor-pointer': true, 'active' : ((tabContentStepShow === 10) ? true : false) }"
                @click.stop.prevent="tabContentStepShow=10; $emit('close-left-sidebar')"
              >
                <feather-icon
                  icon="FileTextIcon"
                  :size="sizeIconsAction"
                  class="mr-75"
                />
                <span class="align-text-bottom line-height-1">{{ $t('Documentos privados') }}</span>
              </b-list-group-item>
              <b-list-group-item
                :class="{'cursor-pointer': true, 'active' : ((tabContentStepShow === 11) ? true : false) }"
                @click.stop.prevent="tabContentStepShow=11;$emit('close-left-sidebar')"
              >
                <feather-icon
                  icon="TagIcon"
                  :size="sizeIconsAction"
                  class="mr-75"
                />
                <span class="align-text-bottom line-height-1">{{ $t('Notas pessoais privados') }}</span>
              </b-list-group-item>
              <b-list-group-item
                :class="{'cursor-pointer': true, 'active' : ((tabContentStepShow === 12) ? true : false) }"
                @click.stop.prevent="tabContentStepShow=12;$emit('close-left-sidebar')"
              >
                <feather-icon
                  icon="TagIcon"
                  :size="sizeIconsAction"
                  class="mr-75"
                />
                <span class="align-text-bottom line-height-1">{{ $t('Notas para a rede') }}</span>
              </b-list-group-item>
              <b-list-group-item
                :class="{'cursor-pointer': true, 'active' : ((tabContentStepShow === 13) ? true : false) }"
                @click.stop.prevent="tabContentStepShow=13;$emit('close-left-sidebar')"
              >
                <feather-icon
                  icon="CameraIcon"
                  :size="sizeIconsAction"
                  class="mr-75"
                />
                <span class="align-text-bottom line-height-1">{{ $t('Fotografias') }}</span>
              </b-list-group-item>
            </b-list-group>

            <h6
              class="section-label mt-2 mb-1 px-2"
            >
              {{ $t('Atividade') }}
            </h6>
            <b-list-group class="list-group-messages">
              <b-list-group-item
                :class="{'cursor-pointer': true, 'active' : ((tabContentStepShow === 14) ? true : false) }"
                @click.stop.prevent="tabContentStepShow=14; $emit('close-left-sidebar')"
              >
                <feather-icon
                  icon="RewindIcon"
                  :size="sizeIconsAction"
                  class="mr-75"
                />
                <span class="align-text-bottom line-height-1">{{ $t('Controlo de versões') }}</span>
              </b-list-group-item>
              <b-list-group-item
                :class="{'cursor-pointer': true, 'active' : ((tabContentStepShow === 15) ? true : false) }"
                @click.stop.prevent="tabContentStepShow=15; $emit('close-left-sidebar')"
              >
                <feather-icon
                  icon="HashIcon"
                  :size="sizeIconsAction"
                  class="mr-75"
                />
                <span class="align-text-bottom line-height-1">{{ $t('Movimento de angariações') }}</span>
              </b-list-group-item>
              <b-list-group-item
                :class="{'cursor-pointer': true, 'active' : ((tabContentStepShow === 16) ? true : false) }"
                @click.stop.prevent="tabContentStepShow=16; $emit('close-left-sidebar')"
              >
                <feather-icon
                  icon="HashIcon"
                  :size="sizeIconsAction"
                  class="mr-75"
                />
                <span class="align-text-bottom line-height-1">{{ $t('Movimento de estados') }}</span>
              </b-list-group-item>
              <b-list-group-item
                :class="{'cursor-pointer': true, 'active' : ((tabContentStepShow === 17) ? true : false) }"
                @click.stop.prevent="tabContentStepShow=17; $emit('close-left-sidebar')"
              >
                <feather-icon
                  icon="HashIcon"
                  :size="sizeIconsAction"
                  class="mr-75"
                />
                <span class="align-text-bottom line-height-1">{{ $t('Motivos de recusa') }}</span>
              </b-list-group-item>
              <b-list-group-item
                :class="{'cursor-pointer': true, 'active' : ((tabContentStepShow === 18) ? true : false) }"
                @click.stop.prevent="tabContentStepShow=18; $emit('close-left-sidebar')"
              >
                <feather-icon
                  icon="EditIcon"
                  :size="sizeIconsAction"
                  class="mr-75"
                />
                <span class="align-text-bottom line-height-1">{{ $t('Contratos') }}</span>
              </b-list-group-item>
              <b-list-group-item
                :class="{'cursor-pointer': true, 'active' : ((tabContentStepShow === 19) ? true : false) }"
                @click.stop.prevent="tabContentStepShow=19; $emit('close-left-sidebar')"
              >
                <feather-icon
                  icon="ActivityIcon"
                  :size="sizeIconsAction"
                  class="mr-75"
                />
                <span class="align-text-bottom line-height-1">{{ $t('Atividades') }}</span>
              </b-list-group-item>
              <b-list-group-item
                :class="{'cursor-pointer': true, 'active' : ((tabContentStepShow === 20) ? true : false) }"
                @click.stop.prevent="tabContentStepShow=20; $emit('close-left-sidebar')"
              >
                <feather-icon
                  icon="UsersIcon"
                  :size="sizeIconsAction"
                  class="mr-75"
                />
                <span class="align-text-bottom line-height-1">{{ $t('Leads interessadas') }}</span>
              </b-list-group-item>
              <b-list-group-item
                :class="{'cursor-pointer': true, 'active' : ((tabContentStepShow === 21) ? true : false) }"
                @click.stop.prevent="tabContentStepShow=21; $emit('close-left-sidebar')"
              >
                <feather-icon
                  icon="NavigationIcon"
                  :size="sizeIconsAction"
                  class="mr-75"
                />
                <span class="align-text-bottom line-height-1">{{ $t('Visitas') }}</span>
              </b-list-group-item>
              <b-list-group-item
                :class="{'cursor-pointer': true, 'active' : ((tabContentStepShow === 22) ? true : false) }"
                @click.stop.prevent="tabContentStepShow=22; $emit('close-left-sidebar')"
              >
                <feather-icon
                  icon="FilePlusIcon"
                  :size="sizeIconsAction"
                  class="mr-75"
                />
                <span class="align-text-bottom line-height-1">{{ $t('Propostas') }}</span>
              </b-list-group-item>
              <b-list-group-item
                :class="{'cursor-pointer': true, 'active' : ((tabContentStepShow === 23) ? true : false) }"
                @click.stop.prevent="tabContentStepShow=23; $emit('close-left-sidebar')"
              >
                <feather-icon
                  icon="BarChart2Icon"
                  :size="sizeIconsAction"
                  class="mr-75"
                />
                <span class="align-text-bottom line-height-1">{{ $t('Estatísticas') }}</span>
              </b-list-group-item>
              <b-list-group-item
                :class="{'cursor-pointer': true, 'active' : ((tabContentStepShow === 24) ? true : false) }"
                @click.stop.prevent="tabContentStepShow=24; $emit('close-left-sidebar')"
              >
                <feather-icon
                  icon="PenToolIcon"
                  :size="sizeIconsAction"
                  class="mr-75"
                />
                <span class="align-text-bottom line-height-1">{{ $t('Zome Design') }}</span>
              </b-list-group-item>
            </b-list-group>

            <h6
              v-if="Number(load_data_geral.formPreAngariacao) !== 1"
              class="section-label mt-2 mb-1 px-2"
            >
              {{ $t('Plano de comunicação') }}
            </h6>
            <b-list-group
              v-if="Number(load_data_geral.formPreAngariacao) !== 1"
              class="list-group-messages"
            >
              <b-list-group-item
                :class="{'cursor-pointer': true, 'active' : ((tabContentStepShow === 25) ? true : false) }"
                @click.stop.prevent="tabContentStepShow=25; $emit('close-left-sidebar')"
              >
                <feather-icon
                  icon="RadioIcon"
                  :size="sizeIconsAction"
                  class="mr-75"
                />
                <span class="align-text-bottom line-height-1">{{ $t('Comunicação') }}</span>
              </b-list-group-item>
            </b-list-group>

            <!-- Extra space -->
            <h6
              class="section-label mt-3 mb-1 px-2"
            >
              {{ '\xa0' }}
            </h6>

          </vue-perfect-scrollbar>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BButton, BListGroup, BListGroupItem,
} from 'bootstrap-vue'
import '@/libs/sweet-alerts'
import { mapGetters } from 'vuex'
import VuePerfectScrollbar from '@/views/modules/components/vue-perfect-scrollbar.vue'
import Ripple from 'vue-ripple-directive'
import { sizeIconsAction } from '@core-custom/mixins/geral'
import { configSettingsScroll } from '@core-custom/utils/ui'
import useCapacitor from '@core-custom/utils/capacitor'
import { mixFormGeral } from './mixins'

export default {
  directives: {
    Ripple,
  },
  components: {
    VuePerfectScrollbar,
    BButton,
    BListGroup,
    BListGroupItem,
  },
  mixins: [sizeIconsAction, mixFormGeral],
  props: {
    redirectPageBack: {
      type: Function,
      required: true,
    },
    saveForm: {
      type: Function,
      required: true,
    },
    actionForm: {
      type: Function,
      required: true,
    },
    cancelForm: {
      type: Function,
      required: true,
    },
    getModalPortaisListing: {
      type: Function,
      required: true,
    },
    getModalRatingListing: {
      type: Function,
      required: true,
    },
    createNewVisit: {
      type: Function,
      required: true,
    },
    changeAgent: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      showButtonEM: false,
      showButtonBrochura: false,
      showButtonSubmit: false,
      prevRoute: null,
    }
  },
  computed: {
    ...mapGetters('listings_form', ['vars', 'load_data_geral', 'formModeView', 'appConfig', 'keyReg', 'formPreAngariacao']),
    ...mapGetters('listings', ['pageSel']),
    ...mapGetters('auth', ['currentUser']),
    tabContentStepShow: {
      get() { return this.$store.getters['listings_form/tabContentStepShow'] },
      set(newValue) {
        this.$store.commit('listings_form/setTabContentStepShow', newValue)
      },
    },
    colorButton: {
      get() {
        const current = new Date()
        const date = `${current.getFullYear()}-${current.getMonth() + 1}-${current.getDate()}`
        const sw012s297 = (typeof this.vars.sw012.sw012s297 && this.vars.sw012.sw012s297 !== null ? new Date(this.vars.sw012.sw012s297) : new Date())
        sw012s297.setDate(sw012s297.getDate() + 45)
        const endPublishDate = `${sw012s297.getFullYear()}-${sw012s297.getMonth() + 1}-${sw012s297.getDate()}`

        if (((typeof this.vars.sw012.sw012s372 && this.vars.sw012.sw012s372 !== null && Number(this.vars.sw012.sw012s372) === 1)
        || (typeof this.vars.sw012.sw012s375 && this.vars.sw012.sw012s375 !== null && Number(this.vars.sw012.sw012s375) === 1))
        && ((typeof this.vars.sw012.sw012s64 && this.vars.sw012.sw012s64 !== null && [1, 2, 5].includes(Number(this.vars.sw012.sw012s64)))
        || (typeof this.vars.sw012.sw012s330 && this.vars.sw012.sw012s330 !== null && [1, 2, 5].includes(Number(this.vars.sw012.sw012s330)))
        || (((typeof this.vars.sw012.sw012s330 && this.vars.sw012.sw012s330 !== null && Number(this.vars.sw012.sw012s330) === 3)
        || (typeof this.vars.sw012.sw012s64 && this.vars.sw012.sw012s64 !== null && Number(this.vars.sw012.sw012s64) === 3))
        && endPublishDate > date))) {
          return 'outline-success'
        }
        return 'outline-danger'
      },
    },
    showButtonPlusAction: {
      get() {
        let count = 0
        if ((([Number(this.vars.sw012.sw012s60), Number(this.vars.sw012.sw012s04)].includes(Number(this.baseDecode(this.currentUser.id)))) || ([5, 9, 10, 13].includes(Number(this.currentUser.id_role))) || (Number(this.load_data_geral.userChangeCanEditAng) === 1))) {
          if (((typeof this.vars.sw012.sw012s64 !== 'undefined' && this.vars.sw012.sw012s64 !== null && [2, 3, 7].includes(Number(this.vars.sw012.sw012s64))) || (typeof this.vars.sw012.sw012s330 !== 'undefined' && this.vars.sw012.sw012s330 !== null && [2, 3, 7].includes(Number(this.vars.sw012.sw012s330)))) && Number(this.vars.sw012.sw012s72) === Number(this.currentUser.idHUB) && [5, 9, 10, 13].includes(Number(this.currentUser.id_role))) {
            count += 1
          } else {
            count += 1
          }
        }

        if ([5, 9, 10, 13].includes(Number(this.currentUser.id_role)) && (typeof this.vars.sw012.sw012s261 === 'undefined' || this.vars.sw012.sw012s261 === null || this.vars.sw012.sw012s261 === '')
        && Number(this.vars.sw012.sw012s72) === Number(this.currentUser.idHUB) && Number(this.load_data_geral.editListingHM) === 1) {
          count += 1
        }

        if (![1, 2, 5, 6, 8, 14, 9, 10, 13, 11, 12].includes(Number(this.currentUser.id_role)) && (typeof this.vars.sw012.sw012s261 === 'undefined' || this.vars.sw012.sw012s261 === null || this.vars.sw012.sw012s261 === '')
        && (Number(this.vars.sw012.sw012s64) === 1 || Number(this.vars.sw012.sw012s330) === 1) && ((typeof this.currentUser.extras !== 'undefined' && this.currentUser.extras.basic === 1) || (typeof this.currentUser.extras !== 'undefined' && Object.values(this.currentUser.extras.perm).includes('pode_marcar_visitas_leads')))) {
          count += 1
        }

        if ((typeof this.vars.sw012.sw012s261 !== 'undefined' && this.vars.sw012.sw012s261 !== null && Number(this.vars.sw012.sw012s261) === 2) || (typeof this.load_data_geral.idVersionRegViewer !== 'undefined' && this.load_data_geral.idVersionRegViewer !== null && this.load_data_geral.idVersionRegViewer !== '')) {
          count = 0
        }

        return count
      },
    },
    showEditButton: {
      get() {
        let bShow = false
        if ((([Number(this.vars.sw012.sw012s60), Number(this.vars.sw012.sw012s04)].includes(Number(this.baseDecode(this.currentUser.id)))) || ([5, 9, 10, 13].includes(Number(this.currentUser.id_role))) || (Number(this.load_data_geral.userChangeCanEditAng) === 1)) && Number(this.load_data_geral.showVersionReg) !== 1 && (typeof this.vars.sw012.sw012s261 === 'undefined' || this.vars.sw012.sw012s261 === null || this.vars.sw012.sw012s261 === '') && Number(this.vars.sw012.sw012s72) === Number(this.currentUser.idHUB)) {
          bShow = true
        }

        return bShow
      },
    },
    showSuspButton: {
      get() {
        let bShow = false
        if ([5, 9, 10, 13].includes(Number(this.currentUser.id_role)) && (typeof this.vars.sw012.sw012s261 === 'undefined' || this.vars.sw012.sw012s261 === null || this.vars.sw012.sw012s261 === '')
        && Number(this.vars.sw012.sw012s72) === Number(this.currentUser.idHUB) && Number(this.load_data_geral.editListingHM) === 1 && (typeof this.vars.sw012.sw012s64 !== 'undefined' && this.vars.sw012.sw012s64 !== null && ![2, 3, 7, 8, 9].includes(Number(this.vars.sw012.sw012s64)))) {
          bShow = true
        } else if (([Number(this.vars.sw012.sw012s60), Number(this.vars.sw012.sw012s04)].includes(Number(this.baseDecode(this.currentUser.id)))) && Number(this.vars.sw012.sw012s72) === Number(this.currentUser.idHUB)
          && (typeof this.vars.sw012.sw012s64 !== 'undefined' && this.vars.sw012.sw012s64 !== null && ![8, 9].includes(Number(this.vars.sw012.sw012s64)))) {
          bShow = true
        }

        return bShow
      },
    },
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      const self = vm
      self.prevRoute = from
    })
  },
  methods: {
    baseDecode(val) {
      if (typeof val !== 'undefined') {
        return atob(val)
      }
      return ''
    },
    currentDate() {
      const current = new Date()
      const date = `${current.getFullYear()}-${current.getMonth() + 1}-${current.getDate()}`
      return date
    },
    async suspenderListing() {
      this.$swal({
        title: '',
        html: `${this.$t('Esta ação vai ter efeito imediato e a angariação deixará de ficar disponível no {nome} e no website.', { nome: process.env.VUE_APP_TITLE })}<br>${this.$t('Deseja continuar?')}`,
        icon: 'warning',
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonText: this.$t('Sim'),
        cancelButtonText: this.$t('Não'),
        customClass: {
          confirmButton: 'btn btn-primary ml-sm-1 btn-xs-block',
          cancelButton: 'btn btn-outline-secondary mb-1 mb-sm-0 btn-xs-block',
        },
        buttonsStyling: false,
      }).then(async resp => {
        if (resp.value) {
          await this.$store.dispatch('listings_form/suspenderReg').then(async res => {
            this.$root.$emit('app::loading', false)
            if (Number(res.error) !== 1) {
              this.showMsgSuccessRequest({ message: res.msg })
              await this.redirectPageBack()
            } else {
              this.showMsgErrorRequest({ message: res.msg })
            }
          }).catch(error => {
            this.showMsgErrorRequest(error)
          })
        }
      })
    },
    async deleteListing(confirmations = {}) {
      this.$root.$emit('app::loading', true)
      await this.$store.dispatch('listings_form/deleteListing', { config: confirmations }).then(async res => {
        this.$root.$emit('app::loading', false)
        if (Number(res.error) === 1) {
          this.showMsgErrorRequest({ message: res.msg })
        } else if (Object.keys(res.required_confirmations).length > 0) {
          this.showNextConfirmation(res.required_confirmations, {})
        } else {
          await this.redirectPageBack()
        }
      }).catch(error => {
        this.showMsgErrorRequest(error)
      })
    },
    async showNextConfirmation(requiredConfirmations, confirmations = {}) {
      const requiredConfirm = requiredConfirmations
      const confirmKey = Object.keys(requiredConfirm)[0]
      const confirmMessage = requiredConfirm[confirmKey]
      const confirm = confirmations

      this.$swal({
        title: '',
        html: confirmMessage,
        icon: 'warning',
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonText: this.$t('Eliminar'),
        cancelButtonText: this.$t('Cancelar'),
        customClass: {
          confirmButton: 'btn btn-danger ml-sm-1 btn-xs-block',
          cancelButton: 'btn btn-outline-secondary mb-1 mb-sm-0 btn-xs-block',
        },
        buttonsStyling: false,
      }).then(async resp => {
        confirm[confirmKey] = resp.value
        delete requiredConfirm[confirmKey]
        if (resp.value) {
          if (Object.keys(requiredConfirm).length > 0) {
            this.showNextConfirmation(requiredConfirm, confirm)
          } else {
            this.deleteListing(confirm)
          }
        }
      })
    },
    async openViewMarketResearch(idImovel) {
      this.$root.$emit('app::loading', true)
      await this.$store.dispatch('listings_form/getMarketStudy', { id: idImovel }).then(async url => {
        this.$root.$emit('app::loading', false)

        if (url !== '') {
          useCapacitor.openUrlNewTab(url)
        }
      }).catch(error => {
        this.showMsgErrorRequest(error)
      })
    },
  },
  setup() {
    return {
      configSettingsScroll,
    }
  },
}
</script>
