import api from '@core-custom/api/api'
import apiConfig from '@core-custom/api/apiConfig'
import i18n from '@/libs/i18n'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {

    async clearSWkeepalive(state, payload) {
      return new Promise((resolve, reject) => {
        const formData = new FormData()
        formData.append('sw130s01', payload.sw130s01 || '')

        api.post(`${apiConfig.url_base_api}leads/propostas/clearSWkeepalive`, formData)
          .then(response => {
            try {
              if (typeof response.data === 'object') {
                resolve(response.data)
              } else {
                reject(new Error(i18n.t('Problema ao desbloquear o detalhe da proposta')))
              }
            } catch (error) {
              if ((error.response !== undefined) && (error.response.status !== undefined)) {
                reject(new Error(i18n.t('Problema ao desbloquear o detalhe da proposta')))
              }
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao desbloquear o detalhe da proposta')))
            }
          })
      })
    },

    async uploadDocs({ dispatch }, payload) {
      let resultUpload = null

      if ((payload !== null) && (payload !== undefined)) {
        if ('files' in payload) {
          if (payload.files.length > 0) {
            await payload.files.reduce(async (previous, file) => {
              const result = await previous
              return [...result, await dispatch('uploadDocumentos', [file])]
            }, Promise.resolve([]))
              .then(result => {
                resultUpload = result
              })
          }
        }
      }

      const response = await new Promise((resolve, reject) => {
        if (resultUpload === null) {
          reject(new Error(i18n.t('Selecione um documento para carregar')))
        } else {
          resolve(resultUpload)
        }
      })

      return response
    },

    async uploadDocumentos(state, payload) {
      const formData = new FormData()
      formData.append('name', payload[0].name)
      formData.append('file', payload[0])

      return new Promise((resolve, reject) => {
        api.post(`${apiConfig.url_base_api}leads/propostas/uploadDocumentos`, formData)
          .then(response => {
            if (typeof response.data === 'object') {
              resolve(response.data)
            } else {
              reject(new Error(i18n.t('Problema ao fazer upload dos documentos')))
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao fazer upload dos documentos')))
            }
          })
      })
    },

  },
}
