<template>
  <div>

    <div
      class="email-app-list position-relative height-100-panel"
    >
      <div
        class="app-fixed-search d-flex align-items-center"
      >
        <div class="d-flex align-items-center flex-grow-1 w-50">

          <div class="sidebar-toggle d-block d-lg-none ml-1">
            <feather-icon
              icon="MenuIcon"
              :size="sizeIconsAction"
              class="cursor-pointer"
              @click="mqShallShowLeftSidebar = true"
            />
          </div>

        </div>
        <div class="d-flex align-items-center w-50 justify-content-end">

          <footer-step-nav ref="footerStepNav" />

        </div>
      </div>

      <vue-perfect-scrollbar
        class="scroll-content"
        :settings="configSettingsScroll"
      >
        <div
          class="app-fixed-search d-flex align-items-center"
        >
          <div class="d-flex flex-column align-items-center">
            <div class="d-flex align-items-center justify-content-start w-100">
              <h4 class="p-1 m-0">
                {{ $t('Atributos') }}
              </h4>
            </div>
          </div>
        </div>

        <b-container
          class="p-2"
          fluid
        >
          <div v-if="filterAttributes.essenciais === true">
            <b-row>
              <b-col
                v-if="showAttributes.showAttrAssoalhadas===true"
                md="2"
              >
                <b-form-group
                  :label="$t('Assoalhadas')"
                >
                  <validation-provider
                    #default="{ errors }"
                    :rules="typeof rulesAttrFields.sw012s37 !== 'undefined' && rulesAttrFields.sw012s37.required === true ? 'required' : ''"
                    :name="$t('Assoalhadas') | lowerBold"
                  >
                    <b-form-input
                      v-if="formModeView===false"
                      v-model="form_data.sw012s37"
                      placeholder=""
                      autocomplete="off"
                      :class="errors.length > 0 ? 'is-invalid':null"
                      inputmode="numeric"
                      @keypress="NumbersOnly"
                    />
                    <p
                      v-else
                      class="text-primary mb-0"
                    >
                      {{ form_data.sw012s37 }}
                    </p>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col
                v-if="showAttributes.showAttrSuite===true"
                md="2"
              >
                <validation-provider
                  #default="{ errors }"
                  :rules="typeof rulesAttrFields.sw012s143 !== 'undefined' && rulesAttrFields.sw012s143.required === true ? 'required' : ''"
                  :name="$t('Suite') | lowerBold"
                >
                  <b-form-group
                    :label="$t('Suite')"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <v-select
                      v-if="formModeView===false"
                      v-model="form_data.sw012s143"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="load_data_geral.arraySuites"
                      append-to-body
                      :calculate-position="withPopper"
                      label="desc"
                      item-text="desc"
                      item-value="id"
                      @input="changeNumberRooms"
                    >
                      <template #option="{ desc }">
                        {{ desc }}
                      </template>
                      <div slot="no-options">
                        {{ $t('Sem resultados') }}
                      </div>
                    </v-select>
                    <p
                      v-else
                      class="text-primary mb-0"
                    >
                      {{ renderObjToTxt(form_data.sw012s143, 'desc') }}
                    </p>
                    <b-form-tags
                      v-if="form_data.sw012s143 != '' && form_data.sw012s143 != null && form_data.sw012s143.id != '0' && formModeView===false"
                      v-model="form_data.sw012s135"
                      input-id="sw012s135"
                      separator=","
                      :placeholder="$t('áreas')"
                      remove-on-delete
                      no-add-on-enter
                      class="mt-1"
                      @keypress="NumbersDecimal"
                    />
                    <p
                      v-if="formModeView===true && typeof form_data.sw012s135 !== 'undefined' && form_data.sw012s135 != null && form_data.sw012s135.length > 0"
                      class="text-primary mb-0"
                    >
                      {{ form_data.sw012s135 | getTxt }}
                    </p>
                    <b-form-text
                      v-if="form_data.sw012s143 != '' && form_data.sw012s143 != null && form_data.sw012s143.id != '0' && formModeView===false"
                      id="tags-remove-on-delete-help"
                    >
                      {{ $t('Separe as áreas com virgulas. Separe as casas decimais com ponto.') }}
                    </b-form-text>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col
                v-if="showAttributes.showAttrRoom===true"
                md="2"
              >
                <b-form-group
                  :label="$t('Quartos')"
                >
                  <b-form-input
                    v-if="formModeView===false"
                    v-model="form_data.sw012s38"
                    placeholder=""
                    autocomplete="off"
                    :disabled="true"
                  />
                  <p
                    v-else
                    class="text-primary mb-0"
                  >
                    {{ form_data.sw012s38 | getTxt }}
                  </p>
                  <b-form-tags
                    v-if="form_data.sw012s38 != '' && form_data.sw012s38 != null && form_data.sw012s38 != '0' && formModeView===false"
                    v-model="form_data.sw012s129"
                    input-id="sw012s129"
                    separator=","
                    :placeholder="$t('áreas')"
                    remove-on-delete
                    no-add-on-enter
                    class="mt-1"
                    @keypress="NumbersDecimal"
                  />
                  <p
                    v-if="formModeView===true && typeof form_data.sw012s129 !== 'undefined' && form_data.sw012s129 != null && form_data.sw012s38 != '0' && form_data.sw012s129.length > 0"
                    class="text-primary mb-0"
                  >
                    {{ form_data.sw012s135 | getTxt }}
                  </p>
                  <b-form-text
                    v-if="form_data.sw012s38 != '' && form_data.sw012s38 != null && formModeView===false"
                    id="tags-remove-on-delete-help"
                  >
                    {{ $t('Separe as áreas com virgulas. Separe as casas decimais com ponto.') }}
                  </b-form-text>
                </b-form-group>
              </b-col>

              <b-col md="2">
                <validation-provider
                  #default="{ errors }"
                  :rules="typeof rulesAttrFields.sw012s39 !== 'undefined' && rulesAttrFields.sw012s39.required === true ? 'required' : ''"
                  :name="$t('Garagem') | lowerBold"
                >
                  <b-form-group
                    :label="$t('Garagem')"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-row>
                      <b-col md="12">
                        <v-select
                          v-if="formModeView===false"
                          v-model="form_data.sw012s39"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="load_data_geral.garages"
                          append-to-body
                          :calculate-position="withPopper"
                          label="desc"
                          item-text="desc"
                          item-value="id"
                        >
                          <template #option="{ desc }">
                            {{ desc }}
                          </template>
                          <div slot="no-options">
                            {{ $t('Sem resultados') }}
                          </div>
                        </v-select>
                        <p
                          v-else
                          class="text-primary mb-0"
                        >
                          {{ renderObjToTxt(form_data.sw012s39, 'desc') }}
                        </p>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col
                        md="12"
                      >
                        <b-form-tags
                          v-if="form_data.sw012s39 != '' && form_data.sw012s39 != null && form_data.sw012s39.id != '1' && formModeView===false"
                          v-model="form_data.sw012s316"
                          input-id="sw012s316"
                          separator=","
                          :placeholder="$t('áreas')"
                          remove-on-delete
                          no-add-on-enter
                          class="mt-1"
                          @keypress="NumbersDecimal"
                        />
                        <p
                          v-if="formModeView===true && typeof form_data.sw012s129 !== 'undefined' && form_data.sw012s129 != null && form_data.sw012s316.length > 0"
                          class="text-primary mb-0"
                        >
                          {{ form_data.sw012s316 | getTxt }}
                        </p>
                        <b-form-text
                          v-if="form_data.sw012s39 != '' && form_data.sw012s39 != null && form_data.sw012s39.id != '1' && formModeView===false"
                          id="tags-remove-on-delete-help"
                        >
                          {{ $t('Separe as áreas com virgulas. Separe as casas decimais com ponto.') }}
                        </b-form-text>
                      </b-col>
                    </b-row>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col
                v-if="showAttributes.showAttrLugaresGaragem===true"
                md="2"
              >
                <b-form-group
                  v-if="form_data.sw012s39 !== null && typeof form_data.sw012s39 !== 'undefined' && form_data.sw012s39.id === '3'"
                  :label="$t('Lugares garagem')"
                >
                  <b-row>
                    <b-col
                      v-if="form_data.sw012s39 !== null && typeof form_data.sw012s39 !== 'undefined' && form_data.sw012s39.id === '3' && formModeView===false"
                      md="12"
                    >
                      <v-select
                        v-if="formModeView===false"
                        v-model="form_data.sw012s40"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="load_data_geral.places"
                        append-to-body
                        :calculate-position="withPopper"
                        label="desc"
                        item-text="desc"
                        item-value="id"
                      >
                        <template #option="{ desc }">
                          {{ desc }}
                        </template>
                        <div slot="no-options">
                          {{ $t('Sem resultados') }}
                        </div>
                      </v-select>
                      <p
                        v-else
                        class="text-primary mb-0"
                      >
                        {{ renderObjToTxt(form_data.sw012s40, 'desc') }}
                      </p>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col
                      v-if="form_data.sw012s39 !== null && typeof form_data.sw012s39 !== 'undefined' && form_data.sw012s39.id === '3'"
                      md="12"
                    >
                      <b-form-tags
                        v-if="formModeView===false"
                        v-model="form_data.sw012s320"
                        input-id="sw012s320"
                        separator=","
                        :placeholder="$t('alturas')"
                        remove-on-delete
                        no-add-on-enter
                        class="mt-1"
                        @keypress="NumbersDecimal"
                      />
                      <p
                        v-else
                        class="text-primary mb-0"
                      >
                        {{ form_data.sw012s320 | getTxt }}
                      </p>
                      <b-form-text id="tags-remove-on-delete-help">
                        {{ $t('Pé-direito') }}
                      </b-form-text>
                    </b-col>
                  </b-row>
                </b-form-group>

              </b-col>

            </b-row>

            <b-row>
              <b-col md="2">
                <validation-provider
                  #default="{ errors }"
                  :rules="typeof rulesAttrFields.sw012s41 !== 'undefined' && rulesAttrFields.sw012s41.required === true ? 'required' : ''"
                  :name="$t('Parqueamento') | lowerBold"
                >
                  <b-form-group
                    :label="$t('Parqueamento')"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <v-select
                      v-if="formModeView===false"
                      v-model="form_data.sw012s41"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="load_data_geral.array20"
                      append-to-body
                      :calculate-position="withPopper"
                      label="desc"
                      item-text="desc"
                      item-value="id"
                    >
                      <template #option="{ desc }">
                        {{ desc }}
                      </template>
                      <div slot="no-options">
                        {{ $t('Sem resultados') }}
                      </div>
                    </v-select>
                    <p
                      v-else
                      class="text-primary mb-0"
                    >
                      {{ renderObjToTxt(form_data.sw012s41, 'desc') }}
                    </p>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col md="2">
                <validation-provider
                  #default="{ errors }"
                  :rules="typeof rulesAttrFields.sw012s13 !== 'undefined' && rulesAttrFields.sw012s13.required === true ? 'required' : ''"
                  :name="$t('Casa de banho') | lowerBold"
                >
                  <b-form-group
                    :label="$t('Casa de banho')"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <v-select
                      v-if="formModeView===false"
                      v-model="form_data.sw012s13"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="load_data_geral.array70"
                      append-to-body
                      :calculate-position="withPopper"
                      label="desc"
                      item-text="desc"
                      item-value="id"
                    >
                      <template #option="{ desc }">
                        {{ desc }}
                      </template>
                      <div slot="no-options">
                        {{ $t('Sem resultados') }}
                      </div>
                    </v-select>
                    <p
                      v-else
                      class="text-primary mb-0"
                    >
                      {{ renderObjToTxt(form_data.sw012s13, 'desc') }}
                    </p>
                    <b-form-tags
                      v-if="form_data.sw012s13 != '' && form_data.sw012s13 != null && form_data.sw012s13.id != '0' && formModeView===false"
                      v-model="form_data.sw012s127"
                      input-id="sw012s127"
                      separator=","
                      :placeholder="$t('áreas')"
                      remove-on-delete
                      no-add-on-enter
                      class="mt-1"
                      @keypress="NumbersDecimal"
                    />
                    <p
                      v-if="formModeView===true && typeof form_data.sw012s13 !== 'undefined' && form_data.sw012s13 != null && form_data.sw012s13.length > 0"
                      class="text-primary mb-0"
                    >
                      {{ form_data.sw012s13 | getTxt }}
                    </p>
                    <b-form-text
                      v-if="form_data.sw012s13 != '' && form_data.sw012s13 != null && form_data.sw012s13.id != '0' && formModeView===false"
                      id="tags-remove-on-delete-help"
                    >
                      {{ $t('Separe as áreas com virgulas. Separe as casas decimais com ponto.') }}
                    </b-form-text>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col
                v-if="showAttributes.showAttrQuintal===true"
                md="2"
              >
                <validation-provider
                  #default="{ errors }"
                  :rules="typeof rulesAttrFields.sw012s43 !== 'undefined' && rulesAttrFields.sw012s43.required === true ? 'required' : ''"
                  :name="$t('Quintal / Jardim') | lowerBold"
                >
                  <b-form-group
                    :label="$t('Quintal / Jardim')"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <v-select
                      v-if="formModeView===false"
                      v-model="form_data.sw012s43"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="load_data_geral.array70"
                      append-to-body
                      :calculate-position="withPopper"
                      label="desc"
                      item-text="desc"
                      item-value="id"
                    >
                      <template #option="{ desc }">
                        {{ desc }}
                      </template>
                      <div slot="no-options">
                        {{ $t('Sem resultados') }}
                      </div>
                    </v-select>
                    <p
                      v-else
                      class="text-primary mb-0"
                    >
                      {{ renderObjToTxt(form_data.sw012s43, 'desc') }}
                    </p>
                    <b-form-tags
                      v-if="form_data.sw012s43 != '' && form_data.sw012s43 != null && form_data.sw012s43.id != '0' && formModeView===false"
                      v-model="form_data.sw012s148"
                      input-id="sw012s148"
                      separator=","
                      :placeholder="$t('áreas')"
                      remove-on-delete
                      no-add-on-enter
                      class="mt-1"
                      @keypress="NumbersDecimal"
                    />
                    <p
                      v-if="formModeView===true && typeof form_data.sw012s148 !== 'undefined' && form_data.sw012s148 != null && form_data.sw012s148.length > 0"
                      class="text-primary mb-0"
                    >
                      {{ form_data.sw012s148 | getTxt }}
                    </p>
                    <b-form-text
                      v-if="form_data.sw012s43 != '' && form_data.sw012s43 != null && form_data.sw012s43.id != '0' && formModeView===false"
                      id="tags-remove-on-delete-help"
                    >
                      {{ $t('Separe as áreas com virgulas. Separe as casas decimais com ponto.') }}
                    </b-form-text>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col md="2">
                <validation-provider
                  #default="{ errors }"
                  :rules="typeof rulesAttrFields.sw012s44 !== 'undefined' && rulesAttrFields.sw012s44.required === true ? 'required' : ''"
                  :name="$t('Terraço') | lowerBold"
                >
                  <b-form-group
                    :label="$t('Terraço')"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <v-select
                      v-if="formModeView===false"
                      v-model="form_data.sw012s44"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="load_data_geral.array70"
                      append-to-body
                      :calculate-position="withPopper"
                      label="desc"
                      item-text="desc"
                      item-value="id"
                    >
                      <template #option="{ desc }">
                        {{ desc }}
                      </template>
                      <div slot="no-options">
                        {{ $t('Sem resultados') }}
                      </div>
                    </v-select>
                    <p
                      v-else
                      class="text-primary mb-0"
                    >
                      {{ renderObjToTxt(form_data.sw012s44, 'desc') }}
                    </p>
                    <b-form-tags
                      v-if="form_data.sw012s44 != '' && form_data.sw012s44 != null && form_data.sw012s44.id != '0' && formModeView===false"
                      v-model="form_data.sw012s141"
                      input-id="sw012s141"
                      separator=","
                      :placeholder="$t('áreas')"
                      remove-on-delete
                      no-add-on-enter
                      class="mt-1"
                      @keypress="NumbersDecimal"
                    />
                    <p
                      v-if="formModeView===true && typeof form_data.sw012s141 !== 'undefined' && form_data.sw012s141 != null && form_data.sw012s141.length > 0"
                      class="text-primary mb-0"
                    >
                      {{ form_data.sw012s141 | getTxt }}
                    </p>
                    <b-form-text
                      v-if="form_data.sw012s44 != '' && form_data.sw012s44 != null && form_data.sw012s44.id != '0' && formModeView===false"
                      id="tags-remove-on-delete-help"
                    >
                      {{ $t('Separe as áreas com virgulas. Separe as casas decimais com ponto.') }}
                    </b-form-text>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>

            <b-row>
              <b-col md="2">
                <validation-provider
                  #default="{ errors }"
                  :rules="typeof rulesAttrFields.sw012s126 !== 'undefined' && rulesAttrFields.sw012s126.required === true ? 'required' : ''"
                  :name="$t('Varanda') | lowerBold"
                >
                  <b-form-group
                    :label="$t('Varanda')"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <v-select
                      v-if="formModeView===false"
                      v-model="form_data.sw012s126"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="load_data_geral.array20"
                      append-to-body
                      :calculate-position="withPopper"
                      label="desc"
                      item-text="desc"
                      item-value="id"
                    >
                      <template #option="{ desc }">
                        {{ desc }}
                      </template>
                      <div slot="no-options">
                        {{ $t('Sem resultados') }}
                      </div>
                    </v-select>
                    <p
                      v-else
                      class="text-primary mb-0"
                    >
                      {{ renderObjToTxt(form_data.sw012s126, 'desc') }}
                    </p>
                    <b-form-tags
                      v-if="form_data.sw012s126 != '' && form_data.sw012s126 != null && form_data.sw012s126.id != '0' && formModeView===false"
                      v-model="form_data.sw012s131"
                      input-id="sw012s131"
                      separator=","
                      :placeholder="$t('áreas')"
                      remove-on-delete
                      no-add-on-enter
                      class="mt-1"
                      @keypress="NumbersDecimal"
                    />
                    <p
                      v-if="formModeView===true && typeof form_data.sw012s131 !== 'undefined' && form_data.sw012s131 != null && form_data.sw012s131.length > 0"
                      class="text-primary mb-0"
                    >
                      {{ form_data.sw012s131 | getTxt }}
                    </p>
                    <b-form-text
                      v-if="form_data.sw012s126 != '' && form_data.sw012s126 != null && form_data.sw012s126.id != '0' && formModeView===false"
                      id="tags-remove-on-delete-help"
                    >
                      {{ $t('Separe as áreas com virgulas. Separe as casas decimais com ponto.') }}
                    </b-form-text>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col
                v-if="showAttributes.showAttrCozinha===true"
                md="2"
              >
                <validation-provider
                  #default="{ errors }"
                  :rules="typeof rulesAttrFields.sw012s137 !== 'undefined' && rulesAttrFields.sw012s137.required === true ? 'required' : ''"
                  :name="$t('Cozinha') | lowerBold"
                >
                  <b-form-group
                    :label="$t('Cozinha')"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <v-select
                      v-if="formModeView===false"
                      v-model="form_data.sw012s137"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="load_data_geral.array70"
                      append-to-body
                      :calculate-position="withPopper"
                      label="desc"
                      item-text="desc"
                      item-value="id"
                    >
                      <template #option="{ desc }">
                        {{ desc }}
                      </template>
                      <div slot="no-options">
                        {{ $t('Sem resultados') }}
                      </div>
                    </v-select>
                    <p
                      v-else
                      class="text-primary mb-0"
                    >
                      {{ renderObjToTxt(form_data.sw012s137, 'desc') }}
                    </p>
                    <b-form-tags
                      v-if="form_data.sw012s137 != '' && form_data.sw012s137 != null && form_data.sw012s137.id != '0' && formModeView===false"
                      v-model="form_data.sw012s128"
                      input-id="sw012s128"
                      separator=","
                      :placeholder="$t('áreas')"
                      remove-on-delete
                      no-add-on-enter
                      class="mt-1"
                      @keypress="NumbersDecimal"
                    />
                    <p
                      v-if="formModeView===true && typeof form_data.sw012s128 !== 'undefined' && form_data.sw012s128 != null && form_data.sw012s128.length > 0"
                      class="text-primary mb-0"
                    >
                      {{ form_data.sw012s128 | getTxt }}
                    </p>
                    <b-form-text
                      v-if="form_data.sw012s137 != '' && form_data.sw012s137 != null && form_data.sw012s137.id != '0' && formModeView===false"
                      id="tags-remove-on-delete-help"
                    >
                      {{ $t('Separe as áreas com virgulas. Separe as casas decimais com ponto.') }}
                    </b-form-text>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col
                v-if="showAttributes.showAttrSala===true"
                md="2"
              >
                <validation-provider
                  #default="{ errors }"
                  :rules="typeof rulesAttrFields.sw012s133 !== 'undefined' && rulesAttrFields.sw012s133.required === true ? 'required' : ''"
                  :name="$t('Sala') | lowerBold"
                >
                  <b-form-group
                    :label="$t('Sala')"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <v-select
                      v-if="formModeView===false"
                      v-model="form_data.sw012s133"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="load_data_geral.array70"
                      append-to-body
                      :calculate-position="withPopper"
                      label="desc"
                      item-text="desc"
                      item-value="id"
                    >
                      <template #option="{ desc }">
                        {{ desc }}
                      </template>
                      <div slot="no-options">
                        {{ $t('Sem resultados') }}
                      </div>
                    </v-select>
                    <p
                      v-else
                      class="text-primary mb-0"
                    >
                      {{ renderObjToTxt(form_data.sw012s133, 'desc') }}
                    </p>
                    <b-form-tags
                      v-if="form_data.sw012s133 != '' && form_data.sw012s133 != null && form_data.sw012s133.id != '0' && formModeView===false"
                      v-model="form_data.sw012s130"
                      input-id="sw012s130"
                      separator=","
                      :placeholder="$t('áreas')"
                      remove-on-delete
                      no-add-on-enter
                      class="mt-1"
                      @keypress="NumbersDecimal"
                    />
                    <p
                      v-if="formModeView===true && typeof form_data.sw012s130 !== 'undefined' && form_data.sw012s130 != null && form_data.sw012s130.length > 0"
                      class="text-primary mb-0"
                    >
                      {{ form_data.sw012s130 | getTxt }}
                    </p>
                    <b-form-text
                      v-if="form_data.sw012s133 != '' && form_data.sw012s133 != null && form_data.sw012s133.id != '0' && formModeView===false"
                      id="tags-remove-on-delete-help"
                    >
                      {{ $t('Separe as áreas com virgulas. Separe as casas decimais com ponto.') }}
                    </b-form-text>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col
                v-if="showAttributes.showAttrPiscina===true"
                md="2"
              >
                <validation-provider
                  #default="{ errors }"
                  :rules="typeof rulesAttrFields.sw012s42 !== 'undefined' && rulesAttrFields.sw012s42.required === true ? 'required' : ''"
                  :name="$t('Piscina') | lowerBold"
                >
                  <b-form-group
                    :label="$t('Piscina')"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <v-select
                      v-if="formModeView===false"
                      v-model="form_data.sw012s42"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="load_data_geral.arrayYesNo"
                      append-to-body
                      :calculate-position="withPopper"
                      label="desc"
                      item-text="desc"
                      item-value="id"
                    >
                      <template #option="{ desc }">
                        {{ desc }}
                      </template>
                      <div slot="no-options">
                        {{ $t('Sem resultados') }}
                      </div>
                    </v-select>
                    <p
                      v-else
                      class="text-primary mb-0"
                    >
                      {{ renderObjToTxt(form_data.sw012s42, 'desc') }}
                    </p>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>

            <b-row>
              <b-col md="2">
                <validation-provider
                  #default="{ errors }"
                  :rules="typeof rulesAttrFields.sw012s47 !== 'undefined' && rulesAttrFields.sw012s47.required === true ? 'required' : ''"
                  :name="$t('Elevador') | lowerBold"
                >
                  <b-form-group
                    :label="$t('Elevador')"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <v-select
                      v-if="formModeView===false"
                      v-model="form_data.sw012s47"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="load_data_geral.arrayYesNo"
                      append-to-body
                      :calculate-position="withPopper"
                      label="desc"
                      item-text="desc"
                      item-value="id"
                    >
                      <template #option="{ desc }">
                        {{ desc }}
                      </template>
                      <div slot="no-options">
                        {{ $t('Sem resultados') }}
                      </div>
                    </v-select>
                    <p
                      v-else
                      class="text-primary mb-0"
                    >
                      {{ renderObjToTxt(form_data.sw012s47, 'desc') }}
                    </p>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col md="2">
                <validation-provider
                  #default="{ errors }"
                  :rules="typeof rulesAttrFields['sw012_attr_orientacao-solar'] !== 'undefined' && rulesAttrFields['sw012_attr_orientacao-solar'].required === true ? 'required' : ''"
                  :name="$t('Orientação solar') | lowerBold"
                >
                  <b-form-group
                    :label="$t('Orientação solar')"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <v-select
                      v-if="formModeView===false"
                      v-model="form_data['sw012_attr_orientacao-solar']"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="load_data_geral.solarOrientations"
                      append-to-body
                      :calculate-position="withPopper"
                      multiple
                      label="desc"
                      item-text="desc"
                      item-value="id"
                    >
                      <template #option="{ desc }">
                        {{ desc }}
                      </template>
                      <div slot="no-options">
                        {{ $t('Sem resultados') }}
                      </div>
                    </v-select>
                    <p
                      v-else
                      class="text-primary mb-0"
                    >
                      {{ renderObjToTxt(form_data['sw012_attr_orientacao-solar'], 'desc') }}
                    </p>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col md="2">
                <validation-provider
                  #default="{ errors }"
                  :rules="typeof rulesAttrFields['sw012_attr_obras-no-imovel'] !== 'undefined' && rulesAttrFields['sw012_attr_obras-no-imovel'] === true ? 'required' : ''"
                  :name="$t('Obras no imóvel') | lowerBold"
                >
                  <b-form-group
                    :label="$t('Obras no imóvel')"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <v-select
                      v-if="formModeView===false"
                      v-model="form_data['sw012_attr_obras-no-imovel']"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="load_data_geral.arrayConstruction"
                      append-to-body
                      :calculate-position="withPopper"
                      label="desc"
                      item-text="desc"
                      item-value="id"
                    >
                      <template #option="{ desc }">
                        {{ desc }}
                      </template>
                      <div slot="no-options">
                        {{ $t('Sem resultados') }}
                      </div>
                    </v-select>
                    <p
                      v-else
                      class="text-primary mb-0"
                    >
                      {{ renderObjToTxt(form_data['sw012_attr_obras-no-imovel'], 'desc') }}
                    </p>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col
                v-if="(typeof form_data.sw012_attr_arrecadacao !== 'undefined' && formModeView===true) || (formModeView===false && $store.getters['auth/countryApp'] === 'es')"
                md="2"
              >
                <validation-provider
                  #default="{ errors }"
                  :rules="typeof rulesAttrFields.sw012_attr_arrecadacao !== 'undefined' && rulesAttrFields.sw012_attr_arrecadacao.required === true ? 'required' : ''"
                  :name="$t('Arrecadação') | lowerBold"
                >
                  <b-form-group
                    :label="$t('Arrecadação')"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <v-select
                      v-if="formModeView===false"
                      v-model="form_data.sw012_attr_arrecadacao"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="load_data_geral.arrayYesNo"
                      append-to-body
                      :calculate-position="withPopper"
                      label="desc"
                      item-text="desc"
                      item-value="id"
                    >
                      <template #option="{ desc }">
                        {{ desc }}
                      </template>
                      <div slot="no-options">
                        {{ $t('Sem resultados') }}
                      </div>
                    </v-select>
                    <p
                      v-else
                      class="text-primary mb-0"
                    >
                      {{ renderObjToTxt(form_data.sw012_attr_arrecadacao, 'desc') }}
                    </p>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col md="4">
                <validation-provider
                  #default="{ errors }"
                  :rules="typeof rulesFields.sw012s391 !== 'undefined' && rulesFields.sw012s391.required === true ? 'required' : ''"
                  :name="$t('Acesso apropriado a pessoas com mobilidade reduzida') | lowerBold"
                >
                  <b-form-group
                    :label="$t('Acesso apropriado a pessoas com mobilidade reduzida')"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <v-select
                      v-if="formModeView===false"
                      v-model="form_data.sw012s391"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="load_data_geral.arrayYesNo"
                      append-to-body
                      :calculate-position="withPopper"
                      placeholder=""
                      label="desc"
                      item-text="desc"
                      item-value="id"
                    >
                      <template #option="{ desc }">
                        {{ desc }}
                      </template>
                      <div slot="no-options">
                        {{ $t('Sem resultados') }}
                      </div>
                    </v-select>
                    <p
                      v-else
                      class="text-primary mb-0"
                    >
                      {{ renderObjToTxt(form_data.sw012s391, 'desc') }}
                    </p>
                    <b-form-text
                      v-if="(formModeView===false) && ($store.getters['auth/countryApp'] === 'pt')"
                    >
                      <b-link
                        class="font-weight-bold"
                        @click="getModalAcesso"
                      >
                        {{ $t('Dúvidas? Consulte aqui as regras') }}
                      </b-link>
                    </b-form-text>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
          </div>

          <div v-if="filterAttributes.areas === true">
            <b-row>
              <b-col md="12">
                <h4
                  class="mt-1"
                >
                  {{ $t('Áreas') }}
                </h4>
              </b-col>
            </b-row>

            <b-row>
              <b-col md="2">
                <b-form-group
                  :label="$t('Área útil de habitação')"
                >
                  <validation-provider
                    #default="{ errors }"
                    :rules="typeof rulesFields.sw012s48 !== 'undefined' && rulesFields.sw012s48.required === true ? 'required' : ''"
                    :name="$t('Área útil de habitação') | lowerBold"
                  >
                    <b-input-group
                      v-if="formModeView===false"
                      :append="$t('m2')"
                    >
                      <b-form-input
                        v-model="form_data.sw012s48"
                        placeholder=""
                        autocomplete="off"
                        :class="errors.length > 0 ? 'is-invalid':null"
                        @keypress="NumbersDecimal"
                      />
                    </b-input-group>
                    <p
                      v-else
                      class="text-primary mb-0"
                    >
                      {{ form_data.sw012s48 | getTxt }} {{ $t('m2') }}
                    </p>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="2">
                <b-form-group
                  :label="$t('Área bruta de construção')"
                >
                  <validation-provider
                    #default="{ errors }"
                    :rules="typeof rulesFields.sw012s50 !== 'undefined' && rulesFields.sw012s50.required === true ? 'required' : ''"
                    :name="$t('Área bruta de construção') | lowerBold"
                  >
                    <b-input-group
                      v-if="formModeView===false"
                      :append="$t('m2')"
                    >
                      <b-form-input
                        v-model="form_data.sw012s50"
                        placeholder=""
                        autocomplete="off"
                        :class="errors.length > 0 ? 'is-invalid':null"
                        @keypress="NumbersDecimal"
                      />
                    </b-input-group>
                    <p
                      v-else
                      class="text-primary mb-0"
                    >
                      {{ form_data.sw012s50 | getTxt }} {{ $t('m2') }}
                    </p>
                    <b-form-text
                      v-if="formModeView===false"
                    >
                      {{ $t('Caderneta predial') }}
                    </b-form-text>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="2">
                <b-form-group
                  :label="$t('Área de implantação')"
                >
                  <validation-provider
                    #default="{ errors }"
                    :rules="typeof rulesFields.sw012s49 !== 'undefined' && rulesFields.sw012s49.required === true ? 'required' : ''"
                    :name="$t('Área de implantação') | lowerBold"
                  >
                    <b-input-group
                      v-if="formModeView===false"
                      :append="$t('m2')"
                    >
                      <b-form-input
                        v-model="form_data.sw012s49"
                        placeholder=""
                        autocomplete="off"
                        :class="errors.length > 0 ? 'is-invalid':null"
                        @keypress="NumbersDecimal"
                      />
                    </b-input-group>
                    <p
                      v-else
                      class="text-primary mb-0"
                    >
                      {{ form_data.sw012s49 | getTxt }} {{ $t('m2') }}
                    </p>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="2">
                <b-form-group
                  :label="$t('Área de Terreno')"
                >
                  <validation-provider
                    #default="{ errors }"
                    :rules="typeof rulesFields.sw012s51 !== 'undefined' && rulesFields.sw012s51.required === true ? 'required' : ''"
                    :name="$t('Área de Terreno') | lowerBold"
                  >
                    <b-input-group
                      v-if="formModeView===false"
                      :append="$t('m2')"
                    >
                      <b-form-input
                        v-model="form_data.sw012s51"
                        placeholder=""
                        autocomplete="off"
                        :class="errors.length > 0 ? 'is-invalid':null"
                        @keypress="NumbersDecimal"
                      />
                    </b-input-group>
                    <p
                      v-else
                      class="text-primary mb-0"
                    >
                      {{ form_data.sw012s51 | getTxt }} {{ $t('m2') }}
                    </p>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </div>

          <attr-extra />
        </b-container>
      </vue-perfect-scrollbar>

    </div>

  </div>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormInput, BContainer, BFormTags, BFormText, BLink, BInputGroup,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import VuePerfectScrollbar from '@/views/modules/components/vue-perfect-scrollbar.vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { sizeIconsAction } from '@core-custom/mixins/geral'
import { ValidationProvider } from 'vee-validate'
import { mask } from 'vue-the-mask'
import { configSettingsScroll } from '@core-custom/utils/ui'
import { mixFormGeral } from '../mixins'
import FooterStepNav from './footerStepNavTop.vue'
import AttrExtra from './atributos_extra.vue'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BContainer,
    BFormTags,
    BFormText,
    BLink,
    BInputGroup,
    VuePerfectScrollbar,
    FooterStepNav,
    ValidationProvider,
    vSelect,
    AttrExtra,
  },
  directives: {
    Ripple,
    mask,
  },
  mixins: [sizeIconsAction, mixFormGeral],
  props: {
    getModalAcesso: {
      type: Function,
      required: true,
    },
  },
  data: () => ({
  }),
  computed:
  {
    ...mapGetters('listings_form', ['form_data', 'load_data_geral', 'rulesFields', 'rulesAttrFields', 'filterAttributes', 'showAttributes', 'formModeView']),
  },
  methods: {
    changeNumberRooms(vlSuite) {
      let nRoomTipologia = 0
      let nRoomTipologiaExtra = 0

      if (typeof this.form_data.sw012s08 !== 'undefined' && this.form_data.sw012s08 !== null) {
        nRoomTipologia = Number(this.form_data.sw012s08.sw005s06)
      }

      if (typeof this.form_data.sw012s363 !== 'undefined' && this.form_data.sw012s363 !== null && this.form_data.sw012s363 !== '' && Number(this.form_data.sw012s363.id) !== 0) {
        nRoomTipologia = Number(this.form_data.sw012s363.desc)
      }

      if (typeof this.form_data.sw012s08_plus !== 'undefined' && this.form_data.sw012s08_plus !== null) {
        nRoomTipologiaExtra = Number(this.form_data.sw012s08_plus.sw005s08)
      }

      let totalRooms = Number(nRoomTipologia + nRoomTipologiaExtra)

      if (vlSuite !== null) {
        if (Number(vlSuite.id) !== 0) {
          totalRooms -= Number(vlSuite.desc)
        }
      }

      if (totalRooms === 0) {
        this.$store.dispatch('listings_form/updateFieldValue', { field: 'sw012s38', value: '0' })
      } else {
        this.$store.dispatch('listings_form/updateFieldValue', { field: 'sw012s38', value: totalRooms })
      }
    },
    NumbersOnly(evt) {
      const charCode = (evt.which) ? evt.which : evt.keyCode
      if ((charCode > 31 && (charCode < 48 || charCode > 57 || charCode === 46))) {
        evt.preventDefault()
        return false
      }
      return true
    },
    NumbersDecimal(evt) {
      const charCode = (evt.which) ? evt.which : evt.keyCode
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault()
        return false
      }
      return true
    },
  },
  setup() {
    return {
      configSettingsScroll,
    }
  },
}
</script>
