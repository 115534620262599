<template>
  <app-timeline class="mt-1 ml-3 mr-1">
    <app-timeline-item
      v-for="(row) in listviewData"
      :key="row.id"
      no-body
      icon="NavigationIcon"
      variant="primary"
    >
      <div class="justify-content align-items-start">
        <!-- 1st Col -->
        <b-row>
          <b-col
            md="2"
          >
            <div class="mb-1 mb-sm-0">
              <span class="text-muted mb-50 d-block">{{ $t('Nome da Lead') }}</span>
              <span>{{ row.nameLead }}</span>
            </div>
          </b-col>
          <b-col
            md="2"
          >
            <div class="mb-1 mb-sm-0">
              <span class="text-muted mb-50 d-block">{{ $t('Consultor') }}</span>
              <span>{{ row.nameAgent }}</span>
            </div>
          </b-col>
          <b-col
            md="2"
          >
            <div class="mb-1 mb-sm-0">
              <span class="text-muted mb-50 d-block">{{ $tc('Contacto', 1) }}</span>
              <span>{{ row.phoneAgent }}</span>
            </div>
          </b-col>
          <b-col
            md="2"
          >
            <div class="mb-1 mb-sm-0">
              <span class="text-muted mb-50 d-block">{{ $t('Data da visita') }}</span>
              <span>{{ row.dateVisit }}</span>
            </div>
          </b-col>
          <b-col
            md="2"
          >
            <div class="mb-1 mb-sm-0">
              <span class="text-muted mb-50 d-block">{{ $t('Estado') }}</span>
              <span>{{ row.stateVisit }}</span>
            </div>
          </b-col>
          <b-col
            v-if="row.classOption !== ''"
            md="2"
          >
            <b-button
              v-ripple.400="'rgba(234, 84, 85, 0.15)'"
              v-b-tooltip.hover
              :title="$t('Visualizar relatório')"
              variant="outline-primary"
              @click="openVisitDetail(row.id, row.report)"
            >
              <span>{{ $t('Ver') }}</span>
            </b-button>
          </b-col>
        </b-row>
      </div>
    </app-timeline-item>
  </app-timeline>
</template>

<script>
import {
  BRow, BCol, BButton, VBTooltip,
} from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    AppTimeline,
    AppTimelineItem,

  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    listviewData: {
      type: Array,
      required: true,
    },
    openVisitDetail: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
    }
  },
  computed: {
  },
  methods: {
  },
}
</script>
