<template>
  <div>

    <div
      class="email-app-list position-relative height-100-panel"
    >
      <div
        class="app-fixed-search d-flex align-items-center"
      >
        <div class="d-flex align-items-center flex-grow-1 w-50">

          <div class="sidebar-toggle d-block d-lg-none ml-1">
            <feather-icon
              icon="MenuIcon"
              :size="sizeIconsAction"
              class="cursor-pointer"
              @click="mqShallShowLeftSidebar = true"
            />
          </div>

        </div>
        <div class="d-flex align-items-center w-50 justify-content-end">

          <b-button
            v-if="this.$store.getters['listings_form/formModeView'] === false"
            variant="flat-secondary"
            class="border-radius-none"
            :class="{ 'btn-sm' : ((this.$store.getters['app/currentBreakPoint'] === 'xs') ? true: false)}"
            @click="$refs.refImagesListing.$el.click()"
          >
            <feather-icon
              icon="PlusIcon"
              :size="sizeIconsAction"
            />
          </b-button>

          <footer-step-nav ref="footerStepNav" />

        </div>
      </div>

      <vue-perfect-scrollbar
        class="scroll-content"
        :settings="configSettingsScroll"
      >
        <div
          class="app-fixed-search d-flex align-items-center"
          :class="{'border-0' : ((imagesPrivadas.length > 0) ? false : true)}"
        >
          <div class="d-flex flex-column align-items-center">

            <div class="d-flex align-items-center justify-content-start w-100">
              <h4 class="p-1 m-0">
                {{ $t('Fotografias') }}
              </h4>
            </div>
            <div
              v-if="this.$store.getters['listings_form/formModeView'] === false"
              class="d-flex align-items-center justify-content-start w-100 pl-1 pr-1 pb-1"
            >
              <b-dropdown
                v-if="imagesPrivadas.length !== 0"
                v-ripple.400="'rgba(255, 159, 67, 0.15)'"
                split
                :text="$t('Carregar fotografias')"
                variant="outline-primary"
                @click="$refs.refImagesListing.$el.click()"
              >
                <b-dropdown-item @click="setShowPhotosEdition(true)">
                  {{ $t('Ordenar / Eliminar imagens') }}
                </b-dropdown-item>
              </b-dropdown>
              <b-button
                v-else
                variant="outline-primary"
                @click="$refs.refImagesListing.$el.click()"
              >
                <span class="align-middle">{{ $t('Carregar fotografias') }}</span>
              </b-button>

              <b-form-file
                ref="refImagesListing"
                accept=".jpg, .png, .gif"
                :hidden="true"
                plain
                multiple
                @input="uploadImageRender"
              />
            </div>

          </div>
        </div>

        <b-container
          class="p-2"
          fluid
        >
          <b-row>
            <b-col md="12">
              <b-alert
                show
                variant="info"
              >
                <div class="alert-body">
                  <feather-icon
                    class="mr-25"
                    icon="InfoIcon"
                  />
                  <span class="ml-25">{{ $t('As fotografias carregadas neste separador serão apenas dispobilizadas para os seus colegas na rede.') }} <strong>{{ $t('Não serão publicadas no website nem estarão disponíveis no Zome Design.') }}</strong></span>
                </div>
              </b-alert>
            </b-col>
          </b-row>

          <b-row
            v-if="imagesPrivadas.length === 0"
          >
            <b-col md="12">
              <b-alert
                show
                variant="danger"
              >
                <div class="alert-body">
                  <span class="ml-25">{{ $t('Nenhuma foto inserida') }}</span>
                </div>
              </b-alert>
            </b-col>
          </b-row>

          <b-row class="mt-2">
            <b-col
              v-for="(row, key) in imagesPrivadas"
              :key="`image-${key}`"
              md="4"
            >
              <component
                :is="blockCardImage"
                :image="row"
              >
                <template slot="blockActions">
                  <div class="grid-view m-0 p-1">
                    <div
                      class="item-options text-center"
                    >
                      <b-form-input
                        v-if="formModeView === false"
                        v-model="row.descricao"
                        type="text"
                        autocomplete="off"
                        @input="updateDescImage(row.id, $event)"
                      />
                      <p
                        v-else
                        class="text-left text-primary mb-0"
                      >
                        {{ row.descricao }}
                      </p>
                    </div>
                  </div>
                </template>
              </component>
            </b-col>
          </b-row>

        </b-container>
      </vue-perfect-scrollbar>

      <!-- Photos Order/Delete -->
      <photos-edition
        :class="{'show': showPhotosEdition}"
        :delete-action="deleteImages"
        :change-order-action="changeOrderImages"
        @close-photos-edition="setShowPhotosEdition(false)"
      />

      <!-- modal -->
      <b-modal
        id="modal-xs-private"
        centered
        size="xl"
        :hide-footer="true"
      >
        <swiper
          class="swiper"
          :options="swiperOptions"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :style="{'--swiper-navigation-color': '#000','--swiper-pagination-color': '#fff'}"
        >
          <swiper-slide
            v-for="data in imagesPrivadas"
            :key="data.src_image"
          >
            <div class="swiper-zoom-container">
              <b-img
                :src="data.src_image + '?v=' + new Date().getTime()"
                class="w-75 image-swiper"
                fluid
              />
            </div>
          </swiper-slide>
          <!-- Add Arrows -->
          <div
            slot="button-next"
            class="swiper-button-next"
          />
          <div
            slot="button-prev"
            class="swiper-button-prev"
          />
          <div
            slot="pagination"
            class="swiper-pagination swiper-pagination-white"
          />
        </swiper>
      </b-modal>

    </div>

  </div>
</template>

<script>
import store from '@/store'
import {
  BContainer, BRow, BCol, BAlert, BButton, BFormInput, BDropdown, BDropdownItem, BFormFile, BModal, VBModal, BImg,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapGetters } from 'vuex'
import modelSW080 from '@store-modules/listings/sw080'
import VuePerfectScrollbar from '@/views/modules/components/vue-perfect-scrollbar.vue'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import { ref, onUnmounted } from '@vue/composition-api'
import { sizeIconsAction } from '@core-custom/mixins/geral'
import { mask } from 'vue-the-mask'
import { showModalDeleteMessage } from '@core/utils/utils'
import { configSettingsScroll } from '@core-custom/utils/ui'
import { mixFormGeral } from '../../mixins'
import PhotosEdition from './sw080/list.vue'
import FooterStepNav from '../footerStepNavTop.vue'
import 'swiper/css/swiper.css'

export default {
  components: {
    BContainer,
    BRow,
    BCol,
    BAlert,
    BButton,
    BFormInput,
    BDropdown,
    BDropdownItem,
    BFormFile,
    BModal,
    VBModal,
    BImg,
    Swiper,
    SwiperSlide,
    VuePerfectScrollbar,
    PhotosEdition,
    FooterStepNav,
  },
  directives: {
    Ripple,
    mask,
  },
  mixins: [sizeIconsAction, mixFormGeral],
  data() {
    return {
      swiperOptions: {
        zoom: true,
        pagination: {
          el: '.swiper-pagination',
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      },
      dragging: false,
      resumeErrorUpload: [],
    }
  },
  computed:
  {
    ...mapGetters('sw080', ['imagesPrivadas', 'showPhotosEdition', 'filterSelected']),
    ...mapGetters('listings_form', ['formModeView']),
    imagesPrivadas: {
      get() {
        return this.$store.getters['sw080/imagesPrivadas']
      },
      set(value) {
        this.$store.commit('sw080/setImagensPrivadas', value)
      },
    },
  },
  methods: {
    async uploadImageRender(aFiles) {
      if (aFiles.length > 0) {
        try {
          this.$root.$emit('app::loading', true)
          await store.dispatch('sw080/uploadSW080', { files: aFiles }).then(result => {
            this.resetFieldUpload()

            Object.keys(result).forEach(index => {
              if (result[index].error === false) {
                this.resumeErrorUpload.push(result[index])
              }
            })

            this.loadImages()
          }).catch(error => {
            this.resetFieldUpload()
            this.showMsgErrorRequest(error)
          })
        } catch (err) {
          this.resetFieldUpload()
        }
      }
    },
    async loadImages() {
      await store.dispatch('sw080/loadImages', {
        loadImagesPrincipais: 1, loadAjax: 1,
      }).then(response => {
        if (typeof response === 'object') {
          this.$store.commit('sw080/setImagensPrivadas', response.imagesAPI)
        } else {
          this.showMsgErrorRequest(this.$t('Problema a carregar as imagens'))
        }
      }).catch(error => {
        this.showMsgErrorRequest(error)
      })
    },
    async changeOrderImages() {
      this.$root.$emit('app::loading', true)

      await store.dispatch('sw080/changeOrderImages', { images: this.imagesPrivadas }).then(response => {
        if (typeof response === 'object' && response.msg === '') {
          this.showMsgSuccessRequest({ message: this.$t('Ordenação alterada com sucesso') })
        } else {
          this.showMsgErrorRequest(this.$t('Problema a trocar a ordenação das imagens'))
        }
      }).catch(error => {
        this.showMsgErrorRequest(error)
      })

      this.$root.$emit('app::loading', false)
    },
    async deleteImages() {
      const self = this

      try {
        if (this.filterSelected.imagesID.value.length > 0) {
          const actionModal = new Promise(resolve => {
            showModalDeleteMessage(resolve, `${this.$t('Pretende eliminar a(s) foto(s)?')}<br>${this.$t('Esta ação é irreversível!')}`)
          })

          await actionModal.then(result => {
            if (result === 'confirm') {
              self.$root.$emit('app::loading', true)

              store.dispatch('sw080/deleteImage', {
                regDel: this.filterSelected.imagesID.value,
              }).then(response => {
                if (response.error === 1) {
                  self.$root.$emit('app::loading', false)
                  self.showMsgErrorRequest(self.$t('Problema a eliminar imagens'))
                } else {
                  self.loadImages()
                  self.showMsgSuccessRequest({ message: response.msg })
                  self.$root.$emit('app::loading', false)
                }
              }).catch(error => {
                self.$root.$emit('app::loading', false)
                self.showMsgErrorRequest(error)
              })
            }
          })
        }
      } catch (err) {
      //
      }
    },
    updateDescImage(idImage, vlDesc) {
      store.dispatch('sw080/updateDescImage', { regImage: idImage, txtDesc: vlDesc }).then(res => {
        if (res.error === 1) {
          this.showMsgErrorRequest(res.msg)
        } else {
          this.$store.commit('sw080/setImagensPrivadas', this.imagesPrivadas)
        }
      }).catch(error => {
        this.showMsgErrorRequest(error)
      })
    },
    setShowPhotosEdition(value) {
      this.$store.commit('sw080/setShowPhotosEdition', value)
    },
    resetFieldUpload() {
      this.$root.$emit('app::loading', false)
      this.resumeErrorUpload = []
      this.$refs.refImagesListing.reset()
    },
  },
  setup() {
    const refImagesListing = ref(null)
    const MODEL_SW080 = 'sw080'
    const blockCardImage = () => import('@/views/modules/listings/form/form_data/private/sw080/cardImage.vue')

    if (!store.hasModule(MODEL_SW080)) {
      store.registerModule(MODEL_SW080, modelSW080)

      onUnmounted(() => {
        if (store.hasModule(MODEL_SW080)) store.unregisterModule(MODEL_SW080)
      })
    }

    return {
      blockCardImage,
      refImagesListing,
      configSettingsScroll,
    }
  },
}
</script>

<style lang="scss" scoped>

.email-app-list .scroll-area {
  height: calc( 100% - calc(1.3rem) )!important;
}

.swiper-container {
  width: 100%;
  height: 100%;
}

.swiper .swiper-slide {
  background: rgb(255, 255, 255);
}

</style>
