<template>
  <div>

    <div
      class="email-app-list position-relative height-100-panel"
    >
      <div
        class="app-fixed-search d-flex align-items-center"
      >
        <div class="d-flex align-items-center flex-grow-1 w-50">

          <div class="sidebar-toggle d-block d-lg-none ml-1">
            <feather-icon
              icon="MenuIcon"
              :size="sizeIconsAction"
              class="cursor-pointer"
              @click="mqShallShowLeftSidebar = true"
            />
          </div>

        </div>
        <div class="d-flex align-items-center w-50 justify-content-end">

          <b-button
            v-if="this.$store.getters['listings_form/formModeView'] === false"
            variant="flat-secondary"
            class="border-radius-none"
            :class="{ 'btn-sm' : ((this.$store.getters['app/currentBreakPoint'] === 'xs') ? true: false)}"
            @click.stop.prevent="createNewSW344"
          >
            <feather-icon
              icon="PlusIcon"
              :size="sizeIconsAction"
            />
          </b-button>

          <footer-step-nav ref="footerStepNav" />

        </div>
      </div>

      <vue-perfect-scrollbar
        class="scroll-content"
        :settings="configSettingsScroll"
      >
        <div
          class="app-fixed-search d-flex align-items-center"
          :class="{'border-0' : ((virtualR.length > 0) ? false : true)}"
        >
          <div class="d-flex flex-column align-items-center">

            <div class="d-flex align-items-center justify-content-start w-100">
              <h4 class="p-1 m-0">
                {{ $t('Realidade virtual') }}
              </h4>
            </div>
            <div
              v-if="this.$store.getters['listings_form/formModeView'] === false"
              class="d-flex align-items-center justify-content-start w-100 pl-1 pr-1 pb-1"
            >
              <b-dropdown
                v-if="virtualR.length !== 0"
                v-ripple.400="'rgba(255, 159, 67, 0.15)'"
                split
                :text="$t('Nova visita virtual')"
                variant="outline-primary"
                @click.stop.prevent="createNewSW344"
              >
                <b-dropdown-item @click="setShowVrEdition(true)">
                  {{ $t('Ordenar / Eliminar VRs') }}
                </b-dropdown-item>
              </b-dropdown>
              <b-button
                v-else
                variant="outline-primary"
                @click.stop.prevent="createNewSW344"
              >
                <span class="align-middle">{{ $t('Nova visita virtual') }}</span>
              </b-button>
            </div>

          </div>
        </div>
        <b-container
          class="p-2"
          fluid
        >
          <b-row
            v-if="virtualR.length === 0"
          >
            <b-col md="12">
              <b-alert
                show
                variant="danger"
              >
                <div class="alert-body">
                  <span class="ml-25">{{ $t('Nenhuma visita virtual inserida') }}</span>
                </div>
              </b-alert>
            </b-col>
          </b-row>

          <b-row class="mt-2">
            <b-col
              v-for="(row, key) in virtualR"
              :key="`image-${key}`"
              md="4"
            >
              <component
                :is="blockCardVR"
                :vr="row"
              >
                <template slot="blockActions">
                  <div class="grid-view m-0 p-1">
                    <div
                      class="item-options text-center"
                    >
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                        :name="$t('Título do VR') | lowerBold"
                      >
                        <b-form-input
                          v-if="formModeView === false"
                          v-model="row.titulo"
                          placeholder=""
                          autocomplete="off"
                          :class="errors.length > 0 ? 'is-invalid' : null"
                          @input="updateTitleVR($event, row.id)"
                        />
                        <p
                          v-else
                          class="text-left text-primary mb-0"
                        >
                          {{ row.titulo }}
                        </p>
                      </validation-provider>
                    </div>
                  </div>
                </template>
              </component>
            </b-col>
          </b-row>
        </b-container>
      </vue-perfect-scrollbar>

    </div>

    <!-- VR Order/Delete -->
    <vr-edition
      :class="{'show': showVrEdition}"
      :delete-action="deleteVRs"
      :order-action="changeOrderVR"
      @close-vr-edition="setShowVrEdition(false)"
    />

    <form-create-new-sw079
      v-if="showFormCreateNew===true"
      ref="modalFormSW344"
      :load-action="loadVRs"
    />

  </div>
</template>

<script>
import store from '@/store'
import {
  BContainer, BRow, BCol, BButton, BAlert, BFormInput, BFormCheckbox, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapGetters } from 'vuex'
import modelSW344 from '@store-modules/listings/sw344'
import VuePerfectScrollbar from '@/views/modules/components/vue-perfect-scrollbar.vue'
import { onUnmounted } from '@vue/composition-api'
import { sizeIconsAction } from '@core-custom/mixins/geral'
import { ValidationProvider } from 'vee-validate'
import { mask } from 'vue-the-mask'
import { showModalDeleteMessage } from '@core/utils/utils'
import { configSettingsScroll } from '@core-custom/utils/ui'
import { mixFormGeral } from '../../mixins'
import FooterStepNav from '../footerStepNavTop.vue'
import formCreateNewSw079 from './sw344/form.vue'
import VrEdition from './sw344/list.vue'

export default {
  components: {
    BContainer,
    BRow,
    BCol,
    BButton,
    BAlert,
    BFormInput,
    BFormCheckbox,
    BDropdown,
    BDropdownItem,
    VuePerfectScrollbar,
    FooterStepNav,
    ValidationProvider,
    formCreateNewSw079,
    VrEdition,
  },
  directives: {
    Ripple,
    mask,
  },
  mixins: [sizeIconsAction, mixFormGeral],
  data() {
    return {
      vrID: [],
      dragging: false,
    }
  },
  computed:
  {
    ...mapGetters('sw344', ['virtualR', 'showVrEdition', 'showFormCreateNew', 'filterSelected']),
    ...mapGetters('listings_form', ['formModeView']),
    virtualR: {
      get() {
        return this.$store.getters['sw344/virtualR']
      },
      set(value) {
        this.$store.commit('sw344/setVRs', value)
      },
    },
  },
  methods: {
    async createNewSW344() {
      this.$root.$emit('app::loading', true)

      setTimeout(() => {
        this.clicked = false
        this.$store.dispatch('sw344/createNewSW344')
      }, 500)
    },
    async loadVRs() {
      await store.dispatch('sw344/loadVRs').then(response => {
        if (typeof response === 'object') {
          this.$store.commit('sw344/setVRs', response.vrsAPI)
        } else {
          this.showMsgErrorRequest(this.$t('Problema a carregar os VR'))
        }
      }).catch(error => {
        this.showMsgErrorRequest(error)
      })
    },
    async updateTitleVR(vlTitle, idVR) {
      this.$root.$emit('app::loading', true)

      await store.dispatch('sw344/updateDescVisitaVirtual', {
        regVisita: idVR, txtDesc: vlTitle,
      }).then(response => {
        if (typeof response === 'object') {
          if (response.error === 1) {
            this.showMsgErrorRequest({ message: response.msg })
          }
        } else {
          this.showMsgErrorRequest(this.$t('Problema a atualizar título do VR'))
        }
      }).catch(error => {
        this.showMsgErrorRequest(error)
      })

      this.$root.$emit('app::loading', false)
    },
    async changeOrderVR() {
      this.$root.$emit('app::loading', true)

      await store.dispatch('sw344/changeOrderVR', { virtualR: this.virtualR }).then(response => {
        if (typeof response === 'object' && response.msg === '') {
          this.showMsgSuccessRequest({ message: this.$t('Ordenação alterada com sucesso') })
        } else {
          this.showMsgErrorRequest(this.$t('Problema a trocar a ordenação dos VRs'))
        }
      }).catch(error => {
        this.showMsgErrorRequest(error)
      })

      this.$root.$emit('app::loading', false)
    },
    async deleteVRs() {
      const self = this

      try {
        if (this.filterSelected.vrID.value.length > 0) {
          const actionModal = new Promise(resolve => {
            showModalDeleteMessage(resolve, `${this.$t('Pretende eliminar o(s) VR(s)?')}<br>${this.$t('Esta ação é irreversível!')}`)
          })

          await actionModal.then(result => {
            if (result === 'confirm') {
              self.$root.$emit('app::loading', true)

              store.dispatch('sw344/deleteVRs', {
                regDel: this.filterSelected.vrID.value,
              }).then(response => {
                if (response.error === 1) {
                  self.$root.$emit('app::loading', false)
                  self.showMsgErrorRequest(self.$t('Problema a eliminar VRs'))
                } else {
                  self.loadVRs()
                  self.showMsgSuccessRequest({ message: response.msg })
                  self.$root.$emit('app::loading', false)
                }
              }).catch(error => {
                self.$root.$emit('app::loading', false)
                self.showMsgErrorRequest(error)
              })
            }
          })
        }
      } catch (err) {
      //
      }
    },
    setShowVrEdition(value) {
      this.$store.commit('sw344/setShowVrEdition', value)
    },
  },
  setup() {
    const MODEL_SW344 = 'sw344'
    const blockCardVR = () => import('@/views/modules/listings/form/form_data/media/sw344/cardVR.vue')

    if (!store.hasModule(MODEL_SW344)) {
      store.registerModule(MODEL_SW344, modelSW344)

      onUnmounted(() => {
        if (store.hasModule(MODEL_SW344)) store.unregisterModule(MODEL_SW344)
      })
    }

    return {
      blockCardVR,
      configSettingsScroll,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import "~@core/scss/base/pages/app-ecommerce.scss";
</style>

<style lang="scss" scoped>

.email-app-list .scroll-area {
  height: calc( 100% - calc(1.3rem) )!important;
}

</style>
