import api from '@core-custom/api/api'
import apiConfig from '@core-custom/api/apiConfig'
import i18n from '@/libs/i18n'
import checkNested from 'check-nested'
import defaultState from './defaultState'

export default {
  namespaced: true,
  state: defaultState,
  getters: {
    listviewInit: state => state.listviewInit.value,
    listviewData: state => state.listviewData.value,
    viewInfoDataSelected: state => state.viewInfoDataSelected.value,
    listviewOrderBy: state => state.listviewOrderBy.value,
    totalRows: state => state.totalRows.value,
    perPage: state => state.perPage.value,
    tabSelectFilter: state => state.tabSelectFilter.value,
    loadingListview: state => state.loadingListview.value,
    currentPage: state => state.currentPage.value,
    searchTxt: state => state.searchTxt.value,
    fieldsOrder: state => state.aFieldsOrder,
    fieldsOrderConcluidos: state => state.aFieldsOrderConcluidos,
    listview_txt_default: state => ({
      txt: state.listview_txt_default,
      txt_not_find: state.listiview_txt_not_find,
      txt_search: state.listiview_txt_search,
      txt_loading: state.listview_txt_loading,
    }),
    filterSelected: state => ({
      visitaSelecionado: state.listviewSelectedVisita.value,
      separadorSelecionado: state.listviewSelectedSeparador.value,
      hubsSelecionados: state.listviewSelectedHubs.value,
      filtroSelecionados: state.listviewSelectedFilter,
      responsavelSelecionado: state.listviewSelectedResponsavel.value,
      totalFields: state.listviewSelectedFields.value,
    }),
    filterData: state => ({
      listviewFilterSeparadores: state.listviewFilterSeparadores,
      listviewFilterHubs: state.listviewFilterHubs.value,
      listviewFilterGeral: state.listviewFilterGeral.value,
      listviewFilterResponsaveis: state.listviewFilterResponsaveis.value,
    }),
    formData070: state => state.formData070,
    formData073: state => state.formData073,
    filterSelectedTotalFields: state => state.filterSelectedTotalFields.value,
    forceHideButtonsVisita: state => state.forceHideButtonsVisita,
    positionListview: state => state.positionListview,
  },
  mutations: {
    resetState(state) {
      Object.assign(state, defaultState)
    },
    setLoadingListview(state, payload) {
      state.loadingListview.value = payload
    },
    setListviewData(state, payload) {
      state.listviewData.value = payload
    },
    setTotalRows(state, payload) {
      state.totalRows.value = payload
    },
    setFilterData(state, payload) {
      const index = Object.keys(payload)[0]
      state[index].value = payload[index]
    },
    setFilterSelected(state, payload) {
      const index = Object.keys(payload)[0]
      state[index].value = payload[index]
    },
    setFilterOptionsSelected(state, payload) {
      const nameParam = Object.keys(payload)[0]
      const valueParam = payload[nameParam]

      const indexItem = state[nameParam].value.indexOf(valueParam)
      if (indexItem === -1) {
        state[nameParam].value.push(valueParam)
      } else {
        state[nameParam].value.splice(indexItem, 1)
      }

      if (state[nameParam].value.includes('all')) {
        const indexItemAll = state[nameParam].value.indexOf('all')
        state[nameParam].value.splice(indexItemAll, 1)
      }

      if (state[nameParam].value.length === 0) {
        state[nameParam].value.push('all')
      }

      if (valueParam === 'all') {
        state[nameParam].value = []
        state[nameParam].value.push('all')
      }
    },
    setSearchTxt(state, payload) {
      state.searchTxt.value = payload
    },
    setCurrentPage(state, payload) {
      state.currentPage.value = payload
    },
    setTabSelectFilter(state, payload) {
      state.listviewSelectedSeparador.value = payload
      state.tabSelectFilter.value = payload
    },
    setListviewOrderBy(state, payload) {
      state.listviewOrderBy.value = {
        sort: payload.sort,
        column: payload.column,
      }
    },
    setListviewInit(state, payload) {
      state.listviewInit.value = payload
    },
    addFilterSelectedTotalFields(state) {
      state.filterSelectedTotalFields.value += 1
    },
    resetFilterSelectedTotalFields(state) {
      state.filterSelectedTotalFields.value = 0
    },
    resetAllFilterSelected(state) {
      state.filterSelectedTotalFields.value = 0
      state.listviewSelectedVisita.value = ''
      state.listviewSelectedHubs.value = ''
      state.listviewSelectedResponsavel = ''
      state.listviewSelectedFilter.value = ['all']
      state.searchTxt.value = ''
    },
    setfltResponsavel(state, payload) {
      if (payload === null) {
        state.listviewSelectedResponsavel.value = ''
      } else {
        state.listviewSelectedResponsavel.value = payload
      }
    },
    setfltHub(state, payload) {
      if (payload === null) {
        state.listviewSelectedHubs.value = ''
      } else {
        state.listviewSelectedHubs.value = payload
      }
    },
    setIntervaloDataInicio(state, payload) {
      if (payload === null) {
        state.listviewSelectedIntervaloDataInicio.value = ''
      } else {
        state.listviewSelectedIntervaloDataInicio.value = payload
      }
    },
    setIntervaloDataFim(state, payload) {
      if (payload === null) {
        state.listviewSelectedIntervaloDataFim.value = ''
      } else {
        state.listviewSelectedIntervaloDataFim.value = payload
      }
    },
    setVisita(state, payload) {
      state.listviewSelectedVisita.value = payload
    },
    setforceHideButtonsVisita(state, payload) {
      if (payload === null) {
        state.forceHideButtonsVisita = false
      } else {
        state.forceHideButtonsVisita = payload
      }
    },
    savePositionListview(state, payload) {
      state.positionListview = payload || {}
    },
    setHistoryKeyStorage(state, payload) {
      state.historyKeyStorage = payload || ''
    },
    saveStates(state) {
      if (state.historyKeyStorage !== '') {
        const oStatesHistory = []

        state.statesToLocalStorage.forEach(elm => {
          if (state[elm] !== undefined) {
            oStatesHistory.push({
              key: elm,
              value: state[elm],
            })
          }
        })

        localStorage.setItem(state.historyKeyStorage, JSON.stringify(oStatesHistory))
      }
    },
    initStates(state) {
      if (state.historyKeyStorage !== '') {
        if ((localStorage.getItem(state.historyKeyStorage) !== null) && (localStorage.getItem(state.historyKeyStorage) !== '')) {
          try {
            const stateCustom = JSON.parse(localStorage.getItem(state.historyKeyStorage))

            if (stateCustom.length > 0) {
              // Verifica se tem o tabulador selecionado, senao fica perde as preferencias todas
              let bSkipLoadPreferencesListview = false

              stateCustom.forEach(field => {
                if (('key' in field) && ('value' in field)) {
                  if (state.statesToLocalStorage.includes(field.key)) {
                    if (field.key === 'tabSelectFilter') {
                      if (field.value.value === '') {
                        bSkipLoadPreferencesListview = true
                      }
                    }
                  }
                }
              })

              if (bSkipLoadPreferencesListview === false) {
                stateCustom.forEach(field => {
                  if (('key' in field) && ('value' in field)) {
                    if (state.statesToLocalStorage.includes(field.key)) {
                      Object.assign(state[field.key], field.value)
                    }
                  }
                })
              }
            }
          } catch (err) {
            localStorage.removeItem(state.historyKeyStorage)
            //
          }
        }
      }
    },
  },
  actions: {
    // Carregar listagem
    async fetchListView({
      state, commit, dispatch, rootGetters,
    }, payload) {
      const listviewFilterParams = new FormData()

      commit('resetFilterSelectedTotalFields')

      await dispatch('request/cancelTokensPendingByUrl', 'leads/visitas/loadListviewVisitas/', { root: true })
      await dispatch('request/cancelTokensPendingByUrl', 'leads/visitas/loadList/', { root: true })

      // if (state.listviewSelectedSeparador.value > 0) {
      //   listviewFilterParams.append('sw404s07', state.listviewSelectedSeparador.value)
      // }

      if (state.listviewSelectedHubs !== undefined && state.listviewSelectedHubs.value !== '' && state.listviewSelectedHubs.value !== null && state.listviewSelectedHubs.value.id !== null) {
        listviewFilterParams.append('fsw002s01', Buffer.from(state.listviewSelectedHubs.value.id).toString('base64'))
        commit('addFilterSelectedTotalFields')
      }

      if (state.listviewSelectedFilter.value.length > 0) {
        let alltag
        state.listviewSelectedFilter.value.forEach(val => {
          listviewFilterParams.append('sFiltro[]', val)
          alltag = val
        })
        if (state.listviewSelectedFilter.value.length > 1 || alltag !== 'all') {
          commit('addFilterSelectedTotalFields')
        }
      }

      if (((Array.isArray(state.listviewSelectedResponsavel.value) && state.listviewSelectedResponsavel.value.length > 0) || !Array.isArray(state.listviewSelectedResponsavel.value)) && state.listviewSelectedResponsavel.value !== undefined && state.listviewSelectedResponsavel.value !== '' && state.listviewSelectedResponsavel.value !== null) {
        listviewFilterParams.append('fsw070s02', Buffer.from(state.listviewSelectedResponsavel.value.id).toString('base64'))
        commit('addFilterSelectedTotalFields')
      }

      if (state.listviewOrderBy.value.column !== undefined) {
        listviewFilterParams.append('order[0][column]', state.listviewOrderBy.value.column)
      }

      if (state.listviewOrderBy.value.sort !== undefined) {
        listviewFilterParams.append('order[0][dir]', state.listviewOrderBy.value.sort)
      }

      if (state.searchTxt.value !== '' && state.searchTxt.value !== null) {
        listviewFilterParams.append('search[value]', state.searchTxt.value)
      }

      listviewFilterParams.append('fstartLetter', 'all')
      listviewFilterParams.append('loadContentRecords', 1)
      listviewFilterParams.append('start', ((state.currentPage.value - 1) * state.perPage.value))
      listviewFilterParams.append('length', state.perPage.value)

      // Por defeito, caso nao seja selecionada a pagina, faz sempre reset para a pagina "1"
      if ((payload !== null) && (payload !== undefined) && ('skipCurrentPage' in payload) && (payload.skipCurrentPage === true)) {
        // Fix para nao alterar a pagina que foi selecionada pelo utilizador
      } else if (state.currentPage.value !== 1) {
        commit('setCurrentPage', 1)
      }

      if (!!payload && (payload.loadingDisable !== undefined) && (payload.loadingDisable === true)) {
        commit('setListviewInit', 1)
      } else {
        commit('setListviewData', [])
        commit('setTotalRows', 0)
        commit('setLoadingListview', true)
        commit('setListviewInit', 1)
      }

      commit('saveStates')

      dispatch('request/cancelTokensPending', null, { root: true })
      const oCurrentUser = rootGetters['auth/currentUser']

      // if (checkNested(oCurrentUser, 'id_role') && (oCurrentUser.id_role === 4) && checkNested(oCurrentUser, 'extras.basic') && (oCurrentUser.extras.basic === 1)) {
      let loadListviewCustom = ''

      if (checkNested(oCurrentUser, 'id_role') && (oCurrentUser.id_role === 3 || oCurrentUser.id_role === 4 || oCurrentUser.id_role === 7)) {
        loadListviewCustom = 'leads/visitas/loadList/'
      } else {
        loadListviewCustom = 'leads/visitas/loadListviewVisitas/'
      }

      return new Promise((resolve, reject) => {
        api.post(`${apiConfig.url_base_api}${loadListviewCustom}${state.tabSelectFilter.value}`, listviewFilterParams)
          .then(response => {
            if (response.data.data !== undefined) {
              commit('setListviewData', response.data.data)
              commit('setTotalRows', response.data.recordsTotal)
              commit('setLoadingListview', false)
              commit('setListviewInit', 0)
              resolve(true)
            } else {
              commit('setListviewData', [])
              commit('setTotalRows', 0)
              commit('setLoadingListview', false)
              commit('setListviewInit', 0)
              reject(new Error('Problema ao carregar a listagem'))
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              commit('setListviewData', [])
              commit('setTotalRows', 0)
              commit('setLoadingListview', false)
              commit('setListviewInit', 0)
              reject(new Error(i18n.t('Problema ao carregar a listagem')))
            }
          })
      })
    },

    // Obter os filtros da listagem
    async getDataFiltersListview({ commit, rootGetters }) {
      return new Promise((resolve, reject) => {
        api.get(`${apiConfig.url_base_api}leads/visitas/list`)
          .then(response => {
            try {
              if (response.data.data !== undefined) {
                if (typeof response.data.data.HUBsMoradas !== 'undefined') {
                  const aMoradasAvailable = []

                  Object.entries(response.data.data.HUBsMoradas).forEach(element => {
                    aMoradasAvailable.push({ id: element[1].id, desc: `${element[1].mkt} ${element[1].zona}` })
                  })

                  commit('setFilterData', { listviewFilterHubs: aMoradasAvailable })
                } else {
                  commit('setforceHideButtonsVisita', false)
                }

                if (typeof response.data.data.filtroConsultor !== 'undefined') {
                  const oCurrentUser = rootGetters['auth/currentUser']

                  const aFilter = []
                  aFilter.push({ id: 'all', desc: 'Todas' })
                  aFilter.push({ id: 'angAtivas', desc: 'Angariações ativas' })

                  if (checkNested(oCurrentUser, 'extras.basic') && (oCurrentUser.extras.basic === 1)) {
                    aFilter.push({ id: 'minhas', desc: 'As minhas angariações' })
                  } else if ((oCurrentUser.extras.cargos !== undefined) && (oCurrentUser.extras.cargos.includes(0))) {
                    aFilter.push({ id: 'minhas', desc: 'As minhas angariações' })
                  }

                  aFilter.push({ id: 'meu_HUB', desc: 'Do meu HUB' })
                  aFilter.push({ id: 'externas', desc: 'Externas' })
                  aFilter.push({ id: 'markedByMe', desc: 'Marcadas por mim' })

                  if (checkNested(oCurrentUser, 'extras.basic') && (oCurrentUser.extras.basic === 1)) {
                    aFilter.push({ id: 'markedByOthers', desc: 'Marcadas por terceiros' })
                  } else {
                    if ((oCurrentUser.extras.cargos !== undefined) && (oCurrentUser.extras.cargos.includes(0))) {
                      aFilter.push({ id: 'markedByOthers', desc: 'Marcadas por terceiros' })
                    } else {
                      aFilter.push({ id: 'markedByOthers', desc: 'Marcadas por membros da equipa' })
                    }
                    if ((oCurrentUser.extras.perm !== undefined) && (oCurrentUser.extras.perm.includes('ve_edita_visitas_equipa'))) {
                      aFilter.push({ id: 'markedToMembersTeam', desc: 'Marcadas pelos membros da equipa' })
                    }

                    if ((oCurrentUser.extras.perm !== undefined) && (oCurrentUser.extras.perm.includes('receciona_visitas_leader'))) {
                      if ((oCurrentUser.extras.cargos !== undefined) && (!oCurrentUser.extras.cargos.includes(0))) {
                        aFilter.push({ id: 'listingsByLeader', desc: `Angariações de ${oCurrentUser.extras.nomeLeader}` })
                        aFilter.push({ id: 'markedToLeader', desc: `Marcadas para o ${oCurrentUser.extras.nomeLeader}` })
                      }
                    }
                  }

                  commit('setFilterData', { listviewFilterGeral: aFilter })
                }

                if (typeof response.data.data.consultoresTeamFilter !== 'undefined') {
                  const aResponsaveis = []

                  Object.entries(response.data.data.consultoresTeamFilter).forEach(element => {
                    aResponsaveis.push({ id: element[0], desc: element[1] })
                  })

                  aResponsaveis.sort((a, b) => a.desc.localeCompare(b.desc))

                  commit('setFilterData', { listviewFilterResponsaveis: aResponsaveis })
                }
              }

              resolve(true)
            } catch (error) {
              if ((error.response !== undefined) && (error.response.status !== undefined)) {
                reject(new Error(i18n.t('Problema ao carregar o filtro')))
              }
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao carregar o filtro')))
            }
          })
      })
    },

    // Carrega os filtros conforme o separador ativo
    async showFiltersListview({ state, commit, dispatch }) {
      if (state.filterDataTabsListview.value.length > 0) {
        const oTabsListviewActive = state.filterDataTabsListview.value.filter(oTab => oTab.show === true)

        try {
          if (oTabsListviewActive[0].key) {
            commit('setTabSelectFilter', oTabsListviewActive[0].key)
            dispatch('showFiltersListviewByTabSelected')
          }
        } catch {
        //
        }
      }
    },

    showFiltersListviewByTabSelected({ commit }) {
      commit('setShowFiltersListviewByTabSelected')
      commit('setFilterData', { filterDataLoad: true })
    },

    // Ordenação da listagem
    listviewFilterOrderBy({ state, commit, dispatch }, field) {
      if ((!!state.listviewOrderBy.value.column) && (state.listviewOrderBy.value.column === field)) {
        if (state.listviewOrderBy.value.sort === 'asc') {
          commit('setListviewOrderBy', {
            sort: 'desc',
            column: field,
          })
        } else {
          commit('setListviewOrderBy', {
            sort: 'asc',
            column: field,
          })
        }
      } else {
        commit('setListviewOrderBy', {
          sort: 'asc',
          column: field,
        })
      }

      return dispatch('fetchListView')
    },

    setlistviewFilterOrderBy({ commit }, field) {
      commit('setListviewOrderBy', {
        sort: 'desc',
        column: field,
      })
    },

    setLeadIDSelected({ commit }, idLead) {
      commit('setSelectedLeadsID', idLead)
    },

    setSearchTxt({ commit, dispatch }, value) {
      commit('setSearchTxt', value)
      commit('setCurrentPage', 1)
      return dispatch('fetchListView', { loadingDisable: true })
    },

    setCurrentPage({ commit, dispatch }, value) {
      commit('setCurrentPage', value)
      return dispatch('fetchListView', { skipCurrentPage: true })
    },

    pullToRefreshListview({ commit, dispatch }) {
      commit('setCurrentPage', 1)
      return dispatch('fetchListView', { loadingDisable: true })
    },

    // Metodos do filtro lateral - Escolher responsavel
    listviewFilterUserResponsable({ commit, dispatch }, oResponsableSelected) {
      if ((oResponsableSelected !== null) && (!!oResponsableSelected.id)) {
        let oResponsable = {}
        oResponsable = oResponsableSelected
        oResponsable.id = btoa(oResponsableSelected.id)

        commit('setUserResponsable', oResponsable)
      } else {
        commit('setUserResponsable', oResponsableSelected)
      }

      return dispatch('fetchListView')
    },

    // Metodos do filtro lateral - Alterar separador
    listviewFilterTab({ commit, dispatch }, tab) {
      commit('setTabSelectFilter', tab)
      // commit('resetAllFilterSelected')
      // commit('setShowFiltersListviewByTabSelected')
      return dispatch('fetchListView')
    },

    // filtro geral
    listviewFilterOption({ commit, dispatch }, option) {
      commit('setFilterOptionsSelected', { listviewSelectedFilter: option })
      return dispatch('fetchListView')
    },

    // Metodos do filtro lateral - Filtro geral dos blocos
    listviewFilterGeral({ state, dispatch }, params) {
      try {
        const nameParam = Object.keys(params)[0]
        const valueParam = params[nameParam]

        const indexItem = state[nameParam].value.indexOf(valueParam)
        if (indexItem === -1) {
          state[nameParam].value.push(valueParam)
        } else {
          state[nameParam].value.splice(indexItem, 1)
        }

        if (state[nameParam].value.includes('all')) {
          const indexItemAll = state[nameParam].value.indexOf('all')
          state[nameParam].value.splice(indexItemAll, 1)
        }

        if (state[nameParam].value.length === 0) {
          state[nameParam].value.push('all')
        }

        if (valueParam === 'all') {
          state[nameParam].value = []
          state[nameParam].value.push('all')
        }

        if (nameParam === 'filterSelectedTiposClienteLead') {
          if (state[nameParam].value.includes('2') || state[nameParam].value.includes('4')) {
            state.filterSelectedShowStatusComprador.value = true
          } else {
            state.filterSelectedShowStatusComprador.value = false
            state.filterSelectedEstadosLeadComprador.value = []
            state.filterSelectedEstadosLeadComprador.value.push('all')
          }

          if (state[nameParam].value.includes('1') || state[nameParam].value.includes('3')) {
            state.filterSelectedShowStatusVendedor.value = true
          } else {
            state.filterSelectedShowStatusVendedor.value = false
            state.filterSelectedEstadosLeadVendedor.value = []
            state.filterSelectedEstadosLeadVendedor.value.push('all')
          }
        }

        return dispatch('fetchListView')
      } catch {
        return null
      }
    },

    // Limpar todos os filtros selecionados
    resetListviewFilterGeral({ commit, dispatch }) {
      commit('resetAllFilterSelected')
      return dispatch('fetchListView')
    },

    selectFilterOptions({ commit, dispatch }, params) {
      if (params[0] === 'fltResponsavel') {
        commit('setfltResponsavel', params[1])
      }
      if (params[0] === 'fltHub') {
        commit('setfltHub', params[1])
      }

      return dispatch('fetchListView')
    },

    selectFilterIntervaloDataInicio({ commit, dispatch }, value) {
      commit('setIntervaloDataInicio', value)
      dispatch('fetchListView')
    },

    selectFilterIntervaloDataFim({ commit, dispatch }, value) {
      commit('setIntervaloDataFim', value)
      dispatch('fetchListView')
    },

    async getInfoVisita({ state, dispatch, commit }, payload) {
      let urlCustom = 'formNewVisitaData'
      commit('setVisita', payload.sw070s01)
      if (state.listviewSelectedSeparador.value === 3 || (payload.idSeparador !== undefined && payload.idSeparador !== null && payload.idSeparador === 3)) {
        urlCustom = 'formFillRelVisitaImovelData'
      }

      await dispatch('request/cancelTokensPendingByUrl', 'leads/visitas/formFillRelVisitaImovelData', { root: true })
      await dispatch('request/cancelTokensPendingByUrl', 'leads/visitas/formNewVisitaData', { root: true })

      return new Promise((resolve, reject) => {
        const formData = new FormData()
        formData.append('visita', payload.sw070s01 || '')
        formData.append('mode', 'view')

        api.post(`${apiConfig.url_base_api}leads/visitas/${urlCustom}`, formData)
          .then(response => {
            try {
              state.formData070 = response.data.data.sw070
              if (response.data.data.sw073 !== undefined && response.data.data.sw073 !== null) {
                state.formData073 = response.data.data.sw073
              }
              resolve(response.data)
            } catch (error) {
              if ((error.response !== undefined) && (error.response.status !== undefined)) {
                reject(new Error(i18n.t('Problema ao carregar o filtro')))
              }
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao carregar o filtro')))
            }
          })
      })
    },

    async saveModalReport({ dispatch }, payload) {
      await dispatch('request/cancelTokensPendingByUrl', 'leads/visitas/saveRelVisitaImovel', { root: true })

      return new Promise((resolve, reject) => {
        const formData = new FormData()

        if (payload.sw073s02 !== undefined && payload.sw073s02 !== null) {
          formData.append('sw073s02', payload.sw073s02 || '')
        } else {
          formData.append('sw070s01', payload.sw070s01 || '')
        }

        formData.append('sw073s03', payload.sw073s03 || '')
        formData.append('sw073s04', payload.sw073s04 || '')
        formData.append('sw073s05', payload.sw073s05 || '')
        formData.append('sw073s06', payload.sw073s06 || '')
        formData.append('sw073s07', payload.sw073s07 || '')
        formData.append('sw073s08', payload.sw073s08 || '')
        formData.append('sw073s09', payload.sw073s09 || '')
        formData.append('sw073s10', payload.sw073s10 || '')
        formData.append('sw073s11', payload.sw073s11 || '')
        formData.append('sw073s12', payload.sw073s12 || '')

        api.post(`${apiConfig.url_base_api}leads/visitas/saveRelVisitaImovel`, formData)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao guardar o relatório')))
            }
          })
      })
    },
    loadModalReportData({ state }) {
      return state.formData073
    },
    async eliminarVisita({ state }) {
      if (state.listviewSelectedVisita.value !== undefined && state.listviewSelectedVisita.value !== null) {
        return new Promise((resolve, reject) => {
          const formData = new FormData()
          formData.append('regDel', state.listviewSelectedVisita.value || '')

          api.post(`${apiConfig.url_base_api}leads/visitas/deleteVisita`, formData)
            .then(response => {
              resolve(response.data)
            })
            .catch(error => {
              if ((error.response !== undefined) && (error.response.status !== undefined)) {
                reject(new Error(i18n.t('Problema ao eliminar a visita')))
              }
            })
        })
      }
      return new Error(i18n.t('Problema ao eliminar a visita'))
    },

    async concluirSemRelatorio({ commit, state }, payload) {
      if (payload !== undefined && payload !== null) {
        commit('setVisita', payload)
      }

      if (state.listviewSelectedVisita.value !== undefined && state.listviewSelectedVisita.value !== null) {
        return new Promise((resolve, reject) => {
          const formData = new FormData()
          formData.append('sw070s01', state.listviewSelectedVisita.value || '')

          api.post(`${apiConfig.url_base_api}leads/visitas/updateCloseVisita`, formData)
            .then(response => {
              resolve(response.data)
            })
            .catch(error => {
              if ((error.response !== undefined) && (error.response.status !== undefined)) {
                reject(new Error(i18n.t('Problema ao concluir a visita')))
              }
            })
        })
      }
      return new Error(i18n.t('Problema ao concluir a visita'))
    },

    async cancelarVisita({ state }, payload) {
      if (state.listviewSelectedVisita.value !== undefined && state.listviewSelectedVisita.value !== null) {
        return new Promise((resolve, reject) => {
          const formData = new FormData()
          formData.append('sw070s01', state.listviewSelectedVisita.value || '')
          formData.append('sw070s44', payload || '')
          api.post(`${apiConfig.url_base_api}leads/visitas/cancelarVisita`, formData)
            .then(response => {
              resolve(response.data)
            })
            .catch(error => {
              if ((error.response !== undefined) && (error.response.status !== undefined)) {
                reject(new Error(i18n.t('Problema ao cancelar a visita')))
              }
            })
        })
      }
      return new Error(i18n.t('Problema ao cancelar a visita'))
    },
    async rejeitarVisita({ state }, payload) {
      if (state.listviewSelectedVisita.value !== undefined && state.listviewSelectedVisita.value !== null) {
        return new Promise((resolve, reject) => {
          const formData = new FormData()
          formData.append('sw070s01', state.listviewSelectedVisita.value || '')
          formData.append('sw070s12', `${payload.dataSugestao} ${payload.horaSugestao}` || '')
          formData.append('sw070s44', payload.motivo || '')
          formData.append('action', 'reject')
          api.post(`${apiConfig.url_base_api}leads/visitas/cancelarVisita`, formData)
            .then(response => {
              resolve(response.data)
            })
            .catch(error => {
              if ((error.response !== undefined) && (error.response.status !== undefined)) {
                reject(new Error(i18n.t('Problema ao rejeitar a visita')))
              }
            })
        })
      }
      return new Error(i18n.t('Problema ao rejeitar a visita'))
    },
    setVisitaID({ commit }, payload) {
      commit('setVisita', payload)
    },
  },
}
