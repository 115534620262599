var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-sidebar',{attrs:{"sidebar-class":"sidebar-lg","visible":"","bg-variant":"white","shadow":"","backdrop":"","no-header":"","right":""},on:{"change":_vm.closeModalForm},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hide = ref.hide;
return [_c('div',{staticClass:"d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"},[_c('h5',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.$t('Nova visita virtual'))+" ")]),_c('div',[_c('feather-icon',{staticClass:"ml-1 cursor-pointer",attrs:{"icon":"XIcon","size":_vm.sizeIconsAction},on:{"click":hide}})],1)]),_c('vue-perfect-scrollbar',{ref:"scroll",staticClass:"b-sidebar-scrollarea-form",attrs:{"settings":_vm.configSettingsScroll}},[_c('b-container',{staticClass:"pt-2 pb-0 pl-2 pr-2",attrs:{"fluid":""}},[_c('b-form',{ref:"formSave",on:{"submit":function($event){$event.preventDefault();}}},[_c('validation-observer',{ref:"formSaveValidatorSW344",scopedSlots:_vm._u([{key:"default",fn:function(ref){return [_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-alert',{attrs:{"show":"","variant":"primary"}},[_c('div',{staticClass:"alert-body"},[_c('feather-icon',{staticClass:"mr-25",attrs:{"icon":"AlertTriangleIcon"}}),_c('span',{staticClass:"ml-25"},[_vm._v(_vm._s(_vm.$t('Os VRs que não usarem URLs oficiais das plataformas podem ser recusados pelos portais parceiros.')))])],1)])],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{class:errors.length > 0 ? 'is-invalid':null,attrs:{"label":_vm.$t('Plataforma')}},[_c('v-select',{class:errors.length > 0 ? 'is-invalid':null,attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.platformsVR,"append-to-body":"","calculate-position":_vm.withPopper,"placeholder":"","label":"sw396s02","item-text":"sw396s02","item-value":"sw396s01"},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var sw396s02 = ref.sw396s02;
return [_vm._v(" "+_vm._s(sw396s02)+" ")]}}],null,true),model:{value:(_vm.plataforma),callback:function ($$v) {_vm.plataforma=$$v},expression:"plataforma"}},[_c('div',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v(" "+_vm._s(_vm.$t('Sem resultados'))+" ")])])],1)]}}],null,true)})],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('URL')}},[_c('b-form-input',{class:errors.length > 0 ? 'is-invalid':null,attrs:{"placeholder":"","autocomplete":"off"},model:{value:(_vm.url),callback:function ($$v) {_vm.url=$$v},expression:"url"}})],1)]}}],null,true)})],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Título')}},[_c('b-form-input',{class:errors.length > 0 ? 'is-invalid':null,attrs:{"placeholder":"","autocomplete":"off"},model:{value:(_vm.titulo),callback:function ($$v) {_vm.titulo=$$v},expression:"titulo"}})],1)]}}],null,true)})],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Descritivo')}},[_c('b-form-input',{attrs:{"placeholder":"","autocomplete":"off"},model:{value:(_vm.descritivo),callback:function ($$v) {_vm.descritivo=$$v},expression:"descritivo"}})],1)],1)],1)]}}],null,true)})],1)],1)],1),_c('div',{staticClass:"d-flex p-1 justify-content-center"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-1",attrs:{"variant":"primary","type":"submit"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.saveFormSW344($event)}}},[_vm._v(" "+_vm._s(_vm.$t('Guardar'))+" ")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(234, 84, 85, 0.15)'),expression:"'rgba(234, 84, 85, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-2",attrs:{"variant":"outline-danger"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.clearFormSW344($event)}}},[_vm._v(" "+_vm._s(_vm.$t('Limpar'))+" ")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"outline-secondary"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return hide($event)}}},[_vm._v(" "+_vm._s(_vm.$t('Fechar'))+" ")])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }