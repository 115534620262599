import { ref } from '@vue/composition-api'

const defaultState = () => ({
  // Documentos
  documentos: ref([]),
  docsPrivados: ref([]),
  filterSelectedDocsID: ref([]),
})

export default defaultState
