import api from '@core-custom/api/api'
import apiConfig from '@core-custom/api/apiConfig'
import i18n from '@/libs/i18n'
import defaultState from './defaultState'

export default {
  namespaced: true,
  state: defaultState,
  getters: {
    listviewInit: state => state.listviewInit.value,
    listviewData: state => state.listviewData.value,
    listviewOrderBy: state => state.listviewOrderBy.value,
    totalRows: state => state.totalRows.value,
    perPage: state => state.perPage.value,
    loadingListview: state => state.loadingListview.value,
    currentPage: state => state.currentPage.value,
    searchTxt: state => state.searchTxt.value,
    fieldsOrder: state => state.aFieldsOrder,
    sortByOptions: state => state.sortByOptions,
    totalRegs: state => state.totalRegs.value,
    listview_txt_default: state => ({
      txt: state.listview_txt_default,
      txt_not_find: state.listiview_txt_not_find,
      txt_loading: state.listview_txt_loading,
    }),
    showPager: state => {
      if (state.totalRows.value > state.perPage.value) {
        return true
      }

      return false
    },
  },
  mutations: {
    setLoadingListview(state, payload) {
      state.loadingListview.value = payload
    },
    setListviewData(state, payload) {
      state.listviewData.value = payload
    },
    setTotalRows(state, payload) {
      state.totalRows.value = payload
    },
    setSearchTxt(state, payload) {
      state.searchTxt.value = payload
    },
    setCurrentPage(state, payload) {
      state.currentPage.value = payload
    },
    setListviewOrderBy(state, payload) {
      state.listviewOrderBy.value = {
        sort: payload.sort,
        column: payload.column,
      }
    },
    setListviewInit(state, payload) {
      state.listviewInit.value = payload
    },
    setTotalRegs(state, payload) {
      state.totalRegs.value = payload
    },
  },
  actions: {
    // Carregar listagem de versões
    fetchListView({
      state, commit, dispatch, rootGetters,
    }, payload) {
      const listviewFilterParams = new FormData()

      listviewFilterParams.append('sw082s02', rootGetters['listings_form/keyReg'])

      if (state.currentPage.value !== 1) {
        listviewFilterParams.append('start', ((state.currentPage.value - 1) * state.perPage.value))
      } else {
        listviewFilterParams.append('start', (state.currentPage.value - 1))
      }

      listviewFilterParams.append('length', state.perPage.value)

      if (state.searchTxt.value !== '') {
        listviewFilterParams.append('search[value]', state.searchTxt.value)
        listviewFilterParams.append('search[regex]', false)
      }

      if (state.listviewOrderBy.value.column !== undefined) {
        listviewFilterParams.append('order[0][column]', state.listviewOrderBy.value.column)
      }

      if (state.listviewOrderBy.value.sort !== undefined) {
        listviewFilterParams.append('order[0][dir]', state.listviewOrderBy.value.sort)
      }

      if (!!payload && (payload.loadingDisable !== undefined) && (payload.loadingDisable === true)) {
        // Nao executa o loading
      } else {
        commit('setLoadingListview', true)
      }

      return new Promise((resolve, reject) => { // reject
        dispatch('request/cancelTokensPending', null, { root: true })

        if (rootGetters['listings_form/keyReg'] !== '') {
          api.post(`${apiConfig.url_base_api}listings/loadListMovimentosListing`, listviewFilterParams)
            .then(response => {
              commit('setListviewData', response.data.data)
              commit('setTotalRegs', response.data.recordsTotal)
              commit('setTotalRows', response.data.recordsTotal)
              commit('setLoadingListview', false)
              commit('setListviewInit', 0)
              resolve(true)
            })
            .catch(error => {
              commit('setLoadingListview', false)
              if ((error.response !== undefined) && (error.response.status !== undefined)) {
                reject(new Error(i18n.t('Problema ao carregar listagem')))
              }
            })
        } else {
          commit('setTotalRegs', 0)
          commit('setTotalRows', 0)
          commit('setLoadingListview', false)
          commit('setListviewInit', 0)
          resolve(true)
        }
      })
    },

    // Ordenação da listagem
    listviewFilterOrderBy({ state, commit, dispatch }, field) {
      if ((!!state.listviewOrderBy.value.column) && (state.listviewOrderBy.value.column === field)) {
        if (state.listviewOrderBy.value.sort === 'asc') {
          commit('setListviewOrderBy', {
            sort: 'desc',
            column: field,
          })
        } else {
          commit('setListviewOrderBy', {
            sort: 'asc',
            column: field,
          })
        }
      } else {
        commit('setListviewOrderBy', {
          sort: 'asc',
          column: field,
        })
      }

      return dispatch('fetchListView')
    },

    setSearchTxt({ commit, dispatch }, payload) {
      commit('setSearchTxt', payload)
      dispatch('fetchListView')
    },

    setCurrentPage({ commit, dispatch }, value) {
      commit('setCurrentPage', value)
      return dispatch('fetchListView')
    },
  },
}
