<template>
  <div>

    <b-button
      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
      v-b-tooltip
      variant="flat-secondary"
      class="btn-icon border-radius-none"
      :class="{ 'btn-sm' : ((this.$store.getters['app/currentBreakPoint'] === 'xs') ? true: false)}"
      :title="$t('Anterior')"
      :disabled="footerStepNavArrowPrevDisable===true"
      @click.stop.prevent="tabContentStepShow-=1;"
    >
      <feather-icon
        :icon="$store.state.appConfig.isRTL ? 'ChevronRightIcon' : 'ChevronLeftIcon'"
        :size="sizeIconsAction"
      />
    </b-button>

    <b-button
      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
      v-b-tooltip
      variant="flat-secondary"
      class="btn-icon border-radius-none"
      :class="{ 'btn-sm' : ((this.$store.getters['app/currentBreakPoint'] === 'xs') ? true: false)}"
      :title="$t('Seguinte')"
      :disabled="footerStepNavArrowNextDisable===true"
      @click.stop.prevent="tabContentStepShow+=1;"
    >
      <feather-icon
        :icon="$store.state.appConfig.isRTL ? 'ChevronLeftIcon' : 'ChevronRightIcon'"
        :size="sizeIconsAction"
      />
    </b-button>

  </div>
</template>

<script>
import {
  VBTooltip, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { sizeIconsAction } from '@core-custom/mixins/geral'

export default {
  components: {
    BButton,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  mixins: [sizeIconsAction],
  data() {
    return {
      footerStepNavArrowPrevDisable: false,
      footerStepNavArrowNextDisable: false,
    }
  },
  computed:
  {
    tabContentStepShow: {
      get() { return this.$store.getters['listings_form/tabContentStepShow'] },
      set(newValue) {
        this.$store.dispatch('listings_form/setTabContentStepShow', newValue)
      },
    },
  },
  methods: {
    updateStatusArrowNavDisableFooter(currentTab, totalTabs) {
      if (currentTab === 0) {
        this.footerStepNavArrowPrevDisable = true
        this.footerStepNavArrowNextDisable = false
      } else if (currentTab === (totalTabs - 1)) {
        this.footerStepNavArrowPrevDisable = false
        this.footerStepNavArrowNextDisable = true
      } else {
        this.footerStepNavArrowPrevDisable = false
        this.footerStepNavArrowNextDisable = false
      }
    },
  },
}
</script>
