<template>
  <div>

    <div
      class="email-app-list position-relative height-100-panel"
    >
      <div
        class="app-fixed-search d-flex align-items-center"
      >
        <div class="d-flex align-items-center flex-grow-1 w-50">

          <div class="sidebar-toggle d-block d-lg-none ml-1">
            <feather-icon
              icon="MenuIcon"
              :size="sizeIconsAction"
              class="cursor-pointer"
              @click="mqShallShowLeftSidebar = true"
            />
          </div>

        </div>
        <div class="d-flex align-items-center w-50 justify-content-end">

          <footer-step-nav ref="footerStepNav" />

        </div>
      </div>

      <vue-perfect-scrollbar
        class="scroll-content"
        :settings="configSettingsScroll"
      >
        <div
          class="app-fixed-search d-flex align-items-center"
        >
          <div class="d-flex flex-column align-items-center">
            <div class="d-flex align-items-center justify-content-start w-100">
              <h4 class="p-1 m-0">
                {{ $t('Geral') }}
              </h4>
            </div>
          </div>
        </div>

        <b-container
          class="p-2"
          fluid
        >
          <b-row>
            <b-col md="12">
              <b-form-group>
                <b-media no-body>
                  <b-media-aside>
                    <b-link :style="[{ border: '1px solid' }]">
                      <b-img
                        ref="previewEl"
                        rounded
                        :src="previewImage"
                        height="150"
                      />
                    </b-link>
                    <!--/ avatar -->
                  </b-media-aside>
                </b-media>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="12">
              <div class="d-flex justify-content-start">
                <b-avatar
                  v-if="formModeView===false && typeof idLeadAssoc !== 'undefined' && idLeadAssoc !== ''"
                  :src="typeof load_data_geral.infoLead !== 'undefined' && load_data_geral.infoLead !== '' ? load_data_geral.infoLead.photo : imagePreviewLead"
                />
                <b-avatar
                  v-if="formModeView===true && typeof load_data_geral.infoLead !== 'undefined' && typeof load_data_geral.infoLead.sw025s01 !== 'undefined' && load_data_geral.infoLead.sw025s01 !== ''"
                  :src="typeof load_data_geral.infoLead !== 'undefined' && typeof load_data_geral.infoLead.sw025s121 !== 'undefined' ? load_data_geral.infoLead.sw025s121 : imagePreviewLead"
                />
                <div
                  v-if="formModeView===false && (typeof idBankAssoc === 'undefined' || idBankAssoc === '')"
                  :class="typeof idLeadAssoc !== 'undefined' && idLeadAssoc !== '' ? 'd-flex flex-column ml-1' : 'd-flex flex-column'"
                >
                  <div
                    v-if="typeof load_data_geral.infoLead !== 'undefined' && load_data_geral.infoLead !== null && load_data_geral.infoLead !== '' && Object.keys(load_data_geral.infoLead).length > 0"
                    class="mb-1"
                  >
                    <h6 class="mb-0">
                      {{ $t("Proprietário") }} -
                      {{ load_data_geral.infoLead.desc }}
                      {{ load_data_geral.infoLead.sw025s03 }} {{ load_data_geral.infoLead.sw025s04 }}
                    </h6>
                    <span class="card-text">
                      {{ load_data_geral.infoLead.email }}
                      {{ load_data_geral.infoLead.sw025s06 }}
                    </span>
                    <p>
                      <span class="card-text">
                        <template v-if="load_data_geral.infoLead.phone">
                          <b-link :href="`tel:${load_data_geral.infoLead.phone.trim()}`">
                            {{ load_data_geral.infoLead.phone }}
                          </b-link>
                        </template>
                        <template v-if="load_data_geral.infoLead.sw025s05">
                          <b-link :href="`tel:${load_data_geral.infoLead.sw025s05.trim()}`">
                            {{ load_data_geral.infoLead.sw025s05 }}
                          </b-link>
                        </template>

                      </span>
                    </p>
                    <div class="d-flex flex-wrap">
                      <b-button
                        v-b-modal="`modal-add-lead`"
                        variant="outline-primary"
                        size="sm"
                      >
                        <span class="align-middle">{{ $t("Editar") }}</span>
                      </b-button>
                    </div>
                  </div>
                  <div
                    v-if="[4, 7].includes(Number(currentUser.id_role)) && (typeof load_data_geral.infoLead === 'undefined' || load_data_geral.infoLead === '' || Object.keys(load_data_geral.infoLead).length === 0)"
                    class="mb-1"
                  >
                    <h6 class="mb-0">
                      {{ $t("Proprietário") }}
                    </h6>
                    <div class="d-flex flex-wrap">
                      <b-button
                        v-b-modal="`modal-add-lead`"
                        variant="outline-primary"
                        class="mr-1 mt-1"
                      >
                        <span class="align-middle">
                          {{ $t("Adicionar Lead") }}
                        </span>
                      </b-button>
                      <b-button
                        v-b-modal="`modal-add-bank`"
                        variant="outline-primary"
                        class="mt-1"
                      >
                        <span class="align-middle">
                          {{ $t("Adicionar Banco") }}
                        </span>
                      </b-button>
                    </div>
                  </div>
                </div>
                <div
                  v-if="formModeView===true && typeof load_data_geral.infoLead !== 'undefined' && typeof load_data_geral.infoLead.sw025s01 !== 'undefined' && load_data_geral.infoLead.sw025s01 !== ''"
                  class="d-flex flex-column ml-1"
                >
                  <div
                    v-if="typeof load_data_geral.infoLead !== 'undefined' && load_data_geral.infoLead !== ''"
                    class="mb-1"
                  >
                    <h6 class="mb-0">
                      {{ $t("Proprietário") }} -
                      {{ load_data_geral.infoLead.sw025s03 }} {{ load_data_geral.infoLead.sw025s04 }}
                    </h6>
                    <span class="card-text">
                      <template v-if="load_data_geral.infoLead.sw025s06">
                        <b-link :href="`mailto:${load_data_geral.infoLead.sw025s06}`">
                          {{ load_data_geral.infoLead.sw025s06 }}
                        </b-link>
                      </template>
                    </span>
                    <p>
                      <span class="card-text">
                        <template v-if="load_data_geral.infoLead.sw025s05">
                          <b-link :href="`tel:${load_data_geral.infoLead.sw025s05.trim()}`">
                            {{ load_data_geral.infoLead.sw025s05 }}
                          </b-link>
                        </template>
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="12">
              <div class="d-flex justify-content-start">
                <div
                  v-if="formModeView===false && (typeof idLeadAssoc === 'undefined' || idLeadAssoc === '')"
                  class="d-flex flex-column"
                >
                  <div
                    v-if="typeof load_data_geral.infoBank !== 'undefined' && load_data_geral.infoBank !== '' && Object.keys(load_data_geral.infoBank).length > 0"
                    class="mb-1"
                  >
                    <h6 class="mb-0">
                      {{ $t("Banco Proprietário") }} -
                      {{ load_data_geral.infoBank.sw129s02 }}
                    </h6>
                    <span class="card-text">
                      {{ load_data_geral.infoBank.sw129s13 }}
                    </span>
                    <div class="d-flex flex-wrap">
                      <b-button
                        v-b-modal="`modal-add-bank`"
                        variant="outline-primary"
                        size="sm"
                        class="mt-1"
                      >
                        <span class="align-middle">{{ $t("Editar") }}</span>
                      </b-button>
                    </div>
                  </div>
                </div>
                <div
                  v-if="formModeView===true && typeof load_data_geral.infoBank !== 'undefined' && typeof load_data_geral.infoBank.sw129s01 !== 'undefined' && load_data_geral.infoBank.sw129s01 !== ''"
                  class="d-flex flex-column ml-1"
                >
                  <div class="mb-1">
                    <h6 class="mb-0">
                      {{ $t("Proprietário") }} -
                      {{ load_data_geral.infoBank.sw129s02 }}
                    </h6>
                    <span
                      v-if="typeof load_data_geral.infoBank.sw129s13 !== 'undefined' && load_data_geral.infoBank.sw129s13 !== ''"
                      class="card-text"
                    >
                      {{ load_data_geral.infoBank.sw129s13 }}
                    </span>
                  </div>
                </div>
              </div>
            </b-col>
          </b-row>

          <b-row v-if="typeof load_data_geral.infoAgent.nomeUser !== 'undefined' && load_data_geral.infoAgent.nomeUser !== ''">
            <b-col md="12">
              <div class="d-flex justify-content-start">
                <b-avatar
                  :src="load_data_geral.infoAgent.imagem"
                />
                <div class="d-flex flex-column ml-1">
                  <div class="mb-1">
                    <h6 class="mb-0">
                      {{ load_data_geral.infoMarket.nameHUB }} -
                      {{ load_data_geral.infoAgent.nomeUser }}
                    </h6>
                    <span class="card-text">
                      <template v-if="load_data_geral.infoAgent.sw001s03">
                        <b-link :href="`mailto:${load_data_geral.infoAgent.sw001s03}`">
                          {{ load_data_geral.infoAgent.sw001s03 }}
                        </b-link>
                      </template>
                    </span>
                    <p>
                      <span class="card-text">
                        <template v-if="load_data_geral.infoAgent.contactoUser">
                          <b-link :href="`tel:${load_data_geral.infoAgent.contactoUser.trim()}`">
                            {{ load_data_geral.infoAgent.contactoUser }}
                          </b-link>
                        </template>
                      </span>
                    </p>
                    <div class="d-flex flex-wrap">
                      <b-button
                        v-if="(typeof form_data.sw012s02 === 'undefined' || form_data.sw012s02 === null || form_data.sw012s02 === '') && formModeView === false && ![4, 7].includes(Number(currentUser.id_role))
                          && typeof load_data_geral.infoAgent !== 'undefined' && Number(load_data_geral.infoAgent.length) > 0 && (typeof form_data.sw012s379 === 'undefined' || form_data.sw012s379 === null || Number(form_data.sw012s379) !== 1)"
                        v-b-modal="`modal-add-agent`"
                        variant="outline-primary"
                        size="sm"
                      >
                        <span class="align-middle">{{ $t("Editar") }}</span>
                      </b-button>
                    </div>
                  </div>
                </div>
              </div>
            </b-col>
          </b-row>

          <b-row v-if="typeof load_data_geral.infoAgent.nome !== 'undefined' && load_data_geral.infoAgent.nome !== ''">
            <b-col md="12">
              <div class="d-flex justify-content-start">
                <b-avatar
                  :src="load_data_geral.infoAgent.imagem"
                />
                <div class="d-flex flex-column ml-1">
                  <div class="mb-1">
                    <h6 class="mb-0">
                      {{ load_data_geral.infoAgent.nameHUB }} -
                      {{ load_data_geral.infoAgent.nome }}
                    </h6>
                    <span class="card-text">
                      <template v-if="load_data_geral.infoAgent.email">
                        <b-link :href="`mailto:${load_data_geral.infoAgent.email}`">
                          {{ load_data_geral.infoAgent.email }}
                        </b-link>
                      </template>
                    </span>
                    <p>
                      <span class="card-text">
                        <template v-if="load_data_geral.infoAgent.sw001s23">
                          <b-link :href="`tel:${load_data_geral.infoAgent.sw001s126.trim()} ${load_data_geral.infoAgent.sw001s23.trim()}`">
                            {{ load_data_geral.infoAgent.sw001s126 }} {{ load_data_geral.infoAgent.sw001s23 }}
                          </b-link>
                        </template>
                      </span>
                    </p>
                    <div class="d-flex flex-wrap">
                      <b-button
                        v-if="(typeof form_data.sw012s02 === 'undefined' || form_data.sw012s02 === null || form_data.sw012s02 === '') && formModeView === false && ![4, 7].includes(Number(currentUser.id_role))
                          && (typeof form_data.sw012s379 === 'undefined' || form_data.sw012s379 === null || Number(form_data.sw012s379) !== 1)"
                        v-b-modal="`modal-add-agent`"
                        variant="outline-primary"
                        size="sm"
                      >
                        <span class="align-middle">{{ $t("Editar") }}</span>
                      </b-button>
                    </div>
                  </div>
                </div>
              </div>
            </b-col>
          </b-row>

          <b-row
            v-if="(typeof form_data.sw012s04 === 'undefined' || form_data.sw012s04 === null || form_data.sw012s04 === '') && formModeView === false && ![4, 7].includes(Number(currentUser.id_role))
              && typeof load_data_geral.infoAgent !== 'undefined' && Number(load_data_geral.infoAgent.length) === 0 && (typeof form_data.sw012s379 === 'undefined' || form_data.sw012s379 === null || Number(form_data.sw012s379) !== 1)"
          >
            <b-col md="12">
              <h6 class="mb-0">
                {{ $t("Consultor") }}
              </h6>
              <b-button
                v-b-modal="`modal-add-agent`"
                variant="outline-primary"
                class="mr-1 mb-1 mt-1"
              >
                <span class="align-middle">
                  {{ $t("Adicionar") }}
                </span>
              </b-button>
            </b-col>
          </b-row>

          <b-row
            v-if="[7].includes(Number(currentUser.id_role)) && Number(baseDecode(currentUser.id)) === Number(form_data.sw012s04)"
          >
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                rules="required"
                :name="$t('Especialista da angariação') | lowerBold"
              >
                <b-form-group
                  :label="$t('Especialista da angariação')"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <v-select
                    v-if="formModeView===false"
                    v-model="form_data.sw012s390"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="load_data_geral.especialistas"
                    :value="form_data.sw012s390"
                    append-to-body
                    :calculate-position="withPopper"
                    :disabled="![7].includes(Number(currentUser.id_role))"
                    label="nome"
                    item-text="nome"
                    item-value="id"
                  >
                    <template #option="{ nome }">
                      {{ nome }}
                    </template>
                    <div slot="no-options">
                      {{ $t("Sem resultados") }}
                    </div>
                  </v-select>
                  <p
                    v-else
                    class="text-primary mb-0"
                  >
                    {{ renderObjToTxt(form_data.sw012s390, 'nome') }}
                  </p>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>

          <b-row
            v-if="formModeView === false || (formModeView === true && ((Number(baseDecode(currentUser.id)) === Number(form_data.sw012s60) || Number(baseDecode(currentUser.id)) === Number(form_data.sw012s04))
              || (Number(form_data.sw012s72) === Number(currentUser.idHUB) && [5, 9, 10, 13].includes(Number(currentUser.id_role)))))"
          >
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                :rules="typeof rulesFields.sw012s03 !== 'undefined' && rulesFields.sw012s03.required === true ? 'required' : ''"
                :name="$t('Nickname do imóvel') | lowerBold"
              >
                <b-form-group :label="$t('Nickname do imóvel')">
                  <b-form-input
                    v-if="formModeView===false"
                    v-model="form_data.sw012s03"
                    placeholder=""
                    autocomplete="off"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  />
                  <p
                    v-else
                    class="text-primary mb-0"
                  >
                    {{ form_data.sw012s03 }}
                  </p>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="6">
              <b-form-group :label="$t('ID')">
                <b-form-input
                  v-if="formModeView===false"
                  v-model="form_data.sw012s02"
                  :disabled="true"
                  placeholder=""
                  autocomplete="off"
                />
                <p
                  v-else
                  class="text-primary mb-0"
                >
                  {{ form_data.sw012s02 }}
                </p>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                :rules="typeof rulesFields.sw012s64 !== 'undefined' && rulesFields.sw012s64.required === true ? 'required' : ''"
                :name="$t('Estado') | lowerBold"
              >
                <b-form-group
                  :label="$t('Estado')"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <v-select
                    v-if="formModeView===false"
                    v-model="form_data.sw012s64"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="load_data_geral.listingStates"
                    :value="form_data.sw012s64"
                    append-to-body
                    :calculate-position="withPopper"
                    :disabled="![5, 9, 10, 13].includes(Number(currentUser.id_role))
                      || ((typeof form_data.sw012s365 !== 'undefined' && form_data.sw012s365 !== null && form_data.sw012s365 !== '')
                        && (typeof form_data.sw012s64 !== 'undefined' && form_data.sw012s64 !== null && [2 ,3 ,7].includes(Number(form_data.sw012s64.sw018s01)))) ? true : false"
                    label="sw018s02"
                    item-text="sw018s02"
                    item-value="sw018s01"
                    @input="validateSW012s64"
                  >
                    <template #option="{ sw018s02 }">
                      {{ sw018s02 }}
                    </template>
                    <div slot="no-options">
                      {{ $t("Sem resultados") }}
                    </div>
                  </v-select>
                  <p
                    v-else
                    class="text-primary mb-0"
                  >
                    <template v-if="statusIsDraft===true">
                      {{ $t('Rascunho') }}
                    </template>
                    <template v-else>
                      {{ renderObjToTxt(form_data.sw012s64, 'sw018s02') }}
                    </template>
                  </p>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="2">
              <b-button
                v-if="showButtonMotivo === true"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                block
                class="mb-1"
                @click="getModalMotivo"
              >
                <span class="align-middle">{{ $t('Ver motivo') }}</span>
              </b-button>
            </b-col>
          </b-row>
        </b-container>
      </vue-perfect-scrollbar>
    </div>

    <b-modal
      id="modal-add-agent"
      ref="modal-agent"
      centered
      :ok-title="$t('Guardar')"
      :cancel-title="$t('Cancelar')"
      cancel-variant="outline-secondary"
      :title="$t('Proprietário')"
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleAgentOk"
    >
      <b-row>
        <b-col md="12">
          <validation-provider
            #default="{ errors }"
            rules="required"
          >
            <b-form-group
              :label="$t('Consultor')"
              :class="errors.length > 0 ? 'is-invalid':null"
            >
              <v-select
                v-model="agentSel"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="searchAgent"
                append-to-body
                :calculate-position="withPopper"
                :loading="isLoadingAgent"
                :filterable="false"
                label="nome"
                item-text="nome"
                item-value="id"
                @search="searchAgentsByUserAuth"
              >
                <div
                  v-if="searchAgent.length > 0"
                  slot="no-options"
                >
                  {{ $t('Sem resultados') }}
                </div>
                <template
                  v-if="searchAgent.length === 0"
                  slot="no-options"
                >
                  {{ $t('Digite o nome') }}
                </template>
                <template
                  slot="option"
                  slot-scope="option"
                >
                  <div class="d-flex align-items-center">
                    <b-avatar
                      :src="option.imagem"
                      class="mr-50"
                    />
                    <div class="detail">
                      <b-card-text class="mb-0">
                        {{ option.nome }}
                      </b-card-text>
                      <b-card-text
                        v-if="option.email"
                        class="mb-0 text-muted"
                      >
                        <small>{{ option.email }}</small>
                      </b-card-text>
                      <b-card-text
                        v-if="option.telefone"
                        class="mb-0 text-muted"
                      >
                        <small>{{ option.telefone }}</small>
                      </b-card-text>
                      <b-card-text
                        v-if="option.nameHUB"
                        class="mb-0 text-muted"
                      >
                        <small>{{ option.nameHUB }}</small>
                      </b-card-text>
                    </div>
                  </div>
                </template>
                <template
                  slot="selected-option"
                  slot-scope="option"
                >
                  {{ option.nome }}
                </template>
              </v-select>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
    </b-modal>

    <b-modal
      id="modal-add-lead"
      ref="my-modal"
      centered
      :ok-title="$t('Guardar')"
      :cancel-title="$t('Cancelar')"
      cancel-variant="outline-secondary"
      :title="$t('Proprietário')"
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleOk"
    >
      <b-row>
        <b-col md="12">
          <validation-provider
            #default="{ errors }"
            rules="required"
          >
            <b-form-group
              :label="$tc('Lead', 1)"
              :class="errors.length > 0 ? 'is-invalid':null"
            >
              <v-select
                v-model="leadSel"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="searchLead"
                append-to-body
                :calculate-position="withPopper"
                :loading="isLoadingLead"
                :filterable="false"
                label="desc"
                item-text="desc"
                item-value="id"
                @search="searchByUserAuth"
              >
                <div
                  v-if="searchLead.length > 0"
                  slot="no-options"
                >
                  {{ $t('Sem resultados') }}
                </div>
                <template
                  v-if="searchLead.length === 0"
                  slot="no-options"
                >
                  {{ $t('Digite o nome') }}
                </template>
                <template
                  slot="option"
                  slot-scope="option"
                >
                  <div class="d-flex align-items-center">
                    <b-avatar
                      :src="option.photo"
                      class="mr-50"
                    />
                    <div class="detail">
                      <b-card-text class="mb-0">
                        {{ option.desc }}
                      </b-card-text>
                      <b-card-text
                        v-if="option.email"
                        class="mb-0 text-muted"
                      >
                        <small>{{ option.email }}</small>
                      </b-card-text>
                      <b-card-text
                        v-if="option.phone"
                        class="mb-0 text-muted"
                      >
                        <small>{{ option.phone }}</small>
                      </b-card-text>
                    </div>
                  </div>
                </template>
                <template
                  slot="selected-option"
                  slot-scope="option"
                >
                  {{ option.desc }}
                </template>
              </v-select>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
    </b-modal>

    <b-modal
      id="modal-add-bank"
      ref="modal-bank"
      centered
      :ok-title="$t('Guardar')"
      :cancel-title="$t('Cancelar')"
      cancel-variant="outline-secondary"
      :title="$t('Proprietário')"
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleOkBank"
    >
      <b-row>
        <b-col md="12">
          <validation-provider
            #default="{ errors }"
            rules="required"
          >
            <b-form-group
              :label="$t('Banco')"
              :class="errors.length > 0 ? 'is-invalid':null"
            >
              <v-select
                v-model="bankSel"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="searchBank"
                append-to-body
                :calculate-position="withPopper"
                :loading="isLoadingBank"
                :filterable="false"
                label="sw129s02"
                item-text="sw129s02"
                item-value="sw129s01"
                @search="searchListBank"
              >
                <div
                  v-if="searchLead.length > 0"
                  slot="no-options"
                >
                  {{ $t('Sem resultados') }}
                </div>
                <template
                  v-if="searchLead.length === 0"
                  slot="no-options"
                >
                  {{ $t('Digite o nome') }}
                </template>
                <template
                  slot="option"
                  slot-scope="option"
                >
                  <div class="d-flex align-items-center">
                    <div class="detail">
                      <b-card-text class="mb-0">
                        {{ option.sw129s02 }}
                      </b-card-text>
                    </div>
                  </div>
                </template>
                <template
                  slot="selected-option"
                  slot-scope="option"
                >
                  {{ option.sw129s02 }}
                </template>
              </v-select>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import store from '@/store'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BContainer,
  BMedia,
  BMediaAside,
  BImg,
  BLink,
  BAvatar,
  BButton,
  BCardText,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapGetters } from 'vuex'
import VuePerfectScrollbar from '@/views/modules/components/vue-perfect-scrollbar.vue'
import { sizeIconsAction } from '@core-custom/mixins/geral'
import { ValidationProvider } from 'vee-validate'
import vSelect from 'vue-select'
import modelSW025 from '@store-modules/crm/leads/data'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref, onUnmounted } from '@vue/composition-api'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import { configSettingsScroll } from '@core-custom/utils/ui'
import { mixFormGeral } from '../mixins'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BContainer,
    VuePerfectScrollbar,
    FooterStepNav: () => import(/* webpackChunkName: "listings-footer-step-nav" */ /* webpackPrefetch: true */ './footerStepNavTop.vue'),
    ValidationProvider,
    vSelect,
    BMedia,
    BMediaAside,
    BImg,
    BLink,
    BAvatar,
    BButton,
    BCardText,
  },
  directives: {
    Ripple,
  },
  mixins: [sizeIconsAction, mixFormGeral],
  props: {
    getModalMotivo: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      leadSel: null,
      bank: null,
      bankSel: null,
      agentSel: null,
      searchLead: [],
      searchBank: [],
      searchAgent: [],
      isLoadingAgent: false,
      isLoadingLead: false,
      isLoadingBank: false,
    }
  },
  computed: {
    ...mapGetters('listings_form', ['form_data', 'load_data_geral', 'vars', 'rulesFields', 'idLeadAssoc', 'idBankAssoc', 'previewImage', 'formModeView']),
    ...mapGetters('auth', ['currentUser']),
    imagePreviewLead() {
      return this.vars.previewPhotos.masc
    },
    showButtonMotivo: {
      get() {
        if (this.formModeView === true && ((typeof this.form_data.sw012s64 !== 'undefined' && this.form_data.sw012s64 !== null && Number(this.form_data.sw012s64.sw018s06) === 1) || (Number(this.load_data_geral.showVersionPending) === 1))
        && (typeof this.form_data.sw012s71 !== 'undefined' && this.form_data.sw012s71 !== null && this.form_data.sw012s71 !== '')) {
          return true
        }

        if (this.formModeView === false && (typeof this.form_data.sw012s379 === 'undefined' || this.form_data.sw012s379 === null || Number(this.form_data.sw012s379) !== 1)
        && (typeof this.form_data.sw012s64 !== 'undefined' && this.form_data.sw012s64 !== null && Number(this.form_data.sw012s64.sw018s06) === 1) && (typeof this.form_data.sw012s71 !== 'undefined' && this.form_data.sw012s71 !== null && this.form_data.sw012s71 !== '')) {
          return true
        }
        return false
      },
    },
    statusIsDraft() {
      if ((this.form_data?.sw012s64?.sw018s01 !== undefined)
        && (this.form_data?.sw012s70 !== undefined)
        && (Number(this.form_data.sw012s64.sw018s01) === 5)
        && (Number(this.form_data.sw012s70) === 1)
      ) {
        return true
      }

      return false
    },
  },
  watch: {
    form_data: {
      deep: true,
      handler() { },
    },
  },
  methods: {
    baseDecode(val) {
      if (typeof val !== 'undefined') {
        return atob(val)
      }
      return ''
    },
    setUpdateFormData() {
      this.$store.commit('listings_form/setUpdateFormData', this.form_data)
    },
    resetModal() {
      return true
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleSubmit()
    },
    handleSubmit() {
      if (this.leadSel !== null) {
        this.$store.commit('listings_form/setInfoLead', this.leadSel)
        this.$store.commit('listings_form/setIdLeadAssoc', btoa(this.leadSel.id))
        this.$store.dispatch('listings_form/updateFieldValue', { field: 'sw012s05Change', value: this.leadSel.id })
      } else {
        this.$store.commit('listings_form/setInfoLead', '')
        this.$store.commit('listings_form/setIdLeadAssoc', '')
        this.$store.dispatch('listings_form/updateFieldValue', { field: 'sw012s05Change', value: '' })
      }

      // Hide the modal manually
      this.$nextTick(() => {
        this.$refs['my-modal'].toggle('#toggle-btn')
      })
    },
    handleOkBank(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleBankSubmit()
    },
    handleBankSubmit() {
      if (this.bankSel !== null) {
        this.$store.commit('listings_form/setInfoBank', this.bankSel)
        this.$store.commit('listings_form/setIdBankAssoc', btoa(this.bankSel.sw129s01))
        this.$store.dispatch('listings_form/updateFieldValue', { field: 'sw012s24', value: 1 })

        if (Number(this.load_data_geral.formPreAngariacao) !== 1) {
          this.$store.dispatch('listings_form/updateFieldValue', { field: 'sw012s397Change', value: btoa(this.bankSel.sw129s01) })
        }
      } else {
        this.$store.commit('listings_form/setInfoBank', '')
        this.$store.commit('listings_form/setIdBankAssoc', '')
        this.$store.dispatch('listings_form/updateFieldValue', { field: 'sw012s24', value: null })

        if (Number(this.load_data_geral.formPreAngariacao) !== 1) {
          this.$store.dispatch('listings_form/updateFieldValue', { field: 'sw012s397Change', value: '' })
        }
      }

      // Hide the modal manually
      this.$nextTick(() => {
        this.$refs['modal-bank'].toggle('#toggle-btn')
      })
    },
    handleAgentOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleAgentSubmit()
    },
    async handleAgentSubmit() {
      if (typeof this.agentSel !== 'undefined' && this.agentSel !== null) {
        await this.$store.commit('listings_form/setInfoAgent', this.agentSel)
        await this.$store.commit('listings_form/setIdAgent', btoa(this.agentSel.id))
        await this.$store.dispatch('listings_form/updateFieldValue', { field: 'sw012s04', value: this.agentSel.id })
        await this.$store.dispatch('listings_form/updateFieldValue', { field: 'sw012s04Change', value: this.agentSel.id })
        await this.$store.dispatch('listings_form/getScriptsVenda', { sw012s04: btoa(this.agentSel.id) })

        await this.$store.dispatch('listings_form/getVenturesByAgent', { sw012s04: btoa(this.agentSel.id) })
        await this.$store.dispatch('listings_form/updateFieldValue', { field: 'sw012s543', value: null })
      } else {
        await this.$store.commit('listings_form/setInfoAgent', [])
        await this.$store.commit('listings_form/setIdAgent', '')
        await this.$store.dispatch('listings_form/updateFieldValue', { field: 'sw012s04', value: null })
        await this.$store.dispatch('listings_form/updateFieldValue', { field: 'sw012s04Change', value: null })
        await this.$store.commit('listings_form/setDescriptionScripts', [])

        await this.$store.commit('listings_form/setEnterprises', [])
        await this.$store.dispatch('listings_form/updateFieldValue', { field: 'sw012s543', value: null })
      }

      // Hide the modal manually
      this.$nextTick(() => {
        this.$refs['modal-agent'].toggle('#toggle-btn')
      })
    },
    resetLead() {
      this.searchLead = []
    },
    searchByUserAuth(text) {
      this.resetLead()

      if (text !== '') {
        this.isLoadingLead = true
        store.dispatch('sw025/searchLeadsByUserAuth', text)
          .then(async res => {
            const aLeads = []
            await Array.from(res).forEach(item => {
              aLeads.push(item)
            })
            this.isLoadingLead = false
            this.searchLead = aLeads
          })
          .catch(error => {
            this.isLoadingLead = false
            this.searchLead = []
            this.showMsgErrorRequest(error)
          })
      } else {
        this.isLoadingLead = false
        this.searchLead = []
      }
    },
    resetBank() {
      this.searchBank = this.load_data_geral.banks
    },
    async searchListBank(txt) {
      this.resetBank()
      if (txt !== '') {
        this.isLoadingBank = true
        const aBanks = []
        await Array.from(this.load_data_geral.banks).forEach(item => {
          const desc = item.sw129s02.toLowerCase()
          if (desc.includes(txt.toLowerCase())) {
            aBanks.push(item)
          }
        })
        this.searchBank = aBanks
        this.isLoadingBank = false
      } else {
        this.isLoadingBank = false
        this.searchBank = this.load_data_geral.banks
      }
    },
    resetAgent() {
      this.searchAgent = this.load_data_geral.usersAgent
    },
    async searchAgentsByUserAuth(text) {
      this.resetAgent()
      if (text !== '') {
        this.isLoadingAgent = true
        const aAgents = []
        await Array.from(this.load_data_geral.usersAgent).forEach(item => {
          const name = item.nome.toLowerCase()
          if (name.includes(text.toLowerCase())) {
            aAgents.push(item)
          }
        })
        this.searchAgent = aAgents
        this.isLoadingAgent = false
      } else {
        this.isLoadingAgent = false
        this.searchAgent = this.load_data_geral.usersAgent
      }
    },
    async validateSW012s64() {
      let vEstado = ''
      let showObservacao = ''
      let fillValorPopup = ''
      // let titlePerguntePreco = ''

      if (typeof this.form_data.sw012s64 !== 'undefined' && this.form_data.sw012s64 !== null) {
        vEstado = Number(this.form_data.sw012s64.sw018s01)
        showObservacao = Number(this.form_data.sw012s64.sw018s06)
        fillValorPopup = Number(this.form_data.sw012s64.sw018s07)
        // titlePerguntePreco = Number(this.form_data.sw012s64.sw018s09)
      }

      // var boxFieldObs = jQuery('.boxObsEstado');
      // boxFieldObs.addClass('hide');

      await this.$store.dispatch('listings_form/updateFieldValue', { field: 'sw012s71', value: null })

      if (showObservacao === 1) {
        // jQuery('#popupRegisterObservacaoEstado').modal('show');
        // boxFieldObs.removeClass('hide');
      }

      if (fillValorPopup === 1) {
        await this.$store.dispatch('listings_form/updateFieldValue', { field: 'sw012s296', value: null })
        await this.$store.dispatch('listings_form/updateFieldValue', { field: 'sw012s297', value: null })

        // jQuery('#popupRegisterDefinirValorEstado input[name=sw012s333]').prop('checked', false).uniform();
        // jQuery('.txtValueVendidoPor').html('');
        // jQuery('.txtValueVendidoPor').addClass('hide');

        // // Define o titulo do modal a perguntar o preco
        // jQuery('#popupRegisterDefinirValorEstado .modal-title').html(titlePerguntePreco);
        // jQuery('#popupRegisterDefinirValorEstado').modal({
        //     backdrop: 'static',
        //     keyboard: false
        // });

        // jQuery('#popupRegisterDefinirValorEstado .boxChangeListingCE').addClass('hide');
        // jQuery('#popupRegisterDefinirValorEstado .boxChangeListingHiddenPrice').addClass('hide');
        await this.$store.dispatch('listings_form/updateFieldValue', { field: 'sw012s19Change', value: null })

        // So mostra o certificado energetico para escolhar caso escolha o estado vendido / reservado
        let tipoImovelShowCE = ''
        if (typeof this.form_data.sw012s07 !== 'undefined' && this.form_data.sw012s07 !== null) {
          tipoImovelShowCE = Number(this.form_data.sw012s07.show_ce)
        }
        if (tipoImovelShowCE === 1 && (vEstado === 2 || vEstado === 3)) {
          // var vlAngCE = jQuery('select[name=sw012s19]').val();

          // jQuery('#popupRegisterDefinirValorEstado .boxChangeListingCE').removeClass('hide');
          // jQuery('#popupRegisterDefinirValorEstado select[name=sw012s19Change]').val(vlAngCE);
          // jQuery('#popupRegisterDefinirValorEstado select[name=sw012s19Change]').selectpicker('refresh');
        }
      } else {
        await this.$store.dispatch('listings_form/updateFieldValue', { field: 'sw012s296', value: null })
        await this.$store.dispatch('listings_form/updateFieldValue', { field: 'sw012s297', value: null })
        // jQuery('#popupRegisterDefinirValorEstado input[name=sw012s333]').prop('checked', false).uniform();
        // jQuery('.txtValueVendidoPor').html('');
        // jQuery('.txtValueVendidoPor').addClass('hide');
      }

      if (vEstado === 2) {
        // jQuery('#popupRegisterDefinirValorEstado .boxChangeListingHiddenPrice').removeClass('hide');
      }
    },
  },
  setup() {
    const refInputEl = ref(null)
    const previewEl = ref(null)
    const { inputImageRenderer } = useInputImageRenderer(refInputEl, previewEl)

    const MODEL_SW025 = 'sw025'

    if (!store.hasModule(MODEL_SW025)) {
      store.registerModule(MODEL_SW025, modelSW025)

      onUnmounted(() => {
        if (store.hasModule(MODEL_SW025)) store.unregisterModule(MODEL_SW025)
      })
    }

    const { t } = useI18nUtils()

    return {
      refInputEl,
      previewEl,
      inputImageRenderer,
      t,
      configSettingsScroll,
    }
  },
}
</script>
