<template>
  <app-timeline class="mt-1 ml-3 mr-1">
    <app-timeline-item
      v-for="(row) in listviewData"
      :key="row.id"
      no-body
      icon="ClockIcon"
      variant="primary"
    >
      <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
        <h6>{{ $t('Versão') }}  {{ row.version }}  - <small class="text-muted">{{ row.dateVersion }}</small></h6>
      </div>
      <div class="justify-content align-items-start">
        <!-- 1st Col -->
        <b-row>
          <b-col
            md="2"
          >
            <div class="mb-1 mb-sm-0">
              <span class="text-muted mb-50 d-block">{{ $t('Registado por') }}</span>
              <span>{{ row.createBy }}</span>
            </div>
          </b-col>
          <b-col
            md="2"
          >
            <div class="mb-1 mb-sm-0">
              <span class="text-muted mb-50 d-block">{{ $t('Data de Aprovação') }}</span>
              <span>{{ row.dateApprove }}</span>
            </div>
          </b-col>
          <b-col
            md="2"
          >
            <div class="mb-1 mb-sm-0">
              <span class="text-muted mb-50 d-block">{{ $t('Aprovado por') }}</span>
              <span>{{ row.approveBy }}</span>
            </div>
          </b-col>
          <b-col
            v-if="view === false"
            md="2"
          >
            <b-button
              v-ripple.400="'rgba(234, 84, 85, 0.15)'"
              v-b-tooltip.hover
              :title="$t('Visualizar versão')"
              variant="outline-primary"
            >
              <feather-icon
                icon="EyeIcon"
                class="mr-25"
              />
              <span>{{ $t('Ver') }}</span>
            </b-button>
          </b-col>

        </b-row>
      </div>
    </app-timeline-item>
  </app-timeline>
</template>

<script>
import {
  BRow, BCol, BButton, VBTooltip,
} from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    AppTimeline,
    AppTimelineItem,

  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    listviewData: {
      type: Array,
      required: true,
    },
    view: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
    }
  },
  computed: {
  },
  methods: {
  },
}
</script>
