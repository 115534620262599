<template>
  <div>

    <div
      class="email-app-list position-relative height-100-panel"
    >
      <div
        class="app-fixed-search d-flex align-items-center"
      >
        <div class="d-flex align-items-center flex-grow-1 w-50">

          <div class="sidebar-toggle d-block d-lg-none ml-1">
            <feather-icon
              icon="MenuIcon"
              :size="sizeIconsAction"
              class="cursor-pointer"
              @click="mqShallShowLeftSidebar = true"
            />
          </div>

        </div>
        <div class="d-flex align-items-center w-50 justify-content-end">

          <footer-step-nav ref="footerStepNav" />

        </div>
      </div>

      <!-- Version Details -->
      <vue-perfect-scrollbar
        class="email-user-list scroll-area"
        :settings="configSettingsScroll"
      >
        <div
          class="app-fixed-search d-flex align-items-center"
          :class="{'border-0' : false}"
        >
          <div class="d-flex flex-column align-items-center">

            <div class="d-flex align-items-center justify-content-start w-100">
              <h4 class="p-1 m-0">
                {{ $t('Estatisticas') }}
              </h4>
            </div>

          </div>
        </div>

        <b-card-body class="statistics-body">
          <b-row>
            <b-col md="12">
              <h3>{{ $t('Website') }} - {{ $t('Valores globais') }}</h3>
              <p>{{ $t('Dados relativos a todas as listagens e aberturas') }}</p>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="12">
              <h4>{{ $t('Listado') }}</h4>
            </b-col>
          </b-row>
          <b-row class="mb-1">
            <b-col
              md="3"
              sm="6"
              class="mb-2 mb-md-0"
            >
              <b-media no-body>
                <b-media-body class="my-auto">
                  <h4 class="font-weight-bolder mb-0 text-primary">
                    {{ (typeof data.estatisticaWebsiteTotais !== 'undefined' ? data.estatisticaWebsiteTotais.totais.totalListado : typeof data.estatisticasSite !== 'undefined' ? Number(data.estatisticasSite.numberDestaqueLista + data.estatisticasSite.numberPesquisaLista) : 0) }}
                  </h4>
                  <b-card-text class="font-small-3 mb-0">
                    {{ $t('Total') }}
                  </b-card-text>
                </b-media-body>
              </b-media>
            </b-col>
            <b-col
              md="3"
              sm="6"
              class="mb-2 mb-md-0"
            >
              <b-media no-body>
                <b-media-body class="my-auto">
                  <h4 class="font-weight-bolder mb-0">
                    {{ (typeof data.estatisticaWebsiteTotais !== 'undefined' ? data.estatisticaWebsiteTotais.destaque.lista : typeof data.estatisticasSite !== 'undefined' ? data.estatisticasSite.numberDestaqueLista : 0) }}
                  </h4>
                  <b-card-text class="font-small-3 mb-0">
                    {{ $t('Em destaque') }}
                  </b-card-text>
                </b-media-body>
              </b-media>
            </b-col>
            <b-col
              md="3"
              sm="6"
              class="mb-2 mb-md-0"
            >
              <b-media no-body>
                <b-media-body class="my-auto">
                  <h4 class="font-weight-bolder mb-0">
                    {{ (typeof data.estatisticaWebsiteTotais !== 'undefined' ? data.estatisticaWebsiteTotais.pesquisa.lista : typeof data.estatisticasSite !== 'undefined' ? data.estatisticasSite.numberPesquisaLista : 0) }}
                  </h4>
                  <b-card-text class="font-small-3 mb-0">
                    {{ $t('Resultados de pesquisa') }}
                  </b-card-text>
                </b-media-body>
              </b-media>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="12">
              <h4>{{ $t('Aberto') }}</h4>
            </b-col>
          </b-row>
          <b-row class="mb-1">
            <b-col
              md="3"
              sm="6"
              class="mb-2 mb-md-0"
            >
              <b-media no-body>
                <b-media-body class="my-auto">
                  <h4 class="font-weight-bolder mb-0 text-primary">
                    {{ (typeof data.estatisticaWebsiteTotais !== 'undefined' ? data.estatisticaWebsiteTotais.totais.totalAberto : typeof data.estatisticasSite !== 'undefined' ? Number(data.estatisticasSite.numberDestaqueAberto + data.estatisticasSite.numberPesquisaAberto + data.estatisticasSite.numberDireto) : 0) }}
                  </h4>
                  <b-card-text class="font-small-3 mb-0">
                    {{ $t('Total') }}
                  </b-card-text>
                </b-media-body>
              </b-media>
            </b-col>
            <b-col
              md="3"
              sm="6"
              class="mb-2 mb-md-0"
            >
              <b-media no-body>
                <b-media-body class="my-auto">
                  <h4 class="font-weight-bolder mb-0">
                    {{ (typeof data.estatisticaWebsiteTotais !== 'undefined' ? data.estatisticaWebsiteTotais.destaque.aberto : typeof data.estatisticasSite !== 'undefined' ? data.estatisticasSite.numberDestaqueAberto : 0) }}
                  </h4>
                  <b-card-text class="font-small-3 mb-0">
                    {{ $t('Em destaque') }}
                  </b-card-text>
                </b-media-body>
              </b-media>
            </b-col>
            <b-col
              md="3"
              sm="6"
              class="mb-2 mb-md-0"
            >
              <b-media no-body>
                <b-media-body class="my-auto">
                  <h4 class="font-weight-bolder mb-0">
                    {{ (typeof data.estatisticaWebsiteTotais !== 'undefined' ? data.estatisticaWebsiteTotais.pesquisa.aberto : typeof data.estatisticasSite !== 'undefined' ? data.estatisticasSite.numberPesquisaAberto : 0) }}
                  </h4>
                  <b-card-text class="font-small-3 mb-0">
                    {{ $t('Resultados de pesquisa') }}
                  </b-card-text>
                </b-media-body>
              </b-media>
            </b-col>
            <b-col
              md="3"
              sm="6"
              class="mb-2 mb-md-0"
            >
              <b-media no-body>
                <b-media-body class="my-auto">
                  <h4 class="font-weight-bolder mb-0">
                    {{ (typeof data.estatisticaWebsiteTotais !== 'undefined' ? data.estatisticaWebsiteTotais.direto.aberto : typeof data.estatisticasSite !== 'undefined' ? data.estatisticasSite.numberDireto : 0) }}
                  </h4>
                  <b-card-text class="font-small-3 mb-0">
                    {{ $t('Link direto') }}
                  </b-card-text>
                </b-media-body>
              </b-media>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="12">
              <h4>{{ $t('Conversão') }}</h4>
            </b-col>
          </b-row>
          <b-row class="mb-1">
            <b-col
              md="3"
              sm="6"
              class="mb-2 mb-md-0"
            >
              <b-media no-body>
                <b-media-body class="my-auto">
                  <h4 class="font-weight-bolder mb-0 text-primary">
                    {{ (typeof data.estatisticaWebsiteTotais !== 'undefined' ? data.estatisticaWebsiteTotais.totais.totalConversao + '%' : typeof data.estatisticasSite !== 'undefined' ? data.estatisticasSite.conversaoTotal + '%' : 0 + '%') }}
                  </h4>
                  <b-card-text class="font-small-3 mb-0">
                    {{ $t('Total') }}
                  </b-card-text>
                </b-media-body>
              </b-media>
            </b-col>
            <b-col
              md="3"
              sm="6"
              class="mb-2 mb-md-0"
            >
              <b-media no-body>
                <b-media-body class="my-auto">
                  <h4 class="font-weight-bolder mb-0">
                    {{ (typeof data.estatisticaWebsiteTotais !== 'undefined' ? data.estatisticaWebsiteTotais.totais.destaque + '%' : typeof data.estatisticasSite !== 'undefined' ? data.estatisticasSite.conversaoDestaque + '%' : 0 + '%') }}
                  </h4>
                  <b-card-text class="font-small-3 mb-0">
                    {{ $t('Listado > abertura') }}
                  </b-card-text>
                </b-media-body>
              </b-media>
            </b-col>
            <b-col
              md="3"
              sm="6"
              class="mb-2 mb-md-0"
            >
              <b-media no-body>
                <b-media-body class="my-auto">
                  <h4 class="font-weight-bolder mb-0">
                    {{ (typeof data.estatisticaWebsiteTotais !== 'undefined' ? data.estatisticaWebsiteTotais.totais.pesquisa + '%' : typeof data.estatisticasSite !== 'undefined' ? data.estatisticasSite.conversaoPesquisa + '%' : 0 + '%') }}
                  </h4>
                  <b-card-text class="font-small-3 mb-0">
                    {{ $t('Pesquisado > abertura') }}
                  </b-card-text>
                </b-media-body>
              </b-media>
            </b-col>
          </b-row>

          <b-row>
            <b-col
              md="12"
              class="mt-4"
            >
              <h3>{{ $t('Website') }} - {{ $t('Utilizadores únicos') }}</h3>
              <p>{{ $t('Dados relativos a quantidade de pessoas distintas') }}</p>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="12">
              <h4>{{ $t('Listado') }}</h4>
            </b-col>
          </b-row>
          <b-row class="mb-1">
            <b-col
              md="3"
              sm="6"
              class="mb-2 mb-md-0"
            >
              <b-media no-body>
                <b-media-body class="my-auto">
                  <h4 class="font-weight-bolder mb-0 text-primary">
                    {{ (typeof data.estatisticaWebsiteUnicos !== 'undefined' ? data.estatisticaWebsiteUnicos.totais.totalListado : typeof data.estatisticasSite !== 'undefined' ? Number(data.estatisticasSite.numberDestaqueListaUnico + data.estatisticasSite.numberPesquisaListaUnico) : 0) }}
                  </h4>
                  <b-card-text class="font-small-3 mb-0">
                    {{ $t('Total') }}
                  </b-card-text>
                </b-media-body>
              </b-media>
            </b-col>
            <b-col
              md="3"
              sm="6"
              class="mb-2 mb-md-0"
            >
              <b-media no-body>
                <b-media-body class="my-auto">
                  <h4 class="font-weight-bolder mb-0">
                    {{ (typeof data.estatisticaWebsiteUnicos !== 'undefined' ? data.estatisticaWebsiteUnicos.destaque.lista : typeof data.estatisticasSite !== 'undefined' ? data.estatisticasSite.numberDestaqueListaUnico : 0) }}
                  </h4>
                  <b-card-text class="font-small-3 mb-0">
                    {{ $t('Em destaque') }}
                  </b-card-text>
                </b-media-body>
              </b-media>
            </b-col>
            <b-col
              md="3"
              sm="6"
              class="mb-2 mb-md-0"
            >
              <b-media no-body>
                <b-media-body class="my-auto">
                  <h4 class="font-weight-bolder mb-0">
                    {{ (typeof data.estatisticaWebsiteUnicos !== 'undefined' ? data.estatisticaWebsiteUnicos.pesquisa.lista : typeof data.estatisticasSite !== 'undefined' ? data.estatisticasSite.numberPesquisaListaUnico : 0) }}
                  </h4>
                  <b-card-text class="font-small-3 mb-0">
                    {{ $t('Resultados de pesquisa') }}
                  </b-card-text>
                </b-media-body>
              </b-media>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="12">
              <h4>{{ $t('Aberto') }}</h4>
            </b-col>
          </b-row>
          <b-row class="mb-1">
            <b-col
              md="3"
              sm="6"
              class="mb-2 mb-md-0"
            >
              <b-media no-body>
                <b-media-body class="my-auto">
                  <h4 class="font-weight-bolder mb-0 text-primary">
                    {{ (typeof data.estatisticaWebsiteUnicos !== 'undefined' ? data.estatisticaWebsiteUnicos.totais.totalAberto : typeof data.estatisticasSite !== 'undefined' ? Number(data.estatisticasSite.numberDestaqueAbertoUnico + data.estatisticasSite.numberPesquisaAbertoUnico + data.estatisticasSite.numberDiretoUnico) : 0) }}
                  </h4>
                  <b-card-text class="font-small-3 mb-0">
                    {{ $t('Total') }}
                  </b-card-text>
                </b-media-body>
              </b-media>
            </b-col>
            <b-col
              md="3"
              sm="6"
              class="mb-2 mb-md-0"
            >
              <b-media no-body>
                <b-media-body class="my-auto">
                  <h4 class="font-weight-bolder mb-0">
                    {{ (typeof data.estatisticaWebsiteUnicos !== 'undefined' ? data.estatisticaWebsiteUnicos.destaque.aberto : typeof data.estatisticasSite !== 'undefined' ? data.estatisticasSite.numberDestaqueAbertoUnico : 0) }}
                  </h4>
                  <b-card-text class="font-small-3 mb-0">
                    {{ $t('Em destaque') }}
                  </b-card-text>
                </b-media-body>
              </b-media>
            </b-col>
            <b-col
              md="3"
              sm="6"
              class="mb-2 mb-md-0"
            >
              <b-media no-body>
                <b-media-body class="my-auto">
                  <h4 class="font-weight-bolder mb-0">
                    {{ (typeof data.estatisticaWebsiteUnicos !== 'undefined' ? data.estatisticaWebsiteUnicos.pesquisa.aberto : typeof data.estatisticasSite !== 'undefined' ? data.estatisticasSite.numberPesquisaAbertoUnico : 0) }}
                  </h4>
                  <b-card-text class="font-small-3 mb-0">
                    {{ $t('Resultados de pesquisa') }}
                  </b-card-text>
                </b-media-body>
              </b-media>
            </b-col>
            <b-col
              md="3"
              sm="6"
              class="mb-2 mb-md-0"
            >
              <b-media no-body>
                <b-media-body class="my-auto">
                  <h4 class="font-weight-bolder mb-0">
                    {{ (typeof data.estatisticaWebsiteUnicos !== 'undefined' ? data.estatisticaWebsiteUnicos.direto.aberto : typeof data.estatisticasSite !== 'undefined' ? data.estatisticasSite.numberDiretoUnico : 0) }}
                  </h4>
                  <b-card-text class="font-small-3 mb-0">
                    {{ $t('Link direto') }}
                  </b-card-text>
                </b-media-body>
              </b-media>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="12">
              <h4>{{ $t('Conversão') }}</h4>
            </b-col>
          </b-row>
          <b-row class="mb-1">
            <b-col
              md="3"
              sm="6"
              class="mb-2 mb-md-0"
            >
              <b-media no-body>
                <b-media-body class="my-auto">
                  <h4 class="font-weight-bolder mb-0 text-primary">
                    {{ (typeof data.estatisticaWebsiteUnicos !== 'undefined' ? data.estatisticaWebsiteUnicos.totais.totalConversao + '%' : typeof data.estatisticasSite !== 'undefined' ? data.estatisticasSite.conversaoTotalUnico + '%' : 0 + '%') }}
                  </h4>
                  <b-card-text class="font-small-3 mb-0">
                    {{ $t('Total') }}
                  </b-card-text>
                </b-media-body>
              </b-media>
            </b-col>
            <b-col
              md="3"
              sm="6"
              class="mb-2 mb-md-0"
            >
              <b-media no-body>
                <b-media-body class="my-auto">
                  <h4 class="font-weight-bolder mb-0">
                    {{ (typeof data.estatisticaWebsiteUnicos !== 'undefined' ? data.estatisticaWebsiteUnicos.totais.destaque + '%' : typeof data.estatisticasSite !== 'undefined' ? data.estatisticasSite.conversaoDestaqueUnico + '%' : 0 + '%') }}
                  </h4>
                  <b-card-text class="font-small-3 mb-0">
                    {{ $t('Listado > abertura') }}
                  </b-card-text>
                </b-media-body>
              </b-media>
            </b-col>
            <b-col
              md="3"
              sm="6"
              class="mb-2 mb-md-0"
            >
              <b-media no-body>
                <b-media-body class="my-auto">
                  <h4 class="font-weight-bolder mb-0">
                    {{ (typeof data.estatisticaWebsiteUnicos !== 'undefined' ? data.estatisticaWebsiteUnicos.totais.pesquisa + '%' : typeof data.estatisticasSite !== 'undefined' ? data.estatisticasSite.conversaoPesquisaUnico + '%' : 0 + '%') }}
                  </h4>
                  <b-card-text class="font-small-3 mb-0">
                    {{ $t('Pesquisado > abertura') }}
                  </b-card-text>
                </b-media-body>
              </b-media>
            </b-col>
          </b-row>

          <b-row class="mt-4">
            <b-col md="12">
              <h3>{{ $t('Zome APP') }}</h3>
            </b-col>
          </b-row>

          <b-row class="mb-1 mt-1">
            <b-col
              md="3"
              sm="6"
              class="mb-2 mb-md-0"
            >
              <b-media no-body>
                <b-media-body class="my-auto">
                  <h4 class="font-weight-bolder mb-0">
                    {{ (typeof data.estatisticaAngElse !== 'undefined' ? data.estatisticaAngElse.numberApresentacaoLista : typeof data.estatisticaAng !== 'undefined' ? data.estatisticaAng[1] : 0) }}
                  </h4>
                  <b-card-text class="font-small-3 mb-0">
                    {{ $t('Apresentações em lista') }}
                  </b-card-text>
                </b-media-body>
              </b-media>
            </b-col>
            <b-col
              md="3"
              sm="6"
              class="mb-2 mb-md-0"
            >
              <b-media no-body>
                <b-media-body class="my-auto">
                  <h4 class="font-weight-bolder mb-0">
                    {{ (typeof data.estatisticaAngElse !== 'undefined' ? data.estatisticaAngElse.numberAberturaVistaRapida : typeof data.estatisticaAng !== 'undefined' ? data.estatisticaAng[2] : 0) }}
                  </h4>
                  <b-card-text class="font-small-3 mb-0">
                    {{ $t('Abertura em vista rápida') }}
                  </b-card-text>
                </b-media-body>
              </b-media>
            </b-col>
            <b-col
              md="3"
              sm="6"
              class="mb-2 mb-md-0"
            >
              <b-media no-body>
                <b-media-body class="my-auto">
                  <h4 class="font-weight-bolder mb-0">
                    {{ (typeof data.estatisticaAngElse !== 'undefined' ? data.estatisticaAngElse.numberAberturaVistaCompleta : typeof data.estatisticaAng !== 'undefined' ? data.estatisticaAng[4] : 0) }}
                  </h4>
                  <b-card-text class="font-small-3 mb-0">
                    {{ $t('Abertura em vista completa') }}
                  </b-card-text>
                </b-media-body>
              </b-media>
            </b-col>
            <b-col
              md="3"
              sm="6"
              class="mb-2 mb-md-0"
            >
              <b-media no-body>
                <b-media-body class="my-auto">
                  <h4 class="font-weight-bolder mb-0">
                    {{ (typeof data.estatisticaAngElse !== 'undefined' ? data.estatisticaAngElse.numberBrochurasCriadas : typeof data.estatisticaAng !== 'undefined' ? data.estatisticaAng[3] : 0) }}
                  </h4>
                  <b-card-text class="font-small-3 mb-0">
                    {{ $t('Brochuras criadas') }}
                  </b-card-text>
                </b-media-body>
              </b-media>
            </b-col>
          </b-row>

          <b-row class="mt-4">
            <b-col md="12">
              <p>{{ $t('Porque um utilizador pode abrir várias vezes a mesma angariação, o valor referido em "Utilizadores únicos" significa a quantidade de pessoas distintas que viram ou abriram a angariação.') }}</p>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="12">
              <p>{{ $t('Aberto e listado na totalidade diferentes das somas dos totais? É possível graças diversos fatores, sendo os principais a abertura por link graças a uma partilha e os diversos métodos existentes de bloqueio de controlo de estatísticas e navegação anónima, existentes nos browsers modernos.') }}</p>
            </b-col>
          </b-row>
        </b-card-body>
      </vue-perfect-scrollbar>
    </div>

  </div>
</template>

<script>
import {
  BRow, BCol, VBTooltip, BCardBody, BMedia, BMediaBody, BCardText,
} from 'bootstrap-vue'
import store from '@/store'
import { onUnmounted } from '@vue/composition-api'
import estatisticasModule from '@store-modules/listings/estatisticas/index'
import { mapGetters } from 'vuex'
import Ripple from 'vue-ripple-directive'
import VuePerfectScrollbar from '@/views/modules/components/vue-perfect-scrollbar.vue'
import { sizeIconsAction } from '@core-custom/mixins/geral'
import { configSettingsScroll } from '@core-custom/utils/ui'
import { mixFormGeral } from '../../mixins'
import FooterStepNav from '../footerStepNavTop.vue'

export default {
  components: {
    BRow,
    BCol,
    BCardBody,
    BMedia,
    BMediaBody,
    BCardText,
    VuePerfectScrollbar,
    FooterStepNav,

  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  mixins: [sizeIconsAction, mixFormGeral],
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters('estatisticas', ['data']),
  },
  beforeDestroy() {
    if (this.$refs.listviewScroll) {
      this.$refs.listviewScroll.destroy()
    }
  },
  methods: {
  },
  setup() {
    const ESTATISTICAS_MODULE_NAME = 'estatisticas'

    if (!store.hasModule(ESTATISTICAS_MODULE_NAME)) {
      store.registerModule(ESTATISTICAS_MODULE_NAME, estatisticasModule)

      onUnmounted(() => {
        if (store.hasModule(ESTATISTICAS_MODULE_NAME)) store.unregisterModule(ESTATISTICAS_MODULE_NAME)
      })
    }

    return {
      configSettingsScroll,
    }
  },
}
</script>

<style lang="scss" scoped>
.email-application .email-user-list {
  height: calc( 100% - calc(3.6rem) )!important;
}

/* Desativa as animação devido que provoca erro no scroll to refresh */
.capacitor{
  .email-user-list ::v-deep{
    .email-media-list li {
      animation:none!important;
      animation-fill-mode:none!important;
    }
  }
}

</style>
