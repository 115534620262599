<template>
  <div>
    <b-row>
      <b-col
        v-if="index > 0"
        cols="12"
      >
        <hr>
      </b-col>
      <b-col
        md="2"
      >
        <b-form-group
          :label="$t('Documento')"
        >
          <div class="text-truncate txt-label d-none d-sm-block">
            <b-link
              :href="row.src"
              target="_blank"
            >
              <feather-icon
                icon="DownloadIcon"
                class="mr-25"
              />
              {{ row.name }}
              <p
                v-if="Number(row.deleted) === 1"
                class="text-danger"
              >
                {{ $t('Documento eliminado') }}
              </p>
            </b-link>
          </div>

          <div class="text-truncate d-xs-block d-sm-none">
            <b-link
              :href="row.src"
              target="_blank"
            >
              <feather-icon
                icon="DownloadIcon"
                class="mr-25"
              />
              {{ row.name }}
            </b-link>
          </div>
        </b-form-group>
      </b-col>
      <b-col md="3">
        <b-form-group
          :label="$t('Comentário')"
        >
          <b-form-input
            v-if="view === false"
            v-model="row.comment"
            type="text"
            autocomplete="off"
            @input="updateAction(row)"
          />
          <p
            v-else
            class="text-primary mb-0"
          >
            {{ row.comment }}
          </p>
        </b-form-group>
      </b-col>
      <b-col md="3">
        <b-form-group
          :label="$t('Tipo de documento')"
        >
          <v-select
            v-if="view === false"
            v-model="row.typesSel"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="row.typeDocs"
            :calculate-position="withPopper"
            append-to-body
            multiple
            label="sw032s02"
            item-text="sw032s02"
            item-value="sw032s01"
            :placeholder="$t('Escolha um tipo')"
            @input="updateAction(row)"
            @change="updateAction(row)"
          >
            <template #option="{ sw032s02 }">
              {{ sw032s02 }}
            </template>
            <div slot="no-options">
              {{ $t('Sem resultados') }}
            </div>
          </v-select>
          <p
            v-else
            class="text-left text-primary mb-0"
          >
            {{ renderObjToTxt(row.typeDocs, 'sw032s02') }}
          </p>
        </b-form-group>
      </b-col>
      <b-col
        md="2"
      >
        <b-form-group
          :label="$t('Inserido por')"
        >
          <div
            class="txt-label text-truncate d-none d-sm-block"
          >
            {{ row.insert }}
          </div>
          <div
            class="txt-label text-truncate d-none d-sm-block"
          >
            {{ row.date }}
          </div>
          <div class="text-truncate d-xs-block d-sm-none">
            {{ row.insert }}
          </div>
          <div class="text-truncate d-xs-block d-sm-none">
            {{ row.date }}
          </div>
        </b-form-group>
      </b-col>
      <b-col
        v-if="view === false"
        md="2"
      >
        <div
          class="d-none d-sm-block"
          style="margin-top: 1.55rem"
        />
        <b-button
          v-ripple.400="'rgba(234, 84, 85, 0.15)'"
          variant="outline-danger"
          @click="deleteAction(row.id)"
        >
          <feather-icon
            icon="XIcon"
            class="mr-25"
          />
          <span>{{ $t('Eliminar') }}</span>
        </b-button>
      </b-col>
    </b-row>

  </div>
</template>

<script>
import {
  BRow, BCol, BButton, BFormGroup, BFormInput, BLink,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { mixFormGeral } from '../../../mixins'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BLink,
    vSelect,

  },
  directives: {
    Ripple,
  },
  mixins: [mixFormGeral],
  props: {
    row: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    deleteAction: {
      type: Function,
      required: true,
    },
    updateAction: {
      type: Function,
      required: true,
    },
    view: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
    }
  },
}
</script>
