import { ref } from '@vue/composition-api'

const defaultState = () => ({
  showFormCreateNew: ref(false),
  platformsVR: ref([]),
  virtualR: ref([]),
  showVrEdition: ref(false),
  filterSelectedVrID: ref([]),
})

export default defaultState
