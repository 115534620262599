<template>
  <div>

    <div
      class="email-app-list position-relative height-100-panel"
    >
      <div
        class="app-fixed-search d-flex align-items-center"
      >
        <div class="d-flex align-items-center flex-grow-1 w-50">

          <div class="sidebar-toggle d-block d-lg-none ml-1">
            <feather-icon
              icon="MenuIcon"
              :size="sizeIconsAction"
              class="cursor-pointer"
              @click="mqShallShowLeftSidebar = true"
            />
          </div>

        </div>
        <div class="d-flex align-items-center w-50 justify-content-end">

          <b-button
            v-if="this.$store.getters['listings_form/formModeView'] === false"
            variant="flat-secondary"
            class="border-radius-none"
            :class="{ 'btn-sm' : ((this.$store.getters['app/currentBreakPoint'] === 'xs') ? true: false)}"
            @click.stop.prevent="createNewSW079"
          >
            <feather-icon
              icon="PlusIcon"
              :size="sizeIconsAction"
            />
          </b-button>

          <footer-step-nav ref="footerStepNav" />

        </div>
      </div>

      <vue-perfect-scrollbar
        class="scroll-content"
        :settings="configSettingsScroll"
      >
        <div
          class="app-fixed-search d-flex align-items-center"
          :class="{'border-0' : ((videos.length > 0) ? false : true)}"
        >
          <div class="d-flex flex-column align-items-center">

            <div class="d-flex align-items-center justify-content-start w-100">
              <h4 class="p-1 m-0">
                {{ $t('Vídeo') }}
              </h4>
            </div>
            <div
              v-if="this.$store.getters['listings_form/formModeView'] === false"
              class="d-flex align-items-center justify-content-start w-100 pl-1 pr-1 pb-1"
            >
              <b-dropdown
                v-if="videos.length !== 0"
                v-ripple.400="'rgba(255, 159, 67, 0.15)'"
                split
                :text="$t('Novo vídeo')"
                variant="outline-primary"
                @click.stop.prevent="createNewSW079"
              >
                <b-dropdown-item @click="setShowVideosEdition(true)">
                  {{ $t('Ordenar / Eliminar vídeos') }}
                </b-dropdown-item>
              </b-dropdown>
              <b-button
                v-else
                variant="outline-primary"
                @click.stop.prevent="createNewSW079"
              >
                <span class="align-middle">{{ $t('Novo vídeo') }}</span>
              </b-button>
            </div>

          </div>
        </div>

        <b-container
          class="p-2"
          fluid
        >

          <validation-observer
            ref="formSaveValidatorDados"
            #default="{}"
          >
            <b-row
              v-if="videos.length === 0"
            >
              <b-col md="12">
                <b-alert
                  show
                  variant="danger"
                >
                  <div class="alert-body">
                    <span class="ml-25">{{ $t('Nenhum vídeo inserido') }}</span>
                  </div>
                </b-alert>
              </b-col>
            </b-row>

            <b-row class="mt-2">
              <b-col
                v-for="(row, key) in videos"
                :key="`image-${key}`"
                md="4"
              >
                <component
                  :is="blockCardVideo"
                  :video="row"
                >
                  <template slot="blockActions">
                    <div class="grid-view m-0 p-1">
                      <div
                        class="item-options text-center"
                      >
                        <validation-provider
                          #default="{ errors }"
                          rules="required"
                          :name="$t('Descritivo do vídeo') | lowerBold"
                        >
                          <b-form-input
                            v-if="formModeView === false"
                            v-model="row.desc"
                            placeholder=""
                            autocomplete="off"
                            :class="errors.length > 0 ? 'is-invalid' : null"
                            @input="updateDescVideo($event, row.id)"
                          />
                          <p
                            v-else
                            class="text-left text-primary mb-0"
                          >
                            {{ row.desc }}
                          </p>
                        </validation-provider>
                      </div>
                    </div>
                  </template>
                </component>
              </b-col>
            </b-row>
          </validation-observer>

        </b-container>
      </vue-perfect-scrollbar>

    </div>

    <!-- Videos Order/Delete -->
    <videos-edition
      :class="{'show': showVideosEdition}"
      :delete-action="deleteVideos"
      :order-action="changeOrderVideos"
      @close-videos-edition="setShowVideosEdition(false)"
    />

    <form-create-new-sw079
      v-if="showFormCreateNew===true"
      ref="modalFormSW079"
      :load-action="loadVideos"
    />

  </div>
</template>

<script>
import store from '@/store'
import {
  BContainer, BRow, BCol, BButton, BAlert, BFormInput, BFormCheckbox, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapGetters } from 'vuex'
import modelSW079 from '@store-modules/listings/sw079'
import VuePerfectScrollbar from '@/views/modules/components/vue-perfect-scrollbar.vue'
import { onUnmounted } from '@vue/composition-api'
import { sizeIconsAction } from '@core-custom/mixins/geral'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { mask } from 'vue-the-mask'
import { showModalDeleteMessage } from '@core/utils/utils'
import { configSettingsScroll } from '@core-custom/utils/ui'
import { mixFormGeral } from '../../mixins'
import FooterStepNav from '../footerStepNavTop.vue'
import formCreateNewSw079 from './sw079/form.vue'
import VideosEdition from './sw079/list.vue'

export default {
  components: {
    BContainer,
    BRow,
    BCol,
    BButton,
    BAlert,
    BFormInput,
    BFormCheckbox,
    BDropdown,
    BDropdownItem,
    VuePerfectScrollbar,
    FooterStepNav,
    ValidationObserver,
    ValidationProvider,
    formCreateNewSw079,
    VideosEdition,
  },
  directives: {
    Ripple,
    mask,
  },
  mixins: [sizeIconsAction, mixFormGeral],
  data() {
    return {
      videosID: [],
      dragging: false,
    }
  },
  computed:
  {
    ...mapGetters('sw079', ['videos', 'showFormCreateNew', 'showVideosEdition', 'filterSelected']),
    ...mapGetters('listings_form', ['formModeView']),
    videos: {
      get() {
        return this.$store.getters['sw079/videos']
      },
      set(value) {
        this.$store.commit('sw079/setVideos', value)
      },
    },
  },
  methods: {
    async createNewSW079() {
      this.$root.$emit('app::loading', true)

      setTimeout(() => {
        this.clicked = false
        this.$store.dispatch('sw079/createNewSW079')
      }, 500)
    },
    async loadVideos() {
      await store.dispatch('sw079/loadVideos').then(response => {
        if (typeof response === 'object') {
          this.$store.commit('sw079/setVideos', response.videosAPI)
        } else {
          this.showMsgErrorRequest(this.$t('Problema a carregar os videos'))
        }
      }).catch(error => {
        this.showMsgErrorRequest(error)
      })
    },
    async updateDescVideo(desc, idVideo) {
      this.$root.$emit('app::loading', true)

      await store.dispatch('sw079/updateDescVideo', {
        regVideo: idVideo, txtDesc: desc,
      }).then(response => {
        if (typeof response === 'object') {
          if (response.error === 1) {
            this.showMsgErrorRequest({ message: response.msg })
          }
        } else {
          this.showMsgErrorRequest(this.$t('Problema a atualizar descrição do vídeo'))
        }
      }).catch(error => {
        this.showMsgErrorRequest(error)
      })

      this.$root.$emit('app::loading', false)
    },
    async changeOrderVideos() {
      this.$root.$emit('app::loading', true)

      await store.dispatch('sw079/changeOrderVideos', { videos: this.videos }).then(response => {
        if (typeof response === 'object' && response.msg === '') {
          this.showMsgSuccessRequest({ message: this.$t('Ordenação alterada com sucesso') })
        } else {
          this.showMsgErrorRequest(this.$t('Problema a trocar a ordenação dos videos'))
        }
      }).catch(error => {
        this.showMsgErrorRequest(error)
      })

      this.$root.$emit('app::loading', false)
    },
    async deleteVideos() {
      const self = this

      try {
        if (this.filterSelected.videosID.value.length > 0) {
          const actionModal = new Promise(resolve => {
            showModalDeleteMessage(resolve, `${this.$t('Pretende eliminar o(s) vídeo(s)?')}<br>${this.$t('Esta ação é irreversível!')}`)
          })

          await actionModal.then(result => {
            if (result === 'confirm') {
              self.$root.$emit('app::loading', true)

              store.dispatch('sw079/deleteVideos', { regDel: this.filterSelected.videosID.value }).then(response => {
                if (response.error === 1) {
                  self.$root.$emit('app::loading', false)
                  self.showMsgErrorRequest(self.$t('Problema a eliminar vídeos'))
                } else {
                  self.loadVideos()
                  self.showMsgSuccessRequest({ message: response.msg })
                  self.$root.$emit('app::loading', false)
                }
              }).catch(error => {
                self.$root.$emit('app::loading', false)
                self.showMsgErrorRequest(error)
              })
            }
          })
        }
      } catch (err) {
      //
      }
    },
    setShowVideosEdition(value) {
      this.$store.commit('sw079/setShowVideosEdition', value)
    },
  },
  setup() {
    const MODEL_SW079 = 'sw079'
    const blockCardVideo = () => import('@/views/modules/listings/form/form_data/media/sw079/cardVideo.vue')

    if (!store.hasModule(MODEL_SW079)) {
      store.registerModule(MODEL_SW079, modelSW079)

      onUnmounted(() => {
        if (store.hasModule(MODEL_SW079)) store.unregisterModule(MODEL_SW079)
      })
    }

    return {
      blockCardVideo,
      configSettingsScroll,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import "~@core/scss/base/pages/app-ecommerce.scss";
</style>

<style lang="scss" scoped>

.email-app-list .scroll-area {
  height: calc( 100% - calc(1.3rem) )!important;
}

</style>
