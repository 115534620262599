<template>
  <app-timeline class="mt-1 ml-3 mr-1">
    <app-timeline-item
      v-for="(row) in listviewData"
      :key="row.id"
      no-body
      icon="FilePlusIcon"
      variant="primary"
    >
      <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
        <h6>{{ row.codProposal }} - {{ row.nameAgent }} - <small class="text-muted">{{ row.dateInit }}</small></h6>
      </div>
      <div class="justify-content align-items-start">
        <!-- 1st Col -->
        <b-row>
          <b-col
            md="2"
          >
            <div class="mb-1 mb-sm-0">
              <span class="text-muted mb-50 d-block">{{ $t('Visita de origem') }}</span>
              <span>{{ row.codVisit }}</span>
            </div>
          </b-col>
          <b-col
            md="2"
          >
            <div class="mb-1 mb-sm-0">
              <span class="text-muted mb-50 d-block">{{ $t('Tipo de proposta') }}</span>
              <span>{{ row.typeProposal }}</span>
            </div>
          </b-col>
          <b-col
            md="2"
          >
            <div class="mb-1 mb-sm-0">
              <span class="text-muted mb-50 d-block">{{ $t('Última negociação') }}</span>
              <span>{{ row.dateLast }}</span>
            </div>
          </b-col>
          <b-col
            md="2"
          >
            <div class="mb-1 mb-sm-0">
              <span class="text-muted mb-50 d-block">{{ $t('Estado') }}</span>
              <span>{{ row.stateProposal }}</span>
            </div>
          </b-col>
          <b-col
            md="2"
          >
            <div class="mb-1 mb-sm-0">
              <span class="text-muted mb-50 d-block">{{ $t('Valor') }}</span>
              <span>{{ formatPrice(row.price) }}</span>
            </div>
          </b-col>
          <b-col
            v-if="row.price !== ''"
            md="2"
          >
            <b-button
              v-ripple.400="'rgba(234, 84, 85, 0.15)'"
              v-b-tooltip.hover
              :title="$t('Visualizar proposta')"
              variant="outline-primary"
              @click="openProposalDetail(row.id)"
            >
              <span>{{ $t('Ver') }}</span>
            </b-button>
          </b-col>

        </b-row>
      </div>
    </app-timeline-item>
  </app-timeline>
</template>

<script>
import {
  BRow, BCol, BButton, VBTooltip,
} from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import Ripple from 'vue-ripple-directive'
import { formatPrice } from '@core-custom/utils/ui'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    AppTimeline,
    AppTimelineItem,

  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    listviewData: {
      type: Array,
      required: true,
    },
    view: {
      type: Boolean,
      default: () => false,
    },
    openProposalDetail: {
      type: Function,
      required: true,
    },
    showMsgErrorRequest: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      formatPrice,
    }
  },
  computed: {
  },
  methods: {
  },
}
</script>
