import { ref } from '@vue/composition-api'

const defaultState = () => ({
  showFormCreateNew: ref(false),
  platformsVideo: ref([]),
  videos: ref([]),
  showVideosEdition: ref(false),
  filterSelectedVideosID: ref([]),
})

export default defaultState
