<template>
  <div>

    <div
      class="email-app-list position-relative height-100-panel"
    >
      <div
        class="app-fixed-search d-flex align-items-center"
      >
        <div class="d-flex align-items-center flex-grow-1 w-50">

          <div class="sidebar-toggle d-block d-lg-none ml-1">
            <feather-icon
              icon="MenuIcon"
              :size="sizeIconsAction"
              class="cursor-pointer"
              @click="mqShallShowLeftSidebar = true"
            />
          </div>

        </div>
        <div class="d-flex align-items-center w-50 justify-content-end">

          <footer-step-nav ref="footerStepNav" />

        </div>
      </div>

      <vue-perfect-scrollbar
        class="scroll-content"
        :settings="configSettingsScroll"
      >
        <div
          class="app-fixed-search d-flex align-items-center"
        >
          <div class="d-flex flex-column align-items-center">
            <div class="d-flex align-items-center justify-content-start w-100">
              <h4 class="p-1 m-0">
                {{ $t('Dados') }}
              </h4>
            </div>
          </div>
        </div>

        <b-container
          class="p-2"
          fluid
        >
          <b-row
            v-show="typeof form_data.sw012s379 !== 'undefined' && form_data.sw012s379 !== null && form_data.sw012s379 === '1'"
          >
            <b-col md="6">
              <b-form-group
                :label="$t('Id Externo')"
              >
                <b-form-input
                  v-if="formModeView===false"
                  v-model="form_data.sw012s02_manual"
                  :disabled="typeof form_data.sw012s01 !== 'undefined' && form_data.sw012s01 !== null ? true : false"
                  placeholder=""
                  autocomplete="off"
                />
                <p
                  v-else
                  class="text-primary mb-0"
                >
                  {{ form_data.sw012s02_manual | getTxt }}
                </p>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                :rules="typeof rulesFields.sw012s312 !== 'undefined' && rulesFields.sw012s312.required === true ? 'required' : ''"
                :name="$t('Tipo') | lowerBold"
              >
                <b-form-group
                  :label="$t('Tipo')"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <v-select
                    v-if="formModeView===false"
                    v-model="form_data.sw012s312"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="load_data_geral.typeCategory"
                    :value="form_data.sw012s312"
                    append-to-body
                    :calculate-position="withPopper"
                    :disabled="typeof form_data.sw012s301 !== 'undefined' && form_data.sw012s301 !== null && form_data.sw012s301 === '1' ? true : false"
                    label="sw087s02"
                    item-text="sw087s02"
                    item-value="sw087s01"
                    @input="getTipoImoveisByCategoria($event);validateVentureExclusive();"
                  >
                    <template #option="{ sw087s02 }">
                      {{ sw087s02 }}
                    </template>
                    <div slot="no-options">
                      {{ $t('Sem resultados') }}
                    </div>
                  </v-select>
                  <p
                    v-else
                    class="text-primary mb-0"
                  >
                    {{ renderObjToTxt(form_data.sw012s312, 'sw087s02') }}
                  </p>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                :rules="typeof rulesFields.sw012s07 !== 'undefined' && rulesFields.sw012s07.required === true ? 'required' : ''"
                :name="$t('Imóvel') | lowerBold"
              >
                <b-form-group
                  :label="$t('Imóvel')"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <v-select
                    v-if="formModeView===false"
                    v-model="form_data.sw012s07"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="load_data_geral.typeListings"
                    :value="form_data.sw012s07"
                    append-to-body
                    :calculate-position="withPopper"
                    :disabled="typeof form_data.sw012s301 !== 'undefined' && form_data.sw012s301 !== null && form_data.sw012s301 === '1' ? true : false"
                    label="desc"
                    item-text="desc"
                    item-value="id"
                    @input="getSubTipoImoveis($event);validateVentureExclusive();"
                  >
                    <template #option="{ desc }">
                      {{ desc }}
                    </template>
                    <div slot="no-options">
                      {{ $t('Sem resultados') }}
                    </div>
                  </v-select>
                  <p
                    v-else
                    class="text-primary mb-0"
                  >
                    {{ renderObjToTxt(form_data.sw012s07, 'desc') }}
                  </p>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>

          <b-row
            v-show="countryApp.toUpperCase() === 'PT' && typeof form_data.sw012s07 !== 'undefined' && form_data.sw012s07 !== null && form_data.sw012s07.show_cl_tipologia === '1'"
          >
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                :rules="typeof rulesFields.sw012s08 !== 'undefined' && rulesFields.sw012s08.required === true ? 'required' : ''"
                :name="$t('Tipologia') | lowerBold"
              >
                <b-form-group
                  :label="$t('Tipologia')"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <v-select
                    v-if="formModeView===false"
                    v-model="form_data.sw012s08"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="load_data_geral.typologiesClasses"
                    :value="form_data.sw012s08"
                    append-to-body
                    :calculate-position="withPopper"
                    label="sw005s02"
                    item-text="sw005s02"
                    item-value="sw005s01"
                    @input="getTipologiasExtras"
                  >
                    <template #option="{ sw005s02 }">
                      {{ sw005s02 }}
                    </template>
                    <div slot="no-options">
                      {{ $t('Sem resultados') }}
                    </div>
                  </v-select>
                  <p
                    v-else
                    class="text-primary mb-0"
                  >
                    {{ form_data.descTipologia | getTxt }}
                  </p>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>

          <b-row
            v-show="countryApp.toUpperCase() === 'PT' && formModeView===false && typeof form_data.sw012s08 !== 'undefined' && form_data.sw012s08 !== null && form_data.sw012s08 !== ''"
          >
            <b-col md="6">
              <b-form-group
                :label="$t('Tipologia extra')"
              >
                <v-select
                  v-if="formModeView===false"
                  v-model="form_data.sw012s08_plus"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="load_data_geral.typologiesExtra"
                  :value="form_data.sw012s08_plus"
                  append-to-body
                  :calculate-position="withPopper"
                  label="sw005s02"
                  item-text="sw005s02"
                  item-value="sw005s01"
                  @input="setNumberRooms"
                >
                  <template #option="{ sw005s02 }">
                    {{ sw005s02 }}
                  </template>
                  <div slot="no-options">
                    {{ $t('Sem resultados') }}
                  </div>
                </v-select>
                <p
                  v-else
                  class="text-primary mb-0"
                >
                  {{ renderObjToTxt(form_data.sw012s08_plus, 'sw005s02') }}
                </p>
              </b-form-group>
            </b-col>
          </b-row>

          <div
            v-show="['ES', 'AR'].includes(countryApp.toUpperCase()) && typeof form_data.sw012s07 !== 'undefined' && form_data.sw012s07 !== null && form_data.sw012s07.show_tipo_terreno !== '1'"
          >
            <b-row>
              <b-col md="6">
                <validation-provider
                  #default="{ errors }"
                  :rules="typeof rulesFields.sw012s363 !== 'undefined' && rulesFields.sw012s363.required === true ? 'required' : ''"
                  :name="$t('Quartos') | lowerBold"
                >
                  <b-form-group
                    :label="$t('Quartos')"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <v-select
                      v-if="formModeView===false"
                      v-model="form_data.sw012s363"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="load_data_geral.array70"
                      :value="form_data.sw012s363"
                      append-to-body
                      :calculate-position="withPopper"
                      label="desc"
                      item-text="desc"
                      item-value="id"
                      @input="setNumberRooms"
                    >
                      <template #option="{ desc }">
                        {{ desc }}
                      </template>
                      <div slot="no-options">
                        {{ $t('Sem resultados') }}
                      </div>
                    </v-select>
                    <p
                      v-else
                      class="text-primary mb-0"
                    >
                      {{ renderObjToTxt(form_data.sw012s363, 'desc') }}
                    </p>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>

            <b-row
              v-show="typeof form_data.sw012s07 !== 'undefined' && form_data.sw012s07 !== null && ![3, 15, 19, 119, 155, 156, 157, 197, 206, 216, 228, 224].includes(Number(form_data.sw012s07.id))"
            >
              <b-col md="6">
                <validation-provider
                  #default="{ errors }"
                  :rules="typeof rulesAttrFields.sw012s13 !== 'undefined' && rulesAttrFields.sw012s13.required === true ? 'required' : ''"
                  :name="$t('Casa de banho') | lowerBold"
                >
                  <b-form-group
                    :label="$t('Casa de banho')"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <v-select
                      v-if="formModeView===false"
                      v-model="form_data.sw012s13"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="load_data_geral.array70"
                      :value="form_data.sw012s13"
                      append-to-body
                      :calculate-position="withPopper"
                      label="desc"
                      item-text="desc"
                      item-value="id"
                    >
                      <template #option="{ desc }">
                        {{ desc }}
                      </template>
                      <div slot="no-options">
                        {{ $t('Sem resultados') }}
                      </div>
                    </v-select>
                    <p
                      v-else
                      class="text-primary mb-0"
                    >
                      {{ renderObjToTxt(form_data.sw012s13, 'desc') }}
                    </p>
                    <b-form-tags
                      v-if="formModeView===false"
                      v-model="form_data.sw012s127"
                      input-id="sw012s127"
                      separator=","
                      :placeholder="$t('áreas')"
                      remove-on-delete
                      no-add-on-enter
                      class="mt-1"
                    />
                    <p
                      v-if="formModeView===true && typeof form_data.sw012s127 !== 'undefined' && form_data.sw012s127 !== null"
                      class="text-primary mb-0"
                    >
                      {{ form_data.sw012s127 | getTxt }}
                    </p>
                    <b-form-text
                      v-if="formModeView===false && typeof form_data.sw012s13 !== 'undefined' && form_data.sw012s13 !== null && form_data.sw012s13.id !== '0'"
                    >
                      {{ $t('Separe as áreas com virgulas. Separe as casas decimais com ponto.') }}
                    </b-form-text>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
          </div>

          <b-row
            v-show="typeof form_data.sw012s07 !== 'undefined' && form_data.sw012s07 !== null && (form_data.sw012s07.escolha_especial === '1' && form_data.sw012s07.show_tipo_terreno !== '1')"
          >
            <b-col md="6">
              <b-form-group
                :label="$t('Especial')"
              >
                <v-select
                  v-if="formModeView===false"
                  v-model="form_data.sw019s03"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="load_data_geral.subTypeListings"
                  append-to-body
                  :calculate-position="withPopper"
                  :multiple="typeof form_data.sw012s07 !== 'undefined' && form_data.sw012s07 !== null && Number(form_data.sw012s07.escolha_especial) === 1 ? true : false"
                  label="sw004s02"
                  item-text="sw004s02"
                  item-value="sw004s01"
                  @input="validateSW019s03"
                >
                  <template #option="{ sw004s02 }">
                    {{ sw004s02 }}
                  </template>
                  <div slot="no-options">
                    {{ $t('Sem resultados') }}
                  </div>
                </v-select>
                <p
                  v-else
                  class="text-primary mb-0"
                >
                  {{ renderArrayToTxt(form_data.sw019s03, 'sw004s02') }}
                </p>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row
            v-show="typeof form_data.sw012s07 !== 'undefined' && form_data.sw012s07 !== null && form_data.sw012s07.show_tipo_terreno === '1'"
          >
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                :rules="typeof rulesFields.sw012s09 !== 'undefined' && rulesFields.sw012s09.required === true ? 'required' : ''"
                :name="$t('Tipo de terreno') | lowerBold"
              >
                <b-form-group
                  :label="$t('Tipo de terreno')"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <v-select
                    v-if="formModeView===false"
                    v-model="form_data.sw012s09"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="load_data_geral.typeLands"
                    append-to-body
                    :calculate-position="withPopper"
                    label="sw008s02"
                    item-text="sw008s02"
                    item-value="sw008s01"
                  >
                    <template #option="{ sw008s02 }">
                      {{ sw008s02 }}
                    </template>
                    <div slot="no-options">
                      {{ $t('Sem resultados') }}
                    </div>
                  </v-select>
                  <p
                    v-else
                    class="text-primary mb-0"
                  >
                    {{ renderObjToTxt(form_data.sw012s09, 'sw008s02') }}
                  </p>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                :rules="typeof rulesFields.sw012s06 !== 'undefined' && rulesFields.sw012s06.required === true ? 'required' : ''"
                :name="$t('Tipo de negócio') | lowerBold"
              >
                <b-form-group
                  :label="$t('Tipo de negócio')"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <v-select
                    v-if="formModeView===false"
                    id="sw012s06"
                    v-model="form_data.sw012s06"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="load_data_geral.typeBusiness"
                    append-to-body
                    :calculate-position="withPopper"
                    :disabled="typeof form_data.sw012s301 !== 'undefined' && form_data.sw012s301 !== null && form_data.sw012s301 === '1' ? true : false"
                    label="sw007s02"
                    item-text="sw007s02"
                    item-value="sw007s01"
                    @input="validateSW012s06"
                  >
                    <template #option="{ sw007s02 }">
                      {{ sw007s02 }}
                    </template>
                    <div slot="no-options">
                      {{ $t('Sem resultados') }}
                    </div>
                  </v-select>
                  <p
                    v-else
                    class="text-primary mb-0"
                  >
                    {{ renderObjToTxt(form_data.sw012s06, 'sw007s02') }}
                  </p>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>

          <b-row v-show="countryApp.toUpperCase() === 'PT'">
            <b-col md="6">
              <b-form-group
                :label="$t('Finalidade')"
              >
                <v-select
                  v-if="formModeView===false"
                  v-model="form_data.sw012s10"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="load_data_geral.finality"
                  :disabled="typeof form_data.sw012s07 !== 'undefined' && form_data.sw012s07 !== null && Number(form_data.sw012s07.id) === Number(appConfig.idTipoImovelApartamento) ? true : false "
                  append-to-body
                  :calculate-position="withPopper"
                  label="sw009s02"
                  item-text="sw009s02"
                  item-value="sw009s01"
                >
                  <template #option="{ sw009s02 }">
                    {{ sw009s02 }}
                  </template>
                  <div slot="no-options">
                    {{ $t('Sem resultados') }}
                  </div>
                </v-select>
                <p
                  v-else
                  class="text-primary mb-0"
                >
                  {{ renderObjToTxt(form_data.sw012s10, 'sw009s02') }}
                </p>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row
            v-show="countryApp.toUpperCase() === 'AR' && typeof form_data.sw012s07 !== 'undefined' && form_data.sw012s07 !== null && ![216, 225, 220].includes(Number(form_data.sw012s07.id))"
          >
            <b-col md="6">
              <b-form-group
                :label="$t('Finalidade')"
              >
                <v-select
                  v-if="formModeView===false"
                  v-model="form_data.sw012s10"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="load_data_geral.finality"
                  :disabled="typeof form_data.sw012s07 !== 'undefined' && form_data.sw012s07 !== null && [216, 225, 220].includes(Number(form_data.sw012s07.id)) ? true : false "
                  append-to-body
                  :calculate-position="withPopper"
                  label="sw009s02"
                  item-text="sw009s02"
                  item-value="sw009s01"
                >
                  <template #option="{ sw009s02 }">
                    {{ sw009s02 }}
                  </template>
                  <div slot="no-options">
                    {{ $t('Sem resultados') }}
                  </div>
                </v-select>
                <p
                  v-else
                  class="text-primary mb-0"
                >
                  {{ renderObjToTxt(form_data.sw012s10, 'sw009s02') }}
                </p>
              </b-form-group>
            </b-col>
          </b-row>

          <div
            v-show="(filterFields.currentlyRent === true) || (typeof form_data.sw012s06 !== 'undefined' && form_data.sw012s06 !== null && [1, 3].includes(Number(form_data.sw012s06.sw007s01)))"
          >
            <b-row>
              <b-col md="6">
                <validation-provider
                  #default="{ errors }"
                  :rules="typeof rulesFields.sw012s376 !== 'undefined' && rulesFields.sw012s376.required === true ? 'required' : ''"
                  :name="$t('Atualmente está arrendado?') | lowerBold"
                >
                  <b-form-group
                    :label="$t('Atualmente está arrendado?')"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <v-select
                      v-if="formModeView===false"
                      v-model="form_data.sw012s376"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="load_data_geral.arrayYesNo"
                      append-to-body
                      :calculate-position="withPopper"
                      label="desc"
                      item-text="desc"
                      item-value="id"
                      @input="validateActualyRent"
                    >
                      <template #option="{ desc }">
                        {{ desc }}
                      </template>
                      <div slot="no-options">
                        {{ $t('Sem resultados') }}
                      </div>
                    </v-select>
                    <p
                      v-else
                      class="text-primary mb-0"
                    >
                      {{ renderObjToTxt(form_data.sw012s376, 'desc') }}
                    </p>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>

            <b-row
              v-show="typeof form_data.sw012s376 !== 'undefined' && form_data.sw012s376 !== null && form_data.sw012s376.id === '1'"
            >
              <b-col md="6">
                <b-form-group
                  :label="$t('Renda mensal')"
                >
                  <b-input-group
                    v-if="formModeView===false"
                    :prepend="symbolMoney"
                    append=""
                    class="input-group-merge"
                  >
                    <b-form-input
                      v-model.lazy="form_data.sw012s377"
                      v-currency="configMoney"
                      placeholder=""
                      autocomplete="off"
                      @input="calculateRentabilidadeBruta"
                    />
                  </b-input-group>
                  <p
                    v-else
                    class="text-primary mb-0"
                  >
                    {{ formatPrice(form_data.sw012s377) | getTxt }} {{ symbolMoney }}
                  </p>
                  <p
                    v-if="typeof form_data.sw012s242 !== 'undefined' && form_data.sw012s242 !== null"
                    class="text-primary mb-0"
                  >
                    {{ $t('Valor máximo de renda') }} - {{ formatPrice(form_data.sw012s242) }} {{ symbolMoney }}
                  </p>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row
              v-show="typeof form_data.sw012s376 !== 'undefined' && form_data.sw012s376 !== null && form_data.sw012s376.id === '1'"
            >
              <b-col md="6">
                <b-form-group
                  :label="$t('Rentabilidade bruta')"
                >
                  <b-form-input
                    v-if="formModeView===false"
                    v-model="form_data.rentabilidadeBruta"
                    :disabled="true"
                    placeholder=""
                    autocomplete="off"
                  />
                  <p
                    v-else
                    class="text-primary mb-0"
                  >
                    {{ formatPrice(form_data.rentabilidadeBruta) | getTxt }} {{ symbolMoney }}
                  </p>
                </b-form-group>
              </b-col>
            </b-row>
          </div>

          <div
            v-show="countryApp.toUpperCase() === 'PT' && form_data.sw012s379 !== '1' && appConfig.bUseListingsPAA === true"
          >
            <b-row
              v-show="typeof form_data.sw012s06 !== 'undefined' && form_data.sw012s06 !== null && form_data.sw012s06.sw007s01 === '2'"
            >
              <b-col md="6">
                <validation-provider
                  #default="{ errors }"
                  :rules="typeof rulesFields.sw012s230 !== 'undefined' && rulesFields.sw012s230.required === true && appConfig.bUseListingsPAA === true ? 'required' : ''"
                  :name="$t('PAA') | lowerBold"
                >
                  <b-form-group
                    :label="$t('PAA')"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <v-select
                      v-if="formModeView===false"
                      v-model="form_data.sw012s230"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="load_data_geral.arrayYesNo"
                      append-to-body
                      :calculate-position="withPopper"
                      label="desc"
                      item-text="desc"
                      item-value="id"
                      @input="validateSW230"
                    >
                      <template #option="{ desc }">
                        {{ desc }}
                      </template>
                      <div slot="no-options">
                        {{ $t('Sem resultados') }}
                      </div>
                    </v-select>
                    <p
                      v-else
                      class="text-primary mb-0"
                    >
                      {{ renderObjToTxt(form_data.sw012s230, 'desc') }}
                    </p>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>

            <b-row
              v-show="typeof form_data.sw012s230 !== 'undefined' && form_data.sw012s230 !== null && form_data.sw012s230.id === '1'"
            >
              <b-col md="6">
                <b-form-group
                  :label="$t('NIF')"
                >
                  <validation-provider
                    #default="{ errors }"
                    :rules="typeof rulesFields.sw012s231 !== 'undefined' && rulesFields.sw012s231.required === true ? 'required' : ''"
                    :name="$t('NIF') | lowerBold"
                  >
                    <b-form-input
                      v-if="formModeView===false"
                      v-model="form_data.sw012s231"
                      v-mask="tokenMaskNIF"
                      placeholder=""
                      autocomplete="off"
                      :class="errors.length > 0 ? 'is-invalid' : null"
                      :type="(($store.getters['auth/countryApp'] == 'pt') ? 'number': 'text')"
                      @input="validatePAA_webservice"
                    />
                    <p
                      v-else
                      class="text-primary mb-0"
                    >
                      {{ form_data.sw012s231 | getTxt }}
                    </p>
                  </validation-provider>
                </b-form-group>

              </b-col>
            </b-row>

            <b-row
              v-show="typeof form_data.sw012s230 !== 'undefined' && form_data.sw012s230 !== null && form_data.sw012s230.id === '1'"
            >
              <b-col md="6">
                <b-form-group
                  :label="$t('Certificado')"
                >
                  <validation-provider
                    #default="{ errors }"
                    :rules="typeof rulesFields.sw012s241 !== 'undefined' && rulesFields.sw012s241.required === true ? 'required' : ''"
                    :name="$t('Certificado') | lowerBold"
                  >
                    <b-form-input
                      v-if="formModeView===false"
                      v-model="form_data.sw012s241"
                      placeholder=""
                      autocomplete="off"
                      :class="errors.length > 0 ? 'is-invalid' : null"
                      @input="validatePAA_webservice"
                    />
                    <p
                      v-else
                      class="text-primary mb-0"
                    >
                      {{ form_data.sw012s241 | getTxt }}
                    </p>
                  </validation-provider>
                </b-form-group>

              </b-col>
            </b-row>
          </div>

          <b-row
            v-show="countryApp.toUpperCase() === 'AR' && typeof form_data.sw012s06 !== 'undefined' && form_data.sw012s06 !== null && [2, 4].includes(Number(form_data.sw012s06.sw007s01))"
          >
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                :rules="typeof rulesFields.sw012s516 !== 'undefined' && rulesFields.sw012s516.required === true ? 'required' : ''"
                :name="$t('Período de arrendamento') | lowerBold"
              >
                <b-form-group
                  :label="$t('Período de arrendamento')"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <v-select
                    v-if="formModeView===false"
                    v-model="form_data.sw012s516"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="load_data_geral.rentPeriod"
                    append-to-body
                    :calculate-position="withPopper"
                    label="sw278s02"
                    item-text="sw278s02"
                    item-value="sw278s01"
                  >
                    <template #option="{ sw278s02 }">
                      {{ sw278s02 }}
                    </template>
                    <div slot="no-options">
                      {{ $t('Sem resultados') }}
                    </div>
                  </v-select>
                  <p
                    v-else
                    class="text-primary mb-0"
                  >
                    {{ renderObjToTxt(form_data.sw012s516, 'sw278s02') }}
                  </p>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                :rules="typeof rulesFields.sw012s11 !== 'undefined' && rulesFields.sw012s11.required === true ? 'required' : ''"
                :name="$t('Condição do imóvel') | lowerBold"
              >
                <b-form-group
                  :label="$t('Condição do imóvel')"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <v-select
                    v-if="formModeView===false"
                    v-model="form_data.sw012s11"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="load_data_geral.conditions"
                    append-to-body
                    :calculate-position="withPopper"
                    label="sw010s02"
                    item-text="sw010s02"
                    item-value="sw010s01"
                  >
                    <template #option="{ sw010s02 }">
                      {{ sw010s02 }}
                    </template>
                    <div slot="no-options">
                      {{ $t('Sem resultados') }}
                    </div>
                  </v-select>
                  <p
                    v-else
                    class="text-primary mb-0"
                  >
                    {{ renderObjToTxt(form_data.sw012s11, 'sw010s02') }}
                  </p>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="6">
              <b-form-group
                :label="$t('Ano de construção')"
              >
                <cleave
                  v-if="formModeView===false"
                  v-model="form_data.sw012s12"
                  class="form-control text-center"
                  :raw="false"
                  :options="maskDateYear"
                  placeholder="yyyy"
                  autocomplete="off"
                  inputmode="numeric"
                />
                <p
                  v-else
                  class="text-primary mb-0"
                >
                  {{ form_data.sw012s12 | getTxt }}
                </p>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row
            v-show="countryApp.toUpperCase() === 'AR' && typeof form_data.sw012s07 !== 'undefined' && form_data.sw012s07 !== null && [206].includes(Number(form_data.sw012s07.id))"
          >
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                :rules="typeof rulesFields.sw012s492 !== 'undefined' && rulesFields.sw012s492.required === true ? 'required' : ''"
                :name="$t('Tipo de garagem') | lowerBold"
              >
                <b-form-group
                  :label="$t('Tipo de garagem')"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <v-select
                    v-if="formModeView===false"
                    v-model="form_data.sw012s492"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="load_data_geral.typeGarage"
                    append-to-body
                    :calculate-position="withPopper"
                    :disabled="typeof form_data.sw012s07 !== 'undefined' && form_data.sw012s07 !== null && [206].includes(Number(form_data.sw012s07.id)) ? true : false"
                    label="desc"
                    item-text="desc"
                    item-value="id"
                  >
                    <template #option="{ desc }">
                      {{ desc }}
                    </template>
                    <div slot="no-options">
                      {{ $t('Sem resultados') }}
                    </div>
                  </v-select>
                  <p
                    v-else
                    class="text-primary mb-0"
                  >
                    {{ renderObjToTxt(form_data.sw012s492, 'desc') }}
                  </p>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>

          <!-- Empreendimentos -->
          <b-row v-show="showBoxVenture===true">
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                :rules="typeof rulesFields.sw012s542 !== 'undefined' && rulesFields.sw012s542.required === true ? 'required' : ''"
                :name="$t('Pertence a um empreendimento?') | lowerBold"
              >
                <b-form-group
                  :label="$t('Pertence a um empreendimento?')"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <v-select
                    v-if="formModeView===false"
                    v-model="form_data.sw012s542"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="load_data_geral.arrayYesNo"
                    append-to-body
                    :calculate-position="withPopper"
                    label="desc"
                    item-text="desc"
                    item-value="id"
                    @input="validateVentureExclusive()"
                  >
                    <template #option="{ desc }">
                      {{ desc }}
                    </template>
                    <div slot="no-options">
                      {{ $t('Sem resultados') }}
                    </div>
                  </v-select>
                  <p
                    v-else
                    class="text-primary mb-0"
                  >
                    {{ renderObjToTxt(form_data.sw012s542, 'desc') }}
                  </p>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row v-show="showBoxVenture===true">
            <b-col
              v-show="showBoxChooseVenture===true"
              md="6"
            >
              <validation-provider
                #default="{ errors }"
                :rules="typeof rulesFields.sw012s543 !== 'undefined' && rulesFields.sw012s543.required === true ? 'required' : ''"
                :name="$t('Qual?') | lowerBold"
              >
                <b-form-group
                  :label="$t('Qual?')"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <v-select
                    v-if="formModeView===false"
                    v-model="form_data.sw012s543"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="load_data_geral.enterprises"
                    append-to-body
                    :calculate-position="withPopper"
                    label="name"
                    item-text="name"
                    item-value="id"
                    @input="checkListingsAssocVenture();validatePercCMI();"
                  >
                    <template #option="option">
                      {{ renderNameVentureHtml(option) }}
                    </template>
                    <template #selected-option="option">
                      {{ renderNameVentureHtml(option) }}
                    </template>
                    <div slot="no-options">
                      {{ $t('Sem resultados') }}
                    </div>
                  </v-select>
                  <p
                    v-else
                    class="text-primary mb-0"
                  >
                    {{ renderNameVentureHtml(form_data.sw012s543) }}
                  </p>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row v-show="(showBoxVenture===true) && (showBoxVentureNote === true)">
            <b-col md="6">
              <b-alert
                variant="warning"
                show
              >
                <div class="alert-body">
                  <!-- eslint-disable vue/no-v-html -->
                  <span
                    v-html="$t('Esta angariação apenas será associada ao empreendimento selecionado, quando a mesma for aprovada (estado \'Ativo\').')"
                  />
                  <!--eslint-enable-->
                </div>
              </b-alert>
            </b-col>
          </b-row>
          <!-- -->

          <b-row
            v-show="formModeView === true && typeof load_data_geral.infoEmpreendimento !== 'undefined' && load_data_geral.infoEmpreendimento !== null && load_data_geral.infoEmpreendimento.length > 0"
          >
            <b-col md="6">
              <b-form-group
                :label="$t('Empreendimento')"
              >
                <b-link
                  href="#"
                >
                  <feather-icon
                    icon="HomeIcon"
                    class="mr-25"
                  />
                  {{ load_data_geral.infoEmpreendimento.sw055s02 }}
                </b-link>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row v-show="appConfig.bUseListingsExclusivity === true">
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                :rules="typeof rulesFields.sw012s466 !== 'undefined' && rulesFields.sw012s466.required === true ? 'required' : ''"
                :name="$t('Regime de contratação') | lowerBold"
              >
                <b-form-group
                  :label="$t('Regime de contratação')"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <v-select
                    v-if="formModeView===false"
                    v-model="form_data.sw012s466"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="load_data_geral.typeRegime"
                    append-to-body
                    :calculate-position="withPopper"
                    :disabled="disableRegimeContratacao"
                    label="desc"
                    item-text="desc"
                    item-value="id"
                  >
                    <template #option="{ desc }">
                      {{ desc }}
                    </template>
                    <div slot="no-options">
                      {{ $t('Sem resultados') }}
                    </div>
                  </v-select>
                  <p
                    v-else
                    class="text-primary mb-0"
                  >
                    {{ renderObjToTxt(form_data.sw012s466, 'desc') }}
                  </p>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>

          <b-row
            v-show="typeof form_data.sw012s07 !== 'undefined' && form_data.sw012s07 !== null && form_data.sw012s07.show_ce === '1'"
          >
            <b-col md="12">
              <h4
                class="mt-1"
              >
                {{ $t('Certificado energético') }}
              </h4>
            </b-col>
          </b-row>

          <b-row
            v-show="typeof form_data.sw012s07 !== 'undefined' && form_data.sw012s07 !== null && form_data.sw012s07.show_ce === '1'"
          >
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                :rules="typeof rulesFields.sw012s19 !== 'undefined' && rulesFields.sw012s19.required === true ? 'required' : ''"
                :name="$t('Classe') | lowerBold"
              >
                <b-form-group
                  :label="$t('Classe')"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <v-select
                    v-if="formModeView===false"
                    v-model="form_data.sw012s19"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="load_data_geral.energy"
                    append-to-body
                    :calculate-position="withPopper"
                    label="sw006s02"
                    item-text="sw006s02"
                    item-value="sw006s01"
                    @input="validateCE"
                  >
                    <template #option="{ sw006s02 }">
                      {{ sw006s02 }}
                    </template>
                    <div slot="no-options">
                      {{ $t('Sem resultados') }}
                    </div>
                  </v-select>
                  <p
                    v-else
                    class="text-primary mb-0"
                  >
                    {{ renderObjToTxt(form_data.sw012s19, 'sw006s02') }}
                  </p>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>

          <b-row
            v-show="typeof form_data.sw012s19 !== 'undefined' && form_data.sw012s19 !== null && ![9, 10, 12].includes(Number(form_data.sw012s19.sw006s01))"
          >
            <b-col md="6">
              <b-form-group
                :label="$t('Válido até')"
              >
                <b-input-group>
                  <date-picker
                    v-if="formModeView===false"
                    v-model="form_data.sw012s319"
                    value-type="format"
                    input-class="form-control"
                    type="date"
                    :editable="false"
                    :clearable="true"
                    :lang="langCalendar"
                    :append-to-body="true"
                    prefix-class="sw"
                  >
                    <template #icon-clear>
                      <v-select-deselect />
                    </template>
                  </date-picker>
                  <p
                    v-else
                    class="text-primary mb-0"
                  >
                    {{ form_data.sw012s319 | getTxt }}
                  </p>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row class="mt-1">
            <b-col
              v-show="formModeView===false"
              md="2"
            >
              <b-form-group>
                <b-form-checkbox
                  v-model="form_data.sw012s22"
                  value="1"
                >
                  {{ $t('Condomínio fechado') }}
                </b-form-checkbox>
              </b-form-group>
            </b-col>
            <b-col
              v-show="formModeView===true"
              md="2"
            >
              <p
                class="text-primary mb-0"
              >
                <feather-icon
                  :icon="((form_data.sw012s22 ==='1') ? 'CheckSquareIcon' : 'SquareIcon')"
                  size="17"
                  class="mr-50"
                />
                {{ $t('Condomínio fechado') }}
              </p>
            </b-col>

            <b-col
              v-show="formModeView===false"
              md="2"
            >
              <b-form-group>
                <b-form-checkbox
                  v-model="form_data.sw012s23"
                  value="1"
                >
                  {{ $t('Imóvel com penhora') }}
                </b-form-checkbox>
              </b-form-group>
            </b-col>
            <b-col
              v-show="formModeView===true"
              md="2"
            >
              <p
                class="text-primary mb-0"
              >
                <feather-icon
                  :icon="((form_data.sw012s23 ==='1') ? 'CheckSquareIcon' : 'SquareIcon')"
                  size="17"
                  class="mr-50"
                />
                {{ $t('Imóvel com penhora') }}
              </p>
            </b-col>

            <b-col
              v-show="formModeView===false"
              md="2"
            >
              <b-form-group>
                <b-form-checkbox
                  v-model="form_data.sw012s24"
                  value="1"
                  :disabled="((idBankAssoc !== '' || idLeadAssoc !== '' || load_data_geral.blockBankOption !== '') && appConfig.bUseBancosAngariacoes === true) ? true : false"
                >
                  {{ $t('Imóvel de banco') }}
                </b-form-checkbox>
              </b-form-group>
            </b-col>
            <b-col
              v-show="formModeView===true"
              md="2"
            >
              <p
                class="text-primary mb-0"
              >
                <feather-icon
                  :icon="((form_data.sw012s24 === '1') ? 'CheckSquareIcon' : 'SquareIcon')"
                  size="17"
                  class="mr-50"
                />
                {{ $t('Imóvel de banco') }}
              </p>
            </b-col>

            <b-col
              v-show="formModeView===false"
              md="2"
            >
              <b-form-group>
                <b-form-checkbox
                  v-model="form_data.sw012s310"
                  value="1"
                >
                  {{ $t('Imóvel de herança') }}
                </b-form-checkbox>
              </b-form-group>
            </b-col>
            <b-col
              v-show="formModeView===true"
              md="2"
            >
              <p
                class="text-primary mb-0"
              >
                <feather-icon
                  :icon="((form_data.sw012s310 ==='1') ? 'CheckSquareIcon' : 'SquareIcon')"
                  size="17"
                  class="mr-50"
                />
                {{ $t('Imóvel de herança') }}
              </p>
            </b-col>

            <b-col
              v-show="countryApp.toUpperCase() === 'PT' && formModeView===false"
              md="2"
            >
              <b-form-group>
                <b-form-checkbox
                  v-model="form_data.sw012s550"
                  value="1"
                  :disabled="((load_data_geral.certificadoBy !== 1)) ? true : false"
                >
                  {{ $t('Imóvel Verificado by Zome') }}
                </b-form-checkbox>
              </b-form-group>
            </b-col>
            <b-col
              v-show="countryApp.toUpperCase() === 'PT' && formModeView===true"
              md="2"
            >
              <p
                class="text-primary mb-0"
              >
                <feather-icon
                  :icon="((Number(form_data.sw012s550) === 1) ? 'CheckSquareIcon' : 'SquareIcon')"
                  size="17"
                  class="mr-50"
                />
                {{ $t('Imóvel Verificado by Zome') }}
              </p>
            </b-col>
          </b-row>

          <b-row class="mt-2">
            <b-col md="12">
              <h4>
                {{ $t('Negócio') }}
              </h4>
            </b-col>
          </b-row>

          <b-row
            v-show="typeof form_data.sw012s06 === 'undefined' || form_data.sw012s06 === null || form_data.sw012s06.sw007s01 !== '2'"
          >
            <b-col md="6">
              <b-form-group
                :label="$t('Preço')"
              >
                <validation-provider
                  #default="{ errors }"
                  :rules="typeof rulesFields.sw012s14 !== 'undefined' && rulesFields.sw012s14.required === true ? 'required' : ''"
                  :name="$t('Preço') | lowerBold"
                >
                  <b-input-group
                    v-if="formModeView===false"
                    :prepend="symbolMoney"
                    append=""
                    :class="errors.length > 0 ? 'is-invalid input-group-merge': 'input-group-merge'"
                  >
                    <b-form-input
                      v-model.lazy="form_data.sw012s14"
                      v-currency="configMoney"
                      placeholder=""
                      autocomplete="off"
                      :class="errors.length > 0 ? 'is-invalid':null"
                      @change="calculateRentabilidadeBruta"
                      @blur="validatePercCMI();validateRegimeContratacao();"
                    />
                  </b-input-group>
                  <p
                    v-else
                    class="text-primary mb-0"
                  >
                    {{ formatPrice(form_data.sw012s14) | getTxt }} {{ symbolMoney }}
                  </p>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row
            v-show="typeof form_data.sw012s01 !== 'undefined' && form_data.sw012s01 !== null && typeof form_data.sw012s301 !== 'undefined' && form_data.sw012s301 === '1' && load_data_geral.historyPricesListing.length > 1"
          >
            <b-col md="6">
              <b-form-group>
                <b-link
                  href="#"
                  @click="openPrices"
                >
                  <feather-icon
                    icon="TrendingUpIcon"
                    class="mr-25"
                  />
                  {{ $t('Visualizar histórico') }}
                </b-link>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row
            v-show="typeof form_data.sw012s06 !== 'undefined' && form_data.sw012s06 !== null && form_data.sw012s06.sw007s01 === '2'"
          >
            <b-col md="6">
              <b-form-group
                :label="$t('Renda mensal')"
              >
                <validation-provider
                  #default="{ errors }"
                  :rules="typeof rulesFields.sw012s69 !== 'undefined' && rulesFields.sw012s69.required === true ? 'required' : ''"
                  :name="$t('Renda mensal') | lowerBold"
                >
                  <b-input-group
                    v-if="formModeView===false"
                    :prepend="symbolMoney"
                    append=""
                    :class="errors.length > 0 ? 'is-invalid input-group-merge': 'input-group-merge'"
                  >
                    <b-form-input
                      v-model.lazy="form_data.sw012s69"
                      v-currency="configMoney"
                      placeholder=""
                      autocomplete="off"
                      :class="errors.length > 0 ? 'is-invalid':null"
                      @input="validatePAA"
                      @blur="validatePercCMI();validateRegimeContratacao();"
                    />
                  </b-input-group>
                  <p
                    v-else
                    class="text-primary mb-0"
                  >
                    {{ formatPrice(form_data.sw012s69) | getTxt }} {{ symbolMoney }}
                  </p>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row
            v-show="countryApp.toUpperCase() === 'PT'"
          >
            <b-col
              v-show="formModeView===false"
              md="6"
            >
              <b-form-group>
                <b-form-checkbox
                  v-model="form_data.sw012s549"
                  value="1"
                >
                  {{ `${$t('Ocultar baixa de preço em')} ${appConfig.domainByUrl}` }}
                </b-form-checkbox>
              </b-form-group>
            </b-col>
            <b-col
              v-show="formModeView===true"
              md="6"
            >
              <p
                class="text-primary mb-0"
              >
                <feather-icon
                  :icon="((form_data.sw012s549 ==='1') ? 'CheckSquareIcon' : 'SquareIcon')"
                  size="17"
                  class="mr-50"
                />
                {{ `${$t('Ocultar baixa de preço em')} ${appConfig.domainByUrl}` }}
              </p>
            </b-col>
          </b-row>

          <b-row class="mt-2">
            <b-col md="12">
              <h4>
                {{ $t('Comissão') }}
              </h4>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="6">
              <b-form-group
                :label="$t('Percentagem')"
              >
                <validation-provider
                  #default="{ errors }"
                  :rules="typeof rulesFields.sw012s15 !== 'undefined' && rulesFields.sw012s15.required === true ? 'required' : ''"
                  :name="$t('Percentagem') | lowerBold"
                >
                  <b-input-group
                    v-if="formModeView===false"
                    prepend="%"
                    append=""
                    :class="(((errors.length > 0) || (invalidFields.includes('sw012s15') === true)) ? 'is-invalid input-group-merge': 'input-group-merge')"
                  >
                    <cleave
                      ref="elmSw012s15"
                      v-model="form_data.sw012s15"
                      :class="['form-control', (((errors.length > 0) || (invalidFields.includes('sw012s15') === true)) ? 'is-invalid':'')]"
                      :raw="false"
                      :options="options.number"
                      placeholder=""
                      autocomplete="off"
                      inputmode="numeric"
                      @blur="validatePercCMI();validateRegimeContratacao();"
                    />
                  </b-input-group>
                  <p
                    v-else
                    class="text-primary mb-0"
                  >
                    {{ form_data.sw012s15 | getTxt }} %
                  </p>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="6">
              <b-form-group
                :label="$t('Mínimo definido no CMI')"
              >
                <validation-provider
                  #default="{ errors }"
                  :rules="typeof rulesFields.sw012s16 !== 'undefined' && rulesFields.sw012s16.required === true ? 'required' : ''"
                  :name="$t('Mínimo definido no CMI') | lowerBold"
                >
                  <b-input-group
                    v-if="formModeView===false"
                    :prepend="symbolMoney"
                    append=""
                    :class="errors.length > 0 ? 'is-invalid input-group-merge': 'input-group-merge'"
                  >
                    <b-form-input
                      v-model="form_data.sw012s16"
                      v-currency="configMoney"
                      placeholder=""
                      autocomplete="off"
                      :class="errors.length > 0 ? 'is-invalid':null"
                      @blur="validateCMI"
                    />
                  </b-input-group>
                  <p
                    v-else
                    class="text-primary mb-0"
                  >
                    {{ formatPrice(form_data.sw012s16) | getTxt }} {{ symbolMoney }}
                  </p>
                </validation-provider>
              </b-form-group>

            </b-col>
          </b-row>

          <b-row v-show="countryApp.toUpperCase() === 'AR'">
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                :rules="typeof rulesFields.sw012s517 !== 'undefined' && rulesFields.sw012s517.required === true ? 'required' : ''"
                :name="$t('Compartilhar comissão e percentagem no Sumaprop') | lowerBold"
              >
                <b-form-group
                  :label="$t('Compartilhar comissão e percentagem no Sumaprop')"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <v-select
                    v-if="formModeView===false"
                    v-model="form_data.sw012s517"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="appConfig.aComissionPercentageShare"
                    append-to-body
                    :calculate-position="withPopper"
                    label="desc"
                    item-text="desc"
                    item-value="id"
                  >
                    <template #option="{ desc }">
                      {{ desc }}
                    </template>
                    <div slot="no-options">
                      {{ $t('Sem resultados') }}
                    </div>
                  </v-select>
                  <p
                    v-else
                    class="text-primary mb-0"
                  >
                    {{ renderObjToTxt(form_data.sw012s517, 'desc') }}
                  </p>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>

          <b-row class="mt-2">
            <b-col md="12">
              <h4>
                {{ $t('Contrato de mediação') }}
              </h4>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="6">
              <b-button
                v-if="getButtonContract === true && showMsgReativar === 0 && showButtonContract === true"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                block
                class="mb-1"
                @click="getModalMediacao"
              >
                <feather-icon
                  icon="CalendarIcon"
                  class="mr-50"
                />
                <span class="align-middle">{{ $t('Redefinir contrato') }}</span>
              </b-button>
              <b-button
                v-if="getButtonContract === true && showMsgReativar === 0 && showButtonCancelContract === true"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-danger"
                block
                class="mb-1"
                @click="cancelarContradoMediacao"
              >
                <feather-icon
                  icon="Trash2Icon"
                  class="mr-50"
                />
                <span class="align-middle">{{ $t('Cancelar contrato') }}</span>
              </b-button>
              <b-form-group
                :label="$t('Data de início')"
              >
                <validation-provider
                  #default="{ errors }"
                  :rules="typeof rulesFields.sw012s17 !== 'undefined' && rulesFields.sw012s17.required === true ? 'required' : ''"
                  :name="$t('Data de início') | lowerBold"
                >
                  <b-input-group>
                    <date-picker
                      v-if="formModeView===false"
                      v-model="form_data.sw012s17"
                      value-type="format"
                      input-class="form-control"
                      :class="errors.length > 0 ? 'is-invalid':null"
                      type="date"
                      :editable="false"
                      :clearable="true"
                      :lang="langCalendar"
                      :append-to-body="true"
                      prefix-class="sw"
                      :disabled-date="disabledMinDate"
                      @input="validateDate"
                    >
                      <template #icon-clear>
                        <v-select-deselect />
                      </template>
                    </date-picker>
                    <p
                      v-else
                      class="text-primary mb-0"
                    >
                      {{ form_data.sw012s17 | getTxt }}
                    </p>
                  </b-input-group>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                :rules="typeof rulesFields.sw012s68 !== 'undefined' && rulesFields.sw012s68.required === true ? 'required' : ''"
                :name="$t('Meses') | lowerBold"
              >
                <b-form-group
                  :label="$t('Meses')"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <v-select
                    v-if="formModeView===false"
                    v-model="form_data.sw012s68"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="['ES', 'AR'].includes(countryApp.toUpperCase()) ? load_data_geral.arrayMesesOutro : load_data_geral.arrayMeses"
                    :value="form_data.sw012s68"
                    append-to-body
                    :calculate-position="withPopper"
                    label="desc"
                    item-text="desc"
                    item-value="id"
                    @input="validateDate"
                  >
                    <template #option="{ desc }">
                      {{ desc }}
                    </template>
                    <div slot="no-options">
                      {{ $t('Sem resultados') }}
                    </div>
                  </v-select>
                  <p
                    v-else
                    class="text-primary mb-0"
                  >
                    {{ renderObjToTxt(form_data.sw012s68, 'desc') }}
                  </p>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="6">
              <b-form-group
                :label="$t('Data de fim')"
              >
                <b-input-group>
                  <date-picker
                    v-if="formModeView===false"
                    v-model="form_data.sw012s18"
                    value-type="format"
                    input-class="form-control"
                    type="date"
                    :editable="false"
                    :clearable="true"
                    :lang="langCalendar"
                    :disabled="['ES', 'AR'].includes(countryApp.toUpperCase()) && typeof form_data.sw012s68 !== 'undefined' && form_data.sw012s68 !== null && Number(form_data.sw012s68.id) === 0 ? false : true"
                    :append-to-body="true"
                    prefix-class="sw"
                    :disabled-date="disabledMinDateEnd"
                  >
                    <template #icon-clear>
                      <v-select-deselect />
                    </template>
                  </date-picker>
                  <p
                    v-else
                    class="text-primary mb-0"
                  >
                    {{ form_data.sw012s18 | getTxt }}
                  </p>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row
            v-if="showMsgReativar === 1 && typeof form_data.sw012s18 !== 'undefined' && form_data.sw012s18 !== null"
          >
            <b-col md="6">
              <p class="text-primary mb-0">
                {{ $t('Contrato cancelado, será excluído da rede na data') }} {{ form_data.sw012s18 }}.
              </p>
            </b-col>
          </b-row>

          <b-row
            v-if="showMsgReativar === 2 && typeof form_data.sw012s18 !== 'undefined' && form_data.sw012s18 !== null"
          >
            <b-col md="6">
              <p class="text-primary mb-0">
                {{ $t('Contrato cancelado, foi excluído da rede na data') }} {{ form_data.sw012s18 }}.
              </p>
            </b-col>
          </b-row>

          <div
            v-show="typeof form_data.sw012s06 !== 'undefined' && form_data.sw012s06 !== null && Number(form_data.sw012s06.sw007s01) === 1 && Number(form_data.sw012s24) !== 1"
          >
            <b-row class="mt-2">
              <b-col md="12">
                <h4>
                  {{ $t('Condições para reserva direta') }}
                </h4>
              </b-col>
            </b-row>

            <b-row>
              <b-col md="6">
                <b-form-group
                  :label="$t('Valor mínimo para CPCV')"
                >
                  <b-input-group
                    v-if="formModeView===false"
                    :prepend="symbolMoney"
                    append=""
                    class="input-group-merge"
                  >
                    <b-form-input
                      v-model.lazy="form_data.sw012s110"
                      v-currency="configMoney"
                      placeholder=""
                      autocomplete="off"
                      @blur="validateMinCPCV"
                    />
                  </b-input-group>
                  <p
                    v-else
                    class="text-primary mb-0"
                  >
                    {{ formatPrice(form_data.sw012s110) | getTxt }} {{ symbolMoney }}
                  </p>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col md="6">
                <b-form-group
                  :label="$t('Prazo mínimo para realização da escritura (em dias)')"
                >
                  <b-form-input
                    v-if="formModeView===false"
                    v-model="form_data.sw012s111"
                    placeholder=""
                    autocomplete="off"
                    @blur="validateMinEscritura"
                  />
                  <p
                    v-else
                    class="text-primary mb-0"
                  >
                    {{ form_data.sw012s111 | getTxt }}
                  </p>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col md="6">
                <b-form-group
                  :label="$t('Prazo máximo para realização da escritura (em dias)')"
                >
                  <b-form-input
                    v-if="formModeView===false"
                    v-model="form_data.sw012s112"
                    placeholder=""
                    autocomplete="off"
                    @blur="validateMaxEscritura"
                  />
                  <p
                    v-else
                    class="text-primary mb-0"
                  >
                    {{ form_data.sw012s112 | getTxt }}
                  </p>
                </b-form-group>
              </b-col>
            </b-row>
          </div>

          <b-row>
            <b-col md="6">
              <b-form-group
                :label="$t('Valor de hipoteca')"
              >
                <validation-provider
                  #default="{ errors }"
                  :rules="typeof rulesFields.sw012s21 !== 'undefined' && rulesFields.sw012s21.required === true ? 'required' : ''"
                  :name="$t('Valor de hipoteca') | lowerBold"
                >
                  <b-input-group
                    v-if="formModeView===false"
                    :prepend="symbolMoney"
                    append=""
                    :class="errors.length > 0 ? 'is-invalid input-group-merge': 'input-group-merge'"
                  >
                    <b-form-input
                      v-model.lazy="form_data.sw012s21"
                      v-currency="configMoney"
                      placeholder=""
                      :class="errors.length > 0 ? 'is-invalid':null"
                      autocomplete="off"
                    />
                  </b-input-group>
                  <p
                    v-else
                    class="text-primary mb-0"
                  >
                    {{ formatPrice(form_data.sw012s21) | getTxt }} {{ symbolMoney }}
                  </p>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row
            v-show="typeof form_data.sw012s07 === 'undefined' || form_data.sw012s07 === null || form_data.sw012s07.show_tipo_terreno !== '1'"
          >
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                :rules="typeof rulesFields.sw012s305 !== 'undefined' && rulesFields.sw012s305.required === true ? 'required' : ''"
                :name="$t('Condomínio') | lowerBold"
              >
                <b-form-group
                  :label="$t('Condomínio')"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <v-select
                    v-if="formModeView===false"
                    v-model="form_data.sw012s305"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="load_data_geral.arrayCondominioYesNo"
                    :value="form_data.sw012s305"
                    append-to-body
                    :calculate-position="withPopper"
                    label="desc"
                    item-text="desc"
                    item-value="id"
                    @input="validateCondominio"
                  >
                    <template #option="{ desc }">
                      {{ desc }}
                    </template>
                    <div slot="no-options">
                      {{ $t('Sem resultados') }}
                    </div>
                  </v-select>
                  <p
                    v-else
                    class="text-primary mb-0"
                  >
                    {{ renderObjToTxt(form_data.sw012s305, 'desc') }}
                  </p>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>

          <b-row
            v-show="typeof form_data.sw012s305 !== 'undefined' && form_data.sw012s305 !== null && form_data.sw012s305.id === '1'"
          >
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                :rules="typeof rulesFields.sw012s314 !== 'undefined' && rulesFields.sw012s314.required === true ? 'required' : ''"
                :name="$t('Periodo') | lowerBold"
              >
                <b-form-group
                  :label="$t('Periodo')"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <v-select
                    v-if="formModeView===false"
                    v-model="form_data.sw012s314"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="load_data_geral.arrayCondominio"
                    :value="form_data.sw012s314"
                    append-to-body
                    :calculate-position="withPopper"
                    label="desc"
                    item-text="desc"
                    item-value="id"
                  >
                    <template #option="{ desc }">
                      {{ desc }}
                    </template>
                    <div slot="no-options">
                      {{ $t('Sem resultados') }}
                    </div>
                  </v-select>
                  <p
                    v-else
                    class="text-primary mb-0"
                  >
                    {{ renderObjToTxt(form_data.sw012s314, 'desc') }}
                  </p>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>

          <b-row
            v-show="typeof form_data.sw012s305 !== 'undefined' && form_data.sw012s305 !== null && form_data.sw012s305.id === '1'"
          >
            <b-col md="6">
              <b-form-group
                :label="$t('Valor')"
              >
                <validation-provider
                  #default="{ errors }"
                  :rules="typeof rulesFields.sw012s25 !== 'undefined' && rulesFields.sw012s25.required === true ? 'required' : ''"
                  :name="$t('Valor') | lowerBold"
                >
                  <b-input-group
                    v-if="formModeView===false"
                    :prepend="symbolMoney"
                    append=""
                    :class="errors.length > 0 ? 'is-invalid input-group-merge': 'input-group-merge'"
                  >
                    <b-form-input
                      v-model.lazy="form_data.sw012s25"
                      v-currency="configMoney"
                      placeholder=""
                      autocomplete="off"
                      :class="errors.length > 0 ? 'is-invalid':null"
                    />
                  </b-input-group>
                  <p
                    v-else
                    class="text-primary mb-0"
                  >
                    {{ formatPrice(form_data.sw012s25) | getTxt }} {{ symbolMoney }}
                  </p>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="6">
              <b-form-group
                :label="$t('Aceita permuta')"
              >
                <v-select
                  v-if="formModeView===false"
                  v-model="form_data.sw012s26"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="load_data_geral.arrayPermuta"
                  :value="form_data.sw012s26"
                  append-to-body
                  :calculate-position="withPopper"
                  label="desc"
                  item-text="desc"
                  item-value="id"
                >
                  <template #option="{ desc }">
                    {{ desc }}
                  </template>
                  <div slot="no-options">
                    {{ $t('Sem resultados') }}
                  </div>
                </v-select>
                <p
                  v-else
                  class="text-primary mb-0"
                >
                  {{ renderObjToTxt(form_data.sw012s26, 'desc') }}
                </p>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row
            v-show="countryApp.toUpperCase() === 'AR' && typeof form_data.sw012s07 !== 'undefined' && form_data.sw012s07 !== null && ![198,197,227,208,228,225,216,220,206,224].includes(Number(form_data.sw012s07.id))"
          >
            <b-col md="6">
              <b-form-group
                :label="$t('Elegível para financiamento')"
              >
                <v-select
                  v-if="formModeView===false"
                  v-model="form_data.sw012s483"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="load_data_geral.arrayYesNo"
                  :value="form_data.sw012s483"
                  append-to-body
                  :calculate-position="withPopper"
                  :disabled="typeof form_data.sw012s07 !== 'undefined' && form_data.sw012s07 !== null && ![198,197,227,208,228,225,216,220,206,224].includes(Number(form_data.sw012s07.id)) ? true : false"
                  label="desc"
                  item-text="desc"
                  item-value="id"
                >
                  <template #option="{ desc }">
                    {{ desc }}
                  </template>
                  <div slot="no-options">
                    {{ $t('Sem resultados') }}
                  </div>
                </v-select>
                <p
                  v-else
                  class="text-primary mb-0"
                >
                  {{ renderObjToTxt(form_data.sw012s483, 'desc') }}
                </p>
              </b-form-group>
            </b-col>
          </b-row>

          <div v-show="['ES', 'AR'].includes(countryApp.toUpperCase())">
            <b-row>
              <b-col md="6">
                <b-form-group
                  :label="$t('Imposto municipal')"
                >
                  <validation-provider
                    #default="{ errors }"
                    :rules="typeof rulesFields.sw012s337 !== 'undefined' && rulesFields.sw012s337.required === true ? 'required' : ''"
                    :name="$t('Imposto municipal') | lowerBold"
                  >
                    <b-input-group
                      v-if="formModeView===false"
                      :prepend="symbolMoney"
                      append=""
                      :class="errors.length > 0 ? 'is-invalid input-group-merge': 'input-group-merge'"
                    >
                      <b-form-input
                        v-model.lazy="form_data.sw012s337"
                        v-currency="configMoney"
                        placeholder=""
                        autocomplete="off"
                        :class="errors.length > 0 ? 'is-invalid':null"
                      />
                    </b-input-group>
                    <p
                      v-else
                      class="text-primary mb-0"
                    >
                      {{ formatPrice(form_data.sw012s337) | getTxt }} {{ symbolMoney }}
                    </p>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col md="6">
                <b-form-group
                  :label="$t('Valor da coleta de lixo')"
                >
                  <validation-provider
                    #default="{ errors }"
                    :rules="typeof rulesFields.sw012s338 !== 'undefined' && rulesFields.sw012s338.required === true ? 'required' : ''"
                    :name="$t('Valor da coleta de lixo') | lowerBold"
                  >
                    <b-input-group
                      v-if="formModeView===false"
                      :prepend="symbolMoney"
                      append=""
                      :class="errors.length > 0 ? 'is-invalid input-group-merge': 'input-group-merge'"
                    >
                      <b-form-input
                        v-model.lazy="form_data.sw012s338"
                        v-currency="configMoney"
                        placeholder=""
                        autocomplete="off"
                        :class="errors.length > 0 ? 'is-invalid':null"
                      />
                    </b-input-group>
                    <p
                      v-else
                      class="text-primary mb-0"
                    >
                      {{ formatPrice(form_data.sw012s338) | getTxt }} {{ symbolMoney }}
                    </p>
                  </validation-provider>
                </b-form-group>

              </b-col>
            </b-row>
          </div>

        </b-container>
      </vue-perfect-scrollbar>

    </div>

  </div>
</template>

<script>
import {
  BButton,
  BCol,
  BContainer,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormTags,
  BFormText,
  BInputGroup,
  BLink,
  BRow,
  BAlert,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapGetters } from 'vuex'
import VuePerfectScrollbar from '@/views/modules/components/vue-perfect-scrollbar.vue'
import { sizeIconsAction } from '@core-custom/mixins/geral'
import { ValidationProvider } from 'vee-validate'
import vSelect from 'vue-select'
import Cleave from 'vue-cleave-component'
import { CurrencyDirective } from 'vue-currency-input'
import { configMoney, symbolMoney } from '@core-custom/utils/money'
import { mask } from 'vue-the-mask'
import { configSettingsScroll, formatPrice } from '@core-custom/utils/ui'
import { mixFormGeral } from '../mixins'
import FooterStepNav from './footerStepNavTop.vue'

const oDatepicker = () => Promise.all([
  import(/* webpackChunkName: "chunk-vue2-datepicker" */ 'vue2-datepicker'),
  import(/* webpackChunkName: "chunk-vue2-datepicker" */ '@/assets/scss/datepicker.scss'),
]).then(([DatePicker]) => DatePicker)

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BContainer,
    BFormCheckbox,
    BInputGroup,
    BFormInput,
    BLink,
    BFormTags,
    BFormText,
    BButton,
    BAlert,
    VuePerfectScrollbar,
    FooterStepNav,
    ValidationProvider,
    vSelect,
    vSelectDeselect: vSelect.components.Deselect,
    Cleave,
    DatePicker: oDatepicker,
  },
  directives: {
    Ripple,
    mask,
    currency: CurrencyDirective,
  },
  mixins: [sizeIconsAction, mixFormGeral],
  props: {
    getModalMediacao: {
      type: Function,
      required: true,
    },
    cancelarContradoMediacao: {
      type: Function,
      required: true,
    },
    openPrices: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      maskDateYear: {
        date: true,
        dateMin: '1000',
        dateMax: '2099',
        datePattern: ['Y'],
      },
      langCalendar: {},
      configMoney,
      symbolMoney,
      options: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
          numeralDecimalScale: 2,
        },
      },
      tokenMaskNIF: {
        mask: '#########',
        tokens: {
          '#': {
            pattern: /\d/,
          },
        },
      },
      showButtonContract: false,
      showButtonCancelContract: false,
      showButtonActionCMI: false,
      showMsgReativar: 0,
      formatPrice,
    }
  },
  computed:
      {
        ...mapGetters('listings_form', ['form_data', 'load_data_geral', 'rulesFields', 'rulesAttrFields', 'formModeView', 'appConfig', 'filterFields', 'filterAttributes', 'idBankAssoc', 'idLeadAssoc', 'empreAssoc', 'keyReg', 'disableRegimeContratacao', 'vars', 'invalidFields', 'idVersionReg']),
        ...mapGetters('auth', ['countryApp', 'currentUser']),
        getButtonContract: {
          get() {
            return this.getShowButtonContract
          },
        },
        showBoxVenture() {
          if (
            (this.form_data?.sw012s07?.id !== undefined)
              && (this.load_data_geral?.idsTypeListingVenture !== undefined)
              && (this.load_data_geral.idsTypeListingVenture.length > 0)
          ) {
            if (this.load_data_geral.idsTypeListingVenture.includes(Number(this.form_data.sw012s07.id))) {
              return true
            }
          }

          return false
        },
        showBoxChooseVenture() {
          if (
            (this.form_data?.sw012s542?.id !== undefined)
              && (Number(this.form_data.sw012s542.id) === 1)
          ) {
            return true
          }

          return false
        },
        showBoxVentureNote() {
          if (
            (this.form_data?.sw012s542?.id !== undefined)
              && (Number(this.form_data.sw012s542.id) === 1)
          ) {
            if ((this.form_data?.sw012s543?.id !== undefined) && (this.form_data.sw012s543.id !== '')) {
              if (this.isApproveListing === false) {
                if (
                  (this.vars.sw012?.sw012s543 !== undefined)
                    && (Number(this.vars.sw012.sw012s543) === this.form_data.sw012s543.id)
                ) {
                  return false
                }

                return true
              }

              return false
            }
          }

          return false
        },
        isApproveListing() {
          if (
            (this.vars.sw012?.sw012s01 !== undefined)
              && (this.vars.sw012.sw012s01 !== '')
              && (this.load_data_geral?.checkStatusApproveVersionReg !== undefined)
              && (this.load_data_geral.checkStatusApproveVersionReg > 0)
          ) {
            return true
          }

          return false
        },
      },
  async mounted() {
    await this.importLocale(this.$i18n.locale).then(res => {
      this.langCalendar = res.default
      // Forçei estes valores para a semana começar sempre no domingo
      this.langCalendar.formatLocale.firstDayOfWeek = 0
      this.langCalendar.formatLocale.firstWeekContainsDate = 4
    })
  },
  methods: {
    async getTipoImoveisByCategoria(tipo) {
      this.$store.dispatch('listings_form/updateFieldValue', { field: 'sw012s07', value: null })
      this.$store.dispatch('listings_form/updateFieldValue', { field: 'sw012s08', value: null })
      this.$store.dispatch('listings_form/updateFieldValue', { field: 'sw012s10', value: null })

      if (tipo !== null) {
        await this.$store.dispatch('listings_form/getTipoImoveisByCategoria', tipo.sw087s03)
      } else {
        this.load_data_geral.typeListings.value = []
      }
    },
    async getSubTipoImoveis(idImovel) {
      this.$store.dispatch('listings_form/updateFieldValue', { field: 'sw012s08', value: null })
      this.$store.dispatch('listings_form/updateFieldValue', { field: 'sw012s08_plus', value: null })
      this.$store.dispatch('listings_form/updateFieldValue', { field: 'sw012s10', value: null })
      this.$store.dispatch('listings_form/updateFieldValue', { field: 'sw012s13', value: null })
      this.$store.dispatch('listings_form/updateFieldValue', { field: 'sw012s19', value: null })
      this.$store.dispatch('listings_form/updateFieldValue', { field: 'sw012s20', value: null })
      this.$store.dispatch('listings_form/updateFieldValue', { field: 'sw012s37', value: null })
      this.$store.dispatch('listings_form/updateFieldValue', { field: 'sw012s38', value: null })
      this.$store.dispatch('listings_form/updateFieldValue', { field: 'sw012s129', value: null })
      this.$store.dispatch('listings_form/updateFieldValue', { field: 'sw012s363', value: null })
      this.$store.dispatch('listings_form/updateFieldValue', { field: 'sw019s03', value: null })

      // jQuery('.boxAtributosEssenciais select').each(function(k, ob)
      // {
      //     jQuery(ob).val("").selectpicker("refresh");
      //     jQuery(ob).closest('.form-group').addClass('hideArea');
      //     jQuery(ob).closest('.form-group').find('.txtNote').addClass('hide');
      //     jQuery(ob).closest('.form-group').find('input[name^=sw012]').tagsinput('removeAll');
      // });

      if (idImovel !== null) {
        if (Number(idImovel.showce) === 1) {
          let estadoAngSel = ''
          let estadoAngDraftSel = ''

          if (typeof this.form_data.sw012s64 !== 'undefined' && this.form_data.sw012s64 !== null) {
            estadoAngSel = Number(this.form_data.sw012s64.sw018s01)
          }

          if (typeof this.form_data.sw012s70 !== 'undefined' && this.form_data.sw012s70 !== null) {
            estadoAngDraftSel = this.form_data.sw012s70
          }

          if ((estadoAngSel === 9) || (estadoAngSel === 5 && estadoAngDraftSel === 1)) {
            // Não faz nada
          } else {
            this.$store.dispatch('listings_form/updateRuleFieldValue', { field: 'sw012s19', value: true })
            this.$store.dispatch('listings_form/updateRuleFieldValue', { field: 'sw012s319', value: true })
          }
        } else {
          this.$store.dispatch('listings_form/updateRuleFieldValue', { field: 'sw012s19', value: false })
          this.$store.dispatch('listings_form/updateRuleFieldValue', { field: 'sw012s319', value: false })
        }

        if (Number(idImovel.fillatribbasic) === 1) {
          this.$store.dispatch('listings_form/updateFieldValue', {
            field: 'sw012s13',
            value: this.load_data_geral.array70.find(o => Number(o.id) === 1),
          })
          this.$store.dispatch('listings_form/updateFieldValue', {
            field: 'sw012s137',
            value: this.load_data_geral.array70.find(o => Number(o.id) === 1),
          })
          this.$store.dispatch('listings_form/updateFieldValue', {
            field: 'sw012s133',
            value: this.load_data_geral.array70.find(o => Number(o.id) === 1),
          })
        } else {
          this.$store.dispatch('listings_form/updateFieldValue', { field: 'sw012s13', value: null })
          this.$store.dispatch('listings_form/updateFieldValue', { field: 'sw012s137', value: null })
          this.$store.dispatch('listings_form/updateFieldValue', { field: 'sw012s133', value: null })
        }

        await this.$store.dispatch('listings_form/getSubTipoImoveis', idImovel.id)

        if (idImovel.id === '1') {
          Array.from(this.load_data_geral.finality).forEach(item => {
            if (item.sw009s01 === '2') {
              this.form_data.sw012s10 = item
            }
          })
        }
      } else {
        this.$store.dispatch('listings_form/updateRuleFieldValue', { field: 'sw012s19', value: false })
        this.$store.dispatch('listings_form/updateRuleFieldValue', { field: 'sw012s319', value: false })
        this.load_data_geral.subTypeListings.value = []
      }

      this.$store.dispatch('listings_form/setAtributosByTipoImovel', idImovel)
      this.validateRent(0)
      this.$store.dispatch('listings_form/validateLicenseNumber')
    },
    getTipologiasExtras(tipologia) {
      this.$store.dispatch('listings_form/updateFieldValue', { field: 'sw012s129', value: null })
      this.$store.dispatch('listings_form/updateFieldValue', { field: 'sw012s143', value: null })

      if (tipologia !== null) {
        const numQ = Number(tipologia.sw005s06)
        this.$store.dispatch('listings_form/updateFieldValue', { field: 'sw012s38', value: numQ })
        this.$store.dispatch('listings_form/getTipologiasExtras', tipologia.sw005s01)
        this.$store.commit('listings_form/setArraySuites', numQ)
      } else {
        this.load_data_geral.typologiesExtra.value = []
        this.$store.dispatch('listings_form/updateFieldValue', { field: 'sw012s38', value: null })
        this.$store.dispatch('listings_form/updateFieldValue', { field: 'sw012s08_plus', value: null })
        this.$store.commit('listings_form/setArraySuites', 70)
      }
    },
    setNumberRooms(tipologiaExtra) {
      if (this.countryApp.toUpperCase() === 'PT') {
        if (tipologiaExtra !== null) {
          const numQ = Number(tipologiaExtra.sw005s08) + Number(this.form_data.sw012s38)
          this.$store.dispatch('listings_form/updateFieldValue', { field: 'sw012s38', value: numQ })
          this.$store.commit('listings_form/setArraySuites', numQ)
        } else if (this.form_data.sw012s38 !== null) {
          const numQ = Number(this.form_data.sw012s08.sw005s06)
          this.$store.dispatch('listings_form/updateFieldValue', { field: 'sw012s38', value: numQ })
          this.$store.dispatch('listings_form/updateFieldValue', { field: 'sw012s08_plus', value: null })
          this.$store.commit('listings_form/setArraySuites', numQ)
        } else {
          this.$store.dispatch('listings_form/updateFieldValue', { field: 'sw012s38', value: null })
          this.$store.dispatch('listings_form/updateFieldValue', { field: 'sw012s08_plus', value: null })
          this.$store.commit('listings_form/setArraySuites', 70)
        }
      } else if (this.countryApp.toUpperCase() === 'ES') {
        if (tipologiaExtra !== null) {
          const numQ = Number(tipologiaExtra.desc)
          this.$store.dispatch('listings_form/updateFieldValue', { field: 'sw012s38', value: numQ })
          this.$store.commit('listings_form/setArraySuites', numQ)
        } else {
          this.$store.dispatch('listings_form/updateFieldValue', { field: 'sw012s38', value: null })
          this.$store.commit('listings_form/setArraySuites', 70)
        }
      }
    },
    validateSW012s06() {
      let vlTipoNegocio = ''
      let showTipoValor = ''
      let estadoAngSel = ''
      let estadoAngDraftSel = ''

      if (typeof this.form_data.sw012s06 !== 'undefined' && this.form_data.sw012s06 !== null) {
        vlTipoNegocio = Number(this.form_data.sw012s06.sw007s01)
        showTipoValor = Number(this.form_data.sw012s06.sw007s06)
      }

      if (typeof this.form_data.sw012s64 !== 'undefined' && this.form_data.sw012s64 !== null) {
        estadoAngSel = Number(this.form_data.sw012s64.sw018s01)
      }

      if (typeof this.form_data.sw012s70 !== 'undefined' && this.form_data.sw012s70 !== null) {
        estadoAngDraftSel = this.form_data.sw012s70
      }

      this.$store.dispatch('listings_form/updateRuleFieldValue', { field: 'sw012s14', value: false })
      this.$store.dispatch('listings_form/updateRuleFieldValue', { field: 'sw012s69', value: false })

      this.$store.dispatch('listings_form/updateFieldValue', { field: 'sw012s14', value: null })
      this.$store.dispatch('listings_form/updateFieldValue', { field: 'sw012s69', value: null })

      if (estadoAngSel === 9 || (estadoAngSel === 5 && estadoAngDraftSel === 1)) {
        // Não faz nada
      } else if (vlTipoNegocio !== 0) {
        this.$store.dispatch('listings_form/updateRuleFieldValue', { field: 'sw012s14', value: true })
      }

      if (showTipoValor === 1) {
        this.$store.dispatch('listings_form/updateRuleFieldValue', { field: 'sw012s14', value: false })

        if (estadoAngSel === 9 || (estadoAngSel === 5 && estadoAngDraftSel === 1)) {
          // Não faz nada
        } else if (vlTipoNegocio !== 0) {
          this.$store.dispatch('listings_form/updateRuleFieldValue', { field: 'sw012s69', value: true })
        }
      }

      this.validateRent(0)
      this.$store.dispatch('listings_form/validateLicenseNumber')
      this.validateRegimeContratacao()
    },
    validateSW019s03(vlEspecial) {
      if (vlEspecial !== null) {
        let count = 0
        const aPos = []

        Array.from(vlEspecial).forEach((item, index) => {
          if (item.sw004s02.toLowerCase() === 'duplex' || item.sw004s02.toLowerCase() === 'triplex') {
            count += 1
            aPos.push(index)
          }

          if (count === 2) {
            this.showMsgErrorRequest(new Error(this.$t('Não pode ter a opção Duplex e Triplex selecionadas. Escolha uma opção.')))

            let nSplice = 0
            aPos.forEach(pos => {
              const key = pos - nSplice

              vlEspecial.splice(key, 1)
              nSplice += 1
            })
          }
        })
      }
    },
    validateCE(vlCE) {
      let vCESel = 0

      if (vlCE !== null) {
        vCESel = Number(vlCE.sw006s01)
      }

      let vEstadoAng = 0
      if (typeof this.form_data.sw012s64 !== 'undefined' && this.form_data.sw012s64 !== null) {
        vEstadoAng = Number(this.form_data.sw012s64.sw018s01)
      }

      let estadoAngDraftSel = 0
      if (typeof this.form_data.sw012s70 !== 'undefined' && this.form_data.sw012s70 !== null) {
        estadoAngDraftSel = Number(this.form_data.sw012s70)
      }

      if (vCESel === 0) {
        if (vEstadoAng === 2 || vEstadoAng === 3) {
          this.showMsgErrorRequest(new Error(this.$t('Tem de indicar um certificado energético, quando o estado da angariação está definida como vendida ou reservada.')))

          this.$store.dispatch('listings_form/updateFieldValue', { field: 'sw012s19', value: null })
        }
      } else {
        this.$store.commit('listings_form/setValueCE', vlCE)
      }

      if (vEstadoAng === 9 || (vEstadoAng === 5 && estadoAngDraftSel === 1)) {
        this.$store.dispatch('listings_form/updateRuleFieldValue', { field: 'sw012s319', value: false })
        if (vCESel === 9 || vCESel === 12 || vCESel === 10 || vCESel === 0) {
          this.$store.dispatch('listings_form/updateFieldValue', { field: 'sw012s319', value: null })
          this.$store.dispatch('listings_form/updateRuleFieldValue', { field: 'sw012s319', value: false })
        }
      } else if (vCESel === 9 || vCESel === 12 || vCESel === 10 || vCESel === 0) {
        this.$store.dispatch('listings_form/updateFieldValue', { field: 'sw012s319', value: null })
        this.$store.dispatch('listings_form/updateRuleFieldValue', { field: 'sw012s319', value: false })
      } else {
        this.$store.dispatch('listings_form/updateRuleFieldValue', { field: 'sw012s319', value: true })
      }
    },
    async validatePercCMI() {
      const vlPercjCMI = this.form_data.sw012s15 || 0

      await this.$store.dispatch('listings_form/validatePercCMI', { vlPerCMI: vlPercjCMI }).catch(async error => {
        this.showMsgErrorRequest(error)
      })
    },
    async validateCMI(objCMI) {
      if (this.countryApp.toUpperCase() === 'PT') {
        await this.$store.dispatch('listings_form/validateCMI', { vlCMI: objCMI.target.value }).catch(async error => {
          this.showMsgErrorRequest(error)
        })
      }

      this.validateRegimeContratacao()
    },
    async validateRegimeContratacao() {
      await this.$store.dispatch('listings_form/validateRegimeContratacao')
    },
    async validateMinCPCV(objMinCMI) {
      await this.$store.dispatch('listings_form/validateMinCPCV', { vlMinCMI: objMinCMI.target.value }).catch(async error => {
        this.showMsgErrorRequest(error)
      })
    },
    validateMinEscritura(objMinDays) {
      let minDays = objMinDays.target.value
      let maxDays = this.form_data.sw012s112

      if (!maxDays) {
        maxDays = 0
      }

      if (!minDays) {
        minDays = 0
      }

      if (Number(minDays) >= Number(maxDays) && Number(maxDays) !== 0 && Number(minDays) !== 0) {
        this.showMsgErrorRequest(new Error(this.$t('O mínimo de dias para realização da escritura não pode ser superior ou igual ao prazo máximo definido')))
        this.$store.dispatch('listings_form/updateFieldValue', { field: 'sw012s111', value: null })
      }
    },
    validateMaxEscritura(objMaxDays) {
      let minDays = this.form_data.sw012s111
      let maxDays = objMaxDays.target.value

      if (!minDays) {
        minDays = 0
      }

      if (!maxDays) {
        maxDays = 0
      }

      if (Number(minDays) >= Number(maxDays) && Number(maxDays) !== 0 && Number(minDays) !== 0) {
        this.showMsgErrorRequest(new Error(this.$t('O máximo de dias para realização da escritura não pode ser inferior ou igual ao prazo mínimo definido')))
        this.$store.dispatch('listings_form/updateFieldValue', { field: 'sw012s112', value: null })
      }
    },
    validateRent(disableClearInputs) {
      this.$store.dispatch('listings_form/validateRent', { clearInputs: disableClearInputs })
    },
    validateActualyRent() {
      this.$store.dispatch('listings_form/validateActualyRent')
    },
    calculateRentabilidadeBruta() {
      this.$store.dispatch('listings_form/calculateRentabilidadeBruta')
    },
    validateSW230(disableClearInputs) {
      this.$store.dispatch('listings_form/validateSW230', { clearInputs: disableClearInputs })
    },
    async validatePAA_webservice() {
      let vlSW012s231 = ''
      let vlSW012s241 = ''
      let vlSW012s467 = ''

      if (typeof this.form_data.sw012s231 !== 'undefined' && this.form_data.sw012s231 !== null) {
        vlSW012s231 = this.form_data.sw012s231
      }

      if (typeof this.form_data.sw012s241 !== 'undefined' && this.form_data.sw012s241 !== null) {
        vlSW012s241 = this.form_data.sw012s241
      }

      if (typeof this.form_data.sw012s467 !== 'undefined' && this.form_data.sw012s467 !== null) {
        vlSW012s467 = this.form_data.sw012s467
      }

      // if(vlSW012s231 && vlSW012s241 && (jQuery('#formInsert').validate().element('#sw012s231') == true))
      if (vlSW012s231 && vlSW012s241) {
        this.$store.dispatch('listings_form/updateFieldValue', { field: 'sw012s242', value: null })
        await this.$store.dispatch('listings_form/getValueMaxIncomePAA', {
          sw012s231: vlSW012s231,
          sw012s241: vlSW012s241,
          sw012s467: vlSW012s467,
        }).catch(async error => {
          this.showMsgErrorRequest(error)
        })
      }
    },
    async validatePAA() {
      await this.$store.dispatch('listings_form/validatePAA').catch(async error => {
        this.showMsgErrorRequest(error)
      })
    },
    validateCondominio(vlSW012s305) {
      if (vlSW012s305 !== null && vlSW012s305.id === 1) {
        this.$store.dispatch('listings_form/updateRuleFieldValue', { field: 'sw012s25', value: true })
        this.$store.dispatch('listings_form/updateRuleFieldValue', { field: 'sw012s314', value: true })
      } else {
        this.$store.dispatch('listings_form/updateFieldValue', { field: 'sw012s25', value: null })
        this.$store.dispatch('listings_form/updateFieldValue', { field: 'sw012s314', value: null })

        this.$store.dispatch('listings_form/updateRuleFieldValue', { field: 'sw012s25', value: false })
        this.$store.dispatch('listings_form/updateRuleFieldValue', { field: 'sw012s314', value: false })
      }
    },
    async validateDate() {
      let vlData = ''
      if (typeof this.form_data.sw012s17 !== 'undefined' && this.form_data.sw012s17 !== null) {
        vlData = this.form_data.sw012s17

        if (['ES', 'AR'].includes(this.countryApp.toUpperCase()) && typeof this.form_data.sw012s18 !== 'undefined' && this.form_data.sw012s18 !== null) {
          const dtaStart = new Date(this.form_data.sw012s17)
          const dtaEnd = new Date(this.form_data.sw012s18)

          if (dtaStart < dtaEnd) {
            await this.$store.dispatch('listings_form/updateFieldValue', { field: 'sw012s18', value: '' })
          }
        }
      } else {
        await this.$store.dispatch('listings_form/updateFieldValue', { field: 'sw012s18', value: '' })
      }

      let vlMeses = this.form_data.sw012s68
      if (typeof this.form_data.sw012s68 !== 'undefined' && this.form_data.sw012s68 !== null && typeof this.form_data.sw012s68.id !== 'undefined') {
        vlMeses = this.form_data.sw012s68.id
      } else {
        await this.$store.dispatch('listings_form/updateFieldValue', {
          field: 'sw012s68',
          value: this.load_data_geral.arrayMeses.find(o => Number(o.id) === 6),
        })
        vlMeses = 6
      }

      this.$store.dispatch('listings_form/updateFieldValue', { field: 'sw012s18', value: null })
      await this.$store.dispatch('listings_form/addMonthDate', {
        dataHome: vlData,
        month: vlMeses,
        fReturn: 0,
      }).catch(async error => {
        this.showMsgErrorRequest(error)
      })
    },
    getShowButtonContract() {
      this.showButtonActionCMI = false
      if (typeof this.form_data.sw012s01 !== 'undefined' && this.form_data.sw012s01 !== null && this.form_data.sw012s01 !== '') {
        if ([5, 9, 10, 13].includes(Number(this.currentUser.id_role)) && Number(this.load_data_geral.showVersionReg) === 1
            && (typeof this.form_data.sw012s261 === 'undefined' || this.form_data.sw012s261 === null || this.form_data.sw012s261 === '')
            && (typeof this.form_data.sw012s72 !== 'undefined' && this.form_data.sw012s72 !== null && this.form_data.sw012s72 !== '' && this.form_data.sw012s72 === this.currentUser.idHUB)) {
          if (typeof this.form_data.sw012s64 !== 'undefined' && this.form_data.sw012s64 !== null && [1, 5, 4, 8, 9].includes(Number(this.form_data.sw012s64.sw018s01))) {
            if ((typeof this.form_data.sw012s329 !== 'undefined' && this.form_data.sw012s329 !== null && Number(this.form_data.sw012s329) === 1)
                || (typeof this.form_data.sw012s325 !== 'undefined' && this.form_data.sw012s325 !== null && Number(this.form_data.sw012s325) === 1)) {
              if (typeof this.form_data.sw012s329 !== 'undefined' && this.form_data.sw012s329 !== null && Number(this.form_data.sw012s329) === 1) {
                this.showMsgReativar = 1
              } else if (typeof this.form_data.sw012s325 !== 'undefined' && this.form_data.sw012s325 !== null && Number(this.form_data.sw012s325) === 1) {
                this.showMsgReativar = 2
              }

              if (this.formModeView === false) {
                this.showButtonActionCMI = true
                this.showButtonContract = true
              }
            } else {
              if (this.formModeView === false) {
                this.showButtonContract = true
              }

              if ((typeof this.form_data.sw012s301 !== 'undefined' && this.form_data.sw012s301 !== null && Number(this.form_data.sw012s301) === 1)
                  || (typeof this.form_data.sw012s17 !== 'undefined' && this.form_data.sw012s17 !== null)) {
                this.showButtonCancelContract = true
              }

              this.showButtonActionCMI = true
            }
          }
        }

        if ([4, 7].includes(Number(this.currentUser.id_role)) && Number(this.load_data_geral.showVersionReg) !== 1
            && (typeof this.form_data.sw012s261 === 'undefined' || this.form_data.sw012s261 === null || this.form_data.sw012s261 === '')
            && (typeof this.form_data.sw012s72 !== 'undefined' && this.form_data.sw012s72 !== null && this.form_data.sw012s72 !== '' && this.form_data.sw012s72 === this.currentUser.idHUB)
            && ((typeof this.form_data.sw012s60 !== 'undefined' && this.form_data.sw012s60 !== null && this.form_data.sw012s60 === atob(this.currentUser.id))
                || (typeof this.form_data.sw012s04 !== 'undefined' && this.form_data.sw012s04 !== null && this.form_data.sw012s04 === atob(this.currentUser.id)))) {
          if ((typeof this.form_data.sw012s329 !== 'undefined' && this.form_data.sw012s329 !== null && Number(this.form_data.sw012s329) === 1)
              || (typeof this.form_data.sw012s325 !== 'undefined' && this.form_data.sw012s325 !== null && Number(this.form_data.sw012s325) === 1)) {
            if (typeof this.form_data.sw012s329 !== 'undefined' && this.form_data.sw012s329 !== null && Number(this.form_data.sw012s329) === 1) {
              this.showMsgReativar = 1
            } else if (typeof this.form_data.sw012s325 !== 'undefined' && this.form_data.sw012s325 !== null && Number(this.form_data.sw012s325) === 1) {
              this.showMsgReativar = 2
            }
          }
        }

        if (this.formModeView === false && this.showButtonActionCMI === false && typeof this.form_data.sw012s17 !== 'undefined' && this.form_data.sw012s17 !== null) {
          if ((typeof this.form_data.sw012s60 !== 'undefined' && this.form_data.sw012s60 !== null && this.form_data.sw012s60 === atob(this.currentUser.id))
              || (typeof this.form_data.sw012s04 !== 'undefined' && this.form_data.sw012s04 !== null && this.form_data.sw012s04 === atob(this.currentUser.id))) {
            if (typeof this.form_data.sw012s64 !== 'undefined' && this.form_data.sw012s64 !== null && Number(this.form_data.sw012s64.sw018s01) === 5
                && (typeof this.form_data.sw012s70 !== 'undefined' && this.form_data.sw012s70 !== null && Number(this.form_data.sw012s70) === 1)) {
              this.showButtonContract = false
            } else {
              this.showButtonContract = true
              this.showButtonActionCMI = true
            }
          }
        }
      }

      return true
    },
    async importLocale(lang) {
      return import(`vue2-datepicker/locale/${lang}`)
    },
    disabledMinDate(date) {
      if (this.load_data_geral.limitContratoMediacao === true) {
        const now = new Date()
        const maxDate = new Date(now.getFullYear(), now.getMonth(), now.getDate())
        maxDate.setDate(maxDate.getDate() - 90)
        return date < maxDate
      }

      return false
    },
    disabledMinDateEnd(date) {
      let minDate = new Date()
      if (typeof this.form_data.sw012s17 !== 'undefined' && this.form_data.sw012s17 !== null) {
        minDate = new Date(this.form_data.sw012s17)
      }

      return date < minDate
    },
    truncateTxt(text, num) {
      const dots = text.length > num ? '...' : ''
      return `${text.substring(0, num)}${dots}`
    },
    async validateVentureExclusive() {
      await this.$store.dispatch('listings_form/validateVentureExclusive').catch(async error => {
        this.showMsgErrorRequest(error)
      })
    },
    async checkListingsAssocVenture() {
      this.$root.$emit('app::loading', true)
      await this.$store.dispatch('listings_form/checkListingsAssocVenture').then(async error => {
        if (error === true) {
          await this.$store.dispatch('listings_form/updateFieldValue', { field: 'sw012s543', value: null })
        }

        await this.validateVentureExclusive()
        this.$root.$emit('app::loading', false)
      }).catch(() => {
        this.$root.$emit('app::loading', false)
      })
    },
  },
  setup() {
    return {
      configSettingsScroll,
    }
  },
}
</script>
