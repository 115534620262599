<template>
  <div>
    <b-sidebar
      sidebar-class="sidebar-lg"
      visible
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @change="closeModalForm"
    >
      <template #default="{ hide }">

        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5
            class="mb-0"
          >
            {{ $t('Novo vídeo') }}
          </h5>
          <div>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              :size="sizeIconsAction"
              @click="hide"
            />
          </div>
        </div>

        <vue-perfect-scrollbar
          ref="scroll"
          class="b-sidebar-scrollarea-form"
          :settings="configSettingsScroll"
        >
          <b-container
            class="pt-2 pb-0 pl-2 pr-2"
            fluid
          >
            <b-form
              ref="formSave"
              @submit.prevent
            >
              <validation-observer
                #default="{ }"
                ref="formSaveValidatorSW079"
              >
                <b-row>
                  <b-col md="12">
                    <validation-provider
                      #default="{ errors }"
                      rules="required"
                    >
                      <b-form-group
                        :label="$t('Plataforma')"
                        :class="errors.length > 0 ? 'is-invalid':null"
                      >
                        <v-select
                          v-model="plataforma"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="platformsVideo"
                          :calculate-position="withPopper"
                          placeholder=""
                          label="sw089s02"
                          item-text="sw089s02"
                          item-value="sw089s01"
                        >
                          <template #option="{ sw089s02 }">
                            {{ sw089s02 }}
                          </template>
                          <div slot="no-options">
                            {{ $t('Sem resultados') }}
                          </div>
                        </v-select>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="12">
                    <validation-provider
                      #default="{ errors }"
                      rules="required"
                    >
                      <b-form-group :label="$t('Designação')">
                        <b-form-input
                          v-model="descricao"
                          placeholder=""
                          autocomplete="off"
                          :class="errors.length > 0 ? 'is-invalid':null"
                        />
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="12">
                    <validation-provider
                      #default="{ errors }"
                      rules="required"
                    >
                      <b-form-group :label="$t('Link')">
                        <b-form-input
                          v-model="link"
                          placeholder=""
                          autocomplete="off"
                          :class="errors.length > 0 ? 'is-invalid':null"
                        />
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>
              </validation-observer>
            </b-form>
          </b-container>
        </vue-perfect-scrollbar>

        <div
          class="d-flex p-1 justify-content-center"
        >
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            type="submit"
            @click.stop.prevent="saveFormSW079"
          >
            {{ $t('Guardar') }}
          </b-button>
          <b-button
            v-ripple.400="'rgba(234, 84, 85, 0.15)'"
            variant="outline-danger"
            class="mr-1"
            @click.stop.prevent="clearFormSW079"
          >
            {{ $t('Limpar') }}
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            @click.stop.prevent="hide"
          >
            {{ $t('Fechar') }}
          </b-button>
        </div>

      </template>
    </b-sidebar>
  </div>
</template>

<script>
import store from '@/store'
import {
  BSidebar, BContainer, BFormGroup, BButton, BRow, BCol, BForm, BFormInput,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import VuePerfectScrollbar from '@/views/modules/components/vue-perfect-scrollbar.vue'
import { mapGetters } from 'vuex'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { sizeIconsAction } from '@core-custom/mixins/geral'
import { configSettingsScroll } from '@core-custom/utils/ui'
import { mixFormGeral } from '../../../mixins'

export default {
  components: {
    BSidebar,
    BContainer,
    BFormGroup,
    BButton,
    BRow,
    BCol,
    BForm,
    BFormInput,
    ValidationObserver,
    ValidationProvider,
    vSelect,
    VuePerfectScrollbar,
  },
  directives: {
    Ripple,
  },
  mixins: [sizeIconsAction, mixFormGeral],
  props: {
    loadAction: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      plataforma: [],
      descricao: '',
      link: '',
    }
  },
  computed:
  {
    ...mapGetters('sw079', ['platformsVideo']),
  },
  async created() {
    try {
      this.$root.$emit('app::loading', true)

      await store.dispatch('sw079/getNewFormVideo').catch(error => {
        this.showMsgErrorRequest(error)
      })

      this.$root.$emit('app::loading', false)
    } catch (err) {
      //
    }
  },
  methods: {
    closeModalForm(val) {
      this.$store.dispatch('sw079/setShowFormCreateNew', val).catch(error => {
        this.showMsgErrorRequest(error)
      })
    },
    async saveFormSW079() {
      const self = this

      if ((typeof self.clicked === 'undefined') || (self.clicked === false)) {
        self.clicked = true
        await this.$refs.formSaveValidatorSW079.validate()
          .then(valid => {
            if (valid) {
              this.getDataSaveForm().then(data => {
                const oData = data

                self.$root.$emit('app::loading', true)

                this.$store.dispatch('sw079/saveSW079', oData).then(res => {
                  self.$root.$emit('app::loading', false)
                  self.showMsgSuccessRequest(res)
                  self.closeModalForm()
                  self.loadAction()
                }).catch(error => {
                  self.$root.$emit('app::loading', false)
                  self.showMsgErrorRequest(error)
                })
              }).catch(error => {
                if (error) {
                  this.showMsgErrorRequest(error)
                }
              })
            } else {
              this.showMsgErrorRequest(new Error(this.$t('Preencha os campos obrigatórios')))
            }
          })
          .catch(() => {
            this.showMsgErrorRequest(new Error(this.$t('Preencha os campos obrigatórios')))
          })

        setTimeout(() => {
          self.clicked = false
        }, 1000)
      }
    },
    clearFormSW079() {
      this.plataforma = []
      this.descricao = ''
      this.link = ''
      this.$refs.formSave.reset()
      this.$refs.formSaveValidatorSW079.reset()
    },
    getDataSaveForm() {
      return new Promise(resolve => {
        resolve({
          plataforma: this.plataforma,
          descricao: this.descricao,
          link: this.link,
        })
      })
    },
  },
  setup() {
    return {
      configSettingsScroll,
    }
  },
}
</script>
