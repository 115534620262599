import api from '@core-custom/api/api'
import apiConfig from '@core-custom/api/apiConfig'
import i18n from '@/libs/i18n'
import defaultState from './defaultState'

export default {
  namespaced: true,
  state: defaultState,
  getters: {
    images: state => state.images.value,
    imagesPrin: state => state.imagesPrin.value,
    mainImages: state => state.mainImages.value,
    showPhotosEdition: state => state.showPhotosEdition.value,
    filterSelected: state => ({
      imagesID: state.filterSelectedImagesID,
    }),
  },
  mutations: {
    resetState(state) {
      Object.assign(state, defaultState)
    },
    setImagens(state, payload) {
      state.images.value = payload
    },
    setImagensPrin(state, payload) {
      state.imagesPrin.value = payload
    },
    setMainImages(state, payload) {
      state.mainImages.value = payload
    },
    setShowPhotosEdition(state, payload) {
      state.showPhotosEdition.value = payload
    },
    setSelectedImagesID(state, payload) {
      state.filterSelectedImagesID.value = payload ? state.images.value.map(row => row.id) : []
    },
  },
  actions: {
    async uploadSW014({ dispatch }, payload) {
      let resultUpload = null

      if ((payload !== null) && (payload !== undefined)) {
        if ('files' in payload) {
          if (payload.files.length > 0) {
            await payload.files.reduce(async (previous, file) => {
              const result = await previous
              return [...result, await dispatch('uploadImages', file)]
            }, Promise.resolve([]))
              .then(result => {
                resultUpload = result
              })
          }
        }
      }

      const response = await new Promise((resolve, reject) => {
        if (resultUpload === null) {
          reject(new Error(i18n.t('Selecione uma imagem para carregar')))
        } else {
          resolve(resultUpload)
        }
      })

      return response
    },

    async uploadImages({ rootGetters }, payload) {
      const formData = new FormData()

      formData.append('keyReg', rootGetters['listings_form/keyReg'])
      formData.append('keyDynamic', rootGetters['listings_form/dynamicIDForm'])
      formData.append('versionDynamic', rootGetters['listings_form/dynamicIDVersion'])
      formData.append('versionReg', rootGetters['listings_form/idVersionReg'])
      formData.append('name', payload.name)
      formData.append('file', payload)

      return new Promise((resolve, reject) => {
        api.post(`${apiConfig.url_base_api}listings/uploadImagens`, formData)
          .then(response => {
            if (typeof response.data === 'object') {
              resolve(response.data)
            } else {
              reject(new Error(i18n.t('Problema ao fazer upload de imagens')))
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao fazer upload de imagens')))
            }
          })
      })
    },

    async loadImages({ rootGetters, dispatch }) {
      const formData = new FormData()

      formData.append('keyReg', rootGetters['listings_form/keyReg'])
      formData.append('keyDynamic', rootGetters['listings_form/dynamicIDForm'])

      if (rootGetters['listings_form/formModeView'] === true) {
        formData.append('modeForm', 1)
      } else {
        formData.append('modeForm', '')
      }

      formData.append('versionDynamic', rootGetters['listings_form/dynamicIDVersion'])
      formData.append('versionReg', rootGetters['listings_form/idVersionReg'])
      formData.append('loadImagesPrincipais', (rootGetters['listings_form/loadTabMedia'] === true ? 1 : ''))
      formData.append('loadAjax', (rootGetters['listings_form/loadTabMedia'] === true ? 1 : ''))

      dispatch('request/cancelTokensPending', null, { root: true })

      return new Promise((resolve, reject) => {
        api.post(`${apiConfig.url_base_api}listings/loadImages`, formData)
          .then(response => {
            if (typeof response.data === 'object') {
              resolve(response.data)
            } else {
              reject(new Error(i18n.t('Problema ao carregar as imagens')))
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao carregar as imagens')))
            }
          })
      })
    },

    async deleteImage({ rootGetters, dispatch }, payload) {
      const formData = new FormData()

      formData.append('keyReg', payload.keyReg)
      formData.append('versionDynamic', rootGetters['listings_form/dynamicIDVersion'])

      payload.regDel.forEach(idImage => {
        formData.append('regDel[]', idImage)
      })

      dispatch('request/cancelTokensPending', null, { root: true })

      return new Promise((resolve, reject) => {
        api.post(`${apiConfig.url_base_api}listings/deleteImages`, formData)
          .then(response => {
            if (typeof response.data === 'object') {
              resolve(response.data)
            } else {
              reject(new Error(i18n.t('Problema ao eliminar as imagens')))
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao eliminar as imagens')))
            }
          })
      })
    },

    async changeOrderImages({ rootGetters, dispatch }, payload) {
      const formData = new FormData()
      let posImage = ''

      payload.images.forEach(element => {
        posImage += `${element.id},`
      })

      formData.append('keyReg', payload.keyReg)
      formData.append('versionDynamic', rootGetters['listings_form/dynamicIDVersion'])
      formData.append('posImage', posImage)

      dispatch('request/cancelTokensPending', null, { root: true })

      return new Promise((resolve, reject) => {
        api.post(`${apiConfig.url_base_api}listings/updateOrderImages`, formData)
          .then(response => {
            if (typeof response.data === 'object') {
              resolve(response.data)
            } else {
              reject(new Error(i18n.t('Problema ao alterar a ordem das imagens')))
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao alterar a ordem das imagens')))
            }
          })
      })
    },

    async updateDescImage({ rootGetters, dispatch }, payload) {
      const formData = new FormData()

      formData.append('regImage', payload.regImage)
      formData.append('tagSel', payload.tagSel)
      formData.append('versionDynamic', rootGetters['listings_form/dynamicIDVersion'])

      dispatch('request/cancelTokensPending', null, { root: true })

      return new Promise((resolve, reject) => {
        api.post(`${apiConfig.url_base_api}listings/updateDescImage`, formData)
          .then(response => {
            if (typeof response.data === 'object') {
              resolve(response.data)
            } else {
              reject(new Error(i18n.t('Problema a atualizar descrição da imagem')))
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema a atualizar descrição da imagem')))
            }
          })
      })
    },

    async saveImagesPrincipais({ rootGetters, dispatch }, payload) {
      const formData = new FormData()

      formData.append('idListing', rootGetters['listings_form/keyReg'])
      formData.append('keyDynamic', rootGetters['listings_form/dynamicIDForm'])
      formData.append('versionDynamic', rootGetters['listings_form/dynamicIDVersion'])
      formData.append('orderImages', payload.orderImages)
      formData.append('idImagesFixed', payload.idImagesFixed)

      dispatch('request/cancelTokensPending', null, { root: true })

      return new Promise((resolve, reject) => {
        api.post(`${apiConfig.url_base_api}listings/saveOrdemImagensPrincipais`, formData)
          .then(response => {
            if (typeof response.data === 'object') {
              resolve(response.data)
            } else {
              reject(new Error(i18n.t('Problema ao gravar as imagens principais')))
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao gravar as imagens principais')))
            }
          })
      })
    },

    setImageIDSelected({ commit }, idImage) {
      commit('setSelectedImagesID', idImage)
    },

  },
}
