<template>
  <div>

    <div
      class="email-app-list position-relative height-100-panel"
    >
      <div
        class="app-fixed-search d-flex align-items-center"
      >
        <div class="d-flex align-items-center flex-grow-1 w-50">

          <div class="sidebar-toggle d-block d-lg-none ml-1">
            <feather-icon
              icon="MenuIcon"
              :size="sizeIconsAction"
              class="cursor-pointer"
              @click="mqShallShowLeftSidebar = true"
            />
          </div>

        </div>
        <div class="d-flex align-items-center w-50 justify-content-end">

          <footer-step-nav ref="footerStepNav" />

        </div>
      </div>

      <vue-perfect-scrollbar
        class="scroll-content"
        :settings="configSettingsScroll"
      >
        <div
          class="app-fixed-search d-flex align-items-center"
        >
          <div class="d-flex flex-column align-items-center">
            <div class="d-flex align-items-center justify-content-start w-100">
              <h4 class="p-1 m-0">
                {{ $t('Morada') }}
              </h4>
            </div>
          </div>
        </div>

        <b-container
          class="p-2"
          fluid
        >
          <b-row>
            <b-col md="6">
              <!-- Morada -->
              <b-row>
                <b-col md="12">
                  <component
                    :is="blockLocalization"
                    ref="location"
                    :view="formModeView"
                    :fill-maps-lat-long="fillMapsLatLong"
                  />
                </b-col>
              </b-row>
              <b-row class="mt-2">
                <b-col
                  v-if="formModeView===false"
                  md="6"
                >
                  <b-form-group>
                    <b-form-checkbox
                      v-model="form_data.sw012s105"
                      value="1"
                    >
                      {{ $t('Publicar morada') }}
                    </b-form-checkbox>

                  </b-form-group>
                </b-col>
                <b-col
                  v-if="(form_data.sw012s105 ==='1') && (formModeView===true)"
                  md="6"
                >
                  <b-form-group>
                    <p
                      class="text-primary mb-0"
                    >
                      <feather-icon
                        :icon="((form_data.sw012s105 ==='1') ? 'CheckSquareIcon' : 'SquareIcon')"
                        size="17"
                        class="mr-50"
                      />
                      {{ $t('Publicar morada') }}
                    </p>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
            <b-col md="6">
              <b-row v-if="formModeView === false">
                <b-col md="12">
                  <b-alert
                    show
                    variant="warning"
                  >
                    <div class="alert-body">
                      <feather-icon
                        class="mr-25"
                        icon="AlertTriangleIcon"
                      />
                      <span class="ml-25">{{ $t('Arraste o ponto no mapa para a localização exata.') }}</span>
                    </div>
                  </b-alert>
                </b-col>
              </b-row>
              <google-map
                ref="googleMaps"
                :center="mapsLatLong"
                :zoom="15"
                map-type-id="roadmap"
                class="vue-google-maps"
                :options="{
                  keyboardShortcuts: false,
                  gestureHandling:'cooperative',
                  rotateControl: false,
                  tilt:0,
                }"
              >
                <google-map-marker
                  :position="mapsLatLong"
                  :clickable="!formModeView"
                  :draggable="!formModeView"
                  @dragend="updateDragMapsLatLong"
                />
              </google-map>
            </b-col>
          </b-row>
        </b-container>
      </vue-perfect-scrollbar>

    </div>

  </div>
</template>

<script>
import store from '@/store'
import {
  BRow, BCol, BContainer, BFormCheckbox, BAlert, BFormGroup,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import VuePerfectScrollbar from '@/views/modules/components/vue-perfect-scrollbar.vue'
import { sizeIconsAction } from '@core-custom/mixins/geral'
import Vue from 'vue'
import * as VueGoogleMaps from 'vue2-google-maps'
import { configSettingsScroll } from '@core-custom/utils/ui'
import { mixFormGeral } from '../mixins'

Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_API_GOOGLE_MAPS,
    region: store.getters['auth/countryApp'],
    language: store.getters['auth/countryApp'],
  },
  installComponents: false,
})

export default {
  components: {
    BRow,
    BCol,
    BContainer,
    BFormCheckbox,
    BAlert,
    BFormGroup,
    VuePerfectScrollbar,
    FooterStepNav: () => import(/* webpackChunkName: "listing-footer-step-nav" */ /* webpackPrefetch: true */ './footerStepNavTop.vue'),
    'google-map': VueGoogleMaps.Map,
    'google-map-marker': VueGoogleMaps.Marker,
  },
  mixins: [sizeIconsAction, mixFormGeral],
  computed:
  {
    ...mapGetters('listings_form', ['form_data', 'load_data_geral', 'formModeView']),
    mapsLatLong() {
      try {
        const [sLat, sLong] = this.form_data.sw012s33.split('###')

        return {
          lat: Number(sLat),
          lng: Number(sLong),
        }
      } catch (err) {
        return {
          lat: Number(0),
          lng: Number(0),
        }
      }
    },
  },
  methods: {
    async fillMapsLatLong() {
      await this.$gmapApiPromiseLazy().then(res => {
        const geocoder = new res.maps.Geocoder()
        let address = ''

        if (typeof this.$refs.location.getNameSearchGoogleMaps() !== 'undefined') {
          address = this.$refs.location.getNameSearchGoogleMaps()
        }

        geocoder.geocode({ address }, async (results, status) => {
          if (status === 'OK') {
            const latitude = results[0].geometry.location.lat()
            const longitude = results[0].geometry.location.lng()
            await this.$store.dispatch('listings_form/setMapLatLong', { lat: latitude, lng: longitude })
          }
        })
      }).catch(() => {
        this.$store.dispatch('listings_form/setMapLatLong', { lat: '', lng: '' })
      })
    },
    updateDragMapsLatLong(location) {
      try {
        if (location.latLng.lat() && location.latLng.lng()) {
          this.$store.dispatch('listings_form/setMapLatLong', { lat: location.latLng.lat(), lng: location.latLng.lng() })
        } else {
          this.$store.dispatch('listings_form/setMapLatLong', { lat: '', lng: '' })
        }
      } catch (err) {
        this.$store.commit('listings_form/setMapLatLong', { lat: '', lng: '' })
      }
    },
  },
  setup() {
    const countryApp = store.getters['auth/countryApp']
    const blockLocalization = () => import(`@/views/modules/components/localization/country/form/${countryApp}.vue`)

    return {
      blockLocalization,
      configSettingsScroll,
    }
  },
}
</script>

<style lang="scss" scoped>
.vue-google-maps{
  width: 100%;
  height: 500px;
}
</style>
