<template>
  <div>

    <div
      class="email-app-list position-relative height-100-panel"
    >
      <div
        class="app-fixed-search d-flex align-items-center"
      >
        <div class="d-flex align-items-center flex-grow-1 w-50">

          <div class="sidebar-toggle d-block d-lg-none ml-1">
            <feather-icon
              icon="MenuIcon"
              :size="sizeIconsAction"
              class="cursor-pointer"
              @click="mqShallShowLeftSidebar = true"
            />
          </div>

        </div>
        <div class="d-flex align-items-center w-50 justify-content-end">

          <footer-step-nav ref="footerStepNav" />

        </div>
      </div>

      <!-- Version Details -->
      <vue-perfect-scrollbar
        class="email-user-list scroll-area"
        :settings="configSettingsScroll"
      >
        <div
          class="app-fixed-search d-flex align-items-center"
          :class="{'border-0' : false}"
        >
          <div class="d-flex flex-column align-items-center">

            <div class="d-flex align-items-center justify-content-start w-100">
              <h4 class="p-1 m-0">
                {{ $t('Plano de comunicação') }}
              </h4>
            </div>

          </div>
        </div>

        <div v-if="load_data_geral.planoComunicacao.length > 0 && load_data_geral.planoComunicacao.sw298s07 !== null && load_data_geral.planoComunicacao.sw298s07 !== ''">
          <!-- Barra pesquisa / ordenação -->
          <div
            class="app-fixed-search align-items-center"
            :style="[{ 'text-align': 'right' }]"
          >
            <div
              v-if="sortByOptions.length > 0"
              class="dropdown"
            >
              <b-dropdown
                variant="link"
                no-caret
                toggle-class="p-0 mr-1"
                right
              >
                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    :size="sizeIconsAction"
                    class="align-middle text-body"
                  />
                </template>
                <b-dropdown-item
                  v-for="oField in sortByOptions"
                  :key="`field-order-${oField.name}`"
                  @click="listviewFilterOrderBy(oField.name)"
                >
                  <feather-icon
                    :icon="listviewIconOrderBy(oField.name)"
                    :class="listviewIconOrderBy(oField.name, true)"
                  />
                  {{ oField.txt }}
                </b-dropdown-item>
              </b-dropdown>
            </div>

          </div>

          <listview-list-data
            v-if="listviewData.length > 0"
            ref="list"
            :listview-data="listviewData"
            :view="this.$store.getters['listings_form/formModeView']"
            :user="this.$store.getters['auth/currentUser']"
          />
          <div
            v-if="listviewData.length === 0"
            class="no-results"
            :class="{'show': !listviewData.length}"
          >
            <h5 v-if="listviewInit === 0">
              {{ listview_txt_default.txt }}
            </h5>
            <h5 v-if="listviewInit === 1">
              {{ listview_txt_default.txt_loading }}
            </h5>
          </div>

          <b-container
            v-if="showPager === true"
            fluid
          >
            <b-row>
              <b-col
                cols="12"
                class="d-flex align-items-center justify-content-center"
              >
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  class="mb-0 pagination-white pt-0_2 pb-0_2"
                  :size="sizePaginationListviewResponsive"
                />
              </b-col>
            </b-row>
          </b-container>
        </div>
        <div v-else>
          <b-container
            class="p-2"
            fluid
          >
            <b-row>
              <b-col md="12">
                <b-alert
                  show
                  variant="info"
                >
                  <div class="alert-body">
                    <feather-icon
                      class="mr-25"
                      icon="AlertTriangleIcon"
                    />
                    <span
                      v-if="load_data_geral.planoComunicacao.length > 0 && (load_data_geral.planoComunicacao.sw298s07 === null || load_data_geral.planoComunicacao.sw298s07 === '')"
                      class="ml-25"
                    >
                      {{ $t('O plano de comunicação será iniciado quando um FAM/FAMA/HOST aprovar angariação.') }}
                    </span>
                    <span
                      v-else
                      class="ml-25"
                    >
                      {{ $t('Angariação sem plano de comunicação definido.') }}
                    </span>
                  </div>
                </b-alert>
              </b-col>
            </b-row>
          </b-container>
        </div>
      </vue-perfect-scrollbar>

      <b-overlay
        :show="$store.getters['sw298/loadingListview']"
        no-wrap
      />
    </div>

  </div>
</template>

<script>
import {
  BRow, BCol, BDropdown, BDropdownItem, BContainer, BPagination, BOverlay, VBTooltip, BAlert,
} from 'bootstrap-vue'
import store from '@/store'
import { onUnmounted } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import sw298Module from '@store-modules/listings/sw298/index'
import { mapGetters } from 'vuex'
import VuePerfectScrollbar from '@/views/modules/components/vue-perfect-scrollbar.vue'
import { sizeIconsAction } from '@core-custom/mixins/geral'
import { configSettingsScroll } from '@core-custom/utils/ui'
import { mixFormGeral } from '../../mixins'
import ListviewListData from './sw298/timeline.vue'
import FooterStepNav from '../footerStepNavTop.vue'

export default {
  components: {
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
    BContainer,
    BPagination,
    BOverlay,
    BAlert,
    VuePerfectScrollbar,
    ListviewListData,
    FooterStepNav,

  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  mixins: [sizeIconsAction, mixFormGeral],
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters('sw298', ['listviewData', 'listviewInit', 'listview_txt_default', 'listviewOrderBy', 'sortByOptions', 'currentPage', 'totalRows', 'perPage', 'showPager']),
    ...mapGetters('listings_form', ['load_data_geral']),
    searchTxt: {
      get() { return this.$store.getters['sw298/searchTxt'] },
      set(newValue) {
        this.$store.dispatch('sw298/setSearchTxt', newValue).catch(error => {
          this.showMsgErrorRequest(error)
        })
      },
    },
    currentPage: {
      get() { return this.$store.getters['sw298/currentPage'] },
      set(newValue) {
        this.$store.dispatch('sw298/setCurrentPage', newValue).catch(error => {
          this.showMsgErrorRequest(error)
        })
      },
    },
    sizePaginationListviewResponsive() {
      const currentBreakPoint = this.$store.getters['app/currentBreakPoint']
      return ((currentBreakPoint === 'xs') ? 'lg' : 'md')
    },
  },
  beforeDestroy() {
    if (this.$refs.listviewScroll) {
      this.$refs.listviewScroll.destroy()
    }
  },
  methods: {
    listviewFilterOrderBy(field) {
      this.$store.dispatch('sw298/listviewFilterOrderBy', field).catch(error => {
        this.showMsgErrorRequest(error)
      })
    },
    listviewIconOrderBy(filterField, renderClass) {
      let returnIcon = 'ArrowUpIcon'
      let returnClass = 'opacity-0'

      if (this.listviewOrderBy.column === filterField) {
        if (this.listviewOrderBy.sort === 'asc') {
          returnClass = 'opacity-1'
          returnIcon = 'ArrowUpIcon'
        } else {
          returnClass = 'opacity-1'
          returnIcon = 'ArrowDownIcon'
        }
      }

      if (renderClass === true) {
        return returnClass
      }

      return returnIcon
    },
  },
  setup() {
    const SW298_MODULE_NAME = 'sw298'

    if (!store.hasModule(SW298_MODULE_NAME)) {
      store.registerModule(SW298_MODULE_NAME, sw298Module)

      onUnmounted(() => {
        if (store.hasModule(SW298_MODULE_NAME)) store.unregisterModule(SW298_MODULE_NAME)
      })
    }

    return {
      configSettingsScroll,
    }
  },
}
</script>

<style lang="scss" scoped>
.email-application .email-user-list {
  height: calc( 100% - calc(6.2rem) )!important;
}

/* Desativa as animação devido que provoca erro no scroll to refresh */
.capacitor{
  .email-user-list ::v-deep{
    .email-media-list li {
      animation:none!important;
      animation-fill-mode:none!important;
    }
  }
}

</style>
