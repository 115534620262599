const defaultState = () => ({
  // Dados
  estatisticaAngElse: [],
  estatisticaAng: [],
  estatisticasSite: [],
  estatisticaWebsiteUnicos: [],
  estatisticaWebsiteTotais: [],

})

export default defaultState
