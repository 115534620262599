import { ref } from '@vue/composition-api'
import i18n from '@/libs/i18n'

const defaultState = () => ({
  // Variaveis gerais da listagem
  listviewInit: ref(1),
  listviewData: ref([]),
  loadingListview: ref(true),
  perPage: ref(25),
  totalRows: ref(0),
  currentPage: ref(1),
  perPageOptions: ref([10, 25, 50, 100]),
  searchTxt: ref(''),
  totalRegs: ref(0),
  listviewOrderBy: ref({
    column: '0',
    sort: 'desc',
  }),
  listview_txt_default: i18n.t('Nenhum resultado disponível'),
  listiview_txt_not_find: i18n.t('Não encontrou nenhum resultado'),
  listview_txt_loading: i18n.t('A carregar registos ...'),
  sortByOptions: [
    {
      name: '0',
      txt: i18n.t('Ordenar por data'),
    },
    {
      name: '1',
      txt: i18n.t('Ordenar por registado'),
    },
    {
      name: '2',
      txt: i18n.t('Ordenar por tipo de atividade'),
    },
    {
      name: '3',
      txt: i18n.t('Ordenar por quantidade'),
    },
  ],

  // Variaveis gerais da listagem design
  listviewInitDesign: ref(1),
  listviewDataDesign: ref([]),
  loadingListviewDesign: ref(true),
  perPageDesign: ref(25),
  totalRowsDesign: ref(0),
  currentPageDesign: ref(1),
  perPageOptionsDesign: ref([10, 25, 50, 100]),
  searchTxtDesign: ref(''),
  totalRegsDesign: ref(0),
  listviewOrderByDesign: ref({
    column: '0',
    sort: 'desc',
  }),
  listview_txt_default_design: i18n.t('Nenhum resultado disponível'),
  listiview_txt_not_find_design: i18n.t('Não encontrou nenhum resultado'),
  listview_txt_loading_design: i18n.t('A carregar registos ...'),
  sortByOptionsDesign: [
    {
      name: '4',
      txt: i18n.t('Ordenar por data'),
    },
    {
      name: '1',
      txt: i18n.t('Ordenar por título'),
    },
    {
      name: '2',
      txt: i18n.t('Ordenar por modelo'),
    },
    {
      name: '3',
      txt: i18n.t('Ordenar por registado'),
    },
    {
      name: '5',
      txt: i18n.t('Ordenar por quantidade'),
    },
  ],
})

export default defaultState
