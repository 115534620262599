<template>
  <div>

    <div
      class="email-app-list position-relative height-100-panel"
    >
      <div
        class="app-fixed-search d-flex align-items-center"
      >
        <div class="d-flex align-items-center flex-grow-1 w-50">

          <div class="sidebar-toggle d-block d-lg-none ml-1">
            <feather-icon
              icon="MenuIcon"
              :size="sizeIconsAction"
              class="cursor-pointer"
              @click="mqShallShowLeftSidebar = true"
            />
          </div>

        </div>
        <div class="d-flex align-items-center w-50 justify-content-end">

          <footer-step-nav ref="footerStepNav" />

        </div>
      </div>

      <vue-perfect-scrollbar
        class="scroll-content"
        :settings="configSettingsScroll"
      >
        <div
          class="app-fixed-search d-flex align-items-center"
        >
          <div class="d-flex flex-column align-items-center">
            <div class="d-flex align-items-center justify-content-start w-100">
              <h4 class="p-1 m-0">
                {{ $t('Dados legais') }}
              </h4>
            </div>
          </div>
        </div>

        <b-container
          class="p-2"
          fluid
        >
          <div v-if="countryApp.toUpperCase() === 'PT'">
            <b-row>
              <b-col md="12">
                <h4>
                  {{ $t('Conservatória') }}
                </h4>
              </b-col>
            </b-row>

            <component
              :is="blockConservatoria"
              v-for="(sw086, index) in form_sw086"
              :key="`conservatoria-item-${index}`"
              ref="conservatoria"
              :sw086="form_sw086"
              :index="index"
              :view="formModeView"
              :iso="countryApp"
            />

            <div v-if="(formModeView===false) || (formModeView===true && form_sw086.length === 0)">
              <component
                :is="blockConservatoria"
                ref="conservatoria"
                :sw086="form_sw086_default"
                :add-btn="true"
                :view="formModeView"
                :iso="countryApp"
              />
            </div>

            <b-row>
              <b-col md="12">
                <h4>
                  {{ $t('Caderneta predial (artigo matricial)') }}
                </h4>
              </b-col>
            </b-row>

            <component
              :is="blockCaderneta"
              v-for="(sw078, index) in form_sw078"
              :key="`caderneta-item-${index}`"
              ref="caderneta"
              :sw078="form_sw078"
              :index="index"
              :view="formModeView"
            />

            <div v-if="(formModeView===false) || (formModeView===true && form_sw078.length === 0)">
              <component
                :is="blockCaderneta"
                ref="caderneta"
                :sw078="form_sw078_default"
                :add-btn="true"
                :view="formModeView"
              />
            </div>

            <b-row>
              <b-col md="12">
                <h4 class="mt-1">
                  {{ $t('Licença de utilização') }} <span>({{ $t('Câmara') }})</span>
                </h4>
              </b-col>
            </b-row>

            <b-row>
              <b-col md="4">
                <ValidationProvider
                  #default="{ errors }"
                  :rules="typeof rulesFields.sw012s56 !== 'undefined' && rulesFields.sw012s56.required === true ? 'required' : ''"
                  :name="$t('Número Licença de utilização') | lowerBold"
                >
                  <b-form-group
                    :label="$t('Número')"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      v-if="formModeView===false"
                      v-model="form_data.sw012s56"
                      placeholder=""
                      autocomplete="off"
                      maxlength="15"
                      :disabled="Number(form_data.sw012s366) === 1"
                      @keypress="NumbersOnly"
                    />
                    <p
                      v-else
                      class="text-primary mb-0"
                    >
                      {{ form_data.sw012s56 | getTxt }}
                    </p>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col md="4">
                <ValidationProvider
                  #default="{ errors }"
                  :rules="typeof rulesFields.sw012s56 !== 'undefined' && rulesFields.sw012s56.required === true ? 'required' : ''"
                  :name="$t('Data de emissão Licença de utilização') | lowerBold"
                >
                  <b-form-group
                    :label="$t('Data de emissão')"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-input-group>
                      <date-picker
                        v-if="formModeView===false"
                        v-model="form_data.sw012s57"
                        value-type="format"
                        input-class="form-control"
                        type="date"
                        :editable="false"
                        :clearable="true"
                        :lang="langCalendar"
                        :append-to-body="true"
                        :disabled="Number(form_data.sw012s366) === 1"
                        prefix-class="sw"
                      >
                        <template #icon-clear>
                          <v-select-deselect />
                        </template>
                      </date-picker>
                      <p
                        v-else
                        class="text-primary mb-0"
                      >
                        {{ form_data.sw012s57 | getTxt }}
                      </p>
                    </b-input-group>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col
                v-if="formModeView===false"
                md="2"
                class="d-flex align-items-center"
              >
                <b-form-group
                  :label="'\xa0'"
                >
                  <b-form-checkbox
                    v-model="form_data.sw012s366"
                    value="1"
                    @change="toggleIsento"
                  >
                    {{ $t('Isento') }}
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
              <b-col
                v-if="formModeView===true"
                md="2"
                class="d-flex align-items-center"
              >
                <p
                  class="text-primary mb-0"
                >
                  <feather-icon
                    :icon="((form_data.sw012s366 ==='1') ? 'CheckSquareIcon' : 'SquareIcon')"
                    size="17"
                    class="mr-50"
                  />
                  {{ $t('Isento') }}
                </p>
              </b-col>
            </b-row>

            <b-row>
              <b-col md="12">
                <h4
                  class="mt-1"
                >
                  {{ $t('IMPIC') }}
                </h4>
              </b-col>
            </b-row>

            <b-row>
              <b-col md="4">
                <b-form-group
                  :label="$t('Código de livro de registos')"
                >
                  <b-form-input
                    v-if="formModeView===false"
                    v-model="form_data.sw012s58"
                    placeholder=""
                    autocomplete="off"
                  />
                  <p
                    v-else
                    class="text-primary mb-0"
                  >
                    {{ form_data.sw012s58 | getTxt }}
                  </p>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col md="12">
                <h4
                  class="mt-1"
                >
                  {{ $t('IMI') }}
                </h4>
              </b-col>
            </b-row>

            <b-row>
              <b-col md="4">
                <b-form-group
                  :label="$t('Valor')"
                >
                  <b-input-group
                    v-if="formModeView===false"
                    :prepend="symbolMoney"
                    append=""
                    class="input-group-merge"
                  >
                    <b-form-input
                      v-model.lazy="form_data.sw012s304"
                      v-currency="configMoney"
                      placeholder=""
                      autocomplete="off"
                    />
                  </b-input-group>
                  <p
                    v-else
                    class="text-primary mb-0"
                  >
                    {{ formatPrice(form_data.sw012s304) | getTxt }} {{ symbolMoney }}
                  </p>
                </b-form-group>
              </b-col>
            </b-row>
          </div>
          <div v-else>
            <b-row>
              <b-col md="12">
                <h4>
                  {{ $t('Registo') }}
                </h4>
              </b-col>
            </b-row>

            <component
              :is="blockConservatoria"
              v-for="(sw086, index) in form_sw086"
              :key="`conservatoria-item-${index}`"
              ref="conservatoria"
              :sw086="form_sw086"
              :index="index"
              :view="formModeView"
              :iso="countryApp"
            />

            <div v-if="(formModeView===false) || (formModeView===true && form_sw086.length === 0)">
              <component
                :is="blockConservatoria"
                ref="conservatoria"
                :sw086="form_sw086_default"
                :add-btn="true"
                :view="formModeView"
                :iso="countryApp"
              />
            </div>

            <b-row>
              <b-col md="12">
                <h4>
                  {{ $t('Licença da primeira ocupação') }}
                </h4>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="6">
                <b-form-group
                  :label="$t('Número de registo')"
                >
                  <b-form-input
                    v-if="formModeView===false"
                    v-model="form_data.sw012s342"
                    placeholder=""
                    autocomplete="off"
                  />
                  <p
                    v-else
                    class="text-primary mb-0"
                  >
                    {{ form_data.sw012s342 | getTxt }}
                  </p>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col md="12">
                <h4>
                  {{ $t('Referência cadastral') }}
                </h4>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="6">
                <b-form-group
                  :label="$t('Referência')"
                >
                  <b-form-input
                    v-if="formModeView===false"
                    v-model="form_data.sw012s343"
                    placeholder=""
                    autocomplete="off"
                  />
                  <p
                    v-else
                    class="text-primary mb-0"
                  >
                    {{ form_data.sw012s343 | getTxt }}
                  </p>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col md="12">
                <h4>
                  {{ $t('Valor tributável da propriedade') }}
                </h4>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="6">
                <b-form-group
                  :label="$t('Valor')"
                >
                  <b-input-group
                    v-if="formModeView===false"
                    :prepend="symbolMoney"
                    append=""
                    class="input-group-merge"
                  >
                    <b-form-input
                      v-model.lazy="form_data.sw012s344"
                      v-currency="configMoney"
                      placeholder=""
                      autocomplete="off"
                    />
                  </b-input-group>
                  <p
                    v-else
                    class="text-primary mb-0"
                  >
                    {{ formatPrice(form_data.sw012s344) | getTxt }} {{ symbolMoney }}
                  </p>
                </b-form-group>
              </b-col>
            </b-row>
          </div>
        </b-container>
      </vue-perfect-scrollbar>

    </div>

  </div>
</template>

<script>
import {
  BRow, BCol, BContainer, BButton, BFormInput, BFormGroup, BInputGroup, BInputGroupAppend, BFormCheckbox,
} from 'bootstrap-vue'
import { mapGetters, mapActions } from 'vuex'
import VuePerfectScrollbar from '@/views/modules/components/vue-perfect-scrollbar.vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { sizeIconsAction } from '@core-custom/mixins/geral'
import { CurrencyDirective } from 'vue-currency-input'
import { configMoney, symbolMoney } from '@core-custom/utils/money'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { configSettingsScroll, formatPrice } from '@core-custom/utils/ui'
import { mixFormGeral } from '../mixins'

const oDatepicker = () => Promise.all([
  import(/* webpackChunkName: "chunk-vue2-datepicker" */ 'vue2-datepicker'),
  import(/* webpackChunkName: "chunk-vue2-datepicker" */ '@/assets/scss/datepicker.scss'),
]).then(([DatePicker]) => DatePicker)

export default {
  components: {
    BRow,
    BCol,
    BContainer,
    BButton,
    BFormInput,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    VuePerfectScrollbar,
    vSelect,
    vSelectDeselect: vSelect.components.Deselect,
    DatePicker: oDatepicker,
    FooterStepNav: () => import(/* webpackChunkName: "listings-footer-step-nav" */ /* webpackPrefetch: true */ './footerStepNavTop.vue'),
    ValidationObserver,
    ValidationProvider,
  },
  directives: {
    Ripple,
    currency: CurrencyDirective,
  },
  mixins: [sizeIconsAction, mixFormGeral],
  data() {
    return {
      configMoney,
      symbolMoney,
      langCalendar: {},
      formatPrice,
      isentoData: {},
    }
  },
  computed:
  {
    ...mapGetters('listings_form', ['form_data', 'form_sw086', 'form_sw078', 'form_sw086_default', 'form_sw078_default', 'load_data_geral', 'formModeView', 'rulesFields']),
    ...mapGetters('auth', ['countryApp']),
    ...mapActions('listings_form', ['addNewConservatoria', 'addNewCaderneta']),
  },
  async mounted() {
    await this.importLocale(this.$i18n.locale).then(res => {
      this.langCalendar = res.default
      // Forçei estes valores para a semana começar sempre no domingo
      this.langCalendar.formatLocale.firstDayOfWeek = 0
      this.langCalendar.formatLocale.firstWeekContainsDate = 4
    })
  },
  methods: {
    NumbersOnly(evt) {
      const charCode = (evt.which) ? evt.which : evt.keyCode
      if ((charCode > 31 && (charCode < 48 || charCode > 57 || charCode === 46))) {
        evt.preventDefault()
        return false
      }
      return true
    },
    async importLocale(lang) {
      return import(`vue2-datepicker/locale/${lang}`)
    },
    toggleIsento() {
      if (Number(this.form_data.sw012s366) === 1) {
        this.isentoData = { sw012s56: this.form_data.sw012s56, sw012s57: this.form_data.sw012s57 }
        this.$store.dispatch('listings_form/updateFieldValue', { field: 'sw012s56', value: null })
        this.$store.dispatch('listings_form/updateFieldValue', { field: 'sw012s57', value: null })
      } else {
        if (this.isentoData.sw012s56 !== undefined) {
          this.$store.dispatch('listings_form/updateFieldValue', { field: 'sw012s56', value: this.isentoData.sw012s56 })
        }
        if (this.isentoData.sw012s57 !== undefined) {
          this.$store.dispatch('listings_form/updateFieldValue', { field: 'sw012s57', value: this.isentoData.sw012s57 })
        }
        this.isentoData = {}
      }

      this.$store.dispatch('listings_form/updateFieldValue', { field: 'sw012s366', value: this.form_data.sw012s366 })
      this.$store.dispatch('listings_form/validateLicenseNumber')
    },
  },
  setup() {
    const blockConservatoria = () => import('@/views/modules/listings/form/form_data/sw086/form.vue')
    const blockCaderneta = () => import('@/views/modules/listings/form/form_data/sw078/form.vue')

    return {
      blockConservatoria,
      blockCaderneta,
      configSettingsScroll,
    }
  },
}
</script>
