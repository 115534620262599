<template>
  <app-timeline class="mt-1 ml-3 mr-1">
    <app-timeline-item
      v-for="(row, index) in listviewData"
      :key="index"
      no-body
      icon="ClockIcon"
      variant="primary"
    >
      <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
        <h6>{{ $t('Toque') }}  #{{ row.nToque }}  - <small class="text-muted">{{ row.data }}</small></h6>
      </div>
      <div class="justify-content align-items-start">
        <!-- 1st Col -->
        <b-row>
          <b-col
            md="2"
          >
            <div class="mb-1 mb-sm-0">
              <span class="text-muted mb-50 d-block">{{ $t('Comunicação') }}</span>
              <span>{{ row.titulo }}</span>
            </div>
          </b-col>
          <b-col
            md="2"
          >
            <div class="mb-1 mb-sm-0">
              <span class="text-muted mb-50 d-block">{{ $t('Tipo do toque') }}</span>
              <span>{{ row.tipoToqueDesc }}</span>
            </div>
          </b-col>
          <b-col
            md="2"
          >
            <div class="mb-1 mb-sm-0">
              <span class="text-muted mb-50 d-block">{{ $t('Estado') }}</span>
              <span
                :class="Number(row.state) === 1 ? 'text-success' : row.sendToque !== '' ? 'text-danger' : ''"
              >
                {{ row.estado }}
              </span>
            </div>
          </b-col>
          <b-col
            md="2"
          >
            <div class="mb-1 mb-sm-0">
              <span class="text-muted mb-50 d-block">{{ $t('Observações') }}</span>
              <span>{{ row.obs }}</span>
            </div>
          </b-col>
          <b-col md="2">
            <b-dropdown
              v-if="showButton1Action(row) === true"
              v-ripple.400="'rgba(255, 159, 67, 0.15)'"
              split
              :text="$t('Ver')"
              variant="outline-primary"
            >
              <b-dropdown-item
                v-if="[3, 4, 7].includes(Number(row.tipoToque))"
                @click="getModalDetalhe(row)"
              >
                {{ $t('Detalhes') }}
              </b-dropdown-item>
              <b-dropdown-item @click="cancelarComunicacao(row)">
                {{ $t('Cancelar comunicação') }}
              </b-dropdown-item>
            </b-dropdown>
            <b-dropdown
              v-if="showButton2Action(row) === true"
              v-ripple.400="'rgba(255, 159, 67, 0.15)'"
              split
              :text="$t('Ver')"
              variant="outline-primary"
            >
              <b-dropdown-item @click="retomarComunicacao(row)">
                {{ $t('Retomar comunicação') }}
              </b-dropdown-item>
              <b-dropdown-item
                v-if="[3, 4, 7].includes(Number(row.tipoToque))"
                @click="getModalDetalhe(row)"
              >
                {{ $t('Detalhes') }}
              </b-dropdown-item>
            </b-dropdown>
            <b-dropdown
              v-if="showButton3Action(row) === true && [3, 4, 7].includes(Number(row.tipoToque))"
              v-ripple.400="'rgba(255, 159, 67, 0.15)'"
              split
              :text="$t('Ver')"
              variant="outline-primary"
            >
              <b-dropdown-item @click="getModalDetalhe(row)">
                {{ $t('Detalhes') }}
              </b-dropdown-item>
            </b-dropdown>
            <b-button
              v-if="showButton3Action(row) === false && ![3, 4, 7].includes(Number(row.tipoToque))"
              v-ripple.400="'rgba(234, 84, 85, 0.15)'"
              v-b-tooltip.hover
              variant="outline-primary"
            >
              <feather-icon
                icon="EyeIcon"
                class="mr-25"
              />
              <span>{{ $t('Ver') }}</span>
            </b-button>
          </b-col>
        </b-row>
      </div>
    </app-timeline-item>

    <component
      :is="ModalDetail"
      v-if="showModalDetail === true"
      ref="modalDetail"
      :report="report"
      :type-email="typeEmail"
    />
  </app-timeline>
</template>

<script>
import {
  BRow, BCol, VBTooltip, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import { showModalMessage } from '@core/utils/utils'
import Ripple from 'vue-ripple-directive'
import { mixFormGeral } from '../../../mixins'

export default {
  components: {
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
    AppTimeline,
    AppTimelineItem,

  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  mixins: [mixFormGeral],
  props: {
    listviewData: {
      type: Array,
      required: true,
    },
    view: {
      type: Boolean,
      default: () => false,
    },
    user: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      showModalDetail: false,
      typeEmail: false,
      report: {},
    }
  },
  computed: {
  },
  methods: {
    baseDecode(val) {
      return atob(val)
    },
    showButton1Action(plan) {
      if (((plan.idAgent !== '' && plan.idAgent === this.baseDecode(this.user.id)) || (plan.registadoPor === this.baseDecode(this.user.id)) || (this.user.extras.idLeader === plan.idAgent && this.baseDecode(this.user.id) === this.user.extras.idLeader))
        && plan.sendToque === '' && plan.emCurso === '' && plan.state === '') {
        return true
      }
      return false
    },
    showButton2Action(plan) {
      const sDateToque = +new Date(plan.data)
      const sDateNow = Date.now()

      if (Number(plan.sendToque) === 1 && plan.emCurso === '' && Number(sDateNow) < Number(sDateToque)) {
        if ((![3, 4, 7].includes(plan.tipoToque)) || (Number(plan.tipoToque) === 7 && Number(plan.rgpdSms) === 1) || ([3, 4].includes(Number(plan.tipoToque)) && Number(plan.rgpdEmail) === 1)) {
          return true
        }
      }
      return false
    },
    showButton3Action(plan) {
      const sDateToque = +new Date(plan.data)
      const sDateNow = Date.now()

      if (Number(plan.sendToque) === 1 && plan.emCurso === '' && Number(sDateNow) < Number(sDateToque)) {
        if ((![3, 4, 7].includes(plan.tipoToque)) || (Number(plan.tipoToque) === 7 && Number(plan.rgpdSms) === 1) || ([3, 4].includes(Number(plan.tipoToque)) && Number(plan.rgpdEmail) === 1)) {
          // Não faz nada
        } else {
          return true
        }
      }
      return false
    },
    async cancelarComunicacao(plan) {
      const actionModal = new Promise(resolve => {
        showModalMessage(resolve, this.$t('Deseja cancelar a comunicação?'))
      })

      await actionModal.then(result => {
        if (result === 'confirm') {
          this.$store.dispatch('sw298/actionComunicacao', {
            idPlano: plan.id, toque: plan.nToque, tipoToque: plan.tipoToque, action: 'cancelar',
          }).then(async response => {
            if (Number(response.error) !== 1) {
              this.showMsgSuccessRequest({ message: response.msg })

              await this.$store.dispatch('sw298/fetchListView')
            } else {
              this.showMsgErrorRequest({ message: response.msg })
            }
          }).catch(error => {
            this.showMsgErrorRequest(error)
          })
        }
      })
    },
    async retomarComunicacao(plan) {
      this.$swal({
        title: '',
        html: `${this.$t('Deseja retomar a comunicação?')}`,
        icon: 'success',
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonText: this.$t('Sim'),
        cancelButtonText: this.$t('Não'),
        customClass: {
          confirmButton: 'btn btn-primary ml-1',
          cancelButton: 'btn btn-outline-secondary',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          await this.$store.dispatch('sw298/actionComunicacao', {
            idPlano: plan.id, toque: plan.nToque, tipoToque: plan.tipoToque, action: 'retomar',
          }).then(async response => {
            if (Number(response.error) !== 1) {
              this.showMsgSuccessRequest({ message: response.msg })

              await this.$store.dispatch('sw298/fetchListView')
            } else {
              this.showMsgErrorRequest({ message: response.msg })
            }
          }).catch(error => {
            this.showMsgErrorRequest(error)
          })
        }
      })
    },
    async getModalDetalhe(plan) {
      this.$root.$emit('app::loading', true)

      await this.$store.dispatch('sw298/popupComunicacaoReport', { idPlano: plan.id, toque: plan.nToque }).then(async response => {
        if (Number(response.error) !== 1) {
          this.report = response.data.report
          this.typeEmail = response.data.typeEmail
          this.showModalDetail = true

          setTimeout(() => {
            this.$root.$emit('app::loading', false)

            if (this.showModalDetail === true) {
              this.$refs.modalDetail.showModal().then(async result => {
                if (result === true) {
                  await this.$refs.modalDetail.hideModal()
                }
              })
            }
          }, 500)
        } else {
          this.showMsgErrorRequest({ message: response.msg })
        }
      }).catch(error => {
        this.showMsgErrorRequest(error)
      })
    },
  },
  setup() {
    const ModalDetail = () => import(/* webpackChunkName: "listing-new-plano-modal-detail" */ /* webpackPrefetch: true */ '../../modal/detailPlanoCommunication.vue')

    return {
      ModalDetail,
    }
  },
}
</script>
