import api from '@core-custom/api/api'
import apiConfig from '@core-custom/api/apiConfig'
import i18n from '@/libs/i18n'
import defaultState from './defaultState'

export default {
  namespaced: true,
  state: defaultState,
  getters: {
    showFormCreateNew: state => state.showFormCreateNew.value,
    platformsVR: state => state.platformsVR.value,
    virtualR: state => state.virtualR.value,
    showVrEdition: state => state.showVrEdition.value,
    filterSelected: state => ({
      vrID: state.filterSelectedVrID,
    }),
  },
  mutations: {
    resetState(state) {
      Object.assign(state, defaultState)
    },
    setCreateNewSW344(state, payload) {
      state.showFormCreateNew.value = payload
    },
    setPlatformsVR(state, payload) {
      state.platformsVR.value = payload
    },
    setVRs(state, payload) {
      state.virtualR.value = payload
    },
    setShowVrEdition(state, payload) {
      state.showVrEdition.value = payload
    },
    setSelectedVrID(state, payload) {
      state.filterSelectedVrID.value = payload ? state.virtualR.value.map(row => row.id) : []
    },
  },
  actions: {
    async getNewFormVR({ commit }) {
      return new Promise((resolve, reject) => {
        api.post(`${apiConfig.url_base_api}listings/newFormVisitaVirtual`)
          .then(response => {
            try {
              if (response.data.data !== undefined) {
                commit('setPlatformsVR', response.data.data.platformsVirtuais)
                resolve(true)
              } else {
                reject(new Error(i18n.t('Problema ao carregar o formulário')))
              }
            } catch (error) {
              if ((error.response !== undefined) && (error.response.status !== undefined)) {
                reject(new Error(i18n.t('Problema ao carregar o formulário')))
              }
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao carregar o formulário')))
            }
          })
      })
    },

    async saveSW344({ rootGetters }, payload) {
      return new Promise((resolve, reject) => {
        const formData = new FormData()
        formData.append('keyReg', rootGetters['listings_form/keyReg'])
        formData.append('keyDynamic', rootGetters['listings_form/dynamicIDForm'])
        formData.append('versionDynamic', '')
        formData.append('versionReg', rootGetters['listings_form/idVersionReg'])
        formData.append('sw344s09', btoa(payload.plataforma.sw396s01))
        formData.append('sw344s04', payload.url)
        formData.append('sw344s05', payload.titulo)
        formData.append('sw344s15', payload.descritivo)

        api.post(`${apiConfig.url_base_api}listings/saveFormVisitaVirtual`, formData)
          .then(response => {
            try {
              if (typeof response.data === 'object') {
                if (response.data.error === 1) {
                  reject(new Error(response.data.msg))
                } else {
                  resolve({ message: response.data.msg })
                }
              } else {
                reject(new Error(i18n.t('Problema ao guardar o VR')))
              }
            } catch (error) {
              if ((error.response !== undefined) && (error.response.status !== undefined)) {
                reject(new Error(i18n.t('Problema ao guardar o VR')))
              }
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao guardar o VR')))
            }
          })
      })
    },

    async loadVRs({ rootGetters, dispatch }) {
      const formData = new FormData()

      formData.append('keyReg', rootGetters['listings_form/keyReg'])
      formData.append('keyDynamic', rootGetters['listings_form/dynamicIDForm'])

      if (rootGetters['listings_form/formModeView'] === true) {
        formData.append('modeForm', 1)
      } else {
        formData.append('modeForm', '')
      }

      formData.append('versionDynamic', rootGetters['listings_form/dynamicIDVersion'])
      formData.append('versionReg', rootGetters['listings_form/idVersionReg'])
      formData.append('loadAjax', '')

      dispatch('request/cancelTokensPending', null, { root: true })

      return new Promise((resolve, reject) => {
        api.post(`${apiConfig.url_base_api}listings/loadVisitasVirtuais`, formData)
          .then(response => {
            if (typeof response.data === 'object') {
              resolve(response.data)
            } else {
              reject(new Error(i18n.t('Problema ao carregar VRs')))
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao carregar VRs')))
            }
          })
      })
    },

    async changeOrderVR({ rootGetters, dispatch }, payload) {
      const formData = new FormData()
      let posVR = ''

      payload.virtualR.forEach(element => {
        posVR += `${element.id},`
      })

      formData.append('keyReg', rootGetters['listings_form/keyReg'])
      formData.append('versionDynamic', rootGetters['listings_form/dynamicIDVersion'])
      formData.append('posVR', posVR)

      dispatch('request/cancelTokensPending', null, { root: true })

      return new Promise((resolve, reject) => {
        api.post(`${apiConfig.url_base_api}listings/updateOrderVR`, formData)
          .then(response => {
            if (typeof response.data === 'object') {
              resolve(response.data)
            } else {
              reject(new Error(i18n.t('Problema ao alterar a ordem dos VRs')))
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao alterar a ordem dos VRs')))
            }
          })
      })
    },

    async deleteVRs({ dispatch }, payload) {
      const formData = new FormData()

      if (Array.isArray(payload.regDel)) {
        payload.regDel.forEach(idVideo => {
          formData.append('regDel[]', idVideo)
        })
      } else {
        formData.append('regDel', payload.regDel)
      }

      dispatch('request/cancelTokensPending', null, { root: true })

      return new Promise((resolve, reject) => {
        api.post(`${apiConfig.url_base_api}listings/deleteVisitaVirtual`, formData)
          .then(response => {
            if (typeof response.data === 'object') {
              resolve(response.data)
            } else {
              reject(new Error(i18n.t('Problema ao eliminar os VRs')))
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao eliminar os VRs')))
            }
          })
      })
    },

    async updateDescVisitaVirtual({ rootGetters, dispatch }, payload) {
      const formData = new FormData()

      formData.append('regVisita', payload.regVisita)
      formData.append('txtDesc', payload.txtDesc)
      formData.append('versionDynamic', rootGetters['listings_form/dynamicIDVersion'])
      formData.append('versionReg', rootGetters['listings_form/idVersionReg'])

      dispatch('request/cancelTokensPending', null, { root: true })

      return new Promise((resolve, reject) => {
        api.post(`${apiConfig.url_base_api}listings/updateDescVisitaVirtual`, formData)
          .then(response => {
            if (typeof response.data === 'object') {
              resolve(response.data)
            } else {
              reject(new Error(i18n.t('Problema a atualizar título do VR')))
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema a atualizar título do VR')))
            }
          })
      })
    },

    createNewSW344({ commit }) {
      commit('setCreateNewSW344', true)
    },

    setShowFormCreateNew({ commit }, value) {
      commit('setCreateNewSW344', value)
    },

    setVrIDSelected({ commit }, idVR) {
      commit('setSelectedVrID', idVR)
    },

  },
}
