import api from '@core-custom/api/api'
import apiConfig from '@core-custom/api/apiConfig'
import i18n from '@/libs/i18n'
import defaultState from './defaultState'

export default {
  namespaced: true,
  state: defaultState,
  getters: {
    filterSelected: state => ({
      totalFields: state.filterSelectedTotalFields,
      estadoReceived: state.filterSelectedEstadoPropostasReceived,
      estadoSent: state.filterSelectedEstadoPropostasSent,
      estadoApprove: state.filterSelectedEstadoPropostasApprove,
      estadoReserve: state.filterSelectedEstadoPropostasReserve,
    }),
    filterData: state => ({
      tabsListview: state.filterDataTabsListview,
      tabsListviewVisits: state.filterDataTabsListviewVisits,
      proposalsToApprove: state.proposalsToApprove,
      proposalsToConcluir: state.proposalsToConcluir,
      proposalsToApproveAll: state.proposalsToApproveAll,
      proposalsToConcluirAll: state.proposalsToConcluirAll,
      proposalsToPaymentPending: state.proposalsToPaymentPending,
      estadoReceived: state.filterDataEstadoPropostasReceived,
      estadoSent: state.filterDataEstadoPropostasSent,
      estadoApprove: state.filterDataEstadoPropostasToApprove,
      estadoReserve: state.filterDataEstadoPropostasReserve,
      load: state.filterDataLoad,
    }),
    listview_txt_default: state => ({
      txt: state.listview_txt_default,
      txt_not_find: state.listiview_txt_not_find,
      txt_search: state.listiview_txt_search,
      txt_search_sent: state.listiview_txt_search_sent,
      txt_loading: state.listview_txt_loading,
    }),
    listviewInit: state => state.listviewInit.value,
    listviewData: state => state.listviewData.value,
    listviewOrderBy: state => state.listviewOrderBy.value,
    listviewOrderBySent: state => state.listviewOrderBySent.value,
    listviewOrderByApprove: state => state.listviewOrderByApprove.value,
    listviewOrderByReserve: state => state.listviewOrderByReserve.value,
    totalRows: state => state.totalRows.value,
    perPage: state => state.perPage.value,
    tabSelectFilter: state => state.tabSelectFilter.value,
    loadingListview: state => state.loadingListview.value,
    currentPage: state => state.currentPage.value,
    searchTxt: state => state.searchTxt.value,
    fieldsOrder: state => state.aFieldsOrder,
    fieldsOrderSent: state => state.aFieldsOrderSent,
    fieldsOrderApprove: state => state.aFieldsOrderP,
    fieldsOrderReserve: state => state.aFieldsOrderReserve,
    positionListview: state => state.positionListview,
    showListVisits: state => state.showListVisits.value,
    showDetail: state => state.showDetail.value,
    showForm: state => state.showForm.value,
    listviewInitVisits: state => state.listviewInitVisits.value,
    listviewDataVisits: state => state.listviewDataVisits.value,
    listviewOrderByVisits: state => state.listviewOrderByVisits.value,
    totalRowsVisits: state => state.totalRowsVisits.value,
    perPageVisits: state => state.perPageVisits.value,
    tabSelectFilterVisits: state => state.tabSelectFilterVisits.value,
    loadingListviewVisits: state => state.loadingListviewVisits.value,
    currentPageVisits: state => state.currentPageVisits.value,
    searchTxtVisits: state => state.searchTxtVisits.value,
    fieldsOrderVisits: state => state.aFieldsOrderVisits,
    positionListviewVisits: state => state.positionListviewVisits,
    listview_txt_default_visits: state => ({
      txt: state.listview_txt_default,
      txt_not_find: state.listiview_txt_not_find,
      txt_search: state.listiviewVisits_txt_search,
      txt_loading: state.listview_txt_loading,
    }),
    proposalDetail: state => ({
      proposal: state.proposal.value,
      flow: state.flow.value,
      visit: state.visit.value,
      agent: state.agent.value,
      listing: state.listing.value,
      listingInfo: state.listingInfo.value,
      action: state.action.value,
      iWhoami: state.iWhoami.value,
      user: state.user.value,
      agentExternal: state.agentExternal.value,
      infoAgentExternal: state.infoAgentExternal.value,
      cargos: state.cargos.value,
      imagesListing: state.imagesListing.value,
      lastEntry: state.lastEntry.value,
      proposalDisable: state.proposalDisable.value,
      salesProcessStatus: state.salesProcessStatus.value,
      notHaveUploadFiles: state.notHaveUploadFiles.value,
      dynamicIDVersion: state.dynamicIDVersion.value,
      urlDocs: state.urlDocs.value,
      imagePreview: state.imagePreview.value,
    }),
    activity: state => state.activity.value,
    showPager: state => {
      if (state.totalRows.value > state.perPage.value) {
        return true
      }

      return false
    },
    showVisitsPager: state => {
      if (state.totalRowsVisits.value > state.perPageVisits.value) {
        return true
      }

      return false
    },
  },
  mutations: {
    resetState(state) {
      Object.assign(state, defaultState)
    },
    setLoadingListview(state, payload) {
      state.loadingListview.value = payload
    },
    setListviewData(state, payload) {
      state.listviewData.value = payload
    },
    setTotalRows(state, payload) {
      state.totalRows.value = payload
    },
    setFilterData(state, payload) {
      const index = Object.keys(payload)[0]
      state[index].value = payload[index]
    },
    setFilterSelected(state, payload) {
      const index = Object.keys(payload)[0]
      state[index].value = payload[index]
    },
    setSearchTxt(state, payload) {
      state.searchTxt.value = payload
    },
    setCurrentPage(state, payload) {
      state.currentPage.value = payload
    },
    setTabSelectFilter(state, payload) {
      state.tabSelectFilter.value = payload
    },
    setListviewOrderBy(state, payload) {
      state.listviewOrderBy.value = {
        sort: payload.sort,
        column: payload.column,
      }
    },
    setListviewOrderBySent(state, payload) {
      state.listviewOrderBySent.value = {
        sort: payload.sort,
        column: payload.column,
      }
    },
    setListviewOrderByApprove(state, payload) {
      state.listviewOrderByApprove.value = {
        sort: payload.sort,
        column: payload.column,
      }
    },
    setListviewOrderByReserve(state, payload) {
      state.listviewOrderByReserve.value = {
        sort: payload.sort,
        column: payload.column,
      }
    },
    setListviewInit(state, payload) {
      state.listviewInit.value = payload
    },
    resetFilterSelectedTotalFields(state) {
      state.filterSelectedTotalFields.value = 0
    },
    resetAllFilterSelected(state) {
      state.searchTxt.value = ''
    },
    addFilterSelectedTotalFields(state) {
      state.filterSelectedTotalFields.value += 1
    },
    savePositionListview(state, payload) {
      state.positionListview = payload || {}
    },
    setHistoryKeyStorage(state, payload) {
      state.historyKeyStorage = payload || ''
    },
    saveStates(state) {
      if (state.historyKeyStorage !== '') {
        const oStatesHistory = []

        state.statesToLocalStorage.forEach(elm => {
          if (state[elm] !== undefined) {
            oStatesHistory.push({
              key: elm,
              value: state[elm],
            })
          }
        })

        localStorage.setItem(state.historyKeyStorage, JSON.stringify(oStatesHistory))
      }
    },
    initStates(state) {
      if (state.historyKeyStorage !== '') {
        if ((localStorage.getItem(state.historyKeyStorage) !== null) && (localStorage.getItem(state.historyKeyStorage) !== '')) {
          try {
            const stateCustom = JSON.parse(localStorage.getItem(state.historyKeyStorage))

            if (stateCustom.length > 0) {
              stateCustom.forEach(field => {
                if (('key' in field) && ('value' in field)) {
                  if (state.statesToLocalStorage.includes(field.key)) {
                    Object.assign(state[field.key], field.value)
                  }
                }
              })
            }
          } catch (err) {
            localStorage.removeItem(state.historyKeyStorage)
            //
          }
        }
      }
    },
    setShowListVisits(state, payload) {
      state.showListVisits.value = payload
    },
    setShowDetail(state, payload) {
      state.showDetail.value = payload
    },
    setShowForm(state, payload) {
      state.showForm.value = payload
    },
    setLoadingListviewVisits(state, payload) {
      state.loadingListviewVisits.value = payload
    },
    setListviewDataVisits(state, payload) {
      state.listviewDataVisits.value = payload
    },
    setTotalRowsVisits(state, payload) {
      state.totalRowsVisits.value = payload
    },
    setFilterDataVisits(state, payload) {
      const index = Object.keys(payload)[0]
      state[index].value = payload[index]
    },
    setFilterSelectedVisits(state, payload) {
      const index = Object.keys(payload)[0]
      state[index].value = payload[index]
    },
    setSearchTxtVisits(state, payload) {
      state.searchTxtVisits.value = payload
    },
    setCurrentPageVisits(state, payload) {
      state.currentPageVisits.value = payload
    },
    setTabSelectFilterVisits(state, payload) {
      state.tabSelectFilterVisits.value = payload
    },
    setListviewOrderByVisits(state, payload) {
      state.listviewOrderByVisits.value = {
        sort: payload.sort,
        column: payload.column,
      }
    },
    setListviewInitVisits(state, payload) {
      state.listviewInitVisits.value = payload
    },
    resetAllFilterSelectedVisits(state) {
      state.searchTxtVisits.value = ''
    },
    setProposal(state, payload) {
      state.proposal.value = payload
    },
    setProposalFlow(state, payload) {
      state.flow.value = payload
    },
    setProposalVisit(state, payload) {
      state.visit.value = payload
    },
    setProposalAgent(state, payload) {
      state.agent.value = payload
    },
    setProposalActionLead(state, payload) {
      state.action.value = payload
    },
    setProposalListing(state, payload) {
      state.listing.value = payload
    },
    setProposalListingInfo(state, payload) {
      state.listingInfo.value = payload
    },
    setIWhoami(state, payload) {
      state.iWhoami.value = payload
    },
    setProposalUser(state, payload) {
      state.user.value = payload
    },
    setProposalAgentExternal(state, payload) {
      state.agentExternal.value = payload
    },
    setProposalInfoAgentExternal(state, payload) {
      state.infoAgentExternal.value = payload
    },
    setCargos(state, payload) {
      state.cargos.value = payload
    },
    setImagesListing(state, payload) {
      state.imagesListing.value = payload
    },
    setLastEntry(state, payload) {
      state.lastEntry.value = payload
    },
    setRestoreProposalDisable(state, payload) {
      state.proposalDisable.value = payload
    },
    setSalesProcessStatus(state, payload) {
      state.salesProcessStatus.value = payload
    },
    setNotHaveUploadFiles(state, payload) {
      state.notHaveUploadFiles.value = payload
    },
    setDynamicIDVersion(state, payload) {
      state.dynamicIDVersion.value = payload
    },
    setUrlDocs(state, payload) {
      state.urlDocs.value = payload
    },
    setActivity(state, payload) {
      state.activity.value = payload
    },
    setPreview(state, payload) {
      state.imagePreview.value = payload
    },
  },
  actions: {
    // Carregar listagem
    async fetchListView({
      state, commit, dispatch,
    }, payload) {
      const listviewFilterParams = new FormData()

      commit('resetFilterSelectedTotalFields')

      // Por defeito, caso nao seja selecionada a pagina, faz sempre reset para a pagina "1"
      if ((payload !== null) && (payload !== undefined) && ('skipCurrentPage' in payload) && (payload.skipCurrentPage === true)) {
        // Fix para nao alterar a pagina que foi selecionada pelo utilizador
      } else if (state.currentPage.value !== 1) {
        commit('setCurrentPage', 1)
      }

      let loadListviewCustom = ''

      if (state.tabSelectFilter.value === 'sent') {
        loadListviewCustom = 'leads/propostas/loadListSent'
      } else if (state.tabSelectFilter.value === 'received') {
        loadListviewCustom = 'leads/propostas/loadListReceived'
      } else if (state.tabSelectFilter.value === 'approve') {
        loadListviewCustom = 'leads/propostas/loadListToApprove'
      } else if (state.tabSelectFilter.value === 'reserve') {
        loadListviewCustom = 'leads/propostas/loadListBooking'
      }

      if (state.filterSelectedEstadoPropostasSent.value !== '' && state.filterSelectedEstadoPropostasSent.value !== null && ((state.filterSelectedEstadoPropostasSent.value === 18 && state.tabSelectFilter.value === 'sent') || (state.filterSelectedEstadoPropostasReceived.value === 17 && state.tabSelectFilter.value === 'received'))) {
        loadListviewCustom = 'leads/propostas/loadListBooking'
      }

      if (state.tabSelectFilter.value === 'sent' && state.filterSelectedEstadoPropostasSent.value !== '' && state.filterSelectedEstadoPropostasSent.value !== null) {
        listviewFilterParams.append('fEstadoProposta', state.filterSelectedEstadoPropostasSent.value)
        listviewFilterParams.append('onAction', 'sent')
      } else if (state.tabSelectFilter.value === 'received' && state.filterSelectedEstadoPropostasReceived.value !== '' && state.filterSelectedEstadoPropostasReceived.value !== null) {
        listviewFilterParams.append('fEstadoProposta', state.filterSelectedEstadoPropostasReceived.value)
        listviewFilterParams.append('onAction', 'received')
      } else if (state.tabSelectFilter.value === 'approve' && state.filterSelectedEstadoPropostasApprove.value !== '' && state.filterSelectedEstadoPropostasApprove.value !== null) {
        listviewFilterParams.append('fEstadoProposta', state.filterSelectedEstadoPropostasApprove.value)
        listviewFilterParams.append('onAction', 'all')
      } else if (state.tabSelectFilter.value === 'reserve' && state.filterSelectedEstadoPropostasReserve.value !== '' && state.filterSelectedEstadoPropostasReserve.value !== null) {
        listviewFilterParams.append('fEstadoProposta', state.filterSelectedEstadoPropostasReserve.value)

        if (state.filterSelectedEstadoPropostasReserve.value === 0) {
          listviewFilterParams.append('onAction', 'all')
        } else if (state.filterSelectedEstadoPropostasReserve.value === 1) {
          listviewFilterParams.append('onAction', 'received')
        } else if (state.filterSelectedEstadoPropostasReserve.value === 2) {
          listviewFilterParams.append('onAction', 'sent')
        }
      }

      commit('addFilterSelectedTotalFields')

      if (state.tabSelectFilter.value === 'sent' && state.filterSelectedEstadoPropostasSent.value !== 18) {
        if (state.listviewOrderBySent.value.column !== undefined) {
          listviewFilterParams.append('order[0][column]', state.listviewOrderBySent.value.column)
        }

        if (state.listviewOrderBySent.value.sort !== undefined) {
          listviewFilterParams.append('order[0][dir]', state.listviewOrderBySent.value.sort)
        }
      } else if (state.tabSelectFilter.value === 'received' && state.filterSelectedEstadoPropostasSent.value !== 17) {
        if (state.listviewOrderBy.value.column !== undefined) {
          listviewFilterParams.append('order[0][column]', state.listviewOrderBy.value.column)
        }

        if (state.listviewOrderBy.value.sort !== undefined) {
          listviewFilterParams.append('order[0][dir]', state.listviewOrderBy.value.sort)
        }
      } else if (state.tabSelectFilter.value === 'approve') {
        if (state.listviewOrderByApprove.value.column !== undefined) {
          listviewFilterParams.append('order[0][column]', state.listviewOrderByApprove.value.column)
        }

        if (state.listviewOrderByApprove.value.sort !== undefined) {
          listviewFilterParams.append('order[0][dir]', state.listviewOrderByApprove.value.sort)
        }
      } else if (state.tabSelectFilter.value === 'reserve' || (state.filterSelectedEstadoPropostasSent.value === 18 && state.tabSelectFilter.value === 'sent') || (state.filterSelectedEstadoPropostasReceived.value === 17 && state.tabSelectFilter.value === 'received')) {
        if (state.listviewOrderByReserve.value.column !== undefined) {
          listviewFilterParams.append('order[0][column]', state.listviewOrderByReserve.value.column)
        }

        if (state.listviewOrderByReserve.value.sort !== undefined) {
          listviewFilterParams.append('order[0][dir]', state.listviewOrderByReserve.value.sort)
        }
      }

      if (state.searchTxt.value !== '' && state.searchTxt.value !== null) {
        listviewFilterParams.append('search[value]', state.searchTxt.value)
      }

      listviewFilterParams.append('start', ((state.currentPage.value - 1) * state.perPage.value))
      listviewFilterParams.append('length', state.perPage.value)

      if (!!payload && (payload.loadingDisable !== undefined) && (payload.loadingDisable === true)) {
        commit('setListviewInit', 1)
      } else {
        commit('setListviewData', [])
        commit('setTotalRows', 0)
        commit('setLoadingListview', true)
        commit('setListviewInit', 1)
      }

      commit('saveStates')

      await dispatch('request/cancelTokensPendingByUrl', 'leads/propostas/loadListReceived', { root: true })
      await dispatch('request/cancelTokensPendingByUrl', 'leads/propostas/loadListSent', { root: true })

      return new Promise((resolve, reject) => {
        api.post(`${apiConfig.url_base_api}${loadListviewCustom}`, listviewFilterParams)
          .then(response => {
            if (response.data.data !== undefined) {
              commit('setListviewData', response.data.data)
              commit('setTotalRows', response.data.recordsTotal)
              commit('setLoadingListview', false)
              commit('setListviewInit', 0)
              resolve(true)
            } else {
              commit('setListviewData', [])
              commit('setTotalRows', 0)
              commit('setLoadingListview', false)
              commit('setListviewInit', 0)
              reject(new Error('Problema ao carregar a listagem'))
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              commit('setListviewData', [])
              commit('setTotalRows', 0)
              commit('setLoadingListview', false)
              commit('setListviewInit', 0)
              reject(new Error(i18n.t('Problema ao carregar a listagem')))
            }
          })
      })
    },

    // Obter os filtros da listagem
    async getDataFiltersListview({ state, commit, rootGetters }, payload) {
      let pageListviewCustom = ''

      if (payload.tab === 'sent') {
        pageListviewCustom = 'leads/propostas/listSent'
      } else if (payload.tab === 'received') {
        pageListviewCustom = 'leads/propostas/listReceived'
      } else if (payload.tab === 'approve') {
        pageListviewCustom = 'leads/propostas/listToApprove'
      } else if (payload.tab === 'reserve') {
        pageListviewCustom = 'leads/propostas/listBooking'
      }

      return new Promise((resolve, reject) => {
        api.get(`${apiConfig.url_base_api}${pageListviewCustom}`)
          .then(response => {
            try {
              if (response.data.data !== undefined) {
                if ([5, 6, 9, 10, 13, 11, 12].includes(Number(rootGetters['auth/currentUser'].id_role))) {
                  if (typeof state.filterDataTabsListview.find(o => o.name === 'approve') === 'undefined' && typeof response.data.data.proposalsToApproveAll !== 'undefined' && Number(response.data.data.proposalsToApproveAll) !== 0) {
                    state.filterDataTabsListview.push({ name: 'approve', text: i18n.t('Por aprovar') })
                  }

                  if (typeof state.aFieldsOrder.find(o => Number(o.name) === 6) === 'undefined') {
                    state.aFieldsOrder = state.aFieldsOrderP
                    state.aFieldsOrderSent = state.aFieldsOrderP
                    state.listviewOrderBySent.value = { column: '4', sort: 'desc' }
                  }
                }

                if ([1, 4, 5, 6, 7, 9, 10, 13, 11, 12].includes(Number(rootGetters['auth/currentUser'].id_role))) {
                  if (typeof state.filterDataTabsListview.find(o => o.name === 'reserve') === 'undefined' && typeof response.data.data.proposalsToConcluir !== 'undefined' && Number(response.data.data.proposalsToConcluir) !== 0) {
                    state.filterDataTabsListview.push({ name: 'reserve', text: i18n.t('Reservas por concluir') })
                  }
                }

                if (typeof response.data.data.proposalsToApprove !== 'undefined') {
                  commit('setFilterData', { proposalsToApprove: response.data.data.proposalsToApprove })

                  if (Number(response.data.data.proposalsToApproveAll) === 0) {
                    state.filterDataEstadoPropostasReceived.forEach((item, index) => {
                      if (Number(item.id) === 15) {
                        state.filterDataEstadoPropostasReceived.splice(index, 1)
                      }
                    })

                    state.filterDataEstadoPropostasSent.forEach((item, index) => {
                      if (Number(item.id) === 16) {
                        state.filterDataEstadoPropostasSent.splice(index, 1)
                      }
                    })
                  }
                }

                if (typeof response.data.data.proposalsToConcluir !== 'undefined') {
                  commit('setFilterData', { proposalsToConcluir: response.data.data.proposalsToConcluir })

                  if (Number(response.data.data.proposalsToConcluir) === 0) {
                    state.filterDataEstadoPropostasReceived.forEach((item, index) => {
                      if (Number(item.id) === 17) {
                        state.filterDataEstadoPropostasReceived.splice(index, 1)
                      }
                    })

                    state.filterDataEstadoPropostasSent.forEach((item, index) => {
                      if (Number(item.id) === 18) {
                        state.filterDataEstadoPropostasSent.splice(index, 1)
                      }
                    })
                  }
                }

                if (typeof response.data.data.proposalsToApproveAll !== 'undefined') {
                  commit('setFilterData', { proposalsToApproveAll: response.data.data.proposalsToApproveAll })
                }

                if (typeof response.data.data.proposalsToConcluirAll !== 'undefined') {
                  commit('setFilterData', { proposalsToConcluirAll: response.data.data.proposalsToConcluirAll })
                }

                if (typeof response.data.data.proposalsToPaymentPending !== 'undefined') {
                  commit('setFilterData', { proposalsToPaymentPending: response.data.data.proposalsToPaymentPending })

                  if (Number(response.data.data.proposalsToPaymentPending) === 0) {
                    state.filterDataEstadoPropostasReceived.forEach((item, index) => {
                      if (Number(item.id) === 24) {
                        state.filterDataEstadoPropostasReceived.splice(index, 1)
                      }
                    })

                    state.filterDataEstadoPropostasSent.forEach((item, index) => {
                      if (Number(item.id) === 25) {
                        state.filterDataEstadoPropostasSent.splice(index, 1)
                      }
                    })
                  }
                }
              }

              commit('setFilterData', { filterDataLoad: true })
              resolve(true)
            } catch (error) {
              if ((error.response !== undefined) && (error.response.status !== undefined)) {
                reject(new Error(i18n.t('Problema ao carregar o filtro')))
              }
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao carregar o filtro')))
            }
          })
      })
    },

    // Carregar listagem das visitas
    async fetchListViewVisits({
      state, commit, dispatch,
    }, payload) {
      const listviewFilterParams = new FormData()

      // Por defeito, caso nao seja selecionada a pagina, faz sempre reset para a pagina "1"
      if ((payload !== null) && (payload !== undefined) && ('skipCurrentPage' in payload) && (payload.skipCurrentPage === true)) {
      // Fix para nao alterar a pagina que foi selecionada pelo utilizador
      } else if (state.currentPageVisits.value !== 1) {
        commit('setCurrentPageVisits', 1)
      }

      listviewFilterParams.append('fEstadoVisita', state.tabSelectFilterVisits.value)

      if (state.listviewOrderByVisits.value.column !== undefined) {
        listviewFilterParams.append('order[0][column]', state.listviewOrderByVisits.value.column)
      }

      if (state.listviewOrderByVisits.value.sort !== undefined) {
        listviewFilterParams.append('order[0][dir]', state.listviewOrderByVisits.value.sort)
      }

      if (state.searchTxtVisits.value !== '' && state.searchTxtVisits.value !== null) {
        listviewFilterParams.append('search[value]', state.searchTxtVisits.value)
      }

      listviewFilterParams.append('start', ((state.currentPageVisits.value - 1) * state.perPageVisits.value))
      listviewFilterParams.append('length', state.perPageVisits.value)

      if (!!payload && (payload.loadingDisable !== undefined) && (payload.loadingDisable === true)) {
        commit('setListviewInitVisits', 1)
      } else {
        commit('setListviewDataVisits', [])
        commit('setTotalRowsVisits', 0)
        commit('setLoadingListviewVisits', true)
        commit('setListviewInitVisits', 1)
      }

      await dispatch('request/cancelTokensPendingByUrl', 'leads/visitas/loadListToProposals', { root: true })

      return new Promise((resolve, reject) => {
        api.post(`${apiConfig.url_base_api}leads/visitas/loadListToProposals`, listviewFilterParams)
          .then(response => {
            if (response.data.data !== undefined) {
              commit('setListviewDataVisits', response.data.data)
              commit('setTotalRowsVisits', response.data.recordsTotal)
              commit('setLoadingListviewVisits', false)
              commit('setListviewInitVisits', 0)
              resolve(true)
            } else {
              commit('setListviewDataVisits', [])
              commit('setTotalRowsVisits', 0)
              commit('setLoadingListviewVisits', false)
              commit('setListviewInitVisits', 0)
              reject(new Error('Problema ao carregar a listagem'))
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              commit('setListviewDataVisits', [])
              commit('setTotalRowsVisits', 0)
              commit('setLoadingListviewVisits', false)
              commit('setListviewInitVisits', 0)
              reject(new Error(i18n.t('Problema ao carregar a listagem')))
            }
          })
      })
    },

    // Carregar a informação detalhada da proposta
    async previewModalProposal({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const formData = new FormData()
        formData.append('sw130s01', payload.sw130s01 || '')
        formData.append('action', payload.action || '')
        formData.append('options', payload.options || '')

        api.post(`${apiConfig.url_base_api}leads/propostas/previewModalProposal`, formData)
          .then(response => {
            try {
              if (typeof response.data === 'object') {
                if (response.data.error === 1) {
                  reject(new Error(response.data.msg))
                } else {
                  commit('setProposal', response.data.data.sw130)
                  commit('setProposalFlow', response.data.data.sw131)
                  commit('setProposalAgent', response.data.data.consultor)
                  commit('setProposalListing', response.data.data.sw012)
                  commit('setProposalListingInfo', response.data.data.sw012Info)
                  commit('setIWhoami', response.data.data.iWhoami)
                  commit('setCargos', response.data.data.cargos)
                  commit('setImagesListing', response.data.data.sw014)
                  commit('setLastEntry', response.data.data.lastEntrySw131)
                  commit('setUrlDocs', response.data.data.urlDocsProposal)
                  commit('setPreview', response.data.data.imagePreviewListing)

                  if (typeof response.data.data.sw001 !== 'undefined') {
                    commit('setProposalUser', response.data.data.sw001)
                  } else {
                    commit('setProposalUser', {})
                  }

                  if (typeof response.data.data.sw025 !== 'undefined') {
                    commit('setProposalAgentExternal', response.data.data.sw025)
                  } else {
                    commit('setProposalAgentExternal', {})
                  }

                  if (typeof response.data.data.infoAgenteSW025 !== 'undefined') {
                    commit('setProposalInfoAgentExternal', response.data.data.infoAgenteSW025)
                  } else {
                    commit('setProposalInfoAgentExternal', {})
                  }

                  if (typeof response.data.data.restoreProposalDisable !== 'undefined') {
                    commit('setRestoreProposalDisable', response.data.data.restoreProposalDisable)
                  } else {
                    commit('setRestoreProposalDisable', false)
                  }

                  if (typeof response.data.data.salesProcessStatus !== 'undefined') {
                    commit('setSalesProcessStatus', response.data.data.salesProcessStatus)
                  } else {
                    commit('setSalesProcessStatus', '')
                  }

                  if (typeof response.data.data.notHaveUploadFiles !== 'undefined') {
                    commit('setNotHaveUploadFiles', response.data.data.notHaveUploadFiles)
                  } else {
                    commit('setNotHaveUploadFiles', '')
                  }

                  if (typeof response.data.data.dynamicIDVersion !== 'undefined') {
                    commit('setDynamicIDVersion', response.data.data.dynamicIDVersion)
                  } else {
                    commit('setDynamicIDVersion', '')
                  }

                  if (typeof response.data.data.actionAccessByLead !== 'undefined') {
                    commit('setProposalActionLead', response.data.data.actionAccessByLead)
                  } else {
                    commit('setProposalActionLead', {})
                  }

                  if (typeof response.data.data.sw070 !== 'undefined' && Object.keys(response.data.data.sw070).length > 0) {
                    commit('setProposalVisit', response.data.data.sw070)
                  } else {
                    commit('setProposalVisit', {})
                  }

                  commit('setLoadingListview', false)

                  resolve(true)
                }
              } else {
                reject(new Error(i18n.t('Problema ao obter a informação')))
              }
            } catch (error) {
              if ((error.response !== undefined) && (error.response.status !== undefined)) {
                reject(new Error(i18n.t('Problema ao obter a informação')))
              }
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao obter a informação')))
            }
          })
      })
    },

    // Carregar a informação detalhada da proposta
    async flowProposalObjects({ commit, state }, payload) {
      return new Promise((resolve, reject) => {
        const formData = new FormData()
        formData.append('sw130s01', payload.sw130s01 || '')
        formData.append('action', payload.action || '')

        api.post(`${apiConfig.url_base_api}leads/propostas/flowProposalObjects`, formData)
          .then(response => {
            try {
              if (typeof response.data === 'object') {
                if (response.data.error === 1) {
                  reject(new Error(response.data.msg))
                } else {
                  commit('setProposal', response.data.data.sw130)
                  commit('setProposalFlow', response.data.data.sw131)
                  commit('setProposalVisit', response.data.data.sw070)
                  commit('setProposalAgent', response.data.data.consultor)
                  commit('setProposalListing', response.data.data.sw012)
                  commit('setIWhoami', response.data.data.iWhoami)
                  commit('setCargos', response.data.data.cargos)
                  commit('setUrlDocs', response.data.data.urlDocsProposal)

                  if (typeof response.data.data.sw001 !== 'undefined') {
                    commit('setProposalUser', response.data.data.sw001)
                  }

                  if (typeof response.data.data.sw025 !== 'undefined') {
                    commit('setProposalAgentExternal', response.data.data.sw025)
                  }

                  if (typeof response.data.data.infoAgenteSW025 !== 'undefined') {
                    commit('setProposalInfoAgentExternal', response.data.data.infoAgenteSW025)
                  }

                  if (typeof response.data.data.sw012Info !== 'undefined') {
                    commit('setProposalListingInfo', response.data.data.sw012Info)
                  }

                  if (typeof response.data.data.sw014 !== 'undefined') {
                    commit('setImagesListing', response.data.data.sw014)
                  }

                  if (typeof response.data.data.tEntrySw131 !== 'undefined') {
                    commit('setLastEntry', response.data.data.tEntrySw131)
                  }

                  if (typeof response.data.data.restoreProposalDisable !== 'undefined') {
                    commit('setRestoreProposalDisable', response.data.data.restoreProposalDisable)
                  }

                  if (typeof response.data.data.salesProcessStatus !== 'undefined') {
                    commit('setSalesProcessStatus', response.data.data.salesProcessStatus)
                  }

                  if (typeof response.data.data.notHaveUploadFiles !== 'undefined') {
                    commit('setNotHaveUploadFiles', response.data.data.notHaveUploadFiles)
                  }

                  if (typeof response.data.data.dynamicIDVersion !== 'undefined') {
                    commit('setDynamicIDVersion', response.data.data.dynamicIDVersion)
                  }

                  if (typeof response.data.data.actionAccessByLead !== 'undefined') {
                    commit('setProposalActionLead', response.data.data.actionAccessByLead)
                  } else {
                    commit('setProposalActionLead', {})
                  }

                  if (typeof response.data.data.sw012.sw012v330 !== 'undefined') {
                    state.listing.value.sw012s330 = response.data.data.sw012.sw012v330
                  }

                  if (typeof response.data.data.sw012.sw012v64 !== 'undefined') {
                    state.listing.value.sw012s64 = response.data.data.sw012.sw012v64
                  }

                  resolve(true)
                }
              } else {
                reject(new Error(i18n.t('Problema ao obter a informação')))
              }
            } catch (error) {
              if ((error.response !== undefined) && (error.response.status !== undefined)) {
                reject(new Error(i18n.t('Problema ao obter a informação')))
              }
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao obter a informação')))
            }
          })
      })
    },

    // Verifica o estado da proposta
    async checkProposalsState({ state }, payload) {
      return new Promise((resolve, reject) => {
        const formData = new FormData()
        formData.append('sw130s01', btoa(state.proposal.value.sw130s01))
        formData.append('cleanAction', payload.cleanAction)

        if (Number(state.iWhoami.value) === 1 || Number(state.iWhoami.value) === 3 || Number(state.iWhoami.value) === 5) {
          formData.append('action', 'sent')
        } else if (Number(state.iWhoami.value) === 2 || Number(state.iWhoami.value) === 3 || Number(state.iWhoami.value) === 4) {
          formData.append('action', 'received')
        } else {
          formData.append('action', 'all')
        }

        if (typeof payload.sw131s20 !== 'undefined') {
          formData.append('sw131s20', payload.sw131s20)
        }

        api.post(`${apiConfig.url_base_api}leads/propostas/checkProposalsState`, formData)
          .then(response => {
            try {
              if (typeof response.data === 'object') {
                if (response.data.error === 1) {
                  reject(new Error(response.data.msg))
                } else {
                  resolve(response.data)
                }
              } else {
                reject(new Error(i18n.t('Problema ao obter a informação')))
              }
            } catch (error) {
              if ((error.response !== undefined) && (error.response.status !== undefined)) {
                reject(new Error(i18n.t('Problema ao obter a informação')))
              }
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao obter a informação')))
            }
          })
      })
    },

    // Modal para recusa da proposta
    async getModalRefuseProposal(state, payload) {
      return new Promise((resolve, reject) => {
        const formData = new FormData()
        formData.append('refuseMultiple', payload.refuseMultiple)
        formData.append('sw130s01', payload.sw130s01)

        api.post(`${apiConfig.url_base_api}leads/propostas/modalRefuseProposal`, formData)
          .then(response => {
            try {
              if (typeof response.data === 'object') {
                if (response.data.error === 1) {
                  reject(new Error(response.data.msg))
                } else {
                  resolve(response.data)
                }
              } else {
                reject(new Error(i18n.t('Problema ao obter modal da recusa da proposta')))
              }
            } catch (error) {
              if ((error.response !== undefined) && (error.response.status !== undefined)) {
                reject(new Error(i18n.t('Problema ao obter modal da recusa da proposta')))
              }
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao obter modal da recusa da proposta')))
            }
          })
      })
    },

    // Aceitar a proposta
    async acceptProposal({ state }, payload) {
      return new Promise((resolve, reject) => {
        const formData = new FormData()
        formData.append('sw130s01', btoa(state.proposal.value.sw130s01))
        formData.append('dynamicIDVersion', state.dynamicIDVersion.value)
        formData.append('refuseMultiple', payload.refuseMultiple)
        formData.append('sFiles', payload.sFiles)
        formData.append('sLegendas', payload.sLegendas)

        if (typeof payload.sw131s20 !== 'undefined') {
          formData.append('sw131s20', payload.sw131s20)
        }

        if (Number(state.iWhoami.value) === 1 || Number(state.iWhoami.value) === 3 || Number(state.iWhoami.value) === 5) {
          formData.append('action', 'sent')
        } else if (Number(state.iWhoami.value) === 2 || Number(state.iWhoami.value) === 3 || Number(state.iWhoami.value) === 4) {
          formData.append('action', 'received')
        } else {
          formData.append('action', 'all')
        }

        api.post(`${apiConfig.url_base_api}leads/propostas/acceptProposal`, formData)
          .then(response => {
            try {
              if (typeof response.data === 'object') {
                if (response.data.error === 1) {
                  reject(new Error(response.data.msg))
                } else {
                  resolve(true)
                }
              } else {
                reject(new Error(i18n.t('Problema a aceitar a proposta')))
              }
            } catch (error) {
              if ((error.response !== undefined) && (error.response.status !== undefined)) {
                reject(new Error(i18n.t('Problema a aceitar a proposta')))
              }
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema a aceitar a proposta')))
            }
          })
      })
    },

    // Recusar a proposta
    async refuseProposal({ state }, payload) {
      return new Promise((resolve, reject) => {
        const formData = new FormData()
        formData.append('sw130s01', btoa(state.proposal.value.sw130s01))
        formData.append('sw130s01Temp', payload.sw130s01Temp)
        formData.append('dynamicIDVersion', state.dynamicIDVersion.value)
        formData.append('sw131s16', payload.sw131s16)
        formData.append('sw131s17', payload.sw131s17)
        formData.append('refuseMultiple', payload.refuseMultiple)
        formData.append('sFiles', payload.sFiles)
        formData.append('sLegendas', payload.sLegendas)

        if (typeof payload.sw131s20 !== 'undefined') {
          formData.append('sw131s20', payload.sw131s20)
        }

        if (Number(state.iWhoami.value) === 1 || Number(state.iWhoami.value) === 3 || Number(state.iWhoami.value) === 5) {
          formData.append('action', 'sent')
        } else if (Number(state.iWhoami.value) === 2 || Number(state.iWhoami.value) === 3 || Number(state.iWhoami.value) === 4) {
          formData.append('action', 'received')
        } else {
          formData.append('action', 'all')
        }

        api.post(`${apiConfig.url_base_api}leads/propostas/refuseProposal`, formData)
          .then(response => {
            try {
              if (typeof response.data === 'object') {
                if (response.data.error === 1) {
                  reject(new Error(response.data.message))
                } else {
                  resolve(response.data)
                }
              } else {
                reject(new Error(i18n.t('Problema a recusar a proposta')))
              }
            } catch (error) {
              if ((error.response !== undefined) && (error.response.status !== undefined)) {
                reject(new Error(i18n.t('Problema a recusar a proposta')))
              }
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema a recusar a proposta')))
            }
          })
      })
    },

    // Cancela a proposta
    async cancelProposal({ state }, payload) {
      return new Promise((resolve, reject) => {
        const formData = new FormData()
        formData.append('sw130s01', btoa(state.proposal.value.sw130s01))
        formData.append('sw131s17', payload.sw131s17)
        formData.append('sw131s20', payload.sw131s20)
        formData.append('sw012s64', payload.sw012s64)
        formData.append('sFiles', payload.sFiles)
        formData.append('sLegendas', payload.sLegendas)

        if (Number(state.iWhoami.value) === 1 || Number(state.iWhoami.value) === 3 || Number(state.iWhoami.value) === 5) {
          formData.append('action', 'sent')
        } else if (Number(state.iWhoami.value) === 2 || Number(state.iWhoami.value) === 3 || Number(state.iWhoami.value) === 4) {
          formData.append('action', 'received')
        } else {
          formData.append('action', 'all')
        }

        api.post(`${apiConfig.url_base_api}leads/propostas/cancelProposal`, formData)
          .then(response => {
            try {
              if (typeof response.data === 'object') {
                if (response.data.error === 1) {
                  reject(new Error(response.data.message))
                } else {
                  resolve(response.data)
                }
              } else {
                reject(new Error(i18n.t('Problema a cancelar a proposta')))
              }
            } catch (error) {
              if ((error.response !== undefined) && (error.response.status !== undefined)) {
                reject(new Error(i18n.t('Problema a cancelar a proposta')))
              }
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema a cancelar a proposta')))
            }
          })
      })
    },

    // Save novo fluxo proposta
    async saveNewFlowProposal({ state }, payload) {
      const formData = new FormData()

      formData.append('sw130s01', payload.sw130s01)
      formData.append('sw130s02', btoa(state.visit.value.sw070s01))
      formData.append('sw130s03', state.visit.value.sw070s10)
      formData.append('sw130s04', btoa(state.visit.value.sw012v01))
      formData.append('sw130s06', btoa(state.visit.value.sw025s01))

      if (payload.reforcos.length > 0) {
        payload.reforcos.forEach(item => {
          if (item.valor !== '' && item.data !== '') {
            formData.append('sw132s03[]', item.valor)
            formData.append('sw132s04[]', item.data)
          }
        })
      }

      if (payload.files.length > 0) {
        payload.files.forEach(item => {
          if (item.name !== '') {
            formData.append('sw131s09[]', item.name)
            formData.append('fileLegenda[]', item.legend)
          }
        })
      }

      Object.entries(payload.formDados).forEach(row => {
        const [name, value] = row
        if (name !== 'sw131s03') {
          if (name === 'sw130s09') {
            formData.append(name, value.sw129s01)
          } else {
            formData.append(name, value)
          }
        } else {
          formData.append(name, value.sw007s01)
        }
      })

      if (Number(state.iWhoami.value) === 1 || Number(state.iWhoami.value) === 3 || Number(state.iWhoami.value) === 5) {
        formData.append('action', 'sent')
      } else if (Number(state.iWhoami.value) === 2 || Number(state.iWhoami.value) === 3 || Number(state.iWhoami.value) === 4) {
        formData.append('action', 'received')
      } else {
        formData.append('action', 'all')
      }

      return new Promise((resolve, reject) => {
        api.post(`${apiConfig.url_base_api}leads/propostas/saveNewFlow`, formData)
          .then(response => {
            if (typeof response.data === 'object') {
              resolve(response.data)
            } else {
              reject(new Error(i18n.t('Problema ao submeter contra-proposta')))
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao submeter proposta')))
            }
          })
      })
    },

    // Contra-proposta
    async counterProposal({ state }, payload) {
      return new Promise((resolve, reject) => {
        const formData = new FormData()
        formData.append('sw130s01', btoa(state.proposal.value.sw130s01))
        formData.append('sw131s16', payload.sw131s16)
        formData.append('sw131s17', payload.sw131s17)
        formData.append('sFiles', payload.sFiles)
        formData.append('sLegendas', payload.sLegendas)

        if (typeof payload.sw131s20 !== 'undefined') {
          formData.append('sw131s20', payload.sw131s20)
        }

        if (Number(state.iWhoami.value) === 1 || Number(state.iWhoami.value) === 3 || Number(state.iWhoami.value) === 5) {
          formData.append('action', 'sent')
        } else if (Number(state.iWhoami.value) === 2 || Number(state.iWhoami.value) === 3 || Number(state.iWhoami.value) === 4) {
          formData.append('action', 'received')
        } else {
          formData.append('action', 'all')
        }

        api.post(`${apiConfig.url_base_api}leads/propostas/counterProposal`, formData)
          .then(response => {
            try {
              if (typeof response.data === 'object') {
                if (response.data.error === 1) {
                  reject(new Error(response.data.msg))
                } else {
                  resolve(true)
                }
              } else {
                reject(new Error(i18n.t('Problema a fazer a contra-proposta')))
              }
            } catch (error) {
              if ((error.response !== undefined) && (error.response.status !== undefined)) {
                reject(new Error(i18n.t('Problema a fazer a contra-proposta')))
              }
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema a fazer a contra-proposta')))
            }
          })
      })
    },

    // Upload documentos na proposta
    async addDocsProposal({ state }, payload) {
      return new Promise((resolve, reject) => {
        const formData = new FormData()
        formData.append('sw130s01', btoa(state.proposal.value.sw130s01))
        formData.append('sw131s20', payload.sw131s20)
        formData.append('addDocsToProposal', payload.addDocsToProposal)
        formData.append('sFiles', payload.sFiles)
        formData.append('sLegendas', payload.sLegendas)

        if (Number(state.iWhoami.value) === 1 || Number(state.iWhoami.value) === 3 || Number(state.iWhoami.value) === 5) {
          formData.append('action', 'sent')
        } else if (Number(state.iWhoami.value) === 2 || Number(state.iWhoami.value) === 3 || Number(state.iWhoami.value) === 4) {
          formData.append('action', 'received')
        } else {
          formData.append('action', 'all')
        }

        api.post(`${apiConfig.url_base_api}leads/propostas/acceptProposal`, formData)
          .then(response => {
            try {
              if (typeof response.data === 'object') {
                if (response.data.error === 1) {
                  reject(new Error(response.data.message))
                } else {
                  resolve(true)
                }
              } else {
                reject(new Error(i18n.t('Problema a adicionar documentos na proposta')))
              }
            } catch (error) {
              if ((error.response !== undefined) && (error.response.status !== undefined)) {
                reject(new Error(i18n.t('Problema a adicionar documentos na proposta')))
              }
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema a adicionar documentos na proposta')))
            }
          })
      })
    },

    // Carrega o registo da atividade da proposta
    async loadActivityProposal({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const formData = new FormData()
        formData.append('sw130s01', payload.sw130s01)
        formData.append('order[0][column]', '1')
        formData.append('order[0][dir]', 'desc')

        api.post(`${apiConfig.url_base_api}leads/propostas/ProposalHistoryActivityLoad`, formData)
          .then(response => {
            try {
              if (typeof response.data.data === 'object') {
                commit('setActivity', response.data.data)
                resolve(true)
              } else {
                reject(new Error(i18n.t('Problema a carregar o registo de atividade')))
              }
            } catch (error) {
              if ((error.response !== undefined) && (error.response.status !== undefined)) {
                reject(new Error(i18n.t('Problema a carregar o registo de atividade')))
              }
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema a carregar o registo de atividade')))
            }
          })
      })
    },

    // Ordenação da listagem
    listviewFilterOrderBy({ state, commit, dispatch }, field) {
      if ((!!state.listviewOrderBy.value.column) && (state.listviewOrderBy.value.column === field)) {
        if (state.listviewOrderBy.value.sort === 'asc') {
          commit('setListviewOrderBy', {
            sort: 'desc',
            column: field,
          })
        } else {
          commit('setListviewOrderBy', {
            sort: 'asc',
            column: field,
          })
        }
      } else {
        commit('setListviewOrderBy', {
          sort: 'asc',
          column: field,
        })
      }

      commit('setCurrentPage', 1)

      return dispatch('fetchListView')
    },

    // Ordenação da listagem propostas efectuadas
    listviewFilterOrderBySent({ state, commit, dispatch }, field) {
      if ((!!state.listviewOrderBySent.value.column) && (state.listviewOrderBySent.value.column === field)) {
        if (state.listviewOrderBySent.value.sort === 'asc') {
          commit('setListviewOrderBySent', {
            sort: 'desc',
            column: field,
          })
        } else {
          commit('setListviewOrderBySent', {
            sort: 'asc',
            column: field,
          })
        }
      } else {
        commit('setListviewOrderBySent', {
          sort: 'asc',
          column: field,
        })
      }

      commit('setCurrentPage', 1)

      return dispatch('fetchListView')
    },

    // Ordenação da listagem propostas por aprovar
    listviewFilterOrderByApprove({ state, commit, dispatch }, field) {
      if ((!!state.listviewOrderByApprove.value.column) && (state.listviewOrderByApprove.value.column === field)) {
        if (state.listviewOrderByApprove.value.sort === 'asc') {
          commit('setListviewOrderByApprove', {
            sort: 'desc',
            column: field,
          })
        } else {
          commit('setListviewOrderByApprove', {
            sort: 'asc',
            column: field,
          })
        }
      } else {
        commit('setListviewOrderByApprove', {
          sort: 'asc',
          column: field,
        })
      }

      commit('setCurrentPage', 1)

      return dispatch('fetchListView')
    },

    // Ordenação da listagem propostas reservadas
    listviewFilterOrderByReserve({ state, commit, dispatch }, field) {
      if ((!!state.listviewOrderByReserve.value.column) && (state.listviewOrderByReserve.value.column === field)) {
        if (state.listviewOrderByReserve.value.sort === 'asc') {
          commit('setListviewOrderByReserve', {
            sort: 'desc',
            column: field,
          })
        } else {
          commit('setListviewOrderByReserve', {
            sort: 'asc',
            column: field,
          })
        }
      } else {
        commit('setListviewOrderByReserve', {
          sort: 'asc',
          column: field,
        })
      }

      commit('setCurrentPage', 1)

      return dispatch('fetchListView')
    },

    // Ordenação da listagem das visitas
    listviewFilterOrderByVisits({ state, commit, dispatch }, field) {
      if ((!!state.listviewOrderByVisits.value.column) && (state.listviewOrderByVisits.value.column === field)) {
        if (state.listviewOrderByVisits.value.sort === 'asc') {
          commit('setListviewOrderByVisits', {
            sort: 'desc',
            column: field,
          })
        } else {
          commit('setListviewOrderByVisits', {
            sort: 'asc',
            column: field,
          })
        }
      } else {
        commit('setListviewOrderByVisits', {
          sort: 'asc',
          column: field,
        })
      }

      commit('setCurrentPageVisits', 1)

      return dispatch('fetchListViewVisits')
    },

    setSearchTxt({ commit, dispatch }, value) {
      commit('setSearchTxt', value)
      commit('setCurrentPage', 1)
      return dispatch('fetchListView', { loadingDisable: true })
    },

    setCurrentPage({ commit, dispatch }, value) {
      commit('setCurrentPage', value)
      return dispatch('fetchListView', { skipCurrentPage: true })
    },

    setSearchTxtVisits({ commit, dispatch }, value) {
      commit('setSearchTxtVisits', value)
      commit('setCurrentPageVisits', 1)
      return dispatch('fetchListViewVisits', { loadingDisable: true })
    },

    setCurrentPageVisits({ commit, dispatch }, value) {
      commit('setCurrentPageVisits', value)
      return dispatch('fetchListViewVisits', { skipCurrentPage: true })
    },

    setShowListVisits({ commit, dispatch }, value) {
      commit('setShowListVisits', value)
      if (value === true) {
        return dispatch('fetchListViewVisits', { loadingDisable: true })
      }
      return ''
    },

    setShowDetail({ commit }, value) {
      commit('setShowDetail', value)
    },

    pullToRefreshListview({ commit, dispatch }) {
      commit('setCurrentPage', 1)
      return dispatch('fetchListView', { loadingDisable: true })
    },

    // Metodos do filtro lateral - Escolher separador
    listviewFilterTab({ commit, dispatch }, tab) {
      commit('setTabSelectFilter', tab)
      commit('resetAllFilterSelected')
      return dispatch('fetchListView')
    },

    // Metodos do filtro lateral - Escolher separador visitas
    listviewFilterTabVisits({ commit, dispatch }, tab) {
      commit('setTabSelectFilterVisits', tab)
      commit('resetAllFilterSelectedVisits')
      return dispatch('fetchListViewVisits')
    },

    // Metodos do filtro lateral - Filtro geral dos blocos
    listviewFilterGeral({ commit, dispatch }, params) {
      try {
        commit('setFilterSelected', params)
        return dispatch('fetchListView')
      } catch {
        return null
      }
    },

    // Limpar todos os filtros selecionados
    resetListviewFilterGeral({ commit, dispatch }) {
      commit('resetAllFilterSelected')
      return dispatch('fetchListView')
    },

    setProposalDetail({ commit }, params) {
      commit('setShowDetail', params.showDetail)
    },

    setShowForm({ commit }, value) {
      commit('setShowForm', value)
    },

  },
}
