import { ref } from '@vue/composition-api'
import i18n from '@/libs/i18n'

const defaultState = () => ({

  // variaveis dos selecionados
  listviewSelectedVisita: ref(0),
  listviewSelectedSeparador: ref(2),
  listviewSelectedHubs: ref(''),
  listviewSelectedFilter: ref(['all']),
  listviewSelectedResponsavel: ref(''),

  // variaveis dos filtros
  listviewFilterSeparadores: ([
    {
      key: 2,
      text: i18n.t('Agendadas'),
      show: true,
    },
    {
      key: 3,
      text: i18n.t('Concluídas com relatório'),
      show: true,
    },
    {
      key: 1,
      text: i18n.t('Concluídas sem relatório'),
      show: true,
    },
    {
      key: 4,
      text: i18n.t('Canceladas'),
      show: true,
    },
  ]),
  listviewFilterHubs: ref([]),
  listviewFilterGeral: ref([]),
  listviewFilterResponsaveis: ref([]),

  // Variaveis gerais da listagem
  listviewInit: ref(1),
  listviewData: ref([]),
  positionListview: {},

  viewInfoDataSelected: ref({}),
  tabSelectFilter: ref(2),
  loadingListview: ref(true),
  perPage: ref(25),
  totalRows: ref(0),
  currentPage: ref(1),
  perPageOptions: ref([10, 25, 50, 100]),
  searchTxt: ref(''),
  listviewOrderBy: ref({
    column: '6',
    sort: 'asc',
  }),
  listview_txt_default: i18n.t('Nenhum resultado disponível'),
  listiview_txt_not_find: i18n.t('Não encontrou nenhum resultado'),
  listiview_txt_search: i18n.t('ZMID ou código'),
  listview_txt_loading: i18n.t('A carregar registos ...'),
  aFieldsOrder: [
    {
      name: '0',
      txt: i18n.t('Ordenar pelo ID Visita'),
    },
    {
      name: '1',
      txt: i18n.t('Ordenar pelo ZMID'),
    },
    {
      name: '2',
      txt: i18n.t('Ordenar pelo Angariador'),
    },
    {
      name: '3',
      txt: i18n.t('Ordenar pelo Lead/Consultor'),
    },
    {
      name: '4',
      txt: i18n.t('Ordenar pelo Telemóvel'),
    },
    {
      name: '5',
      txt: i18n.t('Ordenar pela Data de registo'),
    },
    {
      name: '6',
      txt: i18n.t('Ordenar pela Data da visita'),
    },
  ],
  aFieldsOrderConcluidos: [
    {
      name: '0',
      txt: i18n.t('Ordenar pelo ID Visita'),
    },
    {
      name: '1',
      txt: i18n.t('Ordenar pelo ZMID'),
    },
    {
      name: '2',
      txt: i18n.t('Ordenar pelo Angariador'),
    },
    {
      name: '3',
      txt: i18n.t('Ordenar pelo Lead/Consultor'),
    },
    {
      name: '4',
      txt: i18n.t('Ordenar pelo Telemóvel'),
    },
    {
      name: '5',
      txt: i18n.t('Ordenar pela Data de conclusão'),
    },
    {
      name: '6',
      txt: i18n.t('Ordenar pela Data da visita'),
    },
  ],
  filterSelectedTotalFields: ref(0),
  listviewSelectedFields: ref(0),
  formData070: ref([]),
  formData073: ref([]),
  forceHideButtonsVisita: true,

  // States para guardar em localstorage
  historyKeyStorage: '',
  statesToLocalStorage: [
    'listviewSelectedSeparador',
    'listviewSelectedHubs',
    'listviewSelectedFilter',
    'listviewSelectedResponsavel',
    'tabSelectFilter',
    'currentPage',
    'searchTxt',
    'listviewOrderBy',
    'positionListview',
  ],

})

export default defaultState
