import api from '@core-custom/api/api'
import apiConfig from '@core-custom/api/apiConfig'
import i18n from '@/libs/i18n'
import defaultState from './defaultState'

export default {
  namespaced: true,
  state: defaultState,
  getters: {
    data: state => ({
      estatisticaAngElse: state.estatisticaAngElse.value,
      estatisticaAng: state.estatisticaAng.value,
      estatisticasSite: state.estatisticasSite.value,
      estatisticaWebsiteUnicos: state.estatisticaWebsiteUnicos.value,
      estatisticaWebsiteTotais: state.estatisticaWebsiteTotais.value,
    }),
  },
  mutations: {
    resetState(state) {
      Object.assign(state, defaultState)
    },
    setEstatisticaAngElse(state, payload) {
      state.estatisticaAngElse.value = payload
    },
    setEstatisticaAng(state, payload) {
      state.estatisticaAng.value = payload
    },
    setEstatisticasSite(state, payload) {
      state.estatisticasSite.value = payload
    },
    setEstatisticaWebsiteUnicos(state, payload) {
      state.estatisticaWebsiteUnicos.value = payload
    },
    setEstatisticaWebsiteTotais(state, payload) {
      state.estatisticaWebsiteTotais.value = payload
    },
  },
  actions: {

    // Carrega os dados das estatisticas da APP e Website
    async loadEstatisticas({ rootGetters, commit }, payload) {
      const formData = new FormData()

      formData.append('sw012s01', rootGetters['listings_form/keyReg'])
      formData.append('tab', payload.tab)

      return new Promise((resolve, reject) => {
        api.post(`${apiConfig.url_base_api}listings/getContentByTabFormListing`, formData)
          .then(response => {
            if (typeof response.data !== 'object') {
              reject(new Error(i18n.t('Problema ao carregar dados das estatísticas')))
            } else {
              if (payload.tab === 'estatisticaMYONETOOL') {
                commit('setEstatisticaAngElse', response.data.data.aEstatisticaAngElse)
                commit('setEstatisticaAng', response.data.data.estatisticaAng)
              } else {
                commit('setEstatisticasSite', response.data.data.aEstatisticasSite)
                commit('setEstatisticaWebsiteUnicos', response.data.data.aEstatisticaWebsiteUnicos)
                commit('setEstatisticaWebsiteTotais', response.data.data.aEstatisticaWebsiteTotais)
              }
              resolve(true)
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao carregar dados das estatísticas')))
            }
          })
      })
    },

  },
}
