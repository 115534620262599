import { ref } from '@vue/composition-api'
import i18n from '@/libs/i18n'

const defaultState = () => ({
  // Variaveis gerais da listagem
  listviewInit: ref(1),
  listviewData: ref([]),
  loadingListview: ref(true),
  perPage: ref(25),
  totalRows: ref(0),
  currentPage: ref(1),
  perPageOptions: ref([10, 25, 50, 100]),
  searchTxt: ref(''),
  totalRegs: ref(0),
  listviewOrderBy: ref({
    column: '0',
    sort: 'desc',
  }),
  listview_txt_default: i18n.t('Nenhum resultado disponível'),
  listiview_txt_not_find: i18n.t('Não encontrou nenhum resultado'),
  listview_txt_loading: i18n.t('A carregar registos ...'),
  sortByOptions: [
    {
      name: '0',
      txt: i18n.t('Ordenar por data de registo'),
    },
    {
      name: '1',
      txt: i18n.t('Ordenar por registado'),
    },
    {
      name: '2',
      txt: i18n.t('Ordenar por ação'),
    },
  ],
})

export default defaultState
