<template>
  <div class="email-app-details">
    <!-- Share Header -->
    <div class="email-detail-header">
      <!-- Header: Left -->
      <div class="email-header-left d-flex align-items-center">
        <span class="go-back mr-1">
          <feather-icon
            :icon="$store.state.appConfig.isRTL ? 'ChevronRightIcon' : 'ChevronLeftIcon'"
            size="20"
            class="align-bottom"
            @click="$emit('close-videos-edition')"
          />
        </span>
        <h4 class="email-subject mb-0">
          <b-row>
            <b-col
              md="12"
              class="agent"
            >
              {{ $t('Vídeos') }}
            </b-col>
          </b-row>
        </h4>
      </div>
    </div>
    <div class="email-app-list ecommerce-application position-relative space-bottom">

      <!-- Barra de ações dos registos da listagem -->
      <div
        v-if="videos.length > 0"
        class="app-action"
      >
        <div class="action-left">
          <b-form-checkbox
            :checked="selectAllVideosCheckbox"
            :indeterminate="isselectAllVideosCheckboxIndeterminate"
            @change="selectAllCheckboxUpdate"
          >
            {{ $t('Selecionar tudo') }}
          </b-form-checkbox>
        </div>
        <div
          v-show="filterSelected.videosID.value.length"
          class="align-items-center"
          :class="{'d-flex': filterSelected.videosID.value.length}"
        >
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-danger"
            class="mr-1"
            @click="deleteAction()"
          >
            <feather-icon
              icon="TrashIcon"
              class="mr-50"
            />
            <span class="align-middle">{{ $t('Eliminar selecionadas') }}</span>
          </b-button>

        </div>
      </div>

      <!-- Listings -->
      <vue-perfect-scrollbar
        ref="videos"
        class="email-user-list scroll-area"
        :settings="configSettingsScroll"
      >

        <draggable
          v-if="videos.length > 0"
          v-model="videos"
          class="email-media-list"
          tag="ul"
          handle=".handle"
          @start="dragging = true"
          @end="dragging = false"
          @change="orderAction"
        >
          <b-media
            v-for="(row) in videos"
            :key="row.id"
            tag="li"
            no-body
          >
            <div
              class="d-inline mr-75 handle"
            >
              <div class="d-flex align-items-center">
                <div class="d-flex align-items-center mr-1">
                  <feather-icon
                    icon="MenuIcon"
                    :size="sizeIconsAction"
                    :title="$t('Mover vídeo')"
                  />
                </div>

                <b-form-checkbox
                  :checked="filterSelected.videosID.value.includes(row.id)"
                  @change="toggleSelectedVideo(row.id)"
                  @click.native.stop
                />
                <div :style="[{ 'min-width': '100px' }]">
                  <!-- Video -->
                  <b-embed
                    type="iframe"
                    aspect="16by9"
                    :src="row.src_video"
                    allowfullscreen
                  />
                </div>
                <h5
                  v-if="row.desc"
                  class="ml-1 mb-25"
                >
                  {{ row.desc }}
                </h5>
              </div>
            </div>
          </b-media>
        </draggable>
      </vue-perfect-scrollbar>

    </div>
  </div>

</template>

<script>
import store from '@/store'
import {
  BCol, BRow, BMedia, BEmbed, BButton, BFormCheckbox,
} from 'bootstrap-vue'
import VuePerfectScrollbar from '@/views/modules/components/vue-perfect-scrollbar.vue'
import draggable from 'vuedraggable'
import Ripple from 'vue-ripple-directive'
import { sizeIconsAction } from '@core-custom/mixins/geral'
import { mapGetters } from 'vuex'
import { configSettingsScroll } from '@core-custom/utils/ui'
import { mixFormGeral } from '../../../mixins'

export default {
  components: {
    BCol,
    BRow,
    BMedia,
    BEmbed,
    BButton,
    BFormCheckbox,
    VuePerfectScrollbar,
    draggable,

  },
  directives: {
    Ripple,
  },
  mixins: [mixFormGeral, sizeIconsAction],
  props: {
    deleteAction: {
      type: Function,
      required: true,
    },
    orderAction: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      dragging: false,
    }
  },
  computed: {
    ...mapGetters('sw079', ['videos', 'filterSelected']),
    videos: {
      get() {
        return this.$store.getters['sw079/videos']
      },
      set(value) {
        this.$store.commit('sw079/setVideos', value)
      },
    },
    isselectAllVideosCheckboxIndeterminate() {
      return (Boolean(this.filterSelected.videosID.value.length) && this.videos.length !== this.filterSelected.videosID.value.length)
    },
    selectAllVideosCheckbox() {
      return this.videos.length && (this.videos.length === this.filterSelected.videosID.value.length)
    },
  },
  methods: {
    selectAllCheckboxUpdate(val) {
      store.dispatch('sw079/setVideoIDSelected', val)
    },
    toggleSelectedVideo(idVideo) {
      const indexVideo = this.filterSelected.videosID.value.indexOf(idVideo)
      if (indexVideo === -1) {
        this.filterSelected.videosID.value.push(idVideo)
      } else {
        this.filterSelected.videosID.value.splice(idVideo, 1)
      }
    },
  },
  setup() {
    return {
      configSettingsScroll,
    }
  },
}
</script>

<style lang="scss" scoped>
.email-app-details .email-user-list {
  height: calc( 100% - calc(0.1rem) )!important;
}

.capacitor{
  .email-user-list ::v-deep{
    .email-media-list li {
      animation:none!important;
      animation-fill-mode:none!important;
    }
  }
}

</style>
