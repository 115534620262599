import api from '@core-custom/api/api'
import apiConfig from '@core-custom/api/apiConfig'
import i18n from '@/libs/i18n'
import defaultState from './defaultState'

export default {
  namespaced: true,
  state: defaultState,
  getters: {
    notasPrivadas: state => state.notasPrivadas.value,
    notasRede: state => state.notasRede.value,
  },
  mutations: {
    resetState(state) {
      Object.assign(state, defaultState)
    },
    setNotasPrivadas(state, payload) {
      state.notasPrivadas.value = payload
    },
    setNotasRede(state, payload) {
      state.notasRede.value = payload
    },
  },
  actions: {
    async loadNotesPrivates({ rootGetters, dispatch }) {
      const formData = new FormData()

      formData.append('keyReg', rootGetters['listings_form/keyReg'])
      formData.append('keyDynamic', rootGetters['listings_form/dynamicIDForm'])

      if (rootGetters['listings_form/formModeView'] === true) {
        formData.append('modeForm', 1)
      } else {
        formData.append('modeForm', '')
      }

      formData.append('versionDynamic', rootGetters['listings_form/dynamicIDVersion'])
      formData.append('versionReg', rootGetters['listings_form/idVersionReg'])
      formData.append('versionRegViewer', '')
      formData.append('loadAjax', '')

      dispatch('request/cancelTokensPending', null, { root: true })

      return new Promise((resolve, reject) => {
        api.post(`${apiConfig.url_base_api}listings/loadNotesPessoalListing`, formData)
          .then(response => {
            if (typeof response.data === 'object') {
              resolve(response.data)
            } else {
              reject(new Error(i18n.t('Problema ao carregar as notas privadas')))
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao carregar as notas privadas')))
            }
          })
      })
    },

    async loadNotesNetwork({ rootGetters, dispatch }) {
      const formData = new FormData()

      formData.append('keyReg', rootGetters['listings_form/keyReg'])
      formData.append('keyDynamic', rootGetters['listings_form/dynamicIDForm'])

      if (rootGetters['listings_form/formModeView'] === true) {
        formData.append('modeForm', 1)
      } else {
        formData.append('modeForm', '')
      }

      formData.append('versionDynamic', rootGetters['listings_form/dynamicIDVersion'])
      formData.append('versionReg', rootGetters['listings_form/idVersionReg'])
      formData.append('versionRegViewer', '')
      formData.append('loadAjax', '')

      dispatch('request/cancelTokensPending', null, { root: true })

      return new Promise((resolve, reject) => {
        api.post(`${apiConfig.url_base_api}listings/loadNotesRedeListing`, formData)
          .then(response => {
            if (typeof response.data === 'object') {
              resolve(response.data)
            } else {
              reject(new Error(i18n.t('Problema ao carregar as notas da rede')))
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao carregar as notas da rede')))
            }
          })
      })
    },

    async createNewNote({ rootGetters, dispatch }, payload) {
      const formData = new FormData()

      formData.append('keyReg', rootGetters['listings_form/keyReg'])
      formData.append('keyDynamic', rootGetters['listings_form/dynamicIDForm'])
      formData.append('modeNote', btoa(payload.modeNote))
      formData.append('versionDynamic', rootGetters['listings_form/dynamicIDVersion'])
      formData.append('versionReg', rootGetters['listings_form/idVersionReg'])

      dispatch('request/cancelTokensPending', null, { root: true })

      return new Promise((resolve, reject) => {
        api.post(`${apiConfig.url_base_api}listings/createNewNotesListing`, formData)
          .then(response => {
            if (typeof response.data === 'object') {
              resolve(response.data)
            } else {
              reject(new Error(i18n.t('Problema ao inserir nova nota')))
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao inserir nova nota')))
            }
          })
      })
    },

    async updateNote({ rootGetters, dispatch }, payload) {
      const formData = new FormData()

      formData.append('keyReg', rootGetters['listings_form/keyReg'])
      formData.append('keyDynamic', rootGetters['listings_form/dynamicIDForm'])
      formData.append('versionDynamic', rootGetters['listings_form/dynamicIDVersion'])
      formData.append('sw081s01', btoa(payload.sw081s01))
      formData.append('sw081s04', payload.sw081s04)

      dispatch('request/cancelTokensPending', null, { root: true })

      return new Promise((resolve, reject) => {
        api.post(`${apiConfig.url_base_api}listings/updateNotesListing`, formData)
          .then(response => {
            if (typeof response.data === 'object') {
              resolve(response.data)
            } else {
              reject(new Error(i18n.t('Problema ao atualizar nota')))
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao atualizar nota')))
            }
          })
      })
    },

    async deleteNote({ rootGetters, dispatch }, payload) {
      const formData = new FormData()

      formData.append('keyReg', rootGetters['listings_form/keyReg'])
      formData.append('keyDynamic', rootGetters['listings_form/dynamicIDForm'])
      formData.append('versionDynamic', rootGetters['listings_form/dynamicIDVersion'])
      formData.append('sw081s01', btoa(payload.sw081s01))

      dispatch('request/cancelTokensPending', null, { root: true })

      return new Promise((resolve, reject) => {
        api.post(`${apiConfig.url_base_api}listings/deleteNotesListing`, formData)
          .then(response => {
            if (typeof response.data === 'object') {
              resolve(response.data)
            } else {
              reject(new Error(i18n.t('Problema ao atualizar nota')))
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao atualizar nota')))
            }
          })
      })
    },

  },
}
