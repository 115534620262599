import api from '@core-custom/api/api'
import apiConfig from '@core-custom/api/apiConfig'
import i18n from '@/libs/i18n'
import defaultState from './defaultState'

export default {
  namespaced: true,
  state: defaultState,
  getters: {
    documentos: state => state.documentos.value,
    docsPrivados: state => state.docsPrivados.value,
    filterSelected: state => ({
      docsID: state.filterSelectedDocsID,
    }),
  },
  mutations: {
    resetState(state) {
      Object.assign(state, defaultState)
    },
    setDocumentos(state, payload) {
      state.documentos.value = payload
    },
    setDocsPrivados(state, payload) {
      state.docsPrivados.value = payload
    },
    setDocumentIDSelected(state, payload) {
      state.filterSelectedDocsID.value = payload ? state.documentos.value.map(row => row.id) : []
    },
  },
  actions: {

    async uploadSW035({ dispatch }, payload) {
      let resultUpload = null

      if ((payload !== null) && (payload !== undefined)) {
        if ('files' in payload) {
          if (payload.files.length > 0) {
            await payload.files.reduce(async (previous, file) => {
              const result = await previous
              return [...result, await dispatch('uploadDocumentos', [file, payload.private])]
            }, Promise.resolve([]))
              .then(result => {
                resultUpload = result
              })
          }
        }
      }

      const response = await new Promise((resolve, reject) => {
        if (resultUpload === null) {
          reject(new Error(i18n.t('Selecione um documento para carregar')))
        } else {
          resolve(resultUpload)
        }
      })

      return response
    },

    async uploadDocumentos({ rootGetters }, payload) {
      const formData = new FormData()

      formData.append('keyReg', rootGetters['listings_form/keyReg'])
      formData.append('keyDynamic', rootGetters['listings_form/dynamicIDForm'])
      formData.append('versionDynamic', rootGetters['listings_form/dynamicIDVersion'])
      formData.append('versionReg', rootGetters['listings_form/idVersionReg'])
      formData.append('name', payload[0].name)
      formData.append('file', payload[0])

      let urlUploadDoc = 'listings/uploadDocumentosPublicos'
      if (payload[1] === 1) {
        urlUploadDoc = 'listings/uploadDocumentos'
      }

      return new Promise((resolve, reject) => {
        api.post(`${apiConfig.url_base_api}${urlUploadDoc}`, formData)
          .then(response => {
            if (typeof response.data === 'object') {
              resolve(response.data)
            } else {
              reject(new Error(i18n.t('Problema ao fazer upload dos documentos')))
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao fazer upload dos documentos')))
            }
          })
      })
    },

    async loadDocumentos({ rootGetters, dispatch }, payload) {
      const formData = new FormData()

      formData.append('keyReg', rootGetters['listings_form/keyReg'])
      formData.append('keyDynamic', rootGetters['listings_form/dynamicIDForm'])

      if (rootGetters['listings_form/formModeView'] === true) {
        formData.append('modeForm', 1)
      } else {
        formData.append('modeForm', '')
      }

      formData.append('versionDynamic', rootGetters['listings_form/dynamicIDVersion'])
      formData.append('versionReg', rootGetters['listings_form/idVersionReg'])

      let urlLoadDoc = 'listings/loadDocumentosPublicos'
      if (payload.private === 1) {
        urlLoadDoc = 'listings/loadDocumentos'
      }

      dispatch('request/cancelTokensPending', null, { root: true })

      return new Promise((resolve, reject) => {
        api.post(`${apiConfig.url_base_api}${urlLoadDoc}`, formData)
          .then(response => {
            if (typeof response.data === 'object') {
              const updatedData = {
                ...response.data, // Copia todas as propriedades de response.data
                docsAPI: response.data.docsAPI.map(element => ({
                  ...element,
                  src: `${element.src}/${api.getToken()}`,
                })),
              }
              resolve(updatedData)
            } else {
              reject(new Error(i18n.t('Problema ao carregar os documentos')))
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao carregar os documentos')))
            }
          })
      })
    },

    async deleteDocumentos({ rootGetters }, payload) {
      const formData = new FormData()

      formData.append('keyReg', rootGetters['listings_form/keyReg'])
      formData.append('versionDynamic', rootGetters['listings_form/dynamicIDVersion'])
      formData.append('regDel', payload.regDel)

      return new Promise((resolve, reject) => {
        api.post(`${apiConfig.url_base_api}listings/deleteDocumento`, formData)
          .then(response => {
            if (typeof response.data === 'object') {
              resolve(response.data)
            } else {
              reject(new Error(i18n.t('Problema ao eliminar os documentos')))
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao eliminar os documentos')))
            }
          })
      })
    },

    async updateInformDoc({ rootGetters, dispatch }, payload) {
      const formData = new FormData()

      let idTypesDoc = ''
      if (payload.typesSel !== '') {
        payload.typesSel.forEach(element => {
          idTypesDoc += `${element.sw032s01};`
        })
      }

      formData.append('sw035s01', payload.id)
      formData.append('sw035s05', payload.comment)
      formData.append('sw036s04', idTypesDoc)
      formData.append('versionDynamic', rootGetters['listings_form/dynamicIDVersion'])
      formData.append('versionReg', rootGetters['listings_form/idVersionReg'])

      dispatch('request/cancelTokensPending', null, { root: true })

      return new Promise((resolve, reject) => {
        api.post(`${apiConfig.url_base_api}listings/updateInformDoc`, formData)
          .then(response => {
            if (typeof response.data === 'object') {
              resolve(response.data)
            } else {
              reject(new Error(i18n.t('Problema ao atualizar informação do documento')))
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao atualizar informação do documento')))
            }
          })
      })
    },

    setDocumentIDSelected({ commit }, idDoc) {
      commit('setDocumentIDSelected', idDoc)
    },

  },
}
