<template>
  <div>
    <b-form
      ref="formSaveListing"
      @submit.prevent
    >
      <validation-observer
        ref="formSaveListingValidator"
        #default="{}"
      >
        <b-tabs
          ref="formSteps"
          v-model="tabContentStepShow"
          nav-class="d-none"
          @input="checkStatusArrowNavDisableFooter"
        >
          <!-- Geral -->
          <b-tab
            ref="tabContentForm"
            title=""
            title-item-class="d-none"
          >
            <tabs-form-data-geral :get-modal-motivo="getModalMotivo" />
          </b-tab>

          <!-- Dados -->
          <b-tab
            ref="tabContentForm"
            title=""
            title-item-class="d-none"
          >
            <tabs-form-data-dados
              :get-modal-mediacao="getModalMediacao"
              :cancelar-contrado-mediacao="cancelarContradoMediacao"
              :open-prices="openPrices"
            />
          </b-tab>

          <!-- Localizaçao -->
          <b-tab
            ref="tabContentFormLocalizacao"
            title=""
            title-item-class="d-none"
          >
            <tabs-form-data-localizacao />
          </b-tab>

          <!-- Descritivio -->
          <b-tab
            ref="tabContentFormDescritivo"
            title=""
            title-item-class="d-none"
          >
            <tabs-form-data-descritivo />
          </b-tab>

          <!-- Atributos -->
          <b-tab
            ref="tabContentForm"
            title=""
            title-item-class="d-none"
          >
            <tabs-form-data-atributos :get-modal-acesso="getModalAcesso" />
          </b-tab>

          <!-- Dados Legais -->
          <b-tab
            ref="tabContentForm"
            title=""
            title-item-class="d-none"
          >
            <tabs-form-data-dados-legais />
          </b-tab>

          <!-- Media - Fotografias -->
          <b-tab
            ref="tabContentForm"
            title=""
            title-item-class="d-none"
          >
            <tabs-form-data-media-fotos />
          </b-tab>

          <!-- Media - Videos -->
          <b-tab
            ref="tabContentForm"
            title=""
            title-item-class="d-none"
          >
            <tabs-form-data-media-videos />
          </b-tab>

          <!-- Media - VR -->
          <b-tab
            ref="tabContentForm"
            title=""
            title-item-class="d-none"
          >
            <tabs-form-data-media-vr />
          </b-tab>

          <!-- Media - Documentos -->
          <b-tab
            ref="tabContentForm"
            title=""
            title-item-class="d-none"
          >
            <tabs-form-data-media-documentos />
          </b-tab>

          <!-- Private - Documentos privados -->
          <b-tab
            ref="tabContentForm"
            title=""
            title-item-class="d-none"
          >
            <tabs-form-data-private-documentos />
          </b-tab>

          <!-- Private - Notas privadas -->
          <b-tab
            ref="tabContentForm"
            title=""
            title-item-class="d-none"
          >
            <tabs-form-data-private-notas-privadas />
          </b-tab>

          <!-- Private - Notas rede -->
          <b-tab
            ref="tabContentForm"
            title=""
            title-item-class="d-none"
          >
            <tabs-form-data-private-notas-rede />
          </b-tab>

          <!-- Private - Fotos rede -->
          <b-tab
            ref="tabContentForm"
            title=""
            title-item-class="d-none"
          >
            <tabs-form-data-private-fotos />
          </b-tab>

          <!-- Activity - Versões -->
          <b-tab
            ref="tabContentForm"
            title=""
            title-item-class="d-none"
          >
            <tabs-form-data-activity-versao />
          </b-tab>

          <!-- Activity - Movimento de Angariações -->
          <b-tab
            ref="tabContentForm"
            title=""
            title-item-class="d-none"
          >
            <tabs-form-data-activity-mov-ang />
          </b-tab>

          <!-- Activity - Movimento de estados -->
          <b-tab
            ref="tabContentForm"
            title=""
            title-item-class="d-none"
          >
            <tabs-form-data-activity-mov-state />
          </b-tab>

          <!-- Activity - Motivo de recusa -->
          <b-tab
            ref="tabContentForm"
            title=""
            title-item-class="d-none"
          >
            <tabs-form-data-activity-motivo-recusa />
          </b-tab>

          <!-- Activity - Contratos -->
          <b-tab
            ref="tabContentForm"
            title=""
            title-item-class="d-none"
          >
            <tabs-form-data-activity-contratos />
          </b-tab>

          <!-- Activity - Atividades -->
          <b-tab
            ref="tabContentForm"
            title=""
            title-item-class="d-none"
          >
            <tabs-form-data-activity-atividades />
          </b-tab>

          <!-- Activity - Leads interessadas -->
          <b-tab
            ref="tabContentForm"
            title=""
            title-item-class="d-none"
          >
            <tabs-form-data-activity-leads />
          </b-tab>

          <!-- Activity - Visitas -->
          <b-tab
            ref="tabContentForm"
            title=""
            title-item-class="d-none"
          >
            <tabs-form-data-activity-visitas
              :open-visit-detail="openVisitDetail"
            />
          </b-tab>

          <!-- Activity - propostas -->
          <b-tab
            ref="tabContentForm"
            title=""
            title-item-class="d-none"
          >
            <tabs-form-data-activity-propostas
              :open-proposal-detail="openProposalDetail"
            />
          </b-tab>

          <!-- Activity - estatisticas -->
          <b-tab
            ref="tabContentForm"
            title=""
            title-item-class="d-none"
          >
            <tabs-form-data-activity-estatisticas />
          </b-tab>

          <!-- Activity - design -->
          <b-tab
            ref="tabContentForm"
            title=""
            title-item-class="d-none"
          >
            <tabs-form-data-activity-design />
          </b-tab>

          <!-- Comunication - Planos -->
          <b-tab
            v-if="appConfig.bUsePlanoComunicacao === true && Number(load_data_geral.formPreAngariacao) !== 1"
            ref="tabContentForm"
            title=""
            title-item-class="d-none"
          >
            <tabs-form-data-communication-plan />
          </b-tab>

        </b-tabs>

      </validation-observer>
    </b-form>
  </div>
</template>

<script>
import store from '@/store'
import {
  BTab, BTabs, BForm,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import { ValidationObserver } from 'vee-validate'
import { mixFormGeral } from './mixins'
import TabsFormDataGeral from './form_data/geral.vue'
import TabsFormDataDados from './form_data/dados.vue'
import TabsFormDataLocalizacao from './form_data/localizacao.vue'
import TabsFormDataDescritivo from './form_data/descritivo.vue'
import TabsFormDataAtributos from './form_data/atributos.vue'
import TabsFormDataDadosLegais from './form_data/dadosLegais.vue'
import TabsFormDataMediaFotos from './form_data/media/fotografias.vue'
import TabsFormDataMediaVideos from './form_data/media/video.vue'
import TabsFormDataMediaVr from './form_data/media/realidadeVirtual.vue'
import TabsFormDataMediaDocumentos from './form_data/media/documentos.vue'
import TabsFormDataPrivateDocumentos from './form_data/private/documentos.vue'
import TabsFormDataPrivateNotasPrivadas from './form_data/private/notasPrivadas.vue'
import TabsFormDataPrivateNotasRede from './form_data/private/notasRede.vue'
import TabsFormDataPrivateFotos from './form_data/private/fotografias.vue'
import TabsFormDataActivityVersao from './form_data/activity/controloVersoes.vue'
import TabsFormDataActivityMovAng from './form_data/activity/movimentoAng.vue'
import TabsFormDataActivityMovState from './form_data/activity/movimentoEstados.vue'
import TabsFormDataActivityMotivoRecusa from './form_data/activity/motivosRecusa.vue'
import TabsFormDataActivityContratos from './form_data/activity/contratos.vue'
import TabsFormDataActivityAtividades from './form_data/activity/atividades.vue'
import TabsFormDataActivityLeads from './form_data/activity/leads.vue'
import TabsFormDataActivityVisitas from './form_data/activity/visitas.vue'
import TabsFormDataActivityPropostas from './form_data/activity/propostas.vue'
import TabsFormDataActivityEstatisticas from './form_data/activity/estatisticas.vue'
import TabsFormDataActivityDesign from './form_data/activity/design.vue'
import TabsFormDataCommunicationPlan from './form_data/communication/planoComunicacao.vue'

export default {
  components: {
    BTab,
    BTabs,
    BForm,
    ValidationObserver,
    TabsFormDataGeral,
    TabsFormDataDados,
    TabsFormDataLocalizacao,
    TabsFormDataDescritivo,
    TabsFormDataAtributos,
    TabsFormDataDadosLegais,
    TabsFormDataMediaFotos,
    TabsFormDataMediaVideos,
    TabsFormDataMediaVr,
    TabsFormDataMediaDocumentos,
    TabsFormDataPrivateDocumentos,
    TabsFormDataPrivateNotasPrivadas,
    TabsFormDataPrivateNotasRede,
    TabsFormDataPrivateFotos,
    TabsFormDataActivityVersao,
    TabsFormDataActivityMovAng,
    TabsFormDataActivityMovState,
    TabsFormDataActivityMotivoRecusa,
    TabsFormDataActivityContratos,
    TabsFormDataActivityAtividades,
    TabsFormDataActivityLeads,
    TabsFormDataActivityVisitas,
    TabsFormDataActivityPropostas,
    TabsFormDataActivityEstatisticas,
    TabsFormDataCommunicationPlan,
    TabsFormDataActivityDesign,
  },
  mixins: [mixFormGeral],
  props: {
    getModalMediacao: {
      type: Function,
      required: true,
    },
    cancelarContradoMediacao: {
      type: Function,
      required: true,
    },
    getModalMotivo: {
      type: Function,
      required: true,
    },
    openVisitDetail: {
      type: Function,
      required: true,
    },
    openProposalDetail: {
      type: Function,
      required: true,
    },
    openPrices: {
      type: Function,
      required: true,
    },
    getModalAcesso: {
      type: Function,
      required: true,
    },
  },
  computed: {
    ...mapGetters('listings_form', ['load_data_geral', 'appConfig']),
    tabContentStepShow: {
      get() { return this.$store.getters['listings_form/tabContentStepShow'] },
      set(newValue) {
        this.$store.commit('listings_form/setTabContentStepShow', newValue)
      },
    },
  },
  methods: {
    checkStatusArrowNavDisableFooter(event) {
      try {
        const { currentTab } = this.$refs.formSteps
        const totalTabs = this.$refs.formSteps.getTabs().length

        this.$refs.formSteps.getTabs().forEach(o => {
          o.$children[0].$refs.footerStepNav.updateStatusArrowNavDisableFooter(currentTab, totalTabs)
        })

        if ([6, 7, 8, 9].includes(event)) {
          this.loadDataMedia()
        } else if ([10, 11, 12, 13].includes(event)) {
          this.loadDataPrivado()
        } else if ([14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24].includes(event)) {
          this.loadDataAtividade()
        } else if ([25].includes(event)) {
          this.loadDataCommunication()
        }
      } catch (err) {
        //
      }
    },
    async loadDataMedia() {
      if (this.$store.getters['listings_form/loadTabMedia'] === false) {
        this.$root.$emit('app::loading', true)

        await this.$store.commit('sw014/setImagens', [])
        await this.$store.commit('sw014/setImagensPrin', [])
        await this.$store.commit('sw014/setMainImages', [])
        await this.$store.commit('listings_form/setPreviewImage', '')

        await store.dispatch('sw014/loadImages').then(async response => {
          if (typeof response === 'object') {
            await this.$store.commit('sw014/setImagens', response.imagesAPI)
            await this.$store.commit('sw014/setImagensPrin', response.imagesPrin)

            if (typeof response.imagesAPI[0] !== 'undefined' && typeof response.imagesAPI[0].src_image_preview !== 'undefined') {
              await this.$store.commit('listings_form/setPreviewImage', response.imagesAPI[0].src_image_preview)
            }

            const aMainImages = []

            await response.imagesAPI.forEach(async row => {
              if (typeof response.imagesPrin.ids !== 'undefined' && response.imagesPrin.ids.includes(row.idFixed)) {
                aMainImages.push(row.idFixed)
              } else {
                aMainImages.push('')
              }
            })

            await this.$store.commit('sw014/setMainImages', aMainImages)
          } else {
            this.showMsgErrorRequest(this.$t('Problema a carregar as imagens'))
          }
        }).catch(error => {
          this.showMsgErrorRequest(error)
        })

        await this.$store.commit('sw079/setVideos', [])

        await store.dispatch('sw079/loadVideos').then(response => {
          if (typeof response === 'object') {
            this.$store.commit('sw079/setVideos', response.videosAPI)
          } else {
            this.showMsgErrorRequest(this.$t('Problema a carregar os videos'))
          }
        }).catch(error => {
          this.showMsgErrorRequest(error)
        })

        await this.$store.commit('sw344/setVRs', [])

        await store.dispatch('sw344/loadVRs').then(response => {
          if (typeof response === 'object') {
            this.$store.commit('sw344/setVRs', response.vrsAPI)
          } else {
            this.showMsgErrorRequest(this.$t('Problema a carregar os VR'))
          }
        }).catch(error => {
          this.showMsgErrorRequest(error)
        })

        await this.$store.commit('sw035/setDocumentos', [])

        await store.dispatch('sw035/loadDocumentos', { private: 0 }).then(response => {
          if (typeof response === 'object') {
            this.$store.commit('sw035/setDocumentos', response.docsAPI)

            const docs = response.docsAPI

            response.docsAPI.forEach((doc, index) => {
              const aTypesSel = []

              if (doc.typesSel.length > 0) {
                doc.typesSel.forEach(val => {
                  if (val) {
                    const oValue = doc.typeDocs.find(o => Number(o.sw032s01) === Number(val))
                    if (oValue) {
                      aTypesSel.push(oValue)
                    }
                  }
                })

                docs[index].typesSel = aTypesSel
              }
            })

            this.$store.commit('sw035/setDocumentos', docs)
          } else {
            this.showMsgErrorRequest(this.$t('Problema a carregar os documentos'))
          }
        }).catch(error => {
          this.showMsgErrorRequest(error)
        })

        this.$store.commit('listings_form/setLoadTabMedia', true)

        this.$root.$emit('app::loading', false)
      }
    },
    async loadDataPrivado() {
      if (this.$store.getters['listings_form/loadTabPrivado'] === false) {
        this.$root.$emit('app::loading', true)

        await this.$store.commit('sw035/setDocsPrivados', [])

        await store.dispatch('sw035/loadDocumentos', { private: 1 }).then(response => {
          if (typeof response === 'object') {
            this.$store.commit('sw035/setDocsPrivados', response.docsAPI)

            const docs = response.docsAPI

            response.docsAPI.forEach((doc, index) => {
              const aTypesSel = []

              if (doc.typesSel.length > 0) {
                doc.typesSel.forEach(val => {
                  if (val) {
                    const oValue = doc.typeDocs.find(o => Number(o.sw032s01) === Number(val))
                    if (oValue) {
                      aTypesSel.push(oValue)
                    }
                  }
                })

                docs[index].typesSel = aTypesSel
              }
            })

            this.$store.commit('sw035/setDocsPrivados', docs)
          } else {
            this.showMsgErrorRequest(this.$t('Problema a carregar os documentos'))
          }
        }).catch(error => {
          this.showMsgErrorRequest(error)
        })

        await this.$store.commit('sw081/setNotasPrivadas', [])

        await store.dispatch('sw081/loadNotesPrivates').then(res => {
          if (res.notesAPI !== '') {
            this.$store.commit('sw081/setNotasPrivadas', res.notesAPI)
          } else {
            this.showMsgErrorRequest(res.message)
          }
        }).catch(error => {
          this.showMsgErrorRequest(error)
        })

        await this.$store.commit('sw081/setNotasRede', [])

        await store.dispatch('sw081/loadNotesNetwork').then(res => {
          if (res.notesAPI !== '') {
            this.$store.commit('sw081/setNotasRede', res.notesAPI)
          } else {
            this.showMsgErrorRequest(res.message)
          }
        }).catch(error => {
          this.showMsgErrorRequest(error)
        })

        await this.$store.commit('sw080/setImagensPrivadas', [])

        await store.dispatch('sw080/loadImages').then(response => {
          if (typeof response === 'object') {
            this.$store.commit('sw080/setImagensPrivadas', response.imagesAPI)
          } else {
            this.showMsgErrorRequest(this.$t('Problema a carregar as imagens'))
          }
        }).catch(error => {
          this.showMsgErrorRequest(error)
        })

        this.$store.commit('listings_form/setLoadTabPrivado', true)

        this.$root.$emit('app::loading', false)
      }
    },
    async loadDataAtividade() {
      if (this.$store.getters['listings_form/loadTabAtividade'] === false) {
        this.$root.$emit('app::loading', true)

        await store.dispatch('sw037/fetchListView').catch(error => {
          this.showMsgErrorRequest(error)
        })

        await store.dispatch('sw082/fetchListView').catch(error => {
          this.showMsgErrorRequest(error)
        })

        await store.dispatch('sw088/fetchListView').catch(error => {
          this.showMsgErrorRequest(error)
        })

        await store.dispatch('sw040/fetchListView').catch(error => {
          this.showMsgErrorRequest(error)
        })

        await store.dispatch('sw113/fetchListView').catch(error => {
          this.showMsgErrorRequest(error)
        })

        await store.dispatch('sw355/fetchListView').catch(error => {
          this.showMsgErrorRequest(error)
        })

        await store.dispatch('sw355/fetchListViewDesign').catch(error => {
          this.showMsgErrorRequest(error)
        })

        await store.dispatch('sw070/fetchListView').catch(error => {
          this.showMsgErrorRequest(error)
        })

        await store.dispatch('sw130/fetchListView').catch(error => {
          this.showMsgErrorRequest(error)
        })

        await store.dispatch('estatisticas/loadEstatisticas', { tab: 'estatisticaMYONETOOL' }).catch(error => {
          this.showMsgErrorRequest(error)
        })

        await store.dispatch('estatisticas/loadEstatisticas', { tab: 'estatisticaWebsite' }).catch(error => {
          this.showMsgErrorRequest(error)
        })

        this.$store.commit('listings_form/setLoadTabAtividade', true)

        this.$root.$emit('app::loading', false)
      }
    },
    async loadDataCommunication() {
      if (this.$store.getters['listings_form/loadTabPlano'] === false) {
        this.$root.$emit('app::loading', true)

        await store.dispatch('sw298/fetchListView').catch(error => {
          this.showMsgErrorRequest(error)
        })

        this.$store.commit('listings_form/loadTabPlano', true)

        this.$root.$emit('app::loading', false)
      }
    },
  },
}
</script>
