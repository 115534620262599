<template>
  <div>

    <div
      class="email-app-list position-relative height-100-panel"
    >
      <div
        class="app-fixed-search d-flex align-items-center"
      >
        <div class="d-flex align-items-center flex-grow-1 w-50">

          <div class="sidebar-toggle d-block d-lg-none ml-1">
            <feather-icon
              icon="MenuIcon"
              :size="sizeIconsAction"
              class="cursor-pointer"
              @click="mqShallShowLeftSidebar = true"
            />
          </div>

        </div>
        <div class="d-flex align-items-center w-50 justify-content-end">

          <b-button
            v-if="this.$store.getters['listings_form/formModeView'] === false"
            variant="flat-secondary"
            class="border-radius-none"
            :class="{ 'btn-sm' : ((this.$store.getters['app/currentBreakPoint'] === 'xs') ? true: false)}"
            @click.stop.prevent="newNote"
          >
            <feather-icon
              icon="PlusIcon"
              :size="sizeIconsAction"
            />
          </b-button>

          <footer-step-nav ref="footerStepNav" />

        </div>
      </div>

      <vue-perfect-scrollbar
        class="scroll-content"
        :settings="configSettingsScroll"
      >
        <div
          class="app-fixed-search d-flex align-items-center"
          :class="{'border-0' : ((notasPrivadas.length > 0) ? false : true)}"
        >
          <div class="d-flex flex-column align-items-center">

            <div class="d-flex align-items-center justify-content-start w-100">
              <h4 class="p-1 m-0">
                {{ $t('Notas pessoais privadas') }}
              </h4>
            </div>
            <div
              v-if="this.$store.getters['listings_form/formModeView'] === false"
              class="d-flex align-items-center justify-content-start w-100 pl-1 pr-1 pb-1"
            >
              <b-button
                variant="outline-primary"
                @click.stop.prevent="newNote"
              >
                <span class="align-middle">{{ $t('Adicionar') }}</span>
              </b-button>
            </div>

          </div>
        </div>

        <b-container
          class="p-2"
          fluid
        >
          <b-row>
            <b-col md="12">
              <b-alert
                show
                variant="info"
              >
                <div class="alert-body">
                  <feather-icon
                    class="mr-25"
                    icon="InfoIcon"
                  />
                  <span class="ml-25">{{ $t('As notas aqui escritas são privadas, visíveis apenas para si.') }}</span>
                </div>
              </b-alert>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="12">
              <b-alert
                show
                variant="warning"
              >
                <div class="alert-body">
                  <feather-icon
                    class="mr-25"
                    icon="AlertTriangleIcon"
                  />
                  <span class="ml-25">{{ $t('Estas notas privadas são atualizadas automaticamente, se apenas editou neste separador, não necessita de submeter a angariação para aprovação.') }}</span>
                </div>
              </b-alert>
            </b-col>
          </b-row>
          <b-row
            v-for="(row, key) in notasPrivadas"
            :key="`note-${key}`"
          >
            <b-col
              v-if="key > 0"
              cols="12"
            >
              <hr>
            </b-col>
            <b-col md="12">
              <component
                :is="blockFormNote"
                :note="row"
                :delete-action="deleteNote"
                :update-action="updateNote"
                :view="formModeView"
              />
            </b-col>
          </b-row>

        </b-container>
      </vue-perfect-scrollbar>

    </div>

  </div>
</template>

<script>
import store from '@/store'
import {
  BContainer, BRow, BCol, BButton, BAlert,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapGetters } from 'vuex'
import modelSW081 from '@store-modules/listings/sw081'
import VuePerfectScrollbar from '@/views/modules/components/vue-perfect-scrollbar.vue'
import { onUnmounted } from '@vue/composition-api'
import { sizeIconsAction } from '@core-custom/mixins/geral'
import { ValidationObserver } from 'vee-validate'
import { mask } from 'vue-the-mask'
import { showModalDeleteMessage } from '@core/utils/utils'
import { configSettingsScroll } from '@core-custom/utils/ui'
import { mixFormGeral } from '../../mixins'
import FooterStepNav from '../footerStepNavTop.vue'

export default {
  components: {
    BContainer,
    BRow,
    BCol,
    BButton,
    BAlert,
    VuePerfectScrollbar,
    FooterStepNav,
    ValidationObserver,
  },
  directives: {
    Ripple,
    mask,
  },
  mixins: [sizeIconsAction, mixFormGeral],
  data() {
    return {
    }
  },
  computed:
  {
    ...mapGetters('sw081', ['notasPrivadas']),
    ...mapGetters('listings_form', ['formModeView']),
    notasPrivadas: {
      get() {
        return this.$store.getters['sw081/notasPrivadas']
      },
      set(value) {
        this.$store.commit('sw081/setNotasPrivadas', value)
      },
    },
  },
  methods: {
    async loadNotes() {
      this.$root.$emit('app::loading', true)

      await store.dispatch('sw081/loadNotesPrivates').then(res => {
        if (res.notesAPI !== '') {
          this.$store.commit('sw081/setNotasPrivadas', res.notesAPI)
        } else {
          this.showMsgErrorRequest(res.message)
        }
      }).catch(error => {
        this.showMsgErrorRequest(error)
      })

      this.$root.$emit('app::loading', false)
    },
    async newNote() {
      this.$root.$emit('app::loading', true)

      await store.dispatch('sw081/createNewNote', { modeNote: 1 }).then(res => {
        if (res.sw081s01 !== '') {
          this.loadNotes()
        } else {
          this.showMsgErrorRequest(res.message)
        }
      }).catch(error => {
        this.showMsgErrorRequest(error)
      })
      this.$root.$emit('app::loading', false)
    },
    async updateNote(idNote, txtNote) {
      await store.dispatch('sw081/updateNote', { sw081s01: idNote, sw081s04: txtNote.target.value }).then(res => {
        if (res.update !== 1) {
          this.showMsgErrorRequest(res.message)
        } else {
          this.notasPrivadas.forEach((row, index) => {
            if (row.sw081s01 === idNote) {
              this.notasPrivadas[index].sw081s04 = txtNote.target.value
              this.$store.commit('sw081/setNotasPrivadas', this.notasPrivadas)
            }
          })
        }
      }).catch(error => {
        this.showMsgErrorRequest(error)
      })
    },
    async deleteNote(idNote) {
      const actionModal = new Promise(resolve => {
        showModalDeleteMessage(resolve, `${this.$t('Pretende eliminar a nota?')}<br>${this.$t('Esta ação é irreversível!')}`)
      })

      await actionModal.then(result => {
        if (result === 'confirm') {
          this.$root.$emit('app::loading', true)

          store.dispatch('sw081/deleteNote', { sw081s01: idNote }).then(response => {
            if (response.error === 1) {
              this.$root.$emit('app::loading', false)
              this.showMsgErrorRequest(this.$t('Problema a eliminar nota'))
            } else {
              this.loadNotes()
              this.showMsgSuccessRequest({ message: response.msg })
              this.$root.$emit('app::loading', false)
            }
          }).catch(error => {
            this.$root.$emit('app::loading', false)
            this.showMsgErrorRequest(error)
          })
        }
      })
    },
  },
  setup() {
    const MODEL_SW081 = 'sw081'
    const blockFormNote = () => import('@/views/modules/listings/form/form_data/private/sw081/form.vue')

    if (!store.hasModule(MODEL_SW081)) {
      store.registerModule(MODEL_SW081, modelSW081)

      onUnmounted(() => {
        if (store.hasModule(MODEL_SW081)) store.unregisterModule(MODEL_SW081)
      })
    }

    return {
      blockFormNote,
      configSettingsScroll,
    }
  },
}
</script>

<style lang="scss">
</style>
