<template>
  <div>
    <b-row
      v-if="countryApp.toUpperCase() === 'ES' && typeof load_data_geral.attributes.localizacao !== 'undefined' && load_data_geral.attributes.localizacao.length > 0 && filterAttributes.localizacao === true
        && (formModeView===false || filterAttributes.showLocalizacao === true)"
    >
      <b-col md="12">
        <h4
          class="mt-1"
        >
          {{ $t('Localização') }}
        </h4>
      </b-col>
    </b-row>

    <b-row
      v-if="countryApp.toUpperCase() === 'ES' && typeof load_data_geral.attributes.localizacao !== 'undefined' && load_data_geral.attributes.localizacao.length > 0 && filterAttributes.localizacao === true
        && (formModeView===false || filterAttributes.showLocalizacao === true)"
      class="mt-1"
    >
      <b-col
        v-for="row in load_data_geral.attributes.localizacao"
        :key="`attr-localizacao-${row.id}`"
        md="3"
      >
        <b-form-group v-if="formModeView===false">
          <b-form-checkbox
            v-model="form_data[row.id]"
            value="1"
            @input="updateAttrExtra(form_data[row.id], row.id)"
          >
            {{ row.desc }}
          </b-form-checkbox>
        </b-form-group>
        <b-form-group
          v-if="(form_data[row.id] === '1') && (formModeView===true)"
        >
          <p
            class="text-primary mb-0"
          >
            <feather-icon
              :icon="((form_data[row.id] === '1') ? 'CheckSquareIcon' : 'SquareIcon')"
              size="17"
              class="mr-50"
            />
            {{ row.desc }}
          </p>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row
      v-if="typeof load_data_geral.attributes.extras !== 'undefined' && load_data_geral.attributes.extras.length > 0 && filterAttributes.extras === true && (formModeView===false || filterAttributes.showExtras === true)"
    >
      <b-col md="12">
        <h4
          class="mt-1"
        >
          {{ $t('Extras') }}
        </h4>
      </b-col>
    </b-row>

    <b-row
      v-if="typeof load_data_geral.attributes.extras !== 'undefined' && load_data_geral.attributes.extras.length > 0 && filterAttributes.extras === true && (formModeView===false || filterAttributes.showExtras === true)"
      class="mt-1"
    >
      <b-col
        v-for="row in load_data_geral.attributes.extras"
        :key="`attr-extra-${row.id}`"
        md="3"
      >
        <b-form-group v-if="formModeView===false">
          <b-form-checkbox
            v-model="form_data[row.id]"
            value="1"
            @input="updateAttrExtra(form_data[row.id], row.id)"
          >
            {{ row.desc }}
          </b-form-checkbox>
        </b-form-group>
        <b-form-group
          v-if="(form_data[row.id] === '1') && (formModeView===true)"
        >
          <p
            class="text-primary mb-0"
          >
            <feather-icon
              :icon="((form_data[row.id] === '1') ? 'CheckSquareIcon' : 'SquareIcon')"
              size="17"
              class="mr-50"
            />
            {{ row.desc }}
          </p>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row
      v-if="typeof load_data_geral.attributes.vistas !== 'undefined' && load_data_geral.attributes.vistas.length > 0 && filterAttributes.vistas === true && (formModeView===false || filterAttributes.showVistas === true)"
    >
      <b-col md="12">
        <h4
          class="mt-1"
        >
          {{ $t('Vistas') }}
        </h4>
      </b-col>
    </b-row>

    <b-row
      v-if="typeof load_data_geral.attributes.vistas !== 'undefined' && load_data_geral.attributes.vistas.length > 0 && filterAttributes.vistas === true && (formModeView===false || filterAttributes.showVistas === true)"
      class="mt-1"
    >
      <b-col
        v-for="row in load_data_geral.attributes.vistas"
        :key="`attr-vista-${row.id}`"
        md="3"
      >
        <b-form-group v-if="formModeView===false">
          <b-form-checkbox
            v-model="form_data[row.id]"
            value="1"
          >
            {{ row.desc }}
          </b-form-checkbox>
        </b-form-group>
        <b-form-group
          v-if="(form_data[row.id] === '1') && (formModeView===true)"
        >
          <p
            class="text-primary mb-0"
          >
            <feather-icon
              :icon="((form_data[row.id] === '1') ? 'CheckSquareIcon' : 'SquareIcon')"
              size="17"
              class="mr-50"
            />
            {{ row.desc }}
          </p>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row
      v-if="typeof load_data_geral.attributes.energia !== 'undefined' && load_data_geral.attributes.energia.length > 0 && filterAttributes.energia === true && (formModeView===false || filterAttributes.showEnergia === true)"
    >
      <b-col md="12">
        <h4
          class="mt-1"
        >
          {{ $t('Energia e aquecimento') }}
        </h4>
      </b-col>
    </b-row>

    <b-row
      v-if="typeof load_data_geral.attributes.energia !== 'undefined' && load_data_geral.attributes.energia.length > 0 && filterAttributes.energia === true && (formModeView===false || filterAttributes.showEnergia === true)"
      class="mt-1"
    >
      <b-col
        v-for="row in load_data_geral.attributes.energia"
        :key="`attr-energia-${row.id}`"
        md="3"
      >
        <b-form-group v-if="formModeView===false">
          <b-form-checkbox
            v-model="form_data[row.id]"
            value="1"
          >
            {{ row.desc }}
          </b-form-checkbox>
        </b-form-group>
        <b-form-group
          v-if="(form_data[row.id] === '1') && (formModeView===true)"
        >
          <p
            class="text-primary mb-0"
          >
            <feather-icon
              :icon="((form_data[row.id] === '1') ? 'CheckSquareIcon' : 'SquareIcon')"
              size="17"
              class="mr-50"
            />
            {{ row.desc }}
          </p>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row
      v-if="typeof load_data_geral.attributes.seguranca !== 'undefined' && load_data_geral.attributes.seguranca.length > 0 && filterAttributes.seguranca === true && (formModeView===false || filterAttributes.showSeguranca === true)"
    >
      <b-col md="12">
        <h4
          class="mt-1"
        >
          {{ $t('Segurança') }}
        </h4>
      </b-col>
    </b-row>

    <b-row
      v-if="typeof load_data_geral.attributes.seguranca !== 'undefined' && load_data_geral.attributes.seguranca.length > 0 && filterAttributes.seguranca === true && (formModeView===false || filterAttributes.showSeguranca === true)"
      class="mt-1"
    >
      <b-col
        v-for="row in load_data_geral.attributes.seguranca"
        :key="`attr-seguranca-${row.id}`"
        md="3"
      >
        <b-form-group v-if="formModeView===false">
          <b-form-checkbox
            v-model="form_data[row.id]"
            value="1"
          >
            {{ row.desc }}
          </b-form-checkbox>
        </b-form-group>
        <b-form-group
          v-if="(form_data[row.id] === '1') && (formModeView===true)"
        >
          <p
            class="text-primary mb-0"
          >
            <feather-icon
              :icon="((form_data[row.id] === '1') ? 'CheckSquareIcon' : 'SquareIcon')"
              size="17"
              class="mr-50"
            />
            {{ row.desc }}
          </p>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row
      v-if="typeof load_data_geral.attributes.espacos !== 'undefined' && load_data_geral.attributes.espacos.length > 0 && filterAttributes.espacos === true && (formModeView===false || filterAttributes.showEspacos === true)"
    >
      <b-col md="12">
        <h4
          class="mt-1"
        >
          {{ $t('Espaços') }}
        </h4>
      </b-col>
    </b-row>

    <b-row
      v-if="typeof load_data_geral.attributes.espacos !== 'undefined' && load_data_geral.attributes.espacos.length > 0 && filterAttributes.espacos === true && (formModeView===false || filterAttributes.showEspacos === true)"
      class="mt-1"
    >
      <b-col
        v-for="row in load_data_geral.attributes.espacos"
        :key="`attr-espacos-${row.id}`"
        md="3"
      >
        <b-form-group v-if="formModeView===false">
          <b-form-checkbox
            v-model="form_data[row.id]"
            value="1"
          >
            {{ row.desc }}
          </b-form-checkbox>
        </b-form-group>
        <b-form-group
          v-if="(form_data[row.id] === '1') && (formModeView===true)"
        >
          <p
            class="text-primary mb-0"
          >
            <feather-icon
              :icon="((form_data[row.id] === '1') ? 'CheckSquareIcon' : 'SquareIcon')"
              size="17"
              class="mr-50"
            />
            {{ row.desc }}
          </p>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row
      v-if="typeof load_data_geral.attributes.exterior !== 'undefined' && load_data_geral.attributes.exterior.length > 0 && filterAttributes.exterior === true && (formModeView===false || filterAttributes.showExterior === true)"
    >
      <b-col md="12">
        <h4
          class="mt-1"
        >
          {{ $t('Exterior') }}
        </h4>
      </b-col>
    </b-row>

    <b-row
      v-if="typeof load_data_geral.attributes.exterior !== 'undefined' && load_data_geral.attributes.exterior.length > 0 && filterAttributes.exterior === true && (formModeView===false || filterAttributes.showExterior === true)"
      class="mt-1"
    >
      <b-col
        v-for="row in load_data_geral.attributes.exterior"
        :key="`attr-exterior-${row.id}`"
        md="3"
      >
        <b-form-group
          v-if="formModeView===false
            && ((typeof form_data.sw012s07 !== 'undefined' && form_data.sw012s07 !== null && Number(form_data.sw012s07.show_tipo_terreno) === 1 && ['sw012_attr_furo-de-agua', 'sw012_attr_poco-de-agua'].includes(row.id))
              || (typeof form_data.sw012s07 === 'undefined' || form_data.sw012s07 === null || Number(form_data.sw012s07.show_tipo_terreno) !== 1))"
        >
          <b-form-checkbox
            v-model="form_data[row.id]"
            value="1"
          >
            {{ row.desc }}
          </b-form-checkbox>
        </b-form-group>
        <b-form-group
          v-if="(form_data[row.id] === '1') && (formModeView===true)"
        >
          <p
            class="text-primary mb-0"
          >
            <feather-icon
              :icon="((form_data[row.id] === '1') ? 'CheckSquareIcon' : 'SquareIcon')"
              size="17"
              class="mr-50"
            />
            {{ row.desc }}
          </p>
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormCheckbox,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import Ripple from 'vue-ripple-directive'
import { sizeIconsAction } from '@core-custom/mixins/geral'
import { mixFormGeral } from '../mixins'

export default {
  components: {
    BRow,
    BCol,
    BFormCheckbox,
    BFormGroup,
  },
  directives: {
    Ripple,
  },
  mixins: [sizeIconsAction, mixFormGeral],
  data: () => ({
  }),
  computed:
  {
    ...mapGetters('listings_form', ['form_data', 'load_data_geral', 'filterAttributes', 'formModeView']),
    ...mapGetters('auth', ['countryApp']),
  },
  methods: {
    async updateAttrExtra(vlCheck, vlAttr) {
      if (vlAttr === 'sw012_attr_cozinha-equipada' && Number(vlCheck) === 1) {
        await this.$store.dispatch('listings_form/updateFieldValue', { field: 'sw012_attr_cozinha-mobilada', value: 1 })
      }

      if (vlAttr === 'sw012_attr_cozinha-mobilada' && vlCheck === false && this.form_data['sw012_attr_cozinha-equipada'] !== null && Number(this.form_data['sw012_attr_cozinha-equipada']) === 1) {
        await this.showMsgErrorRequest({ message: this.$t('Não pode retirar o atributo, devido que tem o atributo "Cozinha equipada" activo') })
        await this.$store.dispatch('listings_form/updateFieldValue', { field: 'sw012_attr_cozinha-mobilada', value: 1 })
      }
    },
  },
}
</script>
