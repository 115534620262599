import { ref } from '@vue/composition-api'

const defaultState = () => ({
  images: ref([]),
  imagesPrin: ref([]),
  mainImages: ref([]),
  showPhotosEdition: ref(false),
  filterSelectedImagesID: ref([]),
})

export default defaultState
