<template>
  <div>

    <div
      class="email-app-list position-relative height-100-panel"
    >
      <div
        class="app-fixed-search d-flex align-items-center"
      >
        <div class="d-flex align-items-center flex-grow-1 w-50">

          <div class="sidebar-toggle d-block d-lg-none ml-1">
            <feather-icon
              icon="MenuIcon"
              :size="sizeIconsAction"
              class="cursor-pointer"
              @click="mqShallShowLeftSidebar = true"
            />
          </div>

        </div>
        <div class="d-flex align-items-center w-50 justify-content-end">

          <footer-step-nav ref="footerStepNav" />

        </div>
      </div>

      <vue-perfect-scrollbar
        class="scroll-content"
        :settings="configSettingsScroll"
      >
        <div
          class="app-fixed-search d-flex align-items-center"
        >
          <div class="d-flex flex-column align-items-center">
            <div class="d-flex align-items-center justify-content-start w-100">
              <h4 class="p-1 m-0">
                {{ $t('Descritivo') }}
              </h4>
            </div>
          </div>
        </div>

        <b-container
          class="p-2"
          fluid
        >
          <b-row>
            <b-col md="6">
              <div class="mb-3">
                <b-tabs align="left">
                  <b-tab
                    v-for="(lang, key) in load_data_geral.languages"
                    :key="key === 0 ? 'language' : `language-${lang.sw033s03}`"
                    :title="key === 0 ? countryApp : lang.sw033s03.toUpperCase()"
                    :active="key === 0 ? true : false"
                  >
                    <b-card-text>
                      <component
                        :is="blockDescriptive"
                        :ref="key === 0 ? `${countryApp}_description` : `${lang.sw033s03.toUpperCase()}_description`"
                        :view="formModeView"
                        :iso-lang="key === 0 ? '' : lang.sw033s03.toUpperCase()"
                      />
                      <b-row
                        v-if="key === 0 && load_data_geral.descriptonScripts.length > 0"
                        class="mt-2"
                      >
                        <b-col md="12">
                          <validation-provider
                            #default="{ errors }"
                            :rules="typeof rulesFields.sw012s536 !== 'undefined' && rulesFields.sw012s536.required === true ? 'required' : ''"
                            :name="$t('Script de venda para esta descrição') | lowerBold"
                          >
                            <b-form-group
                              :label="$t('Escolha o script de venda para esta descrição')"
                              :class="errors.length > 0 ? 'is-invalid':null"
                            >
                              <v-select
                                v-if="formModeView===false"
                                v-model="form_data.sw012s536"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                :options="load_data_geral.descriptonScripts"
                                :value="form_data.sw012s536"
                                append-to-body
                                :calculate-position="withPopper"
                                label="sw362s02"
                                item-text="sw362s02"
                                item-value="sw361s01"
                              >
                                <template #option="{ sw362s02 }">
                                  {{ sw362s02 }}
                                </template>
                                <div slot="no-options">
                                  {{ $t('Sem resultados') }}
                                </div>
                              </v-select>
                              <p
                                v-else
                                class="text-primary mb-0"
                              >
                                {{ renderObjToTxt(form_data.sw012s536, 'sw362s02') }}
                              </p>
                            </b-form-group>
                          </validation-provider>
                        </b-col>
                      </b-row>
                    </b-card-text>
                  </b-tab>
                </b-tabs>
              </div>
            </b-col>
          </b-row>
        </b-container>
      </vue-perfect-scrollbar>

    </div>

  </div>
</template>

<script>
import store from '@/store'
import {
  BRow, BCol, BContainer, BTabs, BTab, BCardText, BFormGroup, BFormInput,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import vSelect from 'vue-select'
import VuePerfectScrollbar from '@/views/modules/components/vue-perfect-scrollbar.vue'
import Ripple from 'vue-ripple-directive'
import { sizeIconsAction } from '@core-custom/mixins/geral'
import { ValidationProvider } from 'vee-validate'
import { configSettingsScroll } from '@core-custom/utils/ui'
import { mixFormGeral } from '../mixins'

export default {
  components: {
    BRow,
    BCol,
    BContainer,
    BTabs,
    BTab,
    BCardText,
    BFormGroup,
    BFormInput,
    VuePerfectScrollbar,
    FooterStepNav: () => import(/* webpackChunkName: "listings-footer-step-nav" */ /* webpackPrefetch: true */ './footerStepNavTop.vue'),
    ValidationProvider,
    vSelect,
  },
  directives: {
    Ripple,
  },
  mixins: [sizeIconsAction, mixFormGeral],
  data: () => ({
  }),
  computed:
  {
    ...mapGetters('listings_form', ['form_data', 'load_data_geral', 'formModeView', 'rulesFields']),
  },
  setup() {
    const countryApp = store.getters['auth/countryApp'].toUpperCase()
    const blockDescriptive = () => import('@/views/modules/components/descriptive.vue')

    return {
      countryApp,
      blockDescriptive,
      configSettingsScroll,
    }
  },
}
</script>

<style lang="scss">

.quill-editor {
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
}
.ql-container {
    min-height: 180px;
}
</style>
