import { ref } from '@vue/composition-api'
import i18n from '@/libs/i18n'

const defaultState = () => ({
  // Variaveis do filtro com os dados selecionados
  filterSelectedTotalFields: ref(0),
  filterSelectedEstadoPropostasReceived: ref(0),
  filterSelectedEstadoPropostasSent: ref(0),
  filterSelectedEstadoPropostasApprove: ref(0),
  filterSelectedEstadoPropostasReserve: ref(0),

  // Variaveis do filtro com os dados
  filterDataLoad: ref(false),
  proposalsToApprove: ref(0),
  proposalsToConcluir: ref(0),
  proposalsToApproveAll: ref(0),
  proposalsToConcluirAll: ref(0),
  proposalsToPaymentPending: ref(0),
  filterDataTabsListview: [
    {
      name: 'received',
      text: i18n.t('Propostas recebidas'),
    },
    {
      name: 'sent',
      text: i18n.t('Propostas efetuadas'),
    },
  ],
  filterDataEstadoPropostasReceived: [
    {
      id: '24',
      desc: i18n.t('Pendente de pagamento'),
    },
    {
      id: '17',
      desc: i18n.t('Reservas por concluir'),
    },
    {
      id: '15',
      desc: i18n.t('Por aprovar'),
    },
    {
      id: '0',
      desc: i18n.t('Em Negociação'),
    },
    {
      id: '1',
      desc: i18n.t('Aceites'),
    },
    {
      id: '2',
      desc: i18n.t('Recusadas'),
    },
    {
      id: '9',
      desc: i18n.t('Canceladas'),
    },
  ],
  filterDataEstadoPropostasSent: [
    {
      id: '25',
      desc: i18n.t('Pendente de pagamento'),
    },
    {
      id: '18',
      desc: i18n.t('Reservas por concluir'),
    },
    {
      id: '16',
      desc: i18n.t('Por aprovar'),
    },
    {
      id: '0',
      desc: i18n.t('Em Negociação'),
    },
    {
      id: '1',
      desc: i18n.t('Aceites'),
    },
    {
      id: '2',
      desc: i18n.t('Recusadas'),
    },
    {
      id: '9',
      desc: i18n.t('Canceladas'),
    },
  ],
  filterDataEstadoPropostasToApprove: [
    {
      id: '0',
      desc: i18n.t('Todas'),
    },
    {
      id: '1',
      desc: i18n.t('Recebidas'),
    },
    {
      id: '2',
      desc: i18n.t('Efetuadas'),
    },
  ],
  filterDataEstadoPropostasReserve: [
    {
      id: '0',
      desc: i18n.t('Todas'),
    },
    {
      id: '1',
      desc: i18n.t('Recebidas'),
    },
    {
      id: '2',
      desc: i18n.t('Efetuadas'),
    },
  ],

  // Variaveis gerais da listagem
  listviewInit: ref(1),
  listviewData: ref([]),
  positionListview: {},

  // Detalhe da proposta
  proposal: ref({}),
  flow: ref([]),
  visit: ref({}),
  agent: ref({}),
  listing: ref({}),
  listingInfo: ref({}),
  action: ref({}),
  iWhoami: ref(''),
  user: ref({}),
  agentExternal: ref({}),
  infoAgentExternal: ref({}),
  cargos: ref({}),
  imagesListing: ref({}),
  lastEntry: ref([]),
  proposalDisable: ref(false),
  salesProcessStatus: ref(''),
  notHaveUploadFiles: ref(''),
  dynamicIDVersion: ref(''),
  urlDocs: ref(''),
  imagePreview: ref(''),

  // Registo de atividade
  activity: ref({}),

  tabSelectFilter: ref('received'),
  loadingListview: ref(true),
  perPage: ref(25),
  totalRows: ref(0),
  currentPage: ref(1),
  perPageOptions: ref([10, 25, 50, 100]),
  searchTxt: ref(''),
  showListVisits: ref(false),
  showDetail: ref(false),
  showForm: ref(false),
  listviewOrderBy: ref({
    column: '4',
    sort: 'desc',
  }),
  listviewOrderBySent: ref({
    column: '5',
    sort: 'desc',
  }),
  listviewOrderByApprove: ref({
    column: '4',
    sort: 'desc',
  }),
  listviewOrderByReserve: ref({
    column: '3',
    sort: 'desc',
  }),
  listview_txt_default: i18n.t('Nenhum resultado disponível'),
  listiview_txt_not_find: i18n.t('Não encontrou nenhum resultado'),
  listiview_txt_search: i18n.t('ID proposta, ZMID ou Consultor'),
  listiview_txt_search_sent: i18n.t('ID proposta, ZMID ou Angariador'),
  listview_txt_loading: i18n.t('A carregar registos ...'),
  aFieldsOrder: [
    {
      name: '0',
      txt: i18n.t('Ordenar pelo ID'),
    },
    {
      name: '2',
      txt: i18n.t('Ordenar por estado'),
    },
    {
      name: '3',
      txt: i18n.t('Ordenar por data de início'),
    },
    {
      name: '4',
      txt: i18n.t('Ordenar por última negociação'),
    },
    {
      name: '5',
      txt: i18n.t('Ordenar por consultor'),
    },
  ],
  aFieldsOrderP: [
    {
      name: '0',
      txt: i18n.t('Ordenar pelo ID'),
    },
    {
      name: '2',
      txt: i18n.t('Ordenar por estado'),
    },
    {
      name: '3',
      txt: i18n.t('Ordenar por data de início'),
    },
    {
      name: '4',
      txt: i18n.t('Ordenar por última negociação'),
    },
    {
      name: '5',
      txt: i18n.t('Ordenar por consultor'),
    },
    {
      name: '6',
      txt: i18n.t('Ordenar por proposto'),
    },
  ],
  aFieldsOrderSent: [
    {
      name: '0',
      txt: i18n.t('Ordenar pelo ID'),
    },
    {
      name: '2',
      txt: i18n.t('Ordenar por estado'),
    },
    {
      name: '3',
      txt: i18n.t('Ordenar por Lead'),
    },
    {
      name: '4',
      txt: i18n.t('Ordenar por data de início'),
    },
    {
      name: '5',
      txt: i18n.t('Ordenar por última negociação'),
    },
    {
      name: '6',
      txt: i18n.t('Ordenar por consultor'),
    },
  ],
  aFieldsOrderReserve: [
    {
      name: '0',
      txt: i18n.t('Ordenar pelo ID'),
    },
    {
      name: '1',
      txt: i18n.t('Ordenar por criado'),
    },
    {
      name: '2',
      txt: i18n.t('Ordenar por valor referido'),
    },
    {
      name: '3',
      txt: i18n.t('Ordenar por data de expiração'),
    },
    {
      name: '4',
      txt: i18n.t('Ordenar por pedido'),
    },
  ],

  // Variaveis gerais da listagem das visitas
  listviewInitVisits: ref(1),
  listviewDataVisits: ref([]),
  positionListviewVisits: {},

  tabSelectFilterVisits: ref('3'),
  loadingListviewVisits: ref(true),
  perPageVisits: ref(25),
  totalRowsVisits: ref(0),
  currentPageVisits: ref(1),
  searchTxtVisits: ref(''),
  filterDataTabsListviewVisits: [
    {
      name: '3',
      text: i18n.t('Concluídas com relatório'),
    },
    {
      name: '1',
      text: i18n.t('Concluídas sem relatório'),
    },
  ],
  listviewOrderByVisits: ref({
    column: '4',
    sort: 'desc',
  }),
  listiviewVisits_txt_search: i18n.t('ZMID, angariador ou Lead'),
  aFieldsOrderVisits: [
    {
      name: '0',
      txt: i18n.t('Ordenar pelo ID'),
    },
    {
      name: '1',
      txt: i18n.t('Ordenar pelo angariador'),
    },
    {
      name: '2',
      txt: i18n.t('Ordenar pela Lead'),
    },
    {
      name: '3',
      txt: i18n.t('Ordenar por número telemóvel'),
    },
    {
      name: '4',
      txt: i18n.t('Ordenar pela data da visita'),
    },
  ],

  // States para guardar em localstorage
  historyKeyStorage: '',
  statesToLocalStorage: [
    'filterSelectedEstadoPropostasReceived',
    'filterSelectedEstadoPropostasSent',
    'filterSelectedEstadoPropostasApprove',
    'filterSelectedEstadoPropostasReserve',

    'tabSelectFilter',
    'currentPage',
    'searchTxt',
    'listviewOrderBy',
    'listviewOrderBySent',
    'listviewOrderByApprove',
    'listviewOrderByReserve',
    'positionListview',
  ],

})

export default defaultState
