import api from '@core-custom/api/api'
import apiConfig from '@core-custom/api/apiConfig'
import i18n from '@/libs/i18n'
import defaultState from './defaultState'

export default {
  namespaced: true,
  state: defaultState,
  getters: {
    showFormCreateNew: state => state.showFormCreateNew.value,
    platformsVideo: state => state.platformsVideo.value,
    videos: state => state.videos.value,
    showVideosEdition: state => state.showVideosEdition.value,
    filterSelected: state => ({
      videosID: state.filterSelectedVideosID,
    }),
  },
  mutations: {
    resetState(state) {
      Object.assign(state, defaultState)
    },
    setCreateNewSW079(state, payload) {
      state.showFormCreateNew.value = payload
    },
    setPlatformsVideo(state, payload) {
      state.platformsVideo.value = payload
    },
    setVideos(state, payload) {
      state.videos.value = payload
    },
    setShowVideosEdition(state, payload) {
      state.showVideosEdition.value = payload
    },
    setSelectedVideosID(state, payload) {
      state.filterSelectedVideosID.value = payload ? state.videos.value.map(row => row.id) : []
    },
  },
  actions: {
    async getNewFormVideo({ commit }) {
      return new Promise((resolve, reject) => {
        api.post(`${apiConfig.url_base_api}listings/newFormVideo`)
          .then(response => {
            try {
              if (response.data.data !== undefined) {
                commit('setPlatformsVideo', response.data.data.platformsVideo)
                resolve(true)
              } else {
                reject(new Error(i18n.t('Problema ao carregar o formulário')))
              }
            } catch (error) {
              if ((error.response !== undefined) && (error.response.status !== undefined)) {
                reject(new Error(i18n.t('Problema ao carregar o formulário')))
              }
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao carregar o formulário')))
            }
          })
      })
    },

    async saveSW079({ rootGetters }, payload) {
      return new Promise((resolve, reject) => {
        const formData = new FormData()
        formData.append('keyReg', rootGetters['listings_form/keyReg'])
        formData.append('keyDynamic', rootGetters['listings_form/dynamicIDForm'])
        formData.append('versionDynamic', rootGetters['listings_form/dynamicIDVersion'])
        formData.append('versionReg', rootGetters['listings_form/idVersionReg'])
        formData.append('sw079s09', payload.plataforma.sw089s01)
        formData.append('sw079s05', payload.descricao)
        formData.append('sw079s04', payload.link)

        api.post(`${apiConfig.url_base_api}listings/saveFormVideo`, formData)
          .then(response => {
            try {
              if (typeof response.data === 'object') {
                if (response.data.error === 1) {
                  reject(new Error(response.data.msg))
                } else {
                  resolve({ message: response.data.msg })
                }
              } else {
                reject(new Error(i18n.t('Problema ao guardar o vídeo')))
              }
            } catch (error) {
              if ((error.response !== undefined) && (error.response.status !== undefined)) {
                reject(new Error(i18n.t('Problema ao guardar o vídeo')))
              }
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao guardar o vídeo')))
            }
          })
      })
    },

    async loadVideos({ rootGetters, dispatch }) {
      const formData = new FormData()

      formData.append('keyReg', rootGetters['listings_form/keyReg'])
      formData.append('keyDynamic', rootGetters['listings_form/dynamicIDForm'])

      if (rootGetters['listings_form/formModeView'] === true) {
        formData.append('modeForm', 1)
      } else {
        formData.append('modeForm', '')
      }

      formData.append('versionDynamic', rootGetters['listings_form/dynamicIDVersion'])
      formData.append('versionReg', rootGetters['listings_form/idVersionReg'])
      formData.append('loadAjax', '')

      dispatch('request/cancelTokensPending', null, { root: true })

      return new Promise((resolve, reject) => {
        api.post(`${apiConfig.url_base_api}listings/loadVideos`, formData)
          .then(response => {
            if (typeof response.data === 'object') {
              resolve(response.data)
            } else {
              reject(new Error(i18n.t('Problema ao carregar vídeos')))
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao carregar vídeos')))
            }
          })
      })
    },

    async updateDescVideo({ rootGetters, dispatch }, payload) {
      const formData = new FormData()

      formData.append('regVideo', payload.regVideo)
      formData.append('txtDesc', payload.txtDesc)
      formData.append('versionDynamic', rootGetters['listings_form/dynamicIDVersion'])
      formData.append('versionReg', rootGetters['listings_form/idVersionReg'])

      dispatch('request/cancelTokensPending', null, { root: true })

      return new Promise((resolve, reject) => {
        api.post(`${apiConfig.url_base_api}listings/updateDescVideo`, formData)
          .then(response => {
            if (typeof response.data === 'object') {
              resolve(response.data)
            } else {
              reject(new Error(i18n.t('Problema a atualizar descrição do vídeo')))
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema a atualizar descrição do vídeo')))
            }
          })
      })
    },

    async changeOrderVideos({ rootGetters, dispatch }, payload) {
      const formData = new FormData()
      let posVideo = ''

      payload.videos.forEach(element => {
        posVideo += `${element.id},`
      })

      formData.append('keyReg', rootGetters['listings_form/keyReg'])
      formData.append('versionDynamic', rootGetters['listings_form/dynamicIDVersion'])
      formData.append('posVideo', posVideo)

      dispatch('request/cancelTokensPending', null, { root: true })

      return new Promise((resolve, reject) => {
        api.post(`${apiConfig.url_base_api}listings/updateOrderVideos`, formData)
          .then(response => {
            if (typeof response.data === 'object') {
              resolve(response.data)
            } else {
              reject(new Error(i18n.t('Problema ao alterar a ordem dos vídeos')))
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao alterar a ordem dos vídeos')))
            }
          })
      })
    },

    async deleteVideos({ dispatch }, payload) {
      const formData = new FormData()

      if (Array.isArray(payload.regDel)) {
        payload.regDel.forEach(idVideo => {
          formData.append('regDel[]', idVideo)
        })
      } else {
        formData.append('regDel', payload.regDel)
      }

      dispatch('request/cancelTokensPending', null, { root: true })

      return new Promise((resolve, reject) => {
        api.post(`${apiConfig.url_base_api}listings/deleteVideos`, formData)
          .then(response => {
            if (typeof response.data === 'object') {
              resolve(response.data)
            } else {
              reject(new Error(i18n.t('Problema ao eliminar as imagens')))
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao eliminar as imagens')))
            }
          })
      })
    },

    createNewSW079({ commit }) {
      commit('setCreateNewSW079', true)
    },

    setShowFormCreateNew({ commit }, value) {
      commit('setCreateNewSW079', value)
    },

    setVideoIDSelected({ commit }, idVideo) {
      commit('setSelectedVideosID', idVideo)
    },

  },
}
