import { render, staticRenderFns } from "./contratos.vue?vue&type=template&id=0f6e2318&scoped=true"
import script from "./contratos.vue?vue&type=script&lang=js"
export * from "./contratos.vue?vue&type=script&lang=js"
import style0 from "./contratos.vue?vue&type=style&index=0&id=0f6e2318&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0f6e2318",
  null
  
)

export default component.exports