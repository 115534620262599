import api from '@core-custom/api/api'
import apiConfig from '@core-custom/api/apiConfig'
import i18n from '@/libs/i18n'
import defaultState from './defaultState'

export default {
  namespaced: true,
  state: defaultState,
  getters: {
    listviewInit: state => state.listviewInit.value,
    listviewData: state => state.listviewData.value,
    listviewOrderBy: state => state.listviewOrderBy.value,
    totalRows: state => state.totalRows.value,
    perPage: state => state.perPage.value,
    loadingListview: state => state.loadingListview.value,
    currentPage: state => state.currentPage.value,
    searchTxt: state => state.searchTxt.value,
    fieldsOrder: state => state.aFieldsOrder,
    sortByOptions: state => state.sortByOptions,
    totalRegs: state => state.totalRegs.value,
    listview_txt_default: state => ({
      txt: state.listview_txt_default,
      txt_not_find: state.listiview_txt_not_find,
      txt_loading: state.listview_txt_loading,
    }),
    showPager: state => {
      if (state.totalRows.value > state.perPage.value) {
        return true
      }

      return false
    },

    listviewInitDesign: state => state.listviewInitDesign.value,
    listviewDataDesign: state => state.listviewDataDesign.value,
    listviewOrderByDesign: state => state.listviewOrderByDesign.value,
    totalRowsDesign: state => state.totalRowsDesign.value,
    perPageDesign: state => state.perPageDesign.value,
    loadingListviewDesign: state => state.loadingListviewDesign.value,
    currentPageDesign: state => state.currentPageDesign.value,
    searchTxtDesign: state => state.searchTxtDesign.value,
    fieldsOrderDesign: state => state.aFieldsOrderDesign,
    sortByOptionsDesign: state => state.sortByOptionsDesign,
    totalRegsDesign: state => state.totalRegsDesign.value,
    listview_txt_default_design: state => ({
      txt: state.listview_txt_default_design,
      txt_not_find: state.listiview_txt_not_find_design,
      txt_loading: state.listview_txt_loading_design,
    }),

    showPagerDesign: state => {
      if (state.totalRowsDesign.value > state.perPageDesign.value) {
        return true
      }

      return false
    },
  },
  mutations: {
    setLoadingListview(state, payload) {
      state.loadingListview.value = payload
    },
    setListviewData(state, payload) {
      state.listviewData.value = payload
    },
    setTotalRows(state, payload) {
      state.totalRows.value = payload
    },
    setSearchTxt(state, payload) {
      state.searchTxt.value = payload
    },
    setCurrentPage(state, payload) {
      state.currentPage.value = payload
    },
    setListviewOrderBy(state, payload) {
      state.listviewOrderBy.value = {
        sort: payload.sort,
        column: payload.column,
      }
    },
    setListviewInit(state, payload) {
      state.listviewInit.value = payload
    },
    setTotalRegs(state, payload) {
      state.totalRegs.value = payload
    },

    setLoadingListviewDesign(state, payload) {
      state.loadingListviewDesign.value = payload
    },
    setListviewDataDesign(state, payload) {
      state.listviewDataDesign.value = payload
    },
    setTotalRowsDesign(state, payload) {
      state.totalRowsDesign.value = payload
    },
    setSearchTxtDesign(state, payload) {
      state.searchTxtDesign.value = payload
    },
    setCurrentPageDesign(state, payload) {
      state.currentPageDesign.value = payload
    },
    setListviewOrderByDesign(state, payload) {
      state.listviewOrderByDesign.value = {
        sort: payload.sort,
        column: payload.column,
      }
    },
    setListviewInitDesign(state, payload) {
      state.listviewInitDesign.value = payload
    },
    setTotalRegsDesign(state, payload) {
      state.totalRegsDesign.value = payload
    },
  },
  actions: {
    // Carregar listagem de atividades
    fetchListView({
      state, commit, dispatch, rootGetters,
    }, payload) {
      const listviewFilterParams = new FormData()

      listviewFilterParams.append('sw012s01', rootGetters['listings_form/keyReg'])
      listviewFilterParams.append('start', ((state.currentPage.value - 1) * state.perPage.value))
      listviewFilterParams.append('length', state.perPage.value)

      if (state.searchTxt.value !== '') {
        listviewFilterParams.append('search[value]', state.searchTxt.value)
        listviewFilterParams.append('search[regex]', false)
      }

      if (state.listviewOrderBy.value.column !== undefined) {
        listviewFilterParams.append('order[0][column]', state.listviewOrderBy.value.column)
      }

      if (state.listviewOrderBy.value.sort !== undefined) {
        listviewFilterParams.append('order[0][dir]', state.listviewOrderBy.value.sort)
      }

      if (!!payload && (payload.loadingDisable !== undefined) && (payload.loadingDisable === true)) {
        // Nao executa o loading
      } else {
        commit('setLoadingListview', true)
      }

      return new Promise((resolve, reject) => { // reject
        dispatch('request/cancelTokensPending', null, { root: true })

        if (rootGetters['listings_form/keyReg'] !== '') {
          api.post(`${apiConfig.url_base_api}listings/historyActivity/loadByListing`, listviewFilterParams)
            .then(response => {
              commit('setListviewData', response.data.data)
              commit('setTotalRegs', response.data.recordsTotal)
              commit('setTotalRows', response.data.recordsTotal)
              commit('setLoadingListview', false)
              commit('setListviewInit', 0)
              resolve(true)
            })
            .catch(error => {
              commit('setLoadingListview', false)
              if ((error.response !== undefined) && (error.response.status !== undefined)) {
                reject(new Error(i18n.t('Problema ao carregar listagem')))
              }
            })
        } else {
          commit('setTotalRegs', 0)
          commit('setTotalRows', 0)
          commit('setLoadingListview', false)
          commit('setListviewInitDesign', 0)
          resolve(true)
        }
      })
    },

    // Carregar listagem de Design
    fetchListViewDesign({
      state, commit, dispatch, rootGetters,
    }, payload) {
      const listviewFilterParams = new FormData()

      listviewFilterParams.append('sw012s01', rootGetters['listings_form/keyReg'])
      listviewFilterParams.append('start', ((state.currentPageDesign.value - 1) * state.perPageDesign.value))
      listviewFilterParams.append('length', state.perPageDesign.value)

      if (state.searchTxtDesign.value !== '') {
        listviewFilterParams.append('search[value]', state.searchTxtDesign.value)
        listviewFilterParams.append('search[regex]', false)
      }

      if (state.listviewOrderByDesign.value.column !== undefined) {
        listviewFilterParams.append('order[0][column]', state.listviewOrderByDesign.value.column)
      }

      if (state.listviewOrderByDesign.value.sort !== undefined) {
        listviewFilterParams.append('order[0][dir]', state.listviewOrderByDesign.value.sort)
      }

      if (!!payload && (payload.loadingDisable !== undefined) && (payload.loadingDisable === true)) {
        // Nao executa o loading
      } else {
        commit('setLoadingListviewDesign', true)
      }

      return new Promise((resolve, reject) => { // reject
        dispatch('request/cancelTokensPending', null, { root: true })

        if (rootGetters['listings_form/keyReg'] !== '') {
          api.post(`${apiConfig.url_base_api}listings/historyActivity/loadDesignToolByListing`, listviewFilterParams)
            .then(response => {
              commit('setListviewDataDesign', response.data.data)
              commit('setTotalRegsDesign', response.data.recordsTotal)
              commit('setTotalRowsDesign', response.data.recordsTotal)
              commit('setLoadingListviewDesign', false)
              commit('setListviewInitDesign', 0)
              resolve(true)
            })
            .catch(error => {
              commit('setLoadingListview', false)
              if ((error.response !== undefined) && (error.response.status !== undefined)) {
                reject(new Error(i18n.t('Problema ao carregar listagem')))
              }
            })
        } else {
          commit('setTotalRegsDesign', 0)
          commit('setTotalRowsDesign', 0)
          commit('setLoadingListviewDesign', false)
          commit('setListviewInitDesign', 0)
          resolve(true)
        }
      })
    },

    pullToRefreshListview({ commit, dispatch }) {
      commit('setCurrentPage', 1)
      return dispatch('fetchListView', { loadingDisable: true })
    },

    // Ordenação da listagem
    listviewFilterOrderBy({ state, commit, dispatch }, field) {
      if ((!!state.listviewOrderBy.value.column) && (state.listviewOrderBy.value.column === field)) {
        if (state.listviewOrderBy.value.sort === 'asc') {
          commit('setListviewOrderBy', {
            sort: 'desc',
            column: field,
          })
        } else {
          commit('setListviewOrderBy', {
            sort: 'asc',
            column: field,
          })
        }
      } else {
        commit('setListviewOrderBy', {
          sort: 'asc',
          column: field,
        })
      }

      return dispatch('fetchListView')
    },

    pullToRefreshListviewDesign({ commit, dispatch }) {
      commit('setCurrentPageDesign', 1)
      return dispatch('fetchListViewDesign', { loadingDisable: true })
    },

    // Ordenação da listagem
    listviewFilterOrderByDesign({ state, commit, dispatch }, field) {
      if ((!!state.listviewOrderByDesign.value.column) && (state.listviewOrderByDesign.value.column === field)) {
        if (state.listviewOrderByDesign.value.sort === 'asc') {
          commit('setListviewOrderByDesign', {
            sort: 'desc',
            column: field,
          })
        } else {
          commit('setListviewOrderByDesign', {
            sort: 'asc',
            column: field,
          })
        }
      } else {
        commit('setListviewOrderByDesign', {
          sort: 'asc',
          column: field,
        })
      }

      return dispatch('fetchListViewDesign')
    },

    setSearchTxt({ commit, dispatch }, payload) {
      commit('setSearchTxt', payload)
      dispatch('fetchListView')
    },

    setCurrentPage({ commit, dispatch }, value) {
      commit('setCurrentPage', value)
      return dispatch('fetchListView')
    },

    setSearchTxtDesign({ commit, dispatch }, payload) {
      commit('setSearchTxtDesign', payload)
      dispatch('fetchListViewDesign')
    },

    setCurrentPageDesign({ commit, dispatch }, value) {
      commit('setCurrentPageDesign', value)
      return dispatch('fetchListViewDesign')
    },
  },
}
