<template>
  <div class="email-app-details">
    <!-- Share Header -->
    <div class="email-detail-header">
      <!-- Header: Left -->
      <div class="email-header-left d-flex align-items-center">
        <span class="go-back mr-1">
          <feather-icon
            :icon="$store.state.appConfig.isRTL ? 'ChevronRightIcon' : 'ChevronLeftIcon'"
            size="20"
            class="align-bottom"
            @click="$emit('close-photos-edition')"
          />
        </span>
        <h4 class="email-subject mb-0">
          <b-row>
            <b-col
              md="12"
              class="agent"
            >
              {{ $t('Fotografias') }}
            </b-col>
          </b-row>
        </h4>
      </div>
    </div>
    <div class="email-app-list ecommerce-application position-relative space-bottom">

      <!-- Barra de ações dos registos da listagem -->
      <div
        v-if="images.length > 0"
        class="app-action"
      >
        <div class="action-left">
          <b-form-checkbox
            :checked="selectAllImagesCheckbox"
            :indeterminate="isselectAllImagesCheckboxIndeterminate"
            @change="selectAllCheckboxUpdate"
          >
            {{ $t('Selecionar tudo') }}
          </b-form-checkbox>
        </div>
        <div
          v-show="filterSelected.imagesID.value.length"
          class="align-items-center"
          :class="{'d-flex': filterSelected.imagesID.value.length}"
        >
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-danger"
            class="mr-1"
            @click="deleteAction"
          >
            <feather-icon
              icon="TrashIcon"
              class="mr-50"
            />
            <span class="align-middle">{{ $t('Eliminar selecionadas') }}</span>
          </b-button>

        </div>
      </div>

      <vue-perfect-scrollbar
        ref="images"
        class="email-user-list scroll-area"
        :settings="configSettingsScroll"
      >

        <draggable
          v-if="images.length > 0"
          v-model="images"
          class="email-media-list"
          tag="ul"
          handle=".handle"
          @start="dragging = true"
          @end="dragging = false"
          @change="orderAction"
        >
          <b-media
            v-for="(row) in images"
            :key="row.id"
            tag="li"
            no-body
          >
            <div
              class="d-inline mr-75 handle"
            >
              <div class="d-flex align-items-center">
                <div class="d-flex align-items-center mr-1">
                  <feather-icon
                    icon="MenuIcon"
                    :size="sizeIconsAction"
                    :title="$t('Mover imagem')"
                  />
                </div>

                <b-form-checkbox
                  :checked="filterSelected.imagesID.value.includes(row.id)"
                  :style="[{ display: 'inline-block' }]"
                  @change="toggleSelectedImage(row.id)"
                  @click.native.stop
                />
                <b-avatar
                  v-b-modal.modal-xl-image
                  size="104px"
                  variant="light-primary"
                  class="mr-1"
                  :src="row.src_image + '?v=' + new Date().getTime()"
                  rounded
                />
                <feather-icon
                  v-if="mainImages.length > 0 && mainImages.includes(row.idFixed)"
                  v-b-tooltip.hover.top="(typeof imagesPrin !== 'undefined' && typeof imagesPrin.datasByID !== 'undefined' && (row.idFixed in imagesPrin.datasByID) ? imagesPrin.datasByID[row.idFixed] : '')"
                  icon="StarIcon"
                  class="text-warning mr-1"
                  width="20px"
                  height="20px"
                />
                <h5
                  v-if="row.id_tag !== null && row.id_tag.sw286s02"
                  class="mt-25"
                >
                  {{ row.id_tag.sw286s02 }}
                </h5>
              </div>
            </div>
          </b-media>
        </draggable>
      </vue-perfect-scrollbar>

    </div>

    <!-- modal -->
    <b-modal
      id="modal-xl-image"
      centered
      size="xl"
      :hide-footer="true"
    >
      <swiper
        class="swiper"
        :options="swiperOptions"
        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
        :style="{'--swiper-navigation-color': '#000','--swiper-pagination-color': '#fff'}"
      >
        <swiper-slide
          v-for="data in images"
          :key="data.src_image + '?v=' + new Date().getTime()"
        >
          <div class="swiper-zoom-container">
            <b-img
              :src="data.src_image + '?v=' + new Date().getTime()"
              class="w-75 image-swiper"
              fluid
            />
          </div>
        </swiper-slide>
        <!-- Add Arrows -->
        <div
          slot="button-next"
          class="swiper-button-next"
        />
        <div
          slot="button-prev"
          class="swiper-button-prev"
        />
        <div
          slot="pagination"
          class="swiper-pagination swiper-pagination-white"
        />
      </swiper>
    </b-modal>

  </div>

</template>

<script>
import store from '@/store'
import {
  BMedia, BAvatar, VBTooltip, BCol, BRow, BFormCheckbox, BButton, BModal, VBModal, BImg,
} from 'bootstrap-vue'
import VuePerfectScrollbar from '@/views/modules/components/vue-perfect-scrollbar.vue'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import draggable from 'vuedraggable'
import Ripple from 'vue-ripple-directive'
import { sizeIconsAction } from '@core-custom/mixins/geral'
import { mapGetters } from 'vuex'
import { configSettingsScroll } from '@core-custom/utils/ui'
import { mixFormGeral } from '../../../mixins'

export default {
  components: {
    BCol,
    BRow,
    BMedia,
    BAvatar,
    BButton,
    BFormCheckbox,
    BModal,
    BImg,
    Swiper,
    SwiperSlide,
    VuePerfectScrollbar,
    draggable,

  },
  directives: {
    'b-modal': VBModal,
    'b-tooltip': VBTooltip,
    Ripple,
  },
  mixins: [mixFormGeral, sizeIconsAction],
  props: {
    mainImages: {
      type: Array,
      default: () => [],
    },
    deleteAction: {
      type: Function,
      required: true,
    },
    orderAction: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      swiperOptions: {
        zoom: true,
        pagination: {
          el: '.swiper-pagination',
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      },
      dragging: false,
    }
  },
  computed: {
    ...mapGetters('sw014', ['images', 'imagesPrin', 'filterSelected']),
    images: {
      get() {
        return this.$store.getters['sw014/images']
      },
      set(value) {
        this.$store.commit('sw014/setImagens', value)
      },
    },
    isselectAllImagesCheckboxIndeterminate() {
      return (Boolean(this.filterSelected.imagesID.value.length) && this.images.length !== this.filterSelected.imagesID.value.length)
    },
    selectAllImagesCheckbox() {
      return this.images.length && (this.images.length === this.filterSelected.imagesID.value.length)
    },
  },
  methods: {
    selectAllCheckboxUpdate(val) {
      store.dispatch('sw014/setImageIDSelected', val)
    },
    toggleSelectedImage(idImage) {
      const indexImage = this.filterSelected.imagesID.value.indexOf(idImage)
      if (indexImage === -1) {
        this.filterSelected.imagesID.value.push(idImage)
      } else {
        this.filterSelected.imagesID.value.splice(idImage, 1)
      }
    },
  },
  setup() {
    return {
      configSettingsScroll,
    }
  },
}
</script>

<style lang="scss" scoped>

.email-app-list .email-app-details {
  width: 100%!important;
}

.email-app-details .email-user-list {
  height: calc( 100% - calc(0.1rem) )!important;
}

.swiper-container {
  width: 100%;
  height: 100%;
}

.swiper .swiper-slide {
  background: rgb(255, 255, 255);
}

.capacitor{
  .email-user-list ::v-deep{
    .email-media-list li {
      animation:none!important;
      animation-fill-mode:none!important;
    }
  }
}

</style>
