<template>
  <div>

    <div
      class="email-app-list position-relative height-100-panel"
    >
      <div
        class="app-fixed-search d-flex align-items-center"
      >
        <div class="d-flex align-items-center flex-grow-1 w-50">

          <div class="sidebar-toggle d-block d-lg-none ml-1">
            <feather-icon
              icon="MenuIcon"
              :size="sizeIconsAction"
              class="cursor-pointer"
              @click="mqShallShowLeftSidebar = true"
            />
          </div>

        </div>
        <div class="d-flex align-items-center w-50 justify-content-end">

          <b-button
            v-if="formModeView === false"
            variant="flat-secondary"
            class="border-radius-none"
            :class="{ 'btn-sm' : ((this.$store.getters['app/currentBreakPoint'] === 'xs') ? true: false)}"
            @click="$refs.refDocsListings.$el.click()"
          >
            <feather-icon
              icon="PlusIcon"
              :size="sizeIconsAction"
            />
          </b-button>

          <footer-step-nav ref="footerStepNav" />

        </div>
      </div>

      <vue-perfect-scrollbar
        class="scroll-content"
        :settings="configSettingsScroll"
      >
        <div
          class="app-fixed-search d-flex align-items-center"
          :class="{'border-0' : ((documentos.length > 0) ? false : true)}"
        >
          <div class="d-flex flex-column align-items-center">

            <div class="d-flex align-items-center justify-content-start w-100">
              <h4 class="p-1 m-0">
                {{ $t('Documentos para a rede') }}
              </h4>
            </div>
            <div
              v-if="formModeView === false"
              class="d-flex align-items-center justify-content-start w-100 pl-1 pr-1 pb-1"
            >
              <b-button
                variant="outline-primary"
                @click="$refs.refDocsListings.$el.click()"
              >
                <span class="align-middle">{{ $t('Carregar documentos') }}</span>
              </b-button>
              <b-form-file
                ref="refDocsListings"
                accept="*"
                :hidden="true"
                plain
                multiple
                @input="uploadDocRender"
              />
            </div>

          </div>
        </div>
        <b-container
          class="p-2"
          fluid
        >
          <b-row>
            <b-col md="12">
              <b-alert
                show
                variant="warning"
              >
                <div class="alert-body">
                  <feather-icon
                    class="mr-25"
                    icon="AlertTriangleIcon"
                  />
                  <span class="ml-25"><strong>{{ $t('Atenção!') }}!</strong> {{ $t('Todos os documentos aqui carregados serão dispobilizados para os seus colegas na rede.') }} <strong>{{ $t("Para carregar documentos privados, use o separador 'privado'.") }}</strong></span>
                </div>
              </b-alert>
            </b-col>
          </b-row>
          <b-row
            v-if="documentos.length === 0"
          >
            <b-col md="12">
              <b-alert
                show
                variant="danger"
              >
                <div class="alert-body">
                  <span class="ml-25">{{ $t('Nenhum documento inserido') }}</span>
                </div>
              </b-alert>
            </b-col>
          </b-row>
          <docs-list
            v-for="(rowSW035, index) in documentos"
            :key="`item-sw281-${index}`"
            :class="{'show': true, 'mt-2': true }"
            :row="rowSW035"
            :index="index"
            :delete-action="deleteDocuments"
            :update-action="updateInformDoc"
            :view="formModeView"
          />
        </b-container>
      </vue-perfect-scrollbar>

    </div>

  </div>
</template>

<script>
import store from '@/store'
import {
  BContainer, BAlert, BButton, BFormFile, BRow, BCol,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import modelSW035 from '@store-modules/listings/sw035'
import { mapGetters } from 'vuex'
import VuePerfectScrollbar from '@/views/modules/components/vue-perfect-scrollbar.vue'
import { ref, onUnmounted } from '@vue/composition-api'
import { sizeIconsAction } from '@core-custom/mixins/geral'
import { mask } from 'vue-the-mask'
import { showModalDeleteMessage } from '@core/utils/utils'
import { configSettingsScroll } from '@core-custom/utils/ui'
import { mixFormGeral } from '../../mixins'
import FooterStepNav from '../footerStepNavTop.vue'
import DocsList from './sw035/list.vue'

export default {
  components: {
    BContainer,
    BAlert,
    BButton,
    BFormFile,
    BRow,
    BCol,
    VuePerfectScrollbar,
    FooterStepNav,
    DocsList,
  },
  directives: {
    Ripple,
    mask,
  },
  mixins: [sizeIconsAction, mixFormGeral],
  data() {
    return {
      resumeErrorUpload: [],
    }
  },
  computed:
  {
    ...mapGetters('sw035', ['documentos']),
    ...mapGetters('listings_form', ['formModeView']),
  },
  methods: {
    async uploadDocRender(aFiles) {
      if (aFiles.length > 0) {
        try {
          this.$root.$emit('app::loading', true)
          await store.dispatch('sw035/uploadSW035', { files: aFiles, private: 0 }).then(result => {
            this.resetFieldUpload()

            Object.keys(result).forEach(index => {
              if (result[index].error === false) {
                this.resumeErrorUpload.push(result[index])
              }
            })

            this.loadDocuments()
          }).catch(error => {
            this.resetFieldUpload()
            this.showMsgErrorRequest(error)
          })
        } catch (err) {
          this.resetFieldUpload()
        }
      }
    },
    async loadDocuments() {
      await store.dispatch('sw035/loadDocumentos', { private: 0 }).then(response => {
        if (typeof response === 'object') {
          this.$store.commit('sw035/setDocumentos', response.docsAPI)
        } else {
          this.showMsgErrorRequest(this.$t('Problema a carregar os documentos'))
        }
      }).catch(error => {
        this.showMsgErrorRequest(error)
      })
    },
    async deleteDocuments(idDoc) {
      const self = this

      try {
        if (idDoc) {
          const actionModal = new Promise(resolve => {
            showModalDeleteMessage(resolve, `${this.$t('Pretende eliminar o documento?')}<br>${this.$t('Esta ação é irreversível!')}`)
          })

          await actionModal.then(result => {
            if (result === 'confirm') {
              self.$root.$emit('app::loading', true)

              store.dispatch('sw035/deleteDocumentos', {
                regDel: idDoc,
              }).then(response => {
                if (response.error === 1) {
                  self.$root.$emit('app::loading', false)
                  self.showMsgErrorRequest(self.$t('Problema a eliminar documento'))
                } else {
                  self.loadDocuments()
                  self.showMsgSuccessRequest({ message: response.msg })
                  self.$root.$emit('app::loading', false)
                }
              }).catch(error => {
                self.$root.$emit('app::loading', false)
                self.showMsgErrorRequest(error)
              })
            }
          })
        }
      } catch (err) {
      //
      }
    },
    updateInformDoc(data) {
      const self = this

      this.$store.dispatch('sw035/updateInformDoc', data).then(result => {
        if (result.error === 1) {
          self.showMsgErrorRequest(result)
        }
      }).catch(error => {
        self.showMsgErrorRequest(error)
      })
    },
    resetFieldUpload() {
      this.$root.$emit('app::loading', false)
      this.resumeErrorUpload = []
      this.$refs.refDocsListings.reset()
    },
  },
  setup() {
    const refDocsListings = ref(null)
    const MODEL_SW035 = 'sw035'

    if (!store.hasModule(MODEL_SW035)) {
      store.registerModule(MODEL_SW035, modelSW035)

      onUnmounted(() => {
        if (store.hasModule(MODEL_SW035)) store.unregisterModule(MODEL_SW035)
      })
    }

    return {
      refDocsListings,
      configSettingsScroll,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss" scoped>
@import '@core/scss/base/bootstrap-extended/include';
@import '@core/scss/base/components/include';

.email-application .content-area-wrapper .content-right .email-app-list {
  height: calc( 100% - calc(11.1rem) )!important;
}

</style>
