<template>
  <app-timeline class="mt-1 ml-3 mr-1">
    <app-timeline-item
      v-for="(row) in listviewData"
      :key="row.id"
      no-body
      icon="HomeIcon"
      variant="primary"
    >
      <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
        <small class="text-muted">{{ row.date }}</small>
      </div>
      <div class="d-flex flex-sm-row flex-column justify-content align-items-start">
        <!-- 1st Col -->
        <div class="mb-1 mr-2 mb-sm-0">
          <span class="text-muted mb-50 d-block">{{ $t('Título do trabalho') }}</span>
          <span>{{ row.title }}</span>
        </div>
        <div class="mb-1 mr-2 mb-sm-0">
          <span class="text-muted mb-50 d-block">{{ $t('Modelo') }}</span>
          <span>{{ row.model }}</span>
        </div>
        <div class="mb-1 mr-2 mb-sm-0">
          <span class="text-muted mb-50 d-block">{{ $t('Registado por') }}</span>
          <span>{{ row.createBy }}</span>
        </div>
        <div class="mb-1 mr-1 mb-sm-0">
          <span class="text-muted mb-50 d-block">{{ $t('Quantidade') }}</span>
          <span>{{ row.quantity }}</span>
        </div>
      </div>
    </app-timeline-item>
  </app-timeline>
</template>

<script>
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    AppTimeline,
    AppTimelineItem,

  },
  directives: {
    Ripple,
  },
  props: {
    listviewData: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
    }
  },
  computed: {
  },
  methods: {
  },
}
</script>
